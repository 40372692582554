import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SidePanel, ProfileHeader } from "@components";
import { actions } from '../../_redux/store';
import { FieldGroup, FormBuilder, Validators } from "react-reactive-form";
import { useParams } from "react-router-dom";
import FormSelect from "../../reusablecomponets/FormSelect";
import FormRadio from "../../reusablecomponets/FormRadio";
import CreatableSelect from "../../reusablecomponets/CreatableSelect";
import { decryptText, validStringValidator } from "../../helpers/DynamicFunctions";
import reducersAndActions from "../../_redux/slices/index";
import Preloader from '../../plugins/Preloader';
import FormTextarea from '../../reusablecomponets/FormTextArea';
import { PeakSeasonOptions, LowSeasonOptions, CurrentEmployeesOptions, LastRenovationOptions } from '../../screens/loan-app-tabs/personalProfile/commonFunction';
import Constants from '../../helpers/Constants';
import FormField from '../../components/FormField/FormField';
import OpenAIComponent from '../../components/OpenAI/OpenAIComponent';
import { JSONParseApiResponse } from '../../services/utilServices';
import WeeklyTimeComponent from '../../components/WeeklyTime/WeeklyTImeComponent';

let tempDaySchedule = {};
function IndustryHotel() {
    const dispatch = useDispatch();
    const params = useParams();
    const parsedLoanId = decryptText(params.loanId);

    const [form, setForm] = React.useState(
        FormBuilder.group({
            id: [0],
            loanId: [parsedLoanId],
            geographicMarket: ["", [Validators.required, validStringValidator]],
            suppliersVendorTerms: ["", [Validators.required, validStringValidator]],
            customerVendors: ["", [Validators.required, validStringValidator]],
            keyCompetitors: ["", [Validators.required, validStringValidator]],
            competitiveAdvantages: ["", [Validators.required, validStringValidator]],
            seasonalBusiness: [""],
            numberAfterLoan: [""],
            numberOfRooms: ["", [Validators.required, validStringValidator]],
            numberOfFloors: ["", [Validators.required, validStringValidator]],
            elevatorService: [""],
            lastRenovation: [""],
            performed: [""],
            hotelOrMotel: [""],
            peakSeason: [""],
            lowSeason: [""],
            fullOrLimitedService: [""],
            additionalComments: [""],
            propertyServiceLevel: [""],
            modifiedBy: [0],
            learnAboutHotel: ["", [Validators.required, validStringValidator]],
        })
    );



    useEffect(() => {
        document.title = "Hotel Information - Loan Application";
        setShowErrorMessage(false);
        setSelectOptions({ geographicMarket: [], customerVendors: [], peakSeason: [], lowSeason: [] });
        dispatch(actions.hotelForm.fetchHotelData({ loanId: parsedLoanId, type: 'Hotel' }));
    }, [dispatch, parsedLoanId]);


    const { data, loading, error } = useSelector((state) => state.hotelForm);

    const [dayTimeSchedule, setDayTimeSchedule] = useState({ monday: {}, tuesday: {}, wednesday: {}, thursday: {}, friday: {}, saturday: {}, sunday: {} });
    const [selectOptions, setSelectOptions] = useState({ geographicMarket: [], customerVendors: [], lowSeason: [], peakSeason: [] });
    const [showErrorMessage, setShowErrorMessage] = useState(false);

    useEffect(() => {
        try {
            if (data && data.resultObject && Object.keys(data.resultObject).length > 0) {
                const resultObject = data.resultObject
                console.log(resultObject)

                const geographicMarketOptions = resultObject.geographicMarket ? resultObject.geographicMarket.split(',').map(value => ({ label: value, value })) : [];
                const customerVendorsOptions = resultObject.customerVendors ? resultObject.customerVendors.split(',').map(value => ({ label: value, value })) : [];
                const peakSeasonOptions = resultObject.peakSeason ? resultObject.peakSeason.split(',').map(value => ({ value, label: PeakSeasonOptions.find(option => option.value === value)?.label || value })) : [];
                const lowSeasonOptions = resultObject.lowSeason ? resultObject.lowSeason.split(',').map(value => ({ value, label: LowSeasonOptions.find(option => option.value === value)?.label || value })) : [];

                setSelectOptions({
                    geographicMarket: geographicMarketOptions,
                    customerVendors: customerVendorsOptions,
                    peakSeason: peakSeasonOptions,
                    lowSeason: lowSeasonOptions
                });

                form.patchValue({
                    ...resultObject,
                    loanId: resultObject.loanId || parsedLoanId,
                    seasonalBusiness: resultObject.seasonalBusiness === 'true' ? 'true' : 'false',
                    elevatorService: resultObject.elevatorService === 'true' ? 'true' : 'false',
                    fullOrLimitedService: resultObject.fullOrLimitedService || 'Full service',
                    hotelOrMotel: resultObject.hotelOrMotel || 'Interior corridor',
                    propertyServiceLevel: resultObject.propertyServiceLevel || 'Full service',
                });

                const dayTimeSchedule = resultObject.lstHoursOperation.reduce((acc, item) => {
                    acc[item.daysName] = item;
                    return acc;
                }, {});

                setDayTimeSchedule((prev) => ({ ...prev, ...dayTimeSchedule }));
            } else {
                form.reset({ id: 0, loanId: parsedLoanId, modifiedBy: 0, seasonalBusiness: 'false', elevatorService: 'false', fullOrLimitedService: 'Full service' });
            }
        } catch (error) {
            console.error("Error parsing or updating form:", error);

        }
    }, [data, setSelectOptions, form]);

    useEffect(() => {
        tempDaySchedule = { ...dayTimeSchedule };
    }, [dayTimeSchedule]);

    const handleSubmit = () => {
        setShowErrorMessage(true);

        if (form.status !== "INVALID") {
            const weekDays = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
            weekDays.forEach(day => {
                if (!tempDaySchedule.hasOwnProperty(day)) {
                    tempDaySchedule[day] = { startDate: '09:00 AM', endDate: '06:00 PM', timeHour: '12:00', status: true, daysName: day, id: 0, industryId: 0 };
                }
            })

            const finalData = { ...form.value, lstHoursOperation: Object.values(tempDaySchedule) };

            finalData['peakSeason'] = finalData['seasonalBusiness'] === 'true' ? finalData['peakSeason'] : '';
            finalData['lowSeason'] = finalData['seasonalBusiness'] === 'true' ? finalData['lowSeason'] : '';

            dispatch(actions.hotelForm.submitHotelForm(finalData))
                .then((response) => {
                    dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: response.payload.message, type: "success" }));
                    setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
                    dispatch(actions.hotelForm.fetchHotelData({ loanId: parsedLoanId, type: 'Hotel' }));
                    setShowErrorMessage(false);
                })
                .catch(() => {
                    dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: "Error submitting form", type: "error" }));
                    setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
                });
        } else {
            markAllAsTouched(form);
            dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: "Please filled required fields", type: "error" }));
            setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
        }
    }


    const handleSelectChange = (name, selectedOption) => {
        const currentSelectedValues = selectedOption || [];
        const selectedValues = currentSelectedValues.map(option => option.value);

        // Allow removal if the new selection is less than or equal to the current one
        if (currentSelectedValues.length <= 10) {
            setSelectOptions(prevOptions => ({ ...prevOptions, [name]: currentSelectedValues.filter(opt => opt.value.trim() !== "" && opt.value.length <= 50) }));
            form.controls[name].setValue(selectedValues.join(','));
        } else {
            if (selectOptions[name]?.length < currentSelectedValues.length) {
                dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: "You can't select more than 10 options", type: "error" }));
                setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);

                setSelectOptions(prevOptions => ({ ...prevOptions, [name]: prevOptions[name] }));
            }
        }
    };

    const markAllAsTouched = (formGroup) => {
        Object.keys(formGroup.controls).forEach((controlName) => {
            const control = formGroup.get(controlName);
            if (control && control.status === "INVALID") {
                control.markAsTouched();
                control.updateValueAndValidity();
                if (document.getElementById(controlName)) {
                    document.getElementById(controlName).focus();
                }
            }
        });
    };


    return (
        <>
            {/* SidePanel */}
            <SidePanel />

            {/* ProfileHeader */}
            <ProfileHeader />

            {loading ? (<Preloader />) : (<div>


                <div className="lm-form-box pb-5">
                    <FieldGroup
                        control={form}
                        render={({ get }) => (
                            <form>
                                <div className="lm-card">
                                    <div className="row">
                                        <div className="col-12">
                                            <h3 className="lm-card-heading">Hotel Information</h3>
                                        </div>
                                        <div className="col-md-6">
                                            <CreatableSelect
                                                name="geographicMarket"
                                                label="Geographic markets"
                                                value={selectOptions.geographicMarket}
                                                onChange={handleSelectChange}
                                                placeholder="+ Add"
                                                isClearable
                                                required={true}
                                            />
                                            {showErrorMessage && !form.value.geographicMarket && <div className='error-message mt-2 mb-3'>Required field</div>}
                                        </div>
                                        <div className="col-md-6">
                                            <CreatableSelect
                                                name="customerVendors"
                                                label="Major customers"
                                                value={selectOptions.customerVendors}
                                                onChange={handleSelectChange}
                                                placeholder="+ Add"
                                                isClearable
                                                required={true}
                                            />
                                            {showErrorMessage && !form.value.customerVendors && <div className='error-message mt-2 mb-3'>Required field</div>}
                                        </div>
                                        <div className="col-md-6 mt-2">
                                            <div className="form-group AI_btn_field">
                                                <FormField
                                                    id="keyCompetitors"
                                                    label="Key competitors"
                                                    name="keyCompetitors"
                                                    placeholder="Enter Text"
                                                    required={true}
                                                    errorMessage="Required field"
                                                />
                                                <OpenAIComponent
                                                    fieldName="keyCompetitors"
                                                    formGroup={form}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6 mt-2">
                                            <div className="form-group AI_btn_field">
                                                <FormField
                                                    id="learnAboutHotel"
                                                    label="How do guests learn about the hotel?"
                                                    name="learnAboutHotel"
                                                    placeholder="Enter Text"
                                                    required={true}
                                                    errorMessage="Required field"
                                                />
                                                <OpenAIComponent
                                                    fieldName="learnAboutHotel"
                                                    formGroup={form}
                                                />
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="form-group AI_btn_field">
                                                <FormTextarea
                                                    id="competitiveAdvantages"
                                                    label="Competitive advantage"
                                                    name="competitiveAdvantages"
                                                    placeholder="Enter Text"
                                                    required={true}
                                                    errorMessage="Required field"
                                                />
                                                <OpenAIComponent
                                                    fieldName="competitiveAdvantages"
                                                    formGroup={form}
                                                    type='textarea'
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group AI_btn_field">
                                                <FormTextarea
                                                    id="suppliersVendorTerms"
                                                    label="Major suppliers & terms"
                                                    name="suppliersVendorTerms"
                                                    placeholder="Enter Text"
                                                    required={true}
                                                    errorMessage="Required field"
                                                />
                                                <OpenAIComponent
                                                    fieldName="suppliersVendorTerms"
                                                    formGroup={form}
                                                    type='textarea'
                                                />
                                            </div>
                                        </div>

                                        <div className='col-12'>
                                            <h3 className="lm-card-heading mt-2">Hours of operation</h3>
                                            <div className='row'>
                                                <WeeklyTimeComponent
                                                    weekday="monday"
                                                    setDayTimeSchedule={setDayTimeSchedule}
                                                    value={dayTimeSchedule}
                                                    industryType="Contractor"
                                                />
                                                <WeeklyTimeComponent
                                                    weekday="tuesday"
                                                    setDayTimeSchedule={setDayTimeSchedule}
                                                    value={dayTimeSchedule}
                                                    industryType="Contractor"
                                                />
                                                <WeeklyTimeComponent
                                                    weekday="wednesday"
                                                    setDayTimeSchedule={setDayTimeSchedule}
                                                    value={dayTimeSchedule}
                                                    industryType="Contractor"
                                                />
                                                <WeeklyTimeComponent
                                                    weekday="thursday"
                                                    setDayTimeSchedule={setDayTimeSchedule}
                                                    value={dayTimeSchedule}
                                                    industryType="Contractor"
                                                />
                                                <WeeklyTimeComponent
                                                    weekday="friday"
                                                    setDayTimeSchedule={setDayTimeSchedule}
                                                    value={dayTimeSchedule}
                                                    industryType="Contractor"
                                                />
                                                <WeeklyTimeComponent
                                                    weekday="saturday"
                                                    setDayTimeSchedule={setDayTimeSchedule}
                                                    value={dayTimeSchedule}
                                                    industryType="Contractor"
                                                />
                                                <WeeklyTimeComponent
                                                    weekday="sunday"
                                                    setDayTimeSchedule={setDayTimeSchedule}
                                                    value={dayTimeSchedule}
                                                    industryType="Contractor"
                                                />
                                            </div>
                                        </div>

                                        <div className="col-md-4 col-lg-3">
                                            <FormField
                                                id="numberOfRooms"
                                                label="Number of rooms/suites?"
                                                name="numberOfRooms"
                                                placeholder="0.00"
                                                required={true}
                                                errorMessage="Required field"
                                                onlyNumbers={true}
                                                maxlength={5}
                                            />
                                        </div>
                                        <div className="col-md-4 col-lg-3">
                                            <FormField
                                                id="numberOfFloors"
                                                label="Number of floors/stories"
                                                name="numberOfFloors"
                                                placeholder="0.00"
                                                required={true}
                                                errorMessage="Required field"
                                                onlyNumbers={true}
                                                maxlength={5}
                                            />
                                        </div>

                                        <div className="col-md-6">
                                        </div>

                                        <div className="col-md-6">
                                            <FormRadio
                                                name="hotelOrMotel"
                                                label="Proerty type"
                                                options={[
                                                    { label: 'Interior corridor', value: 'Interior corridor' },
                                                    { label: 'Exterior corridor', value: 'Exterior corridor' },
                                                ]}
                                            />
                                        </div>
                                        <div className="col-md-3">
                                            <FormRadio
                                                name="elevatorService"
                                                label="Does the property have elevator service?"
                                                options={[
                                                    { label: 'Yes', value: 'true' },
                                                    { label: 'No', value: 'false' },
                                                ]}
                                                required={false}
                                                errorMessage="This field is required."
                                            />
                                        </div>
                                        <div className="col-md-3">
                                            <FormRadio
                                                name="propertyServiceLevel"
                                                label="Property service level"
                                                options={[
                                                    { label: 'Full service', value: 'Full service' },
                                                    { label: 'Limited service', value: 'Limited service' },
                                                ]}
                                            />
                                        </div>


                                        <div className="col-md-4 col-lg-3">
                                            <FormSelect
                                                name="lastRenovation"
                                                label="When was last renovation?"
                                                options={LastRenovationOptions}
                                                defaultValue={LastRenovationOptions[0]}
                                                required={false}
                                                errorMessage="Required field"
                                            />
                                        </div>
                                        <div className="col-md-9">
                                            <div className="form-group AI_btn_field">
                                                <FormField
                                                    id="performed"
                                                    label="Provide detail"
                                                    name="performed"
                                                    placeholder="Enter Text"
                                                />
                                                <OpenAIComponent
                                                    fieldName="performed"
                                                    formGroup={form}
                                                />
                                            </div>
                                        </div>

                                        {/* <!--  lm common divider to break the section  --> */}
                                        <div className="lm-divider my-4 mx-3"></div>
                                        {/* <!--  lm common divider to break the section  --> */}

                                        <div className="col-md-6">
                                            <FormRadio
                                                name="seasonalBusiness"
                                                label="Is this a seasonal business?"
                                                options={[
                                                    { label: 'Yes', value: 'true' },
                                                    { label: 'No', value: 'false' },
                                                ]}
                                            />
                                        </div>

                                        {
                                            form.value.seasonalBusiness == "true" &&
                                            <>
                                                <div className="col-12">
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <CreatableSelect
                                                                name="peakSeason"
                                                                label="Peak season"
                                                                value={selectOptions.peakSeason}
                                                                onChange={handleSelectChange}
                                                                placeholder="Select..."
                                                                options={PeakSeasonOptions}
                                                                isMulti
                                                                isClearable
                                                            />
                                                        </div>
                                                        <div className="col-md-6">
                                                            <CreatableSelect
                                                                name="lowSeason"
                                                                label="Off season"
                                                                value={selectOptions.lowSeason}
                                                                onChange={handleSelectChange}
                                                                placeholder="Select..."
                                                                options={LowSeasonOptions}
                                                                isMulti
                                                                isClearable
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        }

                                        {/* <!--  lm common divider to break the section  --> */}
                                        <div className="lm-divider my-4 mx-3"></div>
                                        {/* <!--  lm common divider to break the section  --> */}

                                        <div className="col-md-12">
                                            <FormRadio
                                                name="fullOrLimitedService"
                                                label="Type of property"
                                                options={[
                                                    { label: 'Full service', value: 'Full service' },
                                                    { label: 'Limited service', value: 'Limited service' },
                                                ]}
                                            />
                                        </div>

                                        {/* <!--  lm common divider to break the section  --> */}
                                        <div className="lm-divider my-4 mx-3"></div>
                                        {/* <!--  lm common divider to break the section  --> */}

                                        <div className="col-md-6">
                                            <div className="form-group AI_btn_field">
                                                <FormTextarea
                                                    id="additionalComments"
                                                    label="Additional comments"
                                                    name="additionalComments"
                                                    placeholder="Enter Text"
                                                />
                                                <OpenAIComponent
                                                    fieldName="additionalComments"
                                                    formGroup={form}
                                                    type="textarea"
                                                />
                                            </div>
                                        </div>


                                    </div>
                                </div>
                                <div className="sticky-btn-footer d-flex justify-content-end">
                                    <button type="button" className="lm-button1 ml-3" onClick={handleSubmit}>Save</button>
                                </div>
                            </form>
                        )}
                    />
                </div>
            </div>)}

        </>

    );



}

export default IndustryHotel;