import React, { useState } from "react";
import { FieldControl } from "react-reactive-form";
import { FormatPhoneNumber } from "../../helpers/DynamicFunctions";

const ContactNumberField = ({ name, label, id, placeholder, required, errorMessage }) => {

    return (
        <FieldControl
            name={name}
            render={({ handler, touched, hasError, meta }) => {
                const handlerProps = handler();

                const standardizePhoneNumber = (e) => {
                    // Remove any non-numeric characters
                    let phoneValue = e.target.value.replace(/\D/g, '');
                    let cursorPosition = e.target.selectionStart;

                    // Handle backspace properly
                    if (e.nativeEvent.inputType === "deleteContentBackward") {
                        if (handlerProps.onChange) {
                            handlerProps.onChange(e.target.value); // Allow default backspace behavior
                        }
                        return;
                    }

                    // Format phone number as (XXX) XXX-XXXX
                    if (phoneValue.length <= 10) {
                        const phoneFormatted = FormatPhoneNumber(phoneValue);

                        // Calculate the difference in length before and after formatting
                        const previousLength = e.target.value.length;
                        e.target.value = phoneFormatted;
                        const newLength = e.target.value.length;

                        // Adjust cursor position based on the number of formatting characters inserted
                        if (newLength > previousLength) {
                            cursorPosition += (newLength - previousLength);  // Adjust based on how many formatting characters were added
                        }

                        // Set the cursor back to the correct position
                        e.target.setSelectionRange(cursorPosition, cursorPosition);
                    }

                    // Call the onChange handler to update the form value
                    if (handlerProps.onChange) {
                        handlerProps.onChange(e.target.value); // Pass the formatted phone number value
                    }
                };

                return (<div className="form-group">
                    <label htmlFor={name}>{label}{required && <span className="asterisk">*</span>}</label>
                    <input
                        type="text"
                        className="form-control"
                        id={id}
                        autoComplete="off"
                        placeholder="(877) 847-1134"
                        {...handlerProps}
                        onChange={standardizePhoneNumber}
                        maxlength={14}
                    />

                    {/* Error messages below */}
                    {touched && hasError("required") && <><span className="error-message">{errorMessage}</span><br /></>}
                    {touched && hasError("contactNumberIssue") && <><span className="error-message">Please enter a valid number</span><br /></>}
                </div>
                );
            }}
            meta={{ placeholder: placeholder }}
        />
    );
}

export default ContactNumberField;
