import { Link, useLocation } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import './SidePanel.css';
import lm_logo_white from '../../assets/img/lm-logo-white.svg';
import colse_menu_icon from '../../assets/icons/colse_menu_icon.svg';
import { useDispatch, useSelector } from 'react-redux';
import { sideMenuMapper } from '../../helpers/DropdownsAndMappers';

// Function to Toggle Menu
export function toggleMenu() {
    const menu = document.querySelector('.main-side-panel');
    menu.classList.toggle('side-panel-open');
    const menuOverlay = document.querySelector('.menu-overlay');
    menuOverlay.classList.toggle('menu-overlay-open');
}

function SidePanelEmail() {
    // Declaring variables
    const location = useLocation();
    const dispatch = useDispatch();
    const sidePanelArray = useSelector((state) => state.auth.sidePanel) || [];
    const [activeLink, setActiveLink] = useState('');
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const sideMenuMap = sideMenuMapper;
    // Declaring functions
    const handleSetActiveLink = (link) => setActiveLink(link);
    const handleDropdownToggle = () => setIsDropdownOpen(!isDropdownOpen);

    // List of paths where the dropdown should be expanded
    const dropdownPages = ['/users/advisor-admin', '/users/borrowers', '/users/lenders', '/users/partners', '/users/advisor-admin-detail', '/users/borrowers-detail', '/users/lenders-detail', '/users/partners-detail'];

    // Effect to set the initial state of the dropdown based on the current page
    useEffect(() => {
        // If the current path is in the list of dropdownPages, set the dropdown to open
        if (dropdownPages.includes(location.pathname)) {
            setIsDropdownOpen(true);
        } else {
            setIsDropdownOpen(false);
        }
    }, [location.pathname]);

    // Declaring Hooks
    useEffect(() => {
        const currentPath = window.location.pathname;
        setActiveLink(currentPath);
    }, []);

    return (
        <div className="main-side-panel">
            <div className="close-panel" onClick={toggleMenu}>
                <img src={colse_menu_icon} alt="" />
            </div>
            <Link className="main-logo" href="#">
                <img src={lm_logo_white} alt="" />
            </Link>
            {/* add class active to show active link */}
            <div className="panel-navigation">
                {sidePanelArray.length > 0 && sidePanelArray.map((list) => {
                    return (sideMenuMap[list.menuName] && (
                        list.lstRole.length > 0 ? (
                            <React.Fragment key={list.menuName}>
                                <a onClick={handleDropdownToggle}
                                    role="button"
                                    aria-expanded={isDropdownOpen ? "true" : "false"}
                                    className="panel-nav-item panel-dropdown">
                                    <i className={sideMenuMap[list.menuName].icon}></i>
                                    {list.menuName}
                                </a>
                                <div id="usersDropdown"
                                    className={`panel-dropdown-box collapse dropdown-content ${isDropdownOpen ? 'show' : ''}`}>
                                    <div className="panel-dropdown-item-group">
                                        {
                                            list.lstRole.length > 0 && list.lstRole.map((sublist) => (
                                                <Link key={sublist.menuName}
                                                    to={sideMenuMap[sublist.menuName].route}
                                                    className={`${activeLink === sideMenuMap[sublist.menuName].route || location.pathname.includes(sideMenuMap[sublist.menuName].route) ? 'active' : ''}`}
                                                    onClick={() => handleSetActiveLink(sideMenuMap[sublist.menuName].route)}>
                                                    {sublist.menuName}
                                                </Link>
                                            ))
                                        }
                                    </div>
                                </div>
                            </React.Fragment>
                        ) : (
                            <Link
                                key={list.menuName}
                                to={sideMenuMap[list.menuName].route}
                                className={`panel-nav-item ${activeLink === sideMenuMap[list.menuName].route ? 'active' : ''}`}
                                onClick={() => handleSetActiveLink(sideMenuMap[list.menuName].route)}>
                                <i className={sideMenuMap[list.menuName].icon}></i>
                                
                                {list.menuName}
                            </Link>
                        )
                    ))
                })}
                <hr />
                {/* <Link to="/settings"
                    className={`panel-nav-item ${activeLink === '/settings' ? 'active' : ''}`}
                    onClick={() => handleSetActiveLink('/settings')}>
                    <i className="lm-settings-icon"></i>
                    Settings
                </Link> */}
            </div>
            <div className="copyright-sec">
                <p>© 2025. All right reserved.</p>
                <a href="https://loanmantra.com/privacy-policy/">Privacy Policy</a>
            </div>
        </div>
    );
}

export default SidePanelEmail;
