import { useEffect } from "react";
import { GetHelloSignDownloadRequest } from "../services/documentsServices";

function UpdateHelloSignDocument() {
    useEffect(() => {
        getZipFileFromURL();
    }, []);

    function getCurrentUrlParams() {
        const params = {};
        const parsedUrl = new URL(window.location.href);

        // Iterate through all search parameters
        for (const [key, value] of parsedUrl.searchParams.entries()) {
            params[key] = value;
        }

        return params;
    }

    const getZipFileFromURL = () => {
        const currentUrlParams = getCurrentUrlParams();
        GetHelloSignDownloadRequest({ ...currentUrlParams }).then().catch();
        window.open('/loan-application', '_self');
    };


    const AccessRestricted = () => {
        return (
            <>
                <div className="">
                    <div className="not-found-page-body">
                        <div className="success-page-box not-found-box vh-100">
                            <p>Please wait you will be redirected...</p>
                        </div>
                    </div>
                </div>
            </>
        );
    }

    return <AccessRestricted />;
}

export default UpdateHelloSignDocument;
