import React, { useState, useEffect } from 'react';
import Info_tooltip from '../assets/icons/Info-tooltip.svg';
import Modal from 'react-bootstrap/Modal'
import close_popup from '../assets/icons/close_popup.svg';
import OpenAIComponent from '../components/OpenAI/OpenAIComponent'
import axios from '../helpers/axiosConfig';
import Constants from '../helpers/Constants';
import { useDispatch } from 'react-redux';
import reducersAndActions from '../_redux/slices/index';
import moment from 'moment';
import { SidePanelEmail, ProfileHeader } from "@components";
import { decryptText } from '../helpers/DynamicFunctions';
import { getSessionId } from '../services/storageData';
import Preloader from '../plugins/Preloader';
import { useNavigate } from 'react-router-dom';

const ratingArray = [1, 2, 3, 4, 5];

const UserLenderRating = ({ info }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);

    // Initializing functions
    const urlSearchString = window.location.search;
    const params = new URLSearchParams(urlSearchString);
    const decryptedValue = params.get('data') ? decryptText(params.get('data')) : ""

    // Split the decrypted value into individual parameters
    const paramsArray = decryptedValue ? decryptedValue.split("&") : [];

    const appLoanID = paramsArray.length > 0 ? paramsArray[0].split("=")[1] : null;
    const appNumber = paramsArray.length > 1 ? paramsArray[1].split("=")[1] : null;
    const createdDate = paramsArray.length > 2 ? paramsArray[2].split("=")[1] : null;
    const borrowAmount = paramsArray.length > 3 ? paramsArray[3].split("=")[1] : null;
    const businessCategory = paramsArray.length > 4 ? paramsArray[4].split("=")[1] : null;
    const typeOfBusiness = paramsArray.length > 5 ? paramsArray[5].split("=")[1] : null;
    const applicationStatus = paramsArray.length > 6 ? paramsArray[6].split("=")[1] : null;
    const lenderId = paramsArray.length > 7 ? paramsArray[7].split("=")[1] : null;
    const advisorId = paramsArray.length > 8 ? paramsArray[8].split("=")[1] : null;



    useEffect(() => {
        getCheckRatingStatus();
    }, []);

    const getCheckRatingStatus = async () => {
        try {
            setLoading(true);
            const response = await axios.get('/api/User/GetCheckRating', {
                params: {
                    SessionId: getSessionId(),
                    LoanId: appLoanID,
                    AdvisorId: advisorId,
                    LenderId: lenderId
                }
            });
            setLoading(false);
            if (response.data.status == 200 && response.data.message === "Rating already submitted.") {
                dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: response.data.message, type: "success" }));
                setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
                navigate("/")
            }
        } catch (error) {
            setLoading(false);
        }
    }


    // Accordion state
    const [accordionIndex, setAccordionIndex] = useState(false);
    const [appIndexData, setAppIndexData] = useState({
        communication: 0, communicationText: "",
        expertise: 0, expertiseText: "",
        guidance: 0, guidanceText: "",
        reuseLender: 0, reuseLenderText: "",
        efficiency: 0, efficiencyText: "",
        factorsInfluence: "",
        comment: ""
    });

    const accordianClass = () => {
        return accordionIndex ? "open-user-applicaion-dtls-accordian" : "closed-user-applicaion-dtls-accordian";
    }

    const setHardDataOfOpenAI = (value, key) => {
        const [fieldName] = key.split('_')
        setAppIndexData({ ...appIndexData, [fieldName]: value })
    }

    const checkRequiredFields = ['communication', 'expertise', 'guidance', 'reuseLender', 'efficiency', 'factorsInfluence', 'communicationText', 'guidanceText', 'reuseLenderText', 'efficiencyText'];
    const handleSubmitRating = async () => {
        try {
            for (const key of checkRequiredFields) {
                if (appIndexData[key] === 0 || appIndexData[key] === "") {
                    dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: "Please fill all the fields", type: "error" }));
                    setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
                    return;
                }
            }

            setLoading(true);
            const data = {
                id: 0,
                loanId: parseInt(appLoanID),
                lenderId: parseInt(lenderId),
                communication: appIndexData.communication,
                communicationText: appIndexData.communicationText,
                expertise: appIndexData.expertise,
                expertiseText: appIndexData.expertiseText,
                guidance: appIndexData.guidance,
                guidanceText: appIndexData.guidanceText,
                reuseLender: appIndexData.reuseLender,
                reuseLenderText: appIndexData.reuseLenderText,
                efficiency: appIndexData.efficiency,
                efficiencyText: appIndexData.efficiencyText,
                factorsInfluence: appIndexData.factorsInfluence,
                comment: appIndexData.comment,
                createdBy: 0,
                approvedBy: 0,
                rating: "",
                advisorId: parseInt(advisorId)
            }

            const response = await axios.post('/api/User/InsertGustApplicationRating', data, { params: { SessionId: getSessionId() } });
            setLoading(false);
            if (response.data.status == 200) {
                dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: response.data.message, type: "success" }));
                setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
                navigate("/")
            } else {
                dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: response.response.data.message, type: "error" }));
                setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
            }
        } catch (error) {
            setLoading(false);
            dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: error.response.data.message, type: "error" }));
            setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
        }
    }

    return (
        <>
            {/* SidePanelEmail */}
            <SidePanelEmail />

            {/* ProfileHeader */}
            <ProfileHeader />

            {loading ? <Preloader /> : (
                <>
                    <div className="dashboard-wrap users-screen">

                        <div className="user-rating-content">
                            <div className='row'>
                                <div className='col-12'>
                                    <>
                                        <div className='user-applicaion-dtls'>
                                            <div className='d-flex justify-content-between'>
                                                <div>
                                                    <div className='d-flex gap-10'>
                                                        <h1 className="appliaction-no mr-2">{appNumber}</h1>
                                                    </div>
                                                    <small>Created on {moment(createdDate).format("MMM DD, YYYY")}</small>
                                                </div>
                                            </div>
                                            <div className="user-applicaion-dtls-accordian mt-2">
                                                <div className={`cursor-pointer user-applicaion-dtls-accordian-button ${accordianClass()}`} onClick={() => setAccordionIndex((prev) => !prev)}>
                                                    Details
                                                </div>

                                                <>
                                                    <div className="lm-divider mt-2 mb-0"></div>
                                                    <div className='row pt-3'>
                                                        <div className='col-md-3 col-6'>
                                                            <div className='label'>Loan Amount</div>
                                                            <div className='value'>${borrowAmount}</div>
                                                        </div>
                                                        <div className='col-md-3 col-6'>
                                                            <div className='label'>Business Category</div>
                                                            <div className='value'>{(businessCategory && businessCategory !== 'undefined') ? businessCategory : "N/A"}</div>
                                                        </div>
                                                        <div className='col-md-3 col-6'>
                                                            <div className='label'>Type of Businesst</div>
                                                            <div className='value'>{typeOfBusiness}</div>
                                                        </div>
                                                        <div className='col-md-3 col-6'>
                                                            <div className='label'>Deal Closed</div>
                                                            <div className='value'>{applicationStatus === "Closes" ? "Yes" : "No"}</div>
                                                        </div>
                                                    </div>


                                                    <div className="lm-divider mt-2"></div>

                                                    <div className='row'>
                                                        <div className='col-12'>
                                                            <h3 className="lm-card-heading">Rating
                                                                <span className="lm-tooltip ml-1" data-tooltip="Based on your experience with the transaction listed above, please rate the lender on a scale from 1 to 5" data-tooltip-pos="right" data-tooltip-length="cutsom"> <img src={Info_tooltip} alt="" /> </span>
                                                            </h3>
                                                        </div>
                                                        <div className='col-md-6 mb-2'>
                                                            <div className="form-group">
                                                                <div className="label pb-1">
                                                                    How would you rate the effectiveness of your lender's communication with you and others throughout the loan process? (1 is least effective and 5 is highly effective).<span className="asterisk">*</span>
                                                                </div>
                                                                {
                                                                    ratingArray.map((item, index) => (
                                                                        <div className="form-check form-check-inline">
                                                                            <input
                                                                                className="form-check-input"
                                                                                type="radio"
                                                                                name={'communication_' + item}
                                                                                id={'communication_' + item}
                                                                                checked={appIndexData['communication'] === item}
                                                                                onChange={() => setAppIndexData({ ...appIndexData, communication: item })}
                                                                            />
                                                                            <label className="form-check-label" htmlFor={'communication_' + item}>{item}</label>
                                                                        </div>
                                                                    ))
                                                                }
                                                            </div>
                                                            <div className="form-group">
                                                                <div className='label text-dark mb-2'>What area of communication needs improvement? <span className="asterisk">*</span></div>
                                                                <div className='select_feedback d-flex gap-10 flex-wrap'>
                                                                    {
                                                                        ['Frequency', 'Tone', 'Reliability', 'Other'].map((item, index) => (
                                                                            <div className={appIndexData['communicationText'] === item ? 'option if_selected' : 'option'}
                                                                                onClick={() => {
                                                                                    setAppIndexData({ ...appIndexData, communicationText: item })
                                                                                }}
                                                                            >{item}</div>
                                                                        ))
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className='col-md-6 mb-2'>
                                                            <div className="form-group">
                                                                <div className="label pb-1">
                                                                    To what extent did the lender provide trustworthy expertise regarding the financial products available to you? (1 is no expertise and 5 is deep expertise).<span className="asterisk">*</span>
                                                                </div>
                                                                {
                                                                    ratingArray.map((item, index) => (
                                                                        <div className="form-check form-check-inline">
                                                                            <input
                                                                                className="form-check-input"
                                                                                type="radio"
                                                                                name={'expertise_' + item}
                                                                                id={'expertise_' + item}
                                                                                checked={appIndexData['expertise'] === item}
                                                                                onChange={() => setAppIndexData({ ...appIndexData, expertise: item })}
                                                                            />
                                                                            <label className="form-check-label" htmlFor={'expertise_' + item}>{item}</label>
                                                                        </div>
                                                                    ))
                                                                }
                                                            </div>
                                                            <div className="form-group">
                                                                <div className='label text-dark mb-2'>What area of expertise needs improvement?<span className="asterisk">*</span></div>
                                                                <div className='select_feedback d-flex gap-10 flex-wrap'>
                                                                    {
                                                                        ['SBA', 'CRE', 'Line of Credit', 'Equipment', 'Other'].map((item, index) => (
                                                                            <div className={appIndexData['expertiseText'] === item ? 'option if_selected' : 'option'}
                                                                                onClick={() => {
                                                                                    setAppIndexData({ ...appIndexData, expertiseText: item })
                                                                                }}
                                                                            >{item}</div>
                                                                        ))
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className='col-md-6 mb-2'>
                                                            <div className="form-group">
                                                                <div className="label pb-1">
                                                                    How confident were you in your lender's guidance throughout the loan process, from application to closing? (1 is not confident and 5 is very confident).<span className="asterisk">*</span>
                                                                </div>
                                                                {
                                                                    ratingArray.map((item, index) => (
                                                                        <div className="form-check form-check-inline">
                                                                            <input
                                                                                className="form-check-input"
                                                                                type="radio"
                                                                                name={'guidance_' + item}
                                                                                id={'guidance_' + item}
                                                                                checked={appIndexData['guidance'] === item}
                                                                                onChange={() => setAppIndexData({ ...appIndexData, guidance: item })}
                                                                            />
                                                                            <label className="form-check-label" htmlFor={'guidance_' + item}>{item}</label>
                                                                        </div>
                                                                    ))
                                                                }
                                                            </div>
                                                            <div className="form-group">
                                                                <div className='label text-dark mb-2'>What area of guidance needs improvement?<span className="asterisk">*</span> </div>
                                                                <div className='select_feedback d-flex gap-10 flex-wrap'>
                                                                    {
                                                                        ['Loan Structuring', 'Underwriting ', 'Closing', 'Other'].map((item, index) => (
                                                                            <div className={appIndexData['guidanceText'] === item ? 'option if_selected' : 'option'}
                                                                                onClick={() => {
                                                                                    setAppIndexData({ ...appIndexData, guidanceText: item })
                                                                                }}
                                                                            >{item}</div>
                                                                        ))
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className='col-md-6 mb-2'>
                                                            <div className="form-group">
                                                                <div className="label pb-1">
                                                                    How likely are you to use this lender again in the future? (1 is not at all likely and 5 is very likely).<span className="asterisk">*</span>
                                                                </div>
                                                                {
                                                                    ratingArray.map((item, index) => (
                                                                        <div className="form-check form-check-inline">
                                                                            <input
                                                                                className="form-check-input"
                                                                                type="radio"
                                                                                name={'reuseLender' + item}
                                                                                id={'reuseLender' + item}
                                                                                checked={appIndexData['reuseLender'] === item}
                                                                                onChange={() => setAppIndexData({ ...appIndexData, reuseLender: item })}
                                                                            />
                                                                            <label className="form-check-label" htmlFor={'reuseLender' + item}>{item}</label>
                                                                        </div>
                                                                    ))
                                                                }
                                                            </div>
                                                            <div className="form-group">
                                                                <div className='label text-dark mb-2'>What areas need improvement?<span className="asterisk">*</span> </div>
                                                                <div className='select_feedback d-flex gap-10 flex-wrap'>
                                                                    {
                                                                        ['Communication', 'Expertise', 'Friendliness', 'Reliability', 'Other'].map((item, index) => (
                                                                            <div className={appIndexData['reuseLenderText'] === item ? 'option if_selected' : 'option'}
                                                                                onClick={() => {
                                                                                    setAppIndexData({ ...appIndexData, reuseLenderText: item })
                                                                                }}
                                                                            >{item}</div>
                                                                        ))
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className='col-md-6 mb-2'>
                                                            <div className="form-group">
                                                                <div className="label pb-1">
                                                                    How would you rate the level of efficiency, clarity and transparency shown by the lender throughout the loan process? (1 is inefficient, unclear and confusing and 5 is highly efficient, clear and transparent).<span className="asterisk">*</span>
                                                                </div>
                                                                {
                                                                    ratingArray.map((item, index) => (
                                                                        <div className="form-check form-check-inline">
                                                                            <input
                                                                                className="form-check-input"
                                                                                type="radio"
                                                                                name={'efficiency_' + item}
                                                                                id={'efficiency_' + item}
                                                                                checked={appIndexData['efficiency'] === item}
                                                                                onChange={() => setAppIndexData({ ...appIndexData, efficiency: item })}
                                                                            />
                                                                            <label className="form-check-label" htmlFor={'efficiency_' + item}>{item}</label>
                                                                        </div>
                                                                    ))
                                                                }
                                                            </div>
                                                            <div className="form-group">
                                                                <div className='label text-dark mb-2'>What areas need improvement?<span className="asterisk">*</span> </div>
                                                                <div className='select_feedback d-flex gap-10 flex-wrap'>
                                                                    {
                                                                        ['Confusing', 'Complicated', 'Took longer than expected', 'Other'].map((item, index) => (
                                                                            <div className={appIndexData['efficiencyText'] === item ? 'option if_selected' : 'option'}
                                                                                onClick={() => {
                                                                                    setAppIndexData({ ...appIndexData, efficiencyText: item })
                                                                                }}
                                                                            >{item}</div>
                                                                        ))
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-md-6 mb-2">
                                                            <div className="form-group AI_btn_field">
                                                                <label>
                                                                    What factors influenced your rating of the lender?<span className="asterisk">*</span>
                                                                </label>
                                                                <textarea
                                                                    className="form-control"
                                                                    id={'factorsInfluence'}
                                                                    name={'factorsInfluence'}
                                                                    value={appIndexData['factorsInfluence']}
                                                                    onChange={(e) => setAppIndexData({ ...appIndexData, factorsInfluence: e.target.value })}
                                                                    placeholder="Enter text"
                                                                    maxLength={250}
                                                                >
                                                                </textarea>
                                                                <OpenAIComponent
                                                                    fieldName={'factorsInfluence'}
                                                                    value={appIndexData['factorsInfluence']}
                                                                    isFormGroup={false}
                                                                    setNestedHardData={true}
                                                                    setHardData={setHardDataOfOpenAI}
                                                                    type='textarea'
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className="col-md-6 mb-2">
                                                            <div className="form-group AI_btn_field">
                                                                <label>
                                                                    Comment
                                                                </label>
                                                                <textarea
                                                                    className="form-control"
                                                                    id={'comment'}
                                                                    name={'comment'}
                                                                    value={appIndexData['comment']}
                                                                    onChange={(e) => setAppIndexData({ ...appIndexData, comment: e.target.value })}
                                                                    placeholder="Enter text"
                                                                    maxLength={250}
                                                                >
                                                                </textarea>
                                                                <OpenAIComponent
                                                                    fieldName={'comment'}
                                                                    value={appIndexData['comment']}
                                                                    isFormGroup={false}
                                                                    setNestedHardData={true}
                                                                    setHardData={setHardDataOfOpenAI}
                                                                    type='textarea'
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className='col-12'>
                                                            <button type="button" className='lm-button1' onClick={handleSubmitRating}>
                                                                Submit
                                                            </button>
                                                        </div>
                                                    </div>
                                                </>
                                            </div>
                                        </div >
                                    </>
                                </div>
                            </div>
                        </div>

                    </div>
                </>
            )
            }
        </>
    );

}

export default UserLenderRating;