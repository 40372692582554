import Preloader from '../plugins/Preloader';
import React, { useState, useEffect, useMemo } from 'react';
import Select from 'react-select';
import './styles/Users.css';
import { useDispatch, useSelector } from "react-redux";
import reducersAndActions from "../_redux/slices";
import axiosInstance from '../helpers/axiosConfig';
import Constants from "../helpers/Constants";
import { getUSAStates } from "../services/commonService";
import { FieldControl, FieldGroup, FormBuilder, Validators } from "react-reactive-form";
import AmountContainer from "../components/Amount/AmountContainer";
import FormField from "../components/FormField/FormField";
import ContactNumberField from "../components/ContactField/ContactField";
import FormTextarea from "../reusablecomponets/FormTextArea";
import FormRadio from "../reusablecomponets/FormRadio";

import { contactNumberValidate, emailValidator, valdiateMinMaxAmount, valdiateMinMaxPercenet, validStringValidator, zipCodeValidator } from "../helpers/DynamicFunctions";
import CreatableSelectComponent from "../reusablecomponets/CreatableSelect";
import { GetAllDocumentList } from "../services/documentsServices";
import { staticPrescreenRequirementList, staticPropertyTypeList } from "../helpers/staticContentData";
import { SidePanel, ProfileHeader } from "@components";
import moment from 'moment';

const LendingCriteria = ({ info }) => {
    const dispatch = useDispatch()

    const user = useSelector((state) => state.auth);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        dispatch(reducersAndActions.actions.sessionActions.setLoading(loading));
    }, [loading]);

    const [usastate, setUsastates] = useState([])
    const [propertyType, setPropertyType] = useState([])
    const [prescreenRequirement, setPrescreenRequirement] = useState([])
    const [documentList, setDocumentList] = useState([])

    const [selectOptions, setSelectOptions] = useState({ geographicCoverage: [], propertyType: [], prescreenRequirement: [], preferredIndustry: [], restrictedIndustry: [], prepaymentPenalty: [], requiredDocuments: [], term: [] });

    const getCountryStates = async () => {
        const states = await getUSAStates();
        setUsastates(states);
    }

    useEffect(() => {
        document.title = "Lending Criteria";
        formData.reset();
        getCountryStates()
        setPropertyType(staticPropertyTypeList)
        setPrescreenRequirement(staticPrescreenRequirementList)
        getLenderDetails()
        getAllDocumentsList()
    }, []);


    const [formData, setFormData] = useState(
        FormBuilder.group({
            id: ["", [Validators.required]],
            userId: [""],
            lendingInstitution: ["", [Validators.required, validStringValidator]],
            firstName: ["", [Validators.required, validStringValidator]],
            lastName: ["", [Validators.required, validStringValidator]],
            officeNumber: ["", [Validators.required, contactNumberValidate]],
            cellNumber: ["", [Validators.required, contactNumberValidate]],
            fax: [""],
            email: ["", [Validators.required, emailValidator]],
            website: [""],
            addressLine1: ["", [Validators.required, validStringValidator]],
            addressLine2: [""],
            city: ["", [Validators.required, validStringValidator]],
            state: ["", [Validators.required]],
            zipCode: ["", [Validators.required, zipCodeValidator]],
            geographicCoverage: [""],
            ficoMin: [""],
            propertyType: [""],
            prescreenRequirement: [""],
            notes: [""],
            term: [""],
            maxLtv: [""],
            dscr: [""],
            collateral: [""],
            equity: [""],
            minLoanSize: ["", [valdiateMinMaxAmount]],
            maxLoanSize: [""],
            interestRate: ["", [valdiateMinMaxPercenet]],
            maxInterestRate: ["", [valdiateMinMaxPercenet]],
            isPrimary_CellNumber: [false],
            isPrimary_OfficeNumber: [false],
            isPrimary_SBA: [false],
            isPrimary_Conventional: [false],
            isPrimary_MCA: [false],
            isPrimary_FixAndFlip: [false],
            isPrimary_AlternativeRealState: [false],
            isPrimary_AlternativeOthers: [false],
            prepaymentPenalty: [""],
            preferredIndustry: [""],
            restrictedIndustry: [""],
            requiredDocuments: [""],
            additionalField: [""],
            approvedBy: [""],
            approvalDate: [""],
            approvedName: [""],
        })
    );

    const convertStringToArray = (coverage) => {
        if (typeof coverage === 'string') {
            return coverage.split(',').map(item => {
                const trimmedItem = item.trim();
                return { label: trimmedItem, value: trimmedItem };
            });
        }
        return [];
    };

    const getAllDocumentsList = async () => {
        const documentList = await GetAllDocumentList();
        if (documentList.status = 200) {
            const finalData = JSON.parse(documentList.resultObject)
            setDocumentList(finalData.map(doc => {
                return { label: doc.DocumentName, value: doc.DocumentName }
            }));
        }
    };

    const getLenderDetails = async () => {
        try {
            setLoading(true)
            const storedUserId = localStorage.getItem('user');
            const parsedUserId = storedUserId ? JSON.parse(storedUserId) : null;
            const storedSessionId = localStorage.getItem('sessionId');

            let idApi = info ? info.id : parsedUserId.id
            const response = await axiosInstance.get(`/api/User/GetLenderConsoleDetailsById?Id=${idApi}&SessionId=${storedSessionId}`)
            const data = response.data.resultObject
            console.log(data)

            setSelectOptions({
                geographicCoverage: data.geographicCoverage && convertStringToArray(data.geographicCoverage) || [],
                propertyType: data.propertyType && convertStringToArray(data.propertyType) || [],
                prescreenRequirement: data.prescreenRequirement && convertStringToArray(data.prescreenRequirement) || [],
                preferredIndustry: data.preferredIndustry && convertStringToArray(data.preferredIndustry) || [],
                restrictedIndustry: data.restrictedIndustry && convertStringToArray(data.restrictedIndustry) || [],
                prepaymentPenalty: data.prepaymentPenalty && convertStringToArray(data.prepaymentPenalty) || [],
                requiredDocuments: data.requiredDocuments && convertStringToArray(data.requiredDocuments) || [],
                term: data.term && convertStringToArray(data.term) || [],
            })

            formData.patchValue({
                ...data,
                state: data.state ? { label: data.state, value: data.state } : null,
                isPrimary_SBA: data.isPrimary_SBA,
            });
            setLoading(false)
        } catch (error) {
            setLoading(false)
            console.log(error);
        }
    }

    // Requirements tabs
    const [activeTab, setActiveTab] = useState("Lender_Information");
    const handleTabClick = (tabId) => {
        setActiveTab(tabId);
    };

    const handleSelectChange = (name, selectedOption) => {
        const currentSelectedValues = selectedOption || [];
        const selectedValues = currentSelectedValues.map(option => option.value);

        // Allow removal if the new selection is less than or equal to the current one
        if (currentSelectedValues.length <= 10) {
            setSelectOptions(prevOptions => ({ ...prevOptions, [name]: currentSelectedValues.filter(opt => opt.value.trim() !== "" && opt.value.length <= 50) }));
            formData.controls[name].setValue(selectedValues.join(','));
        } else {
            if (selectOptions[name]?.length < currentSelectedValues.length) {
                dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: "You can't select more than 10 options", type: "error" }));
                setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);

                setSelectOptions(prevOptions => ({ ...prevOptions, [name]: prevOptions[name] }));
            }
        }
    };

    const handleSubmit = async () => {
        try {
            if (formData.status !== "INVALID") {
                const finalData = {
                    ...formData.value,
                    state: formData.value.state.value,

                    geographicCoverage: selectOptions.geographicCoverage.map(item => item.value).join(","),
                    propertyType: selectOptions.propertyType.map(item => item.value).join(","),
                    prescreenRequirement: selectOptions.prescreenRequirement.map(item => item.value).join(","),
                    restrictedIndustry: selectOptions.restrictedIndustry.map(item => item.value).join(","),
                    preferredIndustry: selectOptions.preferredIndustry.map(item => item.value).join(","),
                    prepaymentPenalty: selectOptions.prepaymentPenalty.map(item => item.value).join(","),
                    requiredDocuments: selectOptions.requiredDocuments.map(item => item.value).join(","),
                    term: selectOptions.term.map(item => item.value).join(","),

                }
                setLoading(true)
                console.log(finalData);

                const response = await axiosInstance.post(`/api/User/UpdateLenderConsole?SessionId=${localStorage.getItem('sessionId')}`, finalData);
                setLoading(false)
                if (response.data.status == 200) {
                    dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: response.data.message, }));
                    setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
                    getLenderDetails()
                } else {
                    dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: response.message, type: "error" }));
                    setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
                }
            } else {
                markAllAsTouched(formData);
            }
        } catch (error) {
            setLoading(false)
            dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: error.message, type: "error" }));
            setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
        }
    };

    const checkForProgTab = ["minLoanSize", "interestRate", "maxInterestRate"]

    // Marking all input fields as touched if the form is not valid
    const markAllAsTouched = (formGroup) => {
        Object.keys(formGroup.controls).forEach((controlName) => {
            const control = formGroup.get(controlName);
            if (control && control.status === "INVALID") {
                if (activeTab === "Lender_Information" && checkForProgTab.includes(controlName)) {
                    setActiveTab("Program_Detail")
                } else {
                    setActiveTab("Lender_Information")
                }
                control.active = true;
                control.touched = true;
                if (document.getElementById(controlName)) {
                    document.getElementById(controlName).focus();
                }
            }
        });
    };

    const handleApproveProfile = async () => {
        try {
            setLoading(true)
            const response = await axiosInstance.post(`/api/User/ApprovedLenderConsoleUser`, {}, {
                params: {
                    UserId: info.id,
                    SessionId: localStorage.getItem('sessionId'),
                    ApprovedBy: user.user.id
                }
            });
            setLoading(false)
            if (response.data.status == 200) {
                dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: response.data.message, }));
                setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
                getLenderDetails()
            } else {
                dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: response.message, type: "error" }));
                setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
            }
        } catch (error) {
            setLoading(false)
            dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: error.message, type: "error" }));
            setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
        }
    }

    const AllCriteriaData = () => {
        return (
            <>
                <div className="lm-nav-tabs pfs-radio-tabs  radio-tabs">
                    <ul className="nav nav-tabs" id="laTab" role="tablist">
                        <li className="nav-item">
                            <button className={`nav-link ${activeTab === "Lender_Information" ? "active" : ""}`} onClick={() => handleTabClick("Lender_Information")}>
                                Lender’s Information
                            </button>
                        </li>
                        <li className="nav-item">
                            <button className={`nav-link ${activeTab === "Program_Detail" ? "active" : ""}`} onClick={() => handleTabClick("Program_Detail")}>
                                Program Detail
                            </button>
                        </li>
                    </ul>
                </div>

                <div className="tab-content pt-3" id="laTabContent">

                    <div className={`tab-pane fade ${activeTab === "Lender_Information" ? "show active" : ""}`} id="Lender_Information">
                        <FieldGroup
                            control={formData}
                            render={({ get, invalid }) => (
                                <>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="row">
                                                <div className="col-md-4 col-lg-3">
                                                    <div className="form-group">
                                                        <FormField
                                                            name="firstName"
                                                            label="First Name"
                                                            id="firstName"
                                                            required={true}
                                                            placeholder="First Name"
                                                            onlyCharacters={true}
                                                            errorMessage="Please enter a valid first name"
                                                            maxlength={50}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-4 col-lg-3">
                                                    <div className="form-group">
                                                        <FormField
                                                            name="lastName"
                                                            label="Last Name"
                                                            id="lastName"
                                                            required={true}
                                                            placeholder="Last Name"
                                                            onlyCharacters={true}
                                                            errorMessage="Please enter a valid last name"
                                                            maxlength={50}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-4 col-lg-3">
                                                    <div className="form-group">
                                                        <FormField
                                                            name="lendingInstitution"
                                                            label="Lending Institution"
                                                            id="lendingInstitution"
                                                            required={true}
                                                            placeholder="Lending Institution"
                                                            errorMessage="Please enter a valid lending institution"
                                                            maxlength={50}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="row">
                                                <div className="col-md-4 col-lg-3">
                                                    <div className="form-group mb-2">
                                                        <ContactNumberField
                                                            name="officeNumber"
                                                            label="Office Number"
                                                            id="officeNumber"
                                                            required={true}
                                                            errorMessage="Please enter a valid office number"
                                                        />
                                                    </div>
                                                    <div className="form-group form-check">
                                                        <FieldControl
                                                            name="isPrimary_OfficeNumber"
                                                            render={({ handler }) => (
                                                                <>
                                                                    <input type="checkbox" className="form-check-input" id="isPrimary_OfficeNumber" checked={handler().value}
                                                                        onChange={(e) => {
                                                                            handler().onChange(e.target.checked)
                                                                            formData.controls.isPrimary_CellNumber.setValue(false)
                                                                        }}
                                                                    />
                                                                    <label className="form-check-label" htmlFor="isPrimary_OfficeNumber">Mark as primary </label>
                                                                </>
                                                            )}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-4 col-lg-3">
                                                    <div className="form-group mb-2">
                                                        <ContactNumberField
                                                            name="cellNumber"
                                                            label="Cell Number"
                                                            id="cellNumber"
                                                            required={true}
                                                            errorMessage="Please enter a valid cell number"
                                                        />
                                                    </div>
                                                    <div className="form-group form-check">
                                                        <FieldControl
                                                            name="isPrimary_CellNumber"
                                                            render={({ handler }) => (
                                                                <>
                                                                    <input type="checkbox" className="form-check-input" id="isPrimary_CellNumber" checked={handler().value}
                                                                        onChange={(e) => {
                                                                            handler().onChange(e.target.checked)
                                                                            formData.controls.isPrimary_OfficeNumber.setValue(false)
                                                                        }}
                                                                    />
                                                                    <label className="form-check-label" htmlFor="isPrimary_CellNumber">Mark as primary </label>
                                                                </>
                                                            )}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="row">

                                            </div>
                                        </div>

                                        <div className="col-md-4 col-lg-3">
                                            <div className="form-group">
                                                <FormField
                                                    name="fax"
                                                    label="Fax"
                                                    id="fax"
                                                    placeholder="Fax"
                                                    maxlength={15}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-lg-3">
                                            <div className="form-group">
                                                <FormField
                                                    name="email"
                                                    label="Email Address"
                                                    id="email"
                                                    required={true}
                                                    placeholder="Email Address"
                                                    patternRequired={true}
                                                    errorMessage="Please enter a valid email address"
                                                    disabled={true}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-lg-3">
                                            <div className="form-group">
                                                <FormField
                                                    name="website"
                                                    label="Website"
                                                    id="website"
                                                    placeholder="Website"
                                                    maxlength={50}
                                                />
                                            </div>
                                        </div>

                                        {/* <!-- lm common divider to break the section  --> */}
                                        <div className="lm-divider mx-3 mt-2"></div>
                                        {/* <!-- lm common divider to break the section  --> */}

                                        <div className="col-md-4 col-lg-3">
                                            <div className="form-group">
                                                <FormField
                                                    name="addressLine1"
                                                    placeholder="Enter an Address"
                                                    id="addressLine1"
                                                    label="Street Address"
                                                    required={true}
                                                    errorMessage="Please enter a valid street address"
                                                    maxlength={100}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-lg-3">
                                            <div className="form-group">
                                                <FormField
                                                    name="addressLine2"
                                                    placeholder="Enter an Address"
                                                    id="addressLine2"
                                                    label="Street Address 2"
                                                    maxlength={100}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-lg-3">
                                            <div className="form-group">
                                                <FormField
                                                    name="city"
                                                    label="City"
                                                    id="city"
                                                    required={true}
                                                    placeholder="City"
                                                    onlyCharacters={true}
                                                    errorMessage="Please enter a valid city"
                                                    maxlength={50}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-lg-3">
                                            <FieldControl
                                                name="state"
                                                render={({ handler, touched, hasError, meta, }) => (
                                                    <div className="form-group">
                                                        <label htmlFor="state">State<span className="asterisk">*</span></label>
                                                        <Select
                                                            className="basic-single"
                                                            classNamePrefix="select"
                                                            id="state"
                                                            name="state"
                                                            options={usastate}
                                                            value={usastate.find(option => option.value === handler().value.value)}
                                                            {...handler()}
                                                        />
                                                        {/* Error messages below */}
                                                        <span className="error-message">{touched && hasError("required") && `State is required`}</span>
                                                    </div>
                                                )}
                                            />
                                        </div>
                                        <div className="col-md-4 col-lg-3">
                                            <div className="form-group">
                                                <FormField
                                                    name="zipCode"
                                                    label="ZIP Code"
                                                    id="zipCode"
                                                    required={true}
                                                    placeholder="ZIP Code"
                                                    maxlength={5}
                                                    onlyNumbers={true}
                                                    patternRequired={true}
                                                    patternMessage="Please enter a valid ZIP code"
                                                    errorMessage="Please enter a valid ZIP code"
                                                />
                                            </div>
                                        </div>

                                        {/* <!-- lm common divider to break the section  --> */}
                                        <div className="lm-divider mx-3 mt-2"></div>
                                        {/* <!-- lm common divider to break the section  --> */}

                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <FormTextarea
                                                    name="notes"
                                                    id="notes"
                                                    label="Notes"
                                                    placeholder="Write notes"
                                                    maxlength={250}
                                                />
                                            </div>
                                        </div>

                                    </div>
                                    <div className="sticky-btn-footer drawer-footer ml-0 d-flex justify-content-end">
                                        <button type="button" className="lm-button1 ml-auto" onClick={handleSubmit}>Save</button>
                                    </div>
                                </>
                            )}
                        />
                    </div>

                    <div className={`tab-pane fade ${activeTab === "Program_Detail" ? "show active" : ""}`} id="Program_Detail">
                        <FieldGroup
                            control={formData}
                            render={({ get, invalid }) => (
                                <>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="label mb-2">Program</div>
                                            <div className="d-flex flex-wrap">
                                                <div className="form-group form-check mr-4">
                                                    <FieldControl
                                                        name="isPrimary_SBA"
                                                        render={({ handler }) => (
                                                            <>
                                                                <input type="checkbox" className="form-check-input" id="isPrimary_SBA" checked={handler().value} {...handler()} />
                                                                <label className="form-check-label" htmlFor="isPrimary_SBA">SBA</label>
                                                            </>
                                                        )}
                                                    />
                                                </div>
                                                <div className="form-group form-check mr-4">
                                                    <FieldControl
                                                        name="isPrimary_Conventional"
                                                        render={({ handler }) => (
                                                            <>
                                                                <input type="checkbox" className="form-check-input" id="isPrimary_Conventional" checked={handler().value} {...handler()} />
                                                                <label className="form-check-label" htmlFor="isPrimary_Conventional">Conventional</label>
                                                            </>
                                                        )}
                                                    />
                                                </div>
                                                <div className="form-group form-check mr-4">
                                                    <FieldControl
                                                        name="isPrimary_MCA"
                                                        render={({ handler }) => (
                                                            <>
                                                                <input type="checkbox" className="form-check-input" id="isPrimary_MCA" checked={handler().value} {...handler()} />
                                                                <label className="form-check-label" htmlFor="isPrimary_MCA">MCA</label>
                                                            </>
                                                        )}
                                                    />
                                                </div>
                                                <div className="form-group form-check mr-4">
                                                    <FieldControl
                                                        name="isPrimary_FixAndFlip"
                                                        render={({ handler }) => (
                                                            <>
                                                                <input type="checkbox" className="form-check-input" id="isPrimary_FixAndFlip" checked={handler().value} {...handler()} />
                                                                <label className="form-check-label" htmlFor="isPrimary_FixAndFlip">Fix & Flip</label>
                                                            </>
                                                        )}
                                                    />
                                                </div>
                                                <div className="form-group form-check mr-4">
                                                    <FieldControl
                                                        name="isPrimary_AlternativeRealState"
                                                        render={({ handler }) => (
                                                            <>
                                                                <input type="checkbox" className="form-check-input" id="isPrimary_AlternativeRealState" checked={handler().value} {...handler()} />
                                                                <label className="form-check-label" htmlFor="isPrimary_AlternativeRealState">Alternative (Real Estate)</label>
                                                            </>
                                                        )}
                                                    />
                                                </div>
                                                <div className="form-group form-check mr-4">
                                                    <FieldControl
                                                        name="isPrimary_AlternativeOthers"
                                                        render={({ handler }) => (
                                                            <>
                                                                <input type="checkbox" className="form-check-input" id="isPrimary_AlternativeOthers" checked={handler().value} {...handler()} />
                                                                <label className="form-check-label" htmlFor="isPrimary_AlternativeOthers">Alternative (Other)</label>
                                                            </>
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-md-4 col-lg-3">
                                            <div className="form-group">
                                                <CreatableSelectComponent
                                                    name="geographicCoverage"
                                                    id="geographicCoverage"
                                                    label="Geographic Coverage"
                                                    value={selectOptions.geographicCoverage}
                                                    onChange={handleSelectChange}
                                                    placeholder="Select..."
                                                    options={usastate}
                                                    isMulti
                                                    isClearable
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-lg-3">
                                            <div className="form-group">
                                                <AmountContainer
                                                    name="ficoMin"
                                                    label="Min FICO"
                                                    id="ficoMin"
                                                    placeholder="Write a minimum Fico score"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-lg-6">
                                            <div className="form-group">
                                                <CreatableSelectComponent
                                                    name="propertyType"
                                                    id="propertyType"
                                                    label="Property Type"
                                                    value={selectOptions.propertyType}
                                                    onChange={handleSelectChange}
                                                    placeholder="Select..."
                                                    options={propertyType}
                                                    isMulti
                                                    isClearable
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-lg-6">
                                            <div className="form-group">
                                                <CreatableSelectComponent
                                                    name="prescreenRequirement"
                                                    id="prescreenRequirement"
                                                    label="Prescreen Documents"
                                                    value={selectOptions.prescreenRequirement}
                                                    onChange={handleSelectChange}
                                                    placeholder="Select..."
                                                    options={prescreenRequirement}
                                                    isMulti
                                                    isClearable
                                                />
                                            </div>
                                        </div>

                                        {/* <!-- lm common divider to break the section  --> */}
                                        <div className="lm-divider mx-3 mt-2"></div>
                                        {/* <!-- lm common divider to break the section  --> */}

                                        <div className="col-md-4 col-lg-3">
                                            <div className="form-group input-dollar">
                                                <AmountContainer
                                                    name="minLoanSize"
                                                    label="Min Loan Size"
                                                    id="minLoanSize"
                                                    placeholder="0.00"
                                                    patternMessage="Min loan size should be greater than $5,000"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-lg-3">
                                            <div className="form-group input-dollar">
                                                <AmountContainer
                                                    name="maxLoanSize"
                                                    label="Max Loan Size"
                                                    id="maxLoanSize"
                                                    placeholder="0.00"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-lg-3">
                                            <div className="form-group input-percentage">
                                                <AmountContainer
                                                    name="interestRate"
                                                    label="Min Interest Rate"
                                                    id="interestRate"
                                                    placeholder="0.00"
                                                    patternMessage="Interest rate should be greater than 0%"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-lg-3">
                                            <div className="form-group input-percentage">
                                                <AmountContainer
                                                    name="maxInterestRate"
                                                    label="Max Interest Rate"
                                                    id="maxInterestRate"
                                                    placeholder="0.00"
                                                    patternMessage="Interest rate should be greater than 0%"
                                                />
                                            </div>
                                        </div>


                                        <div className="col-md-4 col-lg-6">
                                            <div className="form-group">
                                                <CreatableSelectComponent
                                                    name="term"
                                                    id="term"
                                                    label="Term (years)"
                                                    value={selectOptions.term}
                                                    onChange={handleSelectChange}
                                                    placeholder="Select..."
                                                    isMulti
                                                    isClearable
                                                />
                                            </div>
                                        </div>

                                        <div className="col-md-4 col-lg-3">
                                            <div className="form-group input-percentage">
                                                <AmountContainer
                                                    name="maxLtv"
                                                    label="Max LTV"
                                                    id="maxLtv"
                                                    placeholder="0.00"
                                                />
                                            </div>
                                        </div>

                                        <div className="col-md-4 col-lg-3">
                                            <div className="form-group input-percentage">
                                                <AmountContainer
                                                    name="dscr"
                                                    label="DSCR"
                                                    id="dscr"
                                                    placeholder="0.00"
                                                />
                                            </div>
                                        </div>

                                        <div className="col-md-6 col-lg-6">
                                            <div className="form-group">
                                                <CreatableSelectComponent
                                                    name="preferredIndustry"
                                                    id="preferredIndustry"
                                                    label="Preferred Industry"
                                                    value={selectOptions.preferredIndustry}
                                                    onChange={handleSelectChange}
                                                    placeholder="Select..."
                                                    options={propertyType}
                                                    isMulti
                                                    isClearable
                                                />
                                            </div>
                                        </div>

                                        <div className="col-md-6 col-lg-6">
                                            <div className="form-group">
                                                <CreatableSelectComponent
                                                    name="restrictedIndustry"
                                                    id="restrictedIndustry"
                                                    label="Restricted Industry"
                                                    value={selectOptions.restrictedIndustry}
                                                    onChange={handleSelectChange}
                                                    placeholder="Select..."
                                                    isMulti
                                                    isClearable
                                                />
                                            </div>
                                        </div>

                                        <div className="col-md-4 col-lg-3">
                                            <div className="form-group">
                                                <FormRadio
                                                    name="collateral"
                                                    label="Collateral"
                                                    options={[
                                                        { label: 'Yes', value: 'true' },
                                                        { label: 'No', value: 'false' },
                                                    ]}
                                                />
                                            </div>
                                        </div>

                                        <div className="col-md-4 col-lg-3">
                                            <div className="form-group input-percentage">
                                                <AmountContainer
                                                    name="equity"
                                                    label="Min Equity (%)"
                                                    id="equity"
                                                    placeholder="0.00"
                                                />
                                            </div>
                                        </div>

                                        <div className="col-md-6 col-lg-6">
                                            <div className="form-group">
                                                <CreatableSelectComponent
                                                    name="prepaymentPenalty"
                                                    id="prepaymentPenalty"
                                                    label="Prepayment Penalty"
                                                    value={selectOptions.prepaymentPenalty}
                                                    onChange={handleSelectChange}
                                                    placeholder="Select..."
                                                    isMulti
                                                    isClearable
                                                />
                                            </div>
                                        </div>

                                        {/* <!-- lm common divider to break the section  --> */}
                                        <div className="lm-divider mx-3 mt-2"></div>
                                        {/* <!-- lm common divider to break the section  --> */}
                                        <div className="col-12">
                                            <h3 className="lm-card-heading">
                                                Required Documents
                                            </h3>
                                        </div>

                                        <div className="col-12">
                                            <div className="row">
                                                <div className="col-md-6 col-lg-6">
                                                    <div className="form-group">
                                                        <CreatableSelectComponent
                                                            name="requiredDocuments"
                                                            id="requiredDocuments"
                                                            label="Required Documents"
                                                            value={selectOptions.requiredDocuments}
                                                            onChange={handleSelectChange}
                                                            placeholder="Select..."
                                                            options={documentList}
                                                            isMulti
                                                            isClearable
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-12">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <FormTextarea
                                                            name="additionalField"
                                                            label="Additional Information"
                                                            id="additionalField"
                                                            placeholder="Write text"
                                                            maxlength={250}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-12 mb-2">
                                            {
                                                formData.value.approvedBy !== "0" && (
                                                    <>
                                                        <p className="mb-0 mt-3 text-14">Approved by <b>{formData.value.approvedName}</b></p>
                                                        <small className="label">{moment.utc(formData.value.approvalDate).local().format('MMM DD, YYYY, hh:mm:A')}</small>
                                                    </>
                                                )
                                            }

                                            {
                                                formData.value.approvedBy === "0" && user.user.roleName === "Admin" && (
                                                    <div>
                                                        <button className="lm-button2 mt-2 mb-3" onClick={handleApproveProfile}>Approve Profile</button>
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </div>
                                    <div className="sticky-btn-footer drawer-footer ml-0 d-flex justify-content-end">
                                        <button type="button" className="lm-button1 ml-auto" onClick={handleSubmit}>Save</button>
                                    </div>
                                </>
                            )}
                        />
                    </div>
                </div>
            </>
        )
    }

    return (
        <>
            {
                user.user.roleName === "Lender" && (
                    <>
                        <SidePanel />
                        <ProfileHeader />
                    </>
                )
            }


            {loading ? <Preloader /> : (
                <>
                    {
                        user.user.roleName === "Lender" ? (
                            <div className="dashboard-wrap loan-app-screen mb-5">
                                <AllCriteriaData />
                            </div>
                        ) : (
                            <AllCriteriaData />
                        )
                    }

                </>
            )}
        </>
    );
}

export default LendingCriteria;