import React, { useState, useEffect, useCallback } from 'react';
import Preloader from '../plugins/Preloader';
import no_data_icon from '../assets/img/no_data_icon_new.svg';
import { Link } from "react-router-dom";
import phone_icon_grid from '../assets/icons/phone_icon_grid.svg';
import fax_icon_grid from '../assets/icons/fax_icon_grid.svg';
import copy_code from '../assets/icons/copy_code.svg';
import see_transactions from '../assets/icons/see_transactions.svg';
import { useDispatch } from 'react-redux';
import reducersAndActions from '../_redux/slices';
import Constants from '../helpers/Constants';
import DynamicTable from '../plugins/DynamicTable';
import { LendersHeader } from '../helpers/DropdownsAndMappers';
import { getAdminAdviserList } from '../services/inquiryServices';
import { SidePanel, ProfileHeader } from "@components";
import { UserLenderRating, LendingCriteria } from "@screens";
import Modal from 'react-bootstrap/Modal'
import requirements from '../assets/icons/requirements.svg';
import CopyLinkComponent from './user/commonFunctions/CopyLinkComponent';
import TransactionsModal from './user/commonFunctions/TransactionsModal';
import convertToIST from './user/commonFunctions/User';
import { debounce } from 'lodash';
import './styles/Users.css';
import close_popup from '../assets/icons/close_popup.svg';


function UserLenders() {
    // Meta Title
    useEffect(() => {
        document.title = "Lenders";
    }, []);

    // Preloader Start
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        dispatch(reducersAndActions.actions.sessionActions.setLoading(loading));
    }, [loading]);

    const [totalData, setTotalData] = useState(0);
    const [inquiries, setInquiries] = useState([]);
    const [pageIndex, setPageIndex] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [searchText, setSearchText] = useState("");
    const [modalData, setModalData] = useState()


    // Fetch inquiries whenever page size or page index changes
    const debouncedGetLoanApplications = useCallback(debounce(() => {
        setLoading(true);
        getInquiriesList()
    }, 1000), [pageSize, pageIndex, searchText]);


    const getInquiriesList = async () => {
        try {
            const storedSessionId = localStorage.getItem('sessionId');
            let queryObj = {
                PageIndex: pageIndex,
                PageSize: pageSize,
                RoleName: "Lender",
                SessionId: storedSessionId
            };

            if (searchText) {
                queryObj["SearchText"] = searchText;
            }
            let response = await getAdminAdviserList(queryObj);
            if (response.status == 200 && response.resultObject) {
                setInquiries(response.resultObject);
                let total = response.message.split(" ");
                setTotalData(Number(total[1]));
                setLoading(false);
            } else {
                dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: response.message, type: "error" }));
                setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
                setLoading(false);
                setTotalData(0);
                setInquiries([]);
            }
        } catch (err) {
            console.log(err);
            setLoading(false);
            setTotalData(0);
        }
    }

    useEffect(() => {
        debouncedGetLoanApplications();
        return () => {
            debouncedGetLoanApplications.cancel();
        };
    }, [debouncedGetLoanApplications]);

    // Popup function
    const [transactionsPopupShow, setTransactionsPopup] = useState(false);
    const transactionsPopupClose = () => setTransactionsPopup(false);


    const handleRatingSidebar = (data) => {
        setRatingPopup(true)
        setRatingInformation(data)
    }

    const handleRequirmentSidebar = (data) => {
        setRequirementsPopup(true)
        setRequiermentsData(data)
    }

    const dynamicTableDisplay = (data) => {
        return (
            <tr className={data.statusDesc == "Active" ? "active" : "inactive"}>
                <td>
                    <Link to={`/users/lenders-detail`} state={{ data }} className="user_name">{data.firstName} {data.lastName}</Link>
                    <br></br>
                    <span className="user_email text-light">{data.email}</span>
                </td>
                <td>{data.companyName.length > 25 ? data.companyName.substring(0, 25) + "..." : data.companyName}</td>
                <td>
                    <div className="tel_number"><img src={phone_icon_grid} alt="" /> {data.mobile}</div>
                    <div className="tel_number"><img src={fax_icon_grid} alt="" />   {data.telephone}</div>
                </td>
                {/* <td>{data.roleName}</td> */}
                <td>{data.code}</td>
                <td><div className="status">{data.statusDesc}</div></td>
                <td><Link onClick={() => handleRatingSidebar(data)}>{data.rating === '0' ? "-" : (parseFloat(data.rating)).toFixed(2)}</Link></td>
                <td className="text-light">
                    <span>
                        {(data?.lastLoginDateTime || data?.loginedUserCity || data?.loginedUserState || data?.loginedUserCountry)
                            ? (
                                <>
                                    {data?.lastLoginDateTime ? convertToIST(data.lastLoginDateTime) : null}
                                    {data?.lastLoginDateTime && (data?.loginedUserCity || data?.loginedUserState || data?.loginedUserCountry) ? <br /> : null}
                                    {[
                                        data?.loginedUserCity,
                                        data?.loginedUserState,
                                        data?.loginedUserCountry
                                    ]
                                        .filter(Boolean)
                                        .join(', ')}
                                </>
                            )
                            : 'NA'}
                    </span>
                </td>
                <td className="action-col">
                    <div className="options_btn"><span className="option_dots">...</span>
                        <div id="popoverContent" className="app_options">

                            <CopyLinkComponent
                                url={`${Constants.FRONTEND_URL}/signup`}
                                text="Copy Code"
                                imgSrc={copy_code}
                                data={data.code}
                            />
                            <div onClick={() => {
                                setTransactionsPopup(true)
                                if (data) {
                                    setModalData(data)
                                }
                            }}><img className="mr-1" src={see_transactions} /> See transactions</div>

                            <Link><div onClick={() => handleRequirmentSidebar(data)}>
                                <img className="mr-1" src={requirements} /> Requirements</div>
                            </Link>
                        </div>
                    </div>
                </td>

            </tr>
        )
    }

    const [requirementsPopupShow, setRequirementsPopup] = useState(false);
    const requirementsPopupClose = () => setRequirementsPopup(false);
    const [requiermentsData, setRequiermentsData] = useState({});

    const [ratingPopupShow, setRatingPopup] = useState(false);
    const [ratingInformation, setRatingInformation] = useState({});
    const ratingPopupClose = () => setRatingPopup(false);

    const handleChangeInput = (e) => {
        setPageIndex(1);
        setSearchText(e.target.value);
    }

    return (
        <>
            {/* SidePanel */}
            <SidePanel />

            {/* ProfileHeader */}
            <ProfileHeader />

            {loading ? (<Preloader />) : (<div>
                <div class="dashboard-wrap loan-app-screen">

                    <div className="d-flex justify-content-between filter-search-group">

                        <h3 className="user-page-heading">Lenders</h3>

                        <div className="d-flex justify-content-between search-button-group">
                            <div className="lm-search-bar mr-4">
                                <input type="text" id="searchInput" autoComplete="off" placeholder="Search by number, name, or other" value={searchText} onChange={handleChangeInput} />
                            </div>
                            <Link to="/users/lenders-detail" className="lm-button1">Add New Lenders</Link>
                        </div>
                    </div>
                    {
                        inquiries.length > 0 ?
                            <DynamicTable
                                data={inquiries}
                                dynamicTableDisplay={dynamicTableDisplay}
                                columns={LendersHeader}
                                changePageSize={setPageSize}
                                pageSize={pageSize}
                                total={totalData}
                                setList={setInquiries}
                                setPageIndex={setPageIndex}
                                pageIndex={pageIndex}
                            />
                            :
                            <div className='no-data-card text-center place-content-center'>
                                <img src={no_data_icon} alt="" className='mb-4 ml-4' />
                                <h3 className='f-700 mb-2'>No data found</h3>
                                <p className='text-light'>Check back soon! Your details will be displayed<br />here shortly.</p>
                            </div>

                    }
                    {/* <!-- adjust colSpan as per columns --> */}

                </div>

                {/* <!--see transitions drawer--> */}
                <TransactionsModal
                    transactionsPopupShow={transactionsPopupShow}
                    setTransactionsPopup={setTransactionsPopup}
                    modalData={modalData}
                />
                {/* <!--see transitions drawer--> */}


                {/* <!--Rating / Lender's Information drawer--> */}
                <Modal
                    className="fade-right drawer-modal rating_popup requirements-drawer"
                    show={ratingPopupShow}
                    onHide={() => setRatingPopup(false)}
                    aria-labelledby="requirementsPopupLabel"
                >
                    <div className="drawer-head d-flex justify-content-between">
                        <div>
                            <h3>{ratingInformation.firstName + " " + ratingInformation.lastName}</h3>
                            <span className="text-light">Performance</span>
                        </div>
                        <button type="button" className="close" onClick={ratingPopupClose}>
                            <span aria-hidden="true"><img src={close_popup} alt="" /></span>
                        </button>
                    </div>
                    <div className="drawer-body mt-3">
                        <UserLenderRating info={ratingInformation} />
                    </div>
                </Modal>
                {/* <!--Rating / Lender's Information drawer--> */}


                {/* <!--Requirement / Lender's Information drawer--> */}
                <Modal
                    className="fade-right drawer-modal rating_popup requirements-drawer"
                    show={requirementsPopupShow}
                    onHide={() => setRequirementsPopup(false)}
                    aria-labelledby="requirementsPopupLabel"
                >
                    <div className="drawer-head d-flex justify-content-between">
                        <div>
                            <h3>{requiermentsData.firstName + " " + requiermentsData.lastName}</h3>
                            <span className="text-light">Requirements</span>
                        </div>
                        <button type="button" className="close" onClick={requirementsPopupClose}>
                            <span aria-hidden="true"><img src={close_popup} alt="" /></span>
                        </button>
                    </div>
                    <div className="drawer-body mt-3">
                        <LendingCriteria info={requiermentsData} />
                    </div>
                </Modal>
                {/* <!--Requirement / Lender's Information drawer--> */}


            </div>)}

        </>
    );
}

export default UserLenders;