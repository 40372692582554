import React from 'react';
import { FieldControl } from 'react-reactive-form';
import PropTypes from 'prop-types';
import { formatNumberWithCommas } from '../../helpers/DynamicFunctions';

const AmountContainer = ({ name, label = "", placeholder, disabled = false, isRequired = false, maxlength = 14, setMaxValidation = false, setMinValidation = false, maxValue, minValue, setUpdateFields = '', patternMessage }) => (
    <FieldControl
        name={name}
        render={({ handler, touched, hasError, meta }) => {
            const handlerProps = handler();
            handlerProps.value = handlerProps.value !== "0.00" ? formatNumberWithCommas(handlerProps.value) : null;
            const checkForUpdateedFields = ["borrowerInjection", "borrowerInjection1", 'totalTransaction'];

            const handleChange = (e) => {
                if (checkForUpdateedFields.includes(e.target.id)) {
                    setUpdateFields(true);
                }

                // Remove all characters except digits, decimal point, and the negative sign at the start
                let rawValue = e.target.value.replace(/[^0-9.-]/g, '');

                // Ensure the negative sign is only at the beginning
                if (rawValue.includes('-')) {
                    rawValue = `-${rawValue.replace(/-/g, '')}`; // Remove any other negative signs and put it only at the start
                }

                // Split the value into integer and decimal parts
                const parts = rawValue.split('.');

                // Ensure only one decimal point
                if (parts.length > 2) {
                    rawValue = `${parts[0]}.${parts[1]}`; // Remove additional decimal points
                }

                // Limit decimal places to two digits
                if (parts[1] && parts[1].length > 2) {
                    rawValue = `${parts[0]}.${parts[1].substring(0, 2)}`;
                }

                // Format the integer part with commas, ignoring the negative sign while formatting
                const integerPart = parts[0].replace(/^-/, ''); // Remove negative sign temporarily
                const formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                const formattedValue = rawValue.startsWith('-') ? `-${formattedInteger}` : formattedInteger;

                // Combine formatted integer and decimal parts
                const finalFormattedValue = parts.length > 1 ? `${formattedValue}.${parts[1]}` : formattedValue;

                // Update the input field value
                e.target.value = finalFormattedValue;

                // Call the onChange handler to update the form value
                if (handlerProps.onChange) {
                    if (setMaxValidation && finalFormattedValue.replace(/[^0-9.-]/g, '') > maxValue) {
                        handlerProps.onChange(maxValue.toString());
                    } else if (setMinValidation && finalFormattedValue.replace(/[^0-9.-]/g, '') < minValue) {
                        handlerProps.onChange(minValue.toString());
                    } else {
                        handlerProps.onChange(finalFormattedValue || "");
                    }
                }
            };

            return (
                <div className="form-group input-dollar">
                    <label htmlFor={name}>
                        {label}{isRequired && <span className="asterisk">*</span>}
                    </label>
                    <input
                        type="text"
                        className={`form-control ${touched && hasError() ? 'is-invalid' : ''}`}
                        id={name}
                        placeholder={placeholder}
                        {...handlerProps}
                        maxLength={maxlength}
                        disabled={disabled}
                        onChange={handleChange}
                    />

                    {/* Error messages below */}
                    {touched && hasError("negitiveOrZero") && <><span className="error-message">Value should be negitive or 0</span><br /></>}

                    {touched && hasError("pattern") && <><span className="error-message">Amount should be only numeric values</span><br /></>}
                    {touched && hasError("borrowAmountMaxIssue") && <><span className="error-message">Amount should be less than or equal to 10000000000</span><br /></>}
                    {touched && hasError("required") && <><span className="error-message">Amount is required</span><br /></>}

                    {touched && hasError("patternMinMax") && <><span className="error-message">{patternMessage}</span><br /></>}
                </div>
            );
        }}
        meta={{ placeholder: placeholder }}
    />
);

AmountContainer.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    isRequired: PropTypes.bool,
    maxlength: PropTypes.number,
    setMaxValidation: PropTypes.bool,
    setMinValidation: PropTypes.bool,
    maxValue: PropTypes.number,
    minValue: PropTypes.number
};

export default AmountContainer;