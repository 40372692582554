import axios from "../helpers/axiosConfig";
import { getLocalStorageData } from "./storageData";

export const getAIRePhaseContent = async (contentToRephrase = "") => {
    try {
        const response = await axios.get(`/api/OpenAI/GetAIRephraseContent`, {
            params: { contentToRephrase, SessionId: getLocalStorageData("sessionId") }
        });
        if (response.data) {
            return response.data.resultObject?.replaceAll('"', '');
        } else {
            return response.error;
        }
    } catch (error) {
        console.error("Error:", error.message);
        throw error;
    }
}


export const JSONParseApiResponse = (response) => {
    try {
        // Step 1: Check if the response is already an object
        if (typeof response === "object") {
            return response;
        }

        // Fix common issues with JSON formatting
        let sanitizedResponse = response
            .replace(/\\r\\n/g, "")                   // Remove Windows-style newline characters
            .replace(/\\n/g, "")                      // Remove lone newline escape sequences
            .replace(/\\"/g, '"')                     // Fix improperly escaped quotes
            .replace(/""([^"]+)""/g, '"$1"')          // Fix double-quoted strings within strings
            .replace(/\\\\/g, "\\")                   // Restore valid backslashes
            .replace(/: ?"\\\\"/g, ': ""')            // Fix improperly escaped empty strings
            .replace(/: ?""/g, ': ""')                // Ensure empty strings are valid JSON strings
            .replace(/": ?"([^"]+)\\?"/g, '": "$1"')  // Fix improperly escaped string values
            .replace(/": ?"?undefined"?/g, '": null')  // Handle undefined values as null
            .replace(/""([^"]+)""/g, '"$1"')          // Handle misplaced double quotes
            .replace(/"\s*test data\s*"/g, '"test data"')  // Clean improper string formatting like "test data" test
            .replace(/^"([\s\S]*)"$/, "'$1'");

        return JSON.parse(sanitizedResponse); // Safely parse
    } catch (error) {
        console.log(response)
        console.error("Error parsing response:", error.message);
        return null; // Return null if parsing fails
    }
}
