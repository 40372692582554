import React, { useState, useEffect, createRef, useRef } from 'react';
import Modal from 'react-bootstrap/Modal'
import Select from 'react-select';
import DatePicker from "react-datepicker";
import { SidePanel, ProfileHeader } from "@components";
import Preloader from '../../plugins/Preloader';
import grid_company_icon from '../../assets/icons/grid_company_icon.svg';
import edit_pen from '../../assets/icons/edit-pen.svg';
import info_tooltip from '../../assets/icons/Info-tooltip.svg';
import feild_minus_icon from '../../assets/icons/feild_minus_icon.svg';
import feild_plus_icon from '../../assets/icons/feild_plus_icon.svg';
import lm_edit_icon from '../../assets/icons/lm-edit-icon.svg';
import Constants from "../../helpers/Constants";
import DynamicTable from "../../plugins/DynamicTable";
import { OwnerListHeadsers } from "../../helpers/DropdownsAndMappers";
import reducersAndActions from "../../_redux/slices/index";
import { FieldGroup, FormBuilder, Validators, FieldControl, } from "react-reactive-form";
import axios from '../../helpers/axiosConfig';
import FormField from "../../components/FormField/FormField";
import { decryptText, encryptText, formatDate, zipCodeValidator } from "../../helpers/DynamicFunctions";
import AmountContainer from '../../components/Amount/AmountContainer';
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { getUSAStates } from "../../services/commonService";
import { getSessionId } from "../../services/storageData";
import { GetBussinessNameListByLoanId, GetBasicInfoLoanApplicationById } from "../../services/loanApplicationServices";
import { GetBussinessProfileByLoanId } from "../../services/businessProfileServices";
import OpenAIComponent from "../../components/OpenAI/OpenAIComponent";
import { staticBusinessTaxReturnList } from '../../helpers/staticContentData';

let loanId;
let objloanId;
let stateLists = [];

const validateNumbers = (control) => {
    // Check if the value is an empty string or a valid positive number
    if (!control.value || /^[0-9]+$/.test(control.value)) {
        return null; // valid
    }
    return { invalidNumber: true };
}


const removeAllCheckings = (formGroup, keyName) => {
    const checkings = formGroup.get(keyName);
    while (checkings && checkings.length) {
        checkings.removeAt(0);
    }
}

// Reactive Form Group BusinessHistoryForm ----------------------------------------------------------
let BusinessHistoryForm = FormBuilder.group({
    businessAddressLine1: ["",],
    businessAddressLine2: [""],
    BusinessState: ["",],
    BusinessCity: ["",],
    businessZIPCode: [""],
    businessName: ['', []],
    anotherAddressLine1: [""],
    anotherAddressLine2: [""],
    anotherCity: [""],
    anotherState: [""],
    anotherZIPCode: ["", [validateNumbers, zipCodeValidator]],
    taxReturnForm: [""],
    isBusinessTaxesPriorYear: [""],
    inceptionDate: [""],
    naicS_Code: [""],
    chkTaxAddress: [""],
    isIndividualOwner: [""],
    isEntity: [""],
    isCrime: [""],
    reasonForCrime: [""],
    isChooseEntity: [""],
    owners: [[]],
    ownersList: [""],
    isYourBusinessFranchise: [""],
    nameOfFranchise: [""],
    isOC: [""],
    isEpc: [""],
    reHoldingCompanyYes: [""],
    reHoldingCompanyNo: [""],
    reHoldingCompanyYes1: [""],
    reHoldingCompanyNo1: [""],
    txtOCEntityName: [""],
    txtREHCEntityName: [""],
    numberOfEmpoyee: ["", [validateNumbers]],
    numberOfEmpoyeeAffiliated: ["", [validateNumbers]],
    numberOfEmpoyeeHire: ["", [validateNumbers]],
    isBankruptcy: [""],
    bankruptcyDetail: [""],
    typeOfBankruptcy: [""],
    dateOfFilingBankruptcy: [""],
    dateOfDischargeBankruptcy: [""],
    presentlyInBanckruptcyDetail: [""],
    presentlyInBanckruptcyActionDate: [""],
    presentlyInBanckruptcyReason: [""],
    president: ["", [Validators.required]],
    vicePresident: [""],
    secretary: ["", [Validators.required]],
    treasurer: [""],
    isPresentlyInBanckruptcy: [""],
    isAppliedGovernmentLoan: [""],
    isAppliedGovDelinquent: [""],
    reasonForDefault: [""],
    isInvolvedLawsuits: [""],
    dateCommencementLawsuit: [""],
    partiesLawsuit: [""],
    involvedLawsuitsDetail: [""],
    productsOrServicesExported: [""],
    estimatedTotalExportSales: [""],
    countriesOfExport1: [""],
    countriesOfExport2: [""],
    countriesOfExport3: [""],
    usedPackagerBroker: [""],
    revenuesDerivedFromGambling: [""],
    LoanId: [""],
    revenuesDerivedFromGamblingExplain_Additional: [""],
    noSBAEmployee: [""],
    noFormerSBAEmployee: [""],
    noMemberOfCongress: [""],
    noGovernmentEmployee: [""],
    noMemberEmployeeOfSmallBusiness: [""],
    entityOwnedBy: [""],
    ownedBySelectedEntity: [""],
    realEstateHoldingEntity: [""],
    everFilledBankruptcyReason: [""],
    howBrowerFileTaxes: [""],
    taxAddressLine1: [""],
    taxAddressLine2: [""],
    taxCity: [""],
    taxState: [""],
    taxZIPCode: [""],
    mailingAddressLine1: ["", [Validators.required]],
    mailingAddressLine2: [""],
    mailingCity: ["", [Validators.required]],
    mailingState: ["", [Validators.required]],
    mailingZIPCode: ["", [Validators.required, validateNumbers, zipCodeValidator]],
    chkMaillingAddress: [""],
    chkPropertyAddress: [""],
    presidentEmail: [""],
    vicePresidentEmail: [""],
    secretaryEmail: [""],
    treasurerEmail: [""],
    govDelinquentDetail: [""],
});

const tempEmptyObject = {
    "businessAddressLine1": "",
    "businessAddressLine2": "",
    "businessCity": "",
    "businessState": "",
    "businessZIPCode": "",
    "businessName": "",
    "anotherAddressLine1": "",
    "anotherAddressLine2": "",
    "anotherCity": "",
    "anotherState": "",
    "anotherZIPCode": "",
    "taxReturnForm": "",
    "isBusinessTaxesPriorYear": false,
    "inceptionDate": "",
    "naicS_Code": "",
    "chkTaxAddress": false,
    "isIndividualOwner": true,
    "isEntity": false,
    "isCrime": false,
    "reasonForCrime": "",
    "isChooseEntity": false,
    "owners": [],
    "ownersList": [],
    "isYourBusinessFranchise": false,
    "nameOfFranchise": "",
    "isOC": false,
    "isEpc": false,
    "reHoldingCompanyYes": false,
    "reHoldingCompanyNo": false,
    "reHoldingCompanyYes1": false,
    "reHoldingCompanyNo1": false,
    "txtOCEntityName": "",
    "txtREHCEntityName": "",
    "numberOfEmpoyee": 0,
    "numberOfEmpoyeeAffiliated": 0,
    "numberOfEmpoyeeHire": 0,
    "isBankruptcy": false,
    "bankruptcyDetail": "",
    "typeOfBankruptcy": "",
    "dateOfFilingBankruptcy": "",
    "dateOfDischargeBankruptcy": "",
    "presentlyInBanckruptcyDetail": "",
    "presentlyInBanckruptcyActionDate": "",
    "presentlyInBanckruptcyReason": "",
    "president": "",
    "vicePresident": "",
    "secretary": "",
    "treasurer": "",
    "isPresentlyInBanckruptcy": false,
    "isAppliedGovernmentLoan": false,
    "isAppliedGovDelinquent": false,
    "reasonForDefault": "",
    "isInvolvedLawsuits": false,
    "dateCommencementLawsuit": "",
    "partiesLawsuit": "",
    "involvedLawsuitsDetail": "",
    "productsOrServicesExported": "",
    "estimatedTotalExportSales": "",
    "countriesOfExport1": "",
    "countriesOfExport2": "",
    "countriesOfExport3": "",
    "usedPackagerBroker": false,
    "revenuesDerivedFromGambling": false,
    "revenuesDerivedFromGamblingExplain_Additional": "",
    "noSBAEmployee": false,
    "noFormerSBAEmployee": false,
    "noMemberOfCongress": false,
    "noGovernmentEmployee": false,
    "noMemberEmployeeOfSmallBusiness": false,
    "productsOrServicesExported": false,
    "entityOwnedBy": "",
    "ownedBySelectedEntity": "",
    "realEstateHoldingEntity": "",
    "everFilledBankruptcyReason": "",
    "howBrowerFileTaxes": "",
    "taxAddressLine1": "",
    "taxAddressLine2": "",
    "taxCity": "",
    "taxState": "",
    "taxZIPCode": "",
    "mailingAddressLine1": "",
    "mailingAddressLine2": "",
    "mailingCity": "",
    "mailingState": "",
    "mailingZIPCode": "",
    "chkMaillingAddress": "",
    "chkPropertyAddress": "",
    "presidentEmail": "",
    "vicePresidentEmail": "",
    "secretaryEmail": "",
    "treasurerEmail": "",
    "govDelinquentDetail": "",
};


function isValidDate(dateString) {
    if (!dateString) return false;
    const date = new Date(dateString);
    return !isNaN(date.getTime());
}

function BusinessProfile({ backPreviousTab }) {
    const dispatch = useDispatch();
    const params = useParams();

    const [BusinessOptions, setBusinessOptions] = useState([]);
    const [entityOptions, setEntityOptions] = useState([]);

    const [selectedEntityOption, setSelectedEntityOption] = useState(null);
    const [selectedownedByEntity, setSelectedownedByEntity] = useState(null);
    const [selectedRealEstateHoldingEntity, setSelectedRealEstateHoldingEntity] = useState(null);

    const [selectedBusinessState, setSelectedBusinessState] = useState(null);
    const [selectedAnotherState, setAnotherState] = useState(null);
    const [selectedTaxReturnState, setSelectedTaxReturnState] = useState(null);
    const [selectedMalingState, setSelectedMalingState] = useState(null);

    const [isVisible, setIsVisible] = useState(true);
    const [modelBusinessType, setModelBusinessType] = useState("");
    const [pageSize, setPageSize] = useState(10);
    const [pageIndex, setPageIndex] = useState(1);

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        dispatch(reducersAndActions.actions.sessionActions.setLoading(loading));
    }, [loading]);

    const [isOC, setIsOC] = useState();
    const [isEpc, setIsEpc] = useState();

    const [selectedPresident, setSelectedPresident] = useState(null);
    const [selectedVicePresident, setSelectedVicePresident] = useState(null);
    const [selectedSecretary, setSelectedSecretary] = useState(null);
    const [selectedTreasurer, setSelectedTreasurer] = useState(null);

    const [intialOwnersList, setInitialOwnersList] = useState([])
    const [selectedOwner, setSelectedOwner] = useState(null);
    const [errMsg, setErrMsg] = useState("");
    const [isEmailDisabled, setIsEmailDisabled] = useState(true);
    const [selectedEntity, setSelectedEntity] = useState(null);

    const [selectedBusinessOption, setSelectedBusinessOption] = useState(null);
    const [businessError, setBusinessError] = useState("");
    const [taxReturnFormOptions, setTaxReturnFormOptions] = useState([]);

    const [ownerErrors, setOwnerErrors] = useState({});

    const [exportCountries, setExportCountries] = useState(['']);
    const [showTaxFormConfirmation, setShowTaxFormConfirmation] = useState(false);
    const [selectedTaxForm, setSelectedTaxForm] = useState('1065');
    const [initialTaxReturnForm, setInitialTaxReturnForm] = useState("");
    const [inquiries, setInquiries] = useState([]);

    const [showOwnersList, setShowOwnersList] = useState([]);

    const businessRef = useRef(null);
    const stateRef = useRef(null);
    const presidentRef = useRef(null);
    const secretaryRef = useRef(null);
    const anotherStateRef = useRef(null);
    const taxReturnStateRef = useRef(null);
    const mailingStateRef = useRef(null);
    // ------------------------------------------------------------------------------ States above 

    useEffect(() => {
        document.title = "Business Profile - Loan Application";
        let oid = decryptText(params.loanId);
        loanId = oid;
        objloanId = oid;
        BusinessHistoryForm.reset();
        getMasterArrayList();
        GetBasicInfoLoanApplication();
    }, []);

    // ---------------------------------------------------------------- Get Basic Info Of LoanApplication
    const GetBasicInfoLoanApplication = async () => {
        try {
            if (loanId !== "" && parseInt(loanId) > 0) {
                let queryObj = {
                    Id: loanId,
                    SessionId: getSessionId(),
                };
                const modelBasicInfoDetails = await GetBasicInfoLoanApplicationById(queryObj);
                const modelBasicInfoArray = JSON.parse(modelBasicInfoDetails.resultObject);

                setModelBusinessType(modelBasicInfoArray[0].TypeOfBusiness)

            } else {
                throw new Error("Invalid loanId");
            }
        } catch (err) {
            console.error("Error in GetBasicInfoLoanApplication:", err);
            throw err;
        }
    };

    // ---------------------------------------------------------------- Conditional visibility
    const shouldRenderOwnersMembersDiv = !modelBusinessType.includes("LLC");
    const shouldRenderOwnersMembersOption = modelBusinessType.includes("LLC");
    // ---------------------------------------------------------------- Get Lists of Businesses by LoadId



    const getMasterArrayList = async () => {
        try {
            setLoading(true);

            const states = await getUSAStates();
            stateLists = states;

            // Fetch business names
            let queryObj = { LoanId: loanId, SessionId: getSessionId() };
            let data2 = await GetBussinessNameListByLoanId(queryObj);


            const options = staticBusinessTaxReturnList
            const defaultTaxReturnOption = { value: '1065', label: '1065' };

            setTaxReturnFormOptions(options.length > 0 ? options : [defaultTaxReturnOption]);
            if (options.length === 0 && !BusinessHistoryForm.value.taxReturnForm) {
                BusinessHistoryForm.patchValue({ taxReturnForm: defaultTaxReturnOption.value });
            }

            if (data2 && data2.status === 200) {
                let responseData2 = JSON.parse(data2.resultObject);

                const BusinessNameOptionsTemp = responseData2.map(item => ({
                    label: item.BusinessName + (item.IsPrimary ? " (Primary)" : ""),
                    value: item.Id,
                    isPrimary: item.IsPrimary
                }));

                setBusinessOptions(BusinessNameOptionsTemp);

                // Find the primary option
                const primaryOption = BusinessNameOptionsTemp.find(option => option.isPrimary);

                // Set the selected business option to the primary option, or the first option if no primary exists
                const defaultOption = primaryOption || BusinessNameOptionsTemp[0];
                setSelectedBusinessOption(defaultOption.value);

                if (defaultOption) {
                    await GetSelectedBusinessDetail(defaultOption.value);
                }

                setEntityOptions(BusinessNameOptionsTemp);
            } else {
                console.error('Failed to fetch data or no data available');
            }
        } catch (err) {
            console.error('Error fetching data:', err);
        } finally {
            setLoading(false);
        }
    };

    const checkForBackButton = () => {
        if (BusinessHistoryForm.dirty) {
            setPreviousTabPopup(true);
        } else {
            backPreviousTab();
        }
    };

    // -------------------------------------------------------------------- Business Select Dropdown
    const GetSelectedBusinessDetail = React.useCallback(async (businessId) => {
        try {
            setLoading(true);
            BusinessHistoryForm.reset();
            setErrMsg("");
            setInitialOwnersList([]);
            setSelectedBusinessState(null);
            setSelectedTaxForm("1065")
            setInitialTaxReturnForm("1065")

            setSelectedPresident(null);
            setSelectedVicePresident(null);
            setSelectedSecretary(null);
            setSelectedTreasurer(null);

            if (businessId && parseInt(businessId) > 0) {
                let queryObj = { LoanId: businessId, SessionId: getSessionId() };
                BusinessHistoryForm.patchValue({ LoanId: businessId });
                const modelBusinessDetail = await GetBussinessProfileByLoanId(queryObj);

                let finalData
                if (modelBusinessDetail.status == 200) {
                    finalData = modelBusinessDetail.resultObject
                } else {
                    finalData = tempEmptyObject
                }

                setModelBusinessType(finalData.typeOfBusiness || "");

                const updatedOwners = finalData.owners.map(owner => ({ ...owner, id: String(owner.id) }));
                setInitialOwnersList(updatedOwners)
                const data = updatedOwners.map(owner => ({
                    label: `${owner.name} ${owner.lastName ? owner.lastName : ''}`,
                    value: owner.email.toString(),
                }));
                setShowOwnersList(data);

                BusinessHistoryForm.patchValue({
                    ...finalData,
                    BusinessCity: finalData.businessCity,
                    BusinessState: finalData.businessState,

                    isIndividualOwner: finalData.isEntity === true ? false : true,

                    // Handle boolean values explicitly
                    noMemberEmployeeOfSmallBusiness: !!finalData.noMemberEmployeeOfSmallBusiness,
                    noMemberOfCongress: !!finalData.noMemberOfCongress,
                    noFormerSBAEmployee: !!finalData.noFormerSBAEmployee,
                    noGovernmentEmployee: !!finalData.noGovernmentEmployee,
                    noSBAEmployee: !!finalData.noSBAEmployee,
                });

                const countries = [
                    BusinessHistoryForm.value.countriesOfExport1,
                    BusinessHistoryForm.value.countriesOfExport2,
                    BusinessHistoryForm.value.countriesOfExport3
                ];

                const filteredCountries = countries.filter(country => country !== "");
                if (filteredCountries.length === 0) {
                    setExportCountries([""])
                } else {
                    setExportCountries(filteredCountries);
                }

                setSelectedEntityOption(finalData.businessName);

                setSelectedBusinessState(finalData.businessState);
                setAnotherState(finalData.anotherState);
                setSelectedTaxReturnState(finalData.taxState);
                setSelectedMalingState(finalData.mailingState);

                setSelectedPresident(finalData.presidentEmail);
                setSelectedVicePresident(finalData.vicePresidentEmail);
                setSelectedSecretary(finalData.secretaryEmail);
                setSelectedTreasurer(finalData.treasurerEmail);

                setInitialTaxReturnForm(finalData.taxReturnForm);
                setSelectedownedByEntity(finalData.ownedBySelectedEntity)
                setSelectedRealEstateHoldingEntity(finalData.realEstateHoldingEntity);
                setIsOC(finalData.isOC);
                setIsEpc(finalData.isEpc);


                if (finalData.taxReturnForm === "") {
                    setSelectedTaxForm("1065")
                    setInitialTaxReturnForm("1065")
                    BusinessHistoryForm.patchValue({ taxReturnForm: "1065" });
                } else {
                    setSelectedTaxForm(finalData.taxReturnForm)
                }

                if (finalData.typeOfBusiness === "Independent Contractor" || finalData.typeOfBusiness === "Sole Proprietor") {
                    setSelectedTaxForm("1040")
                    setInitialTaxReturnForm("1040")
                    BusinessHistoryForm.patchValue({ taxReturnForm: "1040" });
                }

                setLoading(false);
            }
        } catch (error) {
            dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: error.message || "Failed to fetch data", type: "error", }));
            setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
        } finally {
            setLoading(false);
        }
    }, [BusinessHistoryForm, setInitialOwnersList, setLoading]);


    useEffect(() => {
        if (BusinessOptions.length > 0 && !selectedBusinessOption) {
            const primaryOption = BusinessOptions.find(option => option.isPrimary);
            const defaultOption = primaryOption || BusinessOptions[0];
            setSelectedBusinessOption(defaultOption.value);
            if (defaultOption) {
                GetSelectedBusinessDetail(defaultOption.value);
            }
        }
    }, [BusinessOptions, selectedBusinessOption, GetSelectedBusinessDetail]);


    useEffect(() => {
        if (BusinessHistoryForm.value.inceptionDate == "NaN/NaN/NaN" || !isValidDate(BusinessHistoryForm.value.inceptionDate)) {
            BusinessHistoryForm.patchValue({
                inceptionDate: null,
            });
        }
    }, [BusinessHistoryForm.value.inceptionDate]);


    const fetchOwnersForOwnedByEntity = async (ownedByEntityId) => {
        let queryObj = { LoanId: ownedByEntityId, SessionId: getSessionId() };
        const modelBusinessDetail = await GetBussinessProfileByLoanId(queryObj);
        setErrMsg("");

        if (modelBusinessDetail.status === 200) {
            const updatedOwners = modelBusinessDetail.resultObject.owners.map(owner => ({ ...owner, id: String(owner.id) }));
            setInitialOwnersList(updatedOwners)
            const data = updatedOwners.map(owner => ({
                label: `${owner.name} ${owner.lastName ? owner.lastName : ''}`,
                value: owner.email.toString(),
            }));
            setShowOwnersList(data);
        } else {
            setInitialOwnersList([])
        }
        setSelectedPresident(null);
        setSelectedVicePresident(null);
        setSelectedSecretary(null);
        setSelectedTreasurer(null);
    };

    // -------------------------------------------------------------------- Business Select Dropdown

    const handleBusinessSelectChange = (selectedOption) => {
        setSelectedBusinessOption(selectedOption.value);
        if (selectedOption.value) {
            setBusinessError("");
            setIsVisible(selectedOption.value === objloanId);
            // Directly call GetSelectedBusinessDetail here
            GetSelectedBusinessDetail(selectedOption.value);
        } else {
            setBusinessError("Please select a business");
            setIsVisible(false);
        }
    };



    const handleEntitySelectChange = (selectedOption) => {
        setSelectedEntityOption(selectedOption.value);

        // Update the form with the selected entity
        BusinessHistoryForm.patchValue({
            selectedEntityId: selectedOption ? selectedOption.value : null,
            selectedEntityName: selectedOption ? selectedOption.label : null,
            businessName: selectedOption ? selectedOption.label : null,
        });
    };
    // -------------------------------------------------------------------- Options select functions

    const handleIsCrimeChange = (event) => {
        BusinessHistoryForm.patchValue({ isCrime: true, });
    };

    const handleIsNoCrimeChange = () => {
        BusinessHistoryForm.patchValue({ isCrime: false, });
    };

    const handleChangedOwnedBy = (typeSelected) => {
        setInitialOwnersList([]);
        setSelectedownedByEntity(null);
        setShowOwnersList([]);

        setSelectedPresident(null);
        setSelectedVicePresident(null);
        setSelectedSecretary(null);
        setSelectedTreasurer(null);


        if (typeSelected === "isIndividualOwner") {
            BusinessHistoryForm.patchValue({ ownedBySelectedEntity: "" });
        }
        BusinessHistoryForm.patchValue({ president: "", vicePresident: "", secretary: "", treasurer: "" });
        BusinessHistoryForm.patchValue({ isEntity: typeSelected === "isEntity", isIndividualOwner: typeSelected === "isIndividualOwner" });
    };

    const handleIsOCChange = () => {
        setIsOC(true);
        setIsEpc(false);
        BusinessHistoryForm.patchValue({ reHoldingCompanyYes: false, reHoldingCompanyYes1: false, isEpc: false, isOC: true, });
    };

    const handleIsEpcChange = () => {
        setIsEpc(true);
        setIsOC(false);
        BusinessHistoryForm.patchValue({ reHoldingCompanyYes: false, reHoldingCompanyYes1: false, isOC: false, isEpc: true, });
    };

    const handleReHoldingCompanyYesChange = () => {
        BusinessHistoryForm.patchValue({ reHoldingCompanyYes1: false, reHoldingCompanyYes: true, });
    };

    const handleReHoldingCompanyNoChange = () => {
        BusinessHistoryForm.patchValue({ reHoldingCompanyYes: false, });
    };
    const handleReHoldingCompanyYes1Change = () => {
        BusinessHistoryForm.patchValue({ reHoldingCompanyYes: false, reHoldingCompanyYes1: true, });
    };

    const handleReHoldingCompanyNo1Change = () => {
        BusinessHistoryForm.patchValue({ reHoldingCompanyYes1: false, });
    };

    const handleExportCountryChange = (index, value) => {
        let updatedCountries = [...exportCountries];
        updatedCountries.splice(index, 1, value);
        setExportCountries([...updatedCountries]);
        BusinessHistoryForm.patchValue({ [`countriesOfExport${index + 1}`]: value });
    };


    const addExportCountry = () => {
        if (exportCountries[exportCountries.length - 1] !== "") {
            const updatedCountries = [...exportCountries, ""];
            setExportCountries(updatedCountries);

            updatedCountries.forEach((country, index) => {
                BusinessHistoryForm.patchValue({ [`countriesOfExport${index + 1}`]: country });
            });
        } else {
            dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: "Please enter a valid country name", type: "error", }));
            setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
        }
    };

    const removeExportCountry = (index) => {
        const updatedCountries = exportCountries.filter((_, i) => i !== index);
        setExportCountries(updatedCountries);

        for (let i = 1; i <= 3; i++) {
            BusinessHistoryForm.patchValue({ [`countriesOfExport${i}`]: "" });
        }

        updatedCountries.forEach((country, i) => {
            BusinessHistoryForm.patchValue({ [`countriesOfExport${i + 1}`]: country });
        });
    };



    // Add this function to handle the confirmation
    const handleTaxFormConfirmation = (confirmed) => {
        if (confirmed && selectedTaxForm) {
            BusinessHistoryForm.patchValue({ taxReturnForm: selectedTaxForm });
            setInitialTaxReturnForm(selectedTaxForm);
        } else {
            setSelectedTaxForm(BusinessHistoryForm.value.taxReturnForm);
        }
        setShowTaxFormConfirmation(false);
    };

    // ------------------------------------------------------------------------------------- Basics
    // Popup function
    const [ownerDrawerShow, setOwnerDrawer] = useState(false);
    const [ownerDrawerAddShow, setOwnerDrawerAdd] = useState(false);
    const ownerDrawerClose = () => setOwnerDrawer(false);
    const ownerDrawerAddClose = () => setOwnerDrawerAdd(false);

    useEffect(() => {
        setIsEmailDisabled(true)
    }, [ownerDrawerShow])

    const [PreviousTabPopupShow, setPreviousTabPopup] = useState(false);
    const PreviousTabPopupClose = () => setPreviousTabPopup(false);

    // form tabs
    const [activeTab, setActiveTab] = useState("Individual");
    const handleTabClick = (tabId) => {
        setActiveTab(tabId);
    };

    const membersDropdownOptions = [
        { label: "Managing Member", value: "Managing Member" },
        { label: "Managing Member with authority to sign", value: "Managing Member with authority to sign" },
        { label: "Member", value: "Member" },
        { label: "Member with authority to sign", value: "Member with authority to sign" }
    ];

    // ---------------------------------------------------------------------------- Set Datas
    let ownersList = BusinessHistoryForm.value.owners || [];

    // ------------------------------------------------------------------ End Corporate Officers Data Mapping

    const handleEditOwner = (ownerId) => {
        setOwnerErrors({});
        setErrMsg("");
        const ownerToEdit = BusinessHistoryForm.value.owners.find(owner => owner.id === ownerId);
        if (ownerToEdit) {
            setSelectedOwner(ownerToEdit);
            setActiveTab(ownerToEdit.isEntity ? "Entity" : "Individual");

            // Set the selected member
            if (ownerToEdit.businessMember) {
                const memberOption = membersDropdownOptions.find(option => option.value === ownerToEdit.businessMember);
                setSelectedMember(memberOption);
            } else {
                setSelectedMember(null);
            }
            setOwnerErrors({});
            setOwnerDrawer(true);
        }
    };


    const handleAddOwner = (ownerId) => {
        setOwnerErrors({});
        setOwnerDrawerAdd(true);
    };


    const handleEditOwnerDetails = () => {
        if (!validateOwnerForm(selectedOwner)) {
            return;
        }

        // Calculate the current total ownership percentage without the selectedOwner's current ownership
        const currentTotalPercentage = BusinessHistoryForm.value.owners.reduce((total, owner) => {
            if (owner.id !== selectedOwner.id) {
                const ownershipPercentage = parseFloat(owner.ownershipePercentage) || 0;
                return total + ownershipPercentage;
            }
            return total;
        }, 0);

        // Calculate the new total ownership percentage including the selectedOwner's new ownership
        const ownerPercentage = parseFloat(selectedOwner.ownershipePercentage) || 0
        const newTotalPercentage = currentTotalPercentage + ownerPercentage

        if (ownerPercentage < 0) return setErrMsg("Total ownership percentage cannot less than 0.");
        if (newTotalPercentage > 100) return setErrMsg("Total ownership percentage cannot exceed 100%.");

        setErrMsg('');

        // Create an updated owner object with the new businessMember
        const updatedOwner = {
            ...selectedOwner,
            businessMember: selectedMember ? selectedMember.value : null,
            id: String(selectedOwner.id) // Ensure the id is a string
        };

        // Update the owner list in the form
        const updatedOwners = BusinessHistoryForm.value.owners.map(owner =>
            owner.id === updatedOwner.id ? updatedOwner : owner
        );



        BusinessHistoryForm.patchValue({ owners: updatedOwners });
        setInitialOwnersList(updatedOwners);
        const data = updatedOwners.map(owner => ({
            label: `${owner.name} ${owner.lastName ? owner.lastName : ''}`,
            value: owner.email.toString(),
        }));
        setShowOwnersList(data);

        // Close the modal and reset selected states
        setOwnerDrawer(false);
        setSelectedOwner(null);
        setSelectedMember(null);

        // Force a re-render of the table
        setPageIndex(prevIndex => prevIndex); // This triggers a re-render without changing the page
    };

    const updateValidatorsBasedOnBusinessType = (businessType) => {
        if (businessType.includes("LLC")) {
            BusinessHistoryForm.get('president').clearValidators();
            BusinessHistoryForm.get('secretary').clearValidators();
        } else {
            BusinessHistoryForm.get('president').setValidators([Validators.required]);
            BusinessHistoryForm.get('secretary').setValidators([Validators.required]);
        }
        BusinessHistoryForm.get('president').updateValueAndValidity();
        BusinessHistoryForm.get('secretary').updateValueAndValidity();
    };

    const updateAddressValidators = (addressSelected, type) => {
        const physicalAddressList = ['anotherAddressLine1', 'anotherCity', 'anotherState', 'anotherZIPCode'];
        const mailingAddressList = ['businessAddressLine1', 'BusinessCity', 'BusinessState', 'businessZIPCode'];
        const taxAddressList = ['taxAddressLine1', 'taxCity', 'taxState', 'taxZIPCode'];

        const selectedList = type === 'tax' ? taxAddressList : type === 'mailing' ? mailingAddressList : physicalAddressList;
        const validatorsMap = {
            taxZIPCode: [Validators.required, zipCodeValidator], // Custom validator for ZIP code
            mailingZIPCode: [Validators.required, zipCodeValidator],
            anotherZIPCode: [Validators.required, zipCodeValidator],
        };

        selectedList.forEach((field) => {
            const control = BusinessHistoryForm.get(field);
            if (addressSelected) {
                const validators = validatorsMap[field] || [Validators.required]; // Use the custom validators if defined
                control.setValidators(validators);
            } else {
                control.clearValidators();
                let clearAddress2 = type === 'tax' ? 'taxAddressLine2' : type === 'mailing' ? 'businessAddressLine2' : 'anotherAddressLine2';
                let selectedBox = type === 'tax' ? setSelectedTaxReturnState : type === 'mailing' ? setSelectedBusinessState : setAnotherState;
                selectedBox(null);
                BusinessHistoryForm.patchValue({ [field]: "", [clearAddress2]: '' });
            }
            control.updateValueAndValidity();
        });
    };


    const initialPresidentValue = BusinessHistoryForm.value.president;
    const initialVicePresidentValue = BusinessHistoryForm.value.vicePresident;
    const initialSecretaryValue = BusinessHistoryForm.value.secretary;
    const initialTreasurerValue = BusinessHistoryForm.value.treasurer;

    useEffect(() => {
        BusinessHistoryForm.patchValue({
            owners: intialOwnersList,
            president: initialPresidentValue,
            vicePresident: initialVicePresidentValue,
            secretary: initialSecretaryValue,
            treasurer: initialTreasurerValue
        });
    }, [intialOwnersList, initialPresidentValue, initialVicePresidentValue, initialSecretaryValue, initialTreasurerValue])


    useEffect(() => {
        const fetchBusinessType = async () => {
            try {
                if (loanId !== "" && parseInt(loanId) > 0) {
                    let queryObj = { Id: loanId, SessionId: getSessionId() };
                    const modelBasicInfoDetails = await GetBasicInfoLoanApplicationById(queryObj);
                    const modelBasicInfoArray = JSON.parse(modelBasicInfoDetails.resultObject);

                    const businessType = modelBasicInfoArray[0].TypeOfBusiness;
                    setModelBusinessType(businessType);
                    updateValidatorsBasedOnBusinessType(businessType);
                } else {
                    throw new Error("Invalid loanId");
                }
            } catch (err) {
                console.error("Error in GetBasicInfoLoanApplication:", err);
                throw err;
            }
        };

        fetchBusinessType();
    }, [loanId]);

    // Add this function to handle the tax form change
    const handleTaxFormChange = (selectedOption) => {
        if (selectedOption.value !== initialTaxReturnForm) {
            setSelectedTaxForm(selectedOption.value);
            setShowTaxFormConfirmation(true);
        } else {
            BusinessHistoryForm.patchValue({ taxReturnForm: selectedOption.value });
        }
    };

    useEffect(() => {
        BusinessHistoryForm.patchValue({ owners: intialOwnersList });
    }, [intialOwnersList])

    const updatedOwnerListHeaders = OwnerListHeadsers
        .map(header => {
            // If the header value is 'Title', update the name based on the condition
            if (header.value === 'Title') {
                return {
                    ...header,
                    name: shouldRenderOwnersMembersOption ? 'Member' : 'Director',
                };
            }
            return header;
        })
        .filter(header => {
            // If isIndividualOwner is true, exclude the "Actions" header
            if (BusinessHistoryForm.value.isIndividualOwner === false) {
                return header.name !== 'Actions';
            }
            return true;
        });


    const dynamicTableDisplay = (owner, businessType) => {
        const memberOrDirector = businessType.includes("LLC") ? owner?.businessMember : (owner?.isDirector ? 'Director' : '');

        // Check if the owner is an entity and if it's primary
        const isPrimaryEntity = owner.isEntity && owner.isPrimary;
        const displayName = isPrimaryEntity
            ? `${owner.name} (Primary)`
            : owner.isEntity
                ? owner.name
                : `${owner.name} ${owner.lastName || ''}`;

        return (
            owner.isDeleted === 1 ? null : (
                <tr>
                    <td>
                        <b>
                            <img className="mr-2" src={grid_company_icon} alt="" />
                            {displayName}
                        </b>
                    </td>
                    <td>{owner?.email}</td>
                    <td>{memberOrDirector}</td>
                    <td>{owner?.ownershipePercentage || 0}%</td>
                    <td>
                        {
                            BusinessHistoryForm.value.isIndividualOwner && (
                                <a onClick={() => handleEditOwner(owner.id)}>
                                    <img className="lm-grid-edit-icon" src={edit_pen} alt="" />
                                </a>
                            )
                        }
                    </td>
                </tr>
            )
        )
    };

    const saveDataBusinessProfileForm = () => {
        setLoading(true);

        const rawFormData = BusinessHistoryForm.value;
        rawFormData['presidentEmail'] = selectedPresident;
        rawFormData['secretaryEmail'] = selectedSecretary;
        rawFormData['vicePresidentEmail'] = selectedVicePresident;
        rawFormData['treasurerEmail'] = selectedTreasurer;

        const modifiedFormData = {
            ...rawFormData,
            owners: [],
            ownersList: [...(rawFormData.owners || [])],
            modifiedBy: JSON.parse(localStorage.getItem("user"))?.id || 0,

            isAppliedGovDelinquent: rawFormData.isAppliedGovernmentLoan ? rawFormData.isAppliedGovDelinquent : false,
            govDelinquentDetail: rawFormData.isAppliedGovernmentLoan && rawFormData.isAppliedGovDelinquent ? rawFormData.govDelinquentDetail : "",
            reasonForDefault: rawFormData.isAppliedGovernmentLoan && rawFormData.isAppliedGovDelinquent ? rawFormData.reasonForDefault : "",

            partiesLawsuit: rawFormData.isInvolvedLawsuits ? rawFormData.partiesLawsuit : "",
            dateCommencementLawsuit: rawFormData.isInvolvedLawsuits ? rawFormData.dateCommencementLawsuit : "",
            involvedLawsuitsDetail: rawFormData.isInvolvedLawsuits ? rawFormData.involvedLawsuitsDetail : "",

            reasonForCrime: rawFormData.isCrime ? rawFormData.reasonForCrime : "",
            estimatedTotalExportSales: rawFormData.productsOrServicesExported ? rawFormData.estimatedTotalExportSales : "",
            revenuesDerivedFromGamblingExplain_Additional: rawFormData.revenuesDerivedFromGambling ? rawFormData.revenuesDerivedFromGamblingExplain_Additional : "",
            countriesOfExport1: rawFormData.productsOrServicesExported ? rawFormData.countriesOfExport1 : "",
            countriesOfExport2: rawFormData.productsOrServicesExported ? rawFormData.countriesOfExport2 : "",
            countriesOfExport3: rawFormData.productsOrServicesExported ? rawFormData.countriesOfExport3 : "",

            bankruptcyDetail: rawFormData.isBankruptcy ? rawFormData.bankruptcyDetail : "",
            typeOfBankruptcy: rawFormData.isBankruptcy ? rawFormData.typeOfBankruptcy : "",
            dateOfFilingBankruptcy: rawFormData.isBankruptcy ? rawFormData.dateOfFilingBankruptcy : "",
            dateOfDischargeBankruptcy: rawFormData.isBankruptcy ? rawFormData.dateOfDischargeBankruptcy : "",
            everFilledBankruptcyReason: rawFormData.isBankruptcy ? rawFormData.everFilledBankruptcyReason : "",

            presentlyInBanckruptcyDetail: rawFormData.isPresentlyInBanckruptcy ? rawFormData.presentlyInBanckruptcyDetail : "",
            presentlyInBanckruptcyActionDate: rawFormData.isPresentlyInBanckruptcy ? rawFormData.presentlyInBanckruptcyActionDate : "",
            presentlyInBanckruptcyReason: rawFormData.isPresentlyInBanckruptcy ? rawFormData.presentlyInBanckruptcyReason : "",
        };


        const preparedFormData = prepareFormDataForSubmission(modifiedFormData);
        const BusinessFormData = JSON.stringify(preparedFormData);

        axios.post("/api/LoanApplication/InsertUpdateBusinessProfile", BusinessFormData, {
            params: {
                WebURL: `/application-detail/${encryptText(params.loanId)}`,
                SessionId: getSessionId()
            }
        })
            .then(response => {
                setLoading(false);
                if (response.data.status === 200) {
                    dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: response.data.message || "Business profile saved successfully", type: "success", }));
                    setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
                    BusinessHistoryForm.reset(BusinessHistoryForm.value, { keepValues: true });
                } else {
                    throw new Error(response.data.message || "Error occurred while saving the business profile");
                }
            })
            .catch(error => {
                setLoading(false);
                dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: error.response?.data?.message || error.message || "Error occurred while saving the business profile", type: "error", }));
                setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
            });
    };

    const setStateRef = (element) => {
        if (element) {
            stateRef.current = element;
        }
    };

    const setAnotherStateRef = (element) => {
        if (element) {
            anotherStateRef.current = element;
        }
    };

    const setTaxReturnStateRef = (element) => {
        if (element) {
            taxReturnStateRef.current = element;
        }
    };

    const setMailingStateRef = (element) => {
        if (element) {
            mailingStateRef.current = element;
        }
    };

    const setPresidentRef = (element) => {
        if (element) {
            presidentRef.current = element;
        }
    };

    const setSecretaryRef = (element) => {
        if (element) {
            secretaryRef.current = element;
        }
    };

    function markAllAsTouched(formGroup) {
        // Focus on specific refs if they exist
        Object.keys(formGroup.controls).forEach((controlName) => {
            const control = formGroup.get(controlName);
            if (control && control.status === "INVALID") {
                control.active = true;
                control.touched = true;

                if (controlName === "state") stateRef.current.focus();
                if (controlName === "anotherState") anotherStateRef.current.focus();
                if (controlName === "taxState") taxReturnStateRef.current.focus();
                if (controlName === "mailingState") mailingStateRef.current.focus();

                if (controlName === "president") presidentRef.current.focus();
                if (controlName === "secretary") secretaryRef.current.focus();
                if (controlName === "businessRef") businessRef.current.focus();

                if (document.getElementById(controlName)) {
                    document.getElementById(controlName).focus();
                }
            }
        });
    }


    function saveBusinessProfileForm() {
        updateValidatorsBasedOnBusinessType(modelBusinessType);
        updateAddressValidators(BusinessHistoryForm.value.chkMaillingAddress, 'mailing');
        updateAddressValidators(BusinessHistoryForm.value.chkPropertyAddress, 'physical');
        updateAddressValidators(BusinessHistoryForm.value.chkTaxAddress, 'tax');

        const formatOfficerName = (selectedOfficer, role) => {
            const officer = BusinessHistoryForm.value.owners.find(owner => owner.email === selectedOfficer);
            return officer ? `${officer.name} ${officer.lastName || ''}` : "";
        };

        // Call the helper function for each role
        BusinessHistoryForm.patchValue({
            president: formatOfficerName(selectedPresident, 'president'),
            vicePresident: formatOfficerName(selectedVicePresident, 'vicePresident'),
            secretary: formatOfficerName(selectedSecretary, 'secretary'),
            treasurer: formatOfficerName(selectedTreasurer, 'treasurer'),
        })


        markAllAsTouched(BusinessHistoryForm);

        const owners = BusinessHistoryForm.value.owners;
        if (!owners || !Array.isArray(owners) || owners.length === 0) {
            dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: "No owners found. Please add at least one owner.", type: "error", }));
            setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
            return;
        }

        if (BusinessHistoryForm.invalid) {
            dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: "Please fill in all required fields correctly.", type: "error", }));
            setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
            return;
        }

        const totalOwnerShipPercentage = owners.filter(ele => !ele.isDeleted).reduce((total, owner) => total + parseFloat(owner.ownershipePercentage || 0), 0);
        if (totalOwnerShipPercentage.toFixed(2) !== "100.00") {
            dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: "Total ownership percentage should be 100%", type: "error", }));
            setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
            return;
        }

        // If we've reached this point, there are no errors
        saveDataBusinessProfileForm();
    }

    // ------------------------------------------------------------------------------------------------ 
    const handleEntitySelectChangeModel = (selectedOption) => {
        setSelectedEntity(selectedOption);
    };
    const [selectedMember, setSelectedMember] = useState(null); // State to store selected member

    const validateOwnerForm = (data) => {
        const errors = {};
        const checkDuplicateEmail = BusinessHistoryForm.value.owners.some(owner => owner.email === data.email && owner.id !== data.id);
        if (activeTab === "Individual") {
            if (!/^[A-Za-z\s]+$/.test(data.name)) errors.firstName = "First name should contain only characters";
            if (!data.name) errors.firstName = "First Name is required";
            if (!/^[A-Za-z\s]+$/.test(data.lastName)) errors.lastName = "Last name should contain only characters";
            if (!data.lastName) errors.lastName = "Last Name is required";
            if (shouldRenderOwnersMembersOption && !selectedMember) errors.member = "Member selection is required";
            if (data.ownershipePercentage < 0 || data.ownershipePercentage > 100) errors.ownership = "Ownership value should between 0 - 100"
            if (!/\S+@\S+\.\S+/.test(data.email)) errors.email = "Enter a valid email address";
            if (!data.email) errors.email = "Email is required";
            if (checkDuplicateEmail) errors.email = "Email already exists as owner or memeber";
        } else if (activeTab === "Entity") {
            if (!selectedEntity) errors.entityName = "Entity selection is required";
            if (data.ownershipePercentage < 0 || data.ownershipePercentage > 100) errors.entityOwnership = "Ownership value should between 0 - 100"
            if (!/\S+@\S+\.\S+/.test(data.email)) errors.entityEmail = "Enter a valid email address";
            if (!data.email) errors.entityEmail = "Email is required";
            if (checkDuplicateEmail) errors.entityEmail = "Email already exists as owner or memeber";
        }

        setOwnerErrors(errors);
        return Object.keys(errors).length === 0;
    };

    function generateRandomID() {
        return Math.floor(100000000 + Math.random() * 900000000).toString();
    }


    const saveOwnerDetails = () => {
        let newOwner = {};
        if (activeTab === 'Individual') {
            const ownerDirectorElement = document.getElementById('owner_Director');
            newOwner = {
                id: generateRandomID(),
                name: document.getElementById('Owner_First_Name').value.trim(),
                lastName: document.getElementById('Owner_Last_Name').value.trim(),
                email: document.getElementById('Owner_Email').value.trim(),
                ownershipePercentage: parseFloat(document.getElementById('Owner_Ownership').value) || 0,
                businessMember: selectedMember ? selectedMember.value : null,
                isDirector: ownerDirectorElement ? ownerDirectorElement.checked : false,
            };
        } else if (activeTab === 'Entity') {
            newOwner = {
                id: generateRandomID(),
                name: selectedEntity ? selectedEntity.label : "", // Change this line
                email: document.getElementById('Email_entity').value.trim(),
                ownershipePercentage: parseFloat(document.getElementById('Ownership_entity').value) || 0,
                member: selectedMember ? selectedMember.value : null,
                isDirector: false,
                isPrimary: selectedEntity ? selectedEntity.isPrimary : false,
                isEntity: true
            };
        }

        if (!validateOwnerForm(newOwner)) {
            return;
        }

        // Ensure BusinessHistoryForm.value.owners is an array
        if (!Array.isArray(BusinessHistoryForm.value.owners)) {
            BusinessHistoryForm.patchValue({ owners: [] });
        }

        const currentOwners = [...BusinessHistoryForm.value.owners];
        const currentTotalPercentage = currentOwners.filter(ele => !ele.isDeleted).reduce((total, owner) => total + parseFloat(owner.ownershipePercentage || 0), 0);

        const ownerPercentage = parseFloat(newOwner.ownershipePercentage) || 0
        const newTotalPercentage = currentTotalPercentage + ownerPercentage

        if (ownerPercentage < 0) return setErrMsg("Total ownership percentage cannot less than 0.");
        if (newTotalPercentage > 100) return setErrMsg("Total ownership percentage cannot exceed 100%.");

        setErrMsg('');

        const updatedOwners = [...currentOwners, newOwner];
        BusinessHistoryForm.patchValue({ owners: updatedOwners });
        setInitialOwnersList(updatedOwners);
        const data = updatedOwners.map(owner => ({
            label: `${owner.name} ${owner.lastName ? owner.lastName : ''}`,
            value: owner.email.toString(),
        }));
        setShowOwnersList(data);

        ownersList = updatedOwners;
        setPageIndex(0); // Reset to first page
        setPageSize(prevSize => prevSize); // Keep the current page size, but trigger re-render

        setOwnerDrawerAdd(false);
    };

    const handleRemoveOwner = () => {
        if (selectedOwner) {
            const selectedOwnerValue = selectedOwner.name + " " + selectedOwner.lastName
            // const updatedOwnersList = ownersList.filter(owner => owner.id !== selectedOwner.id);
            const updatedOwnersList = ownersList.map(owner => {
                if (owner.id === selectedOwner.id) {
                    return { ...owner, isDeleted: 1 }
                }
                return owner;
            })
            ownersList = updatedOwnersList;

            setInitialOwnersList(updatedOwnersList);
            BusinessHistoryForm.patchValue({ owners: updatedOwnersList });

            setShowOwnersList(updatedOwnersList.map(owner => ({
                label: `${owner.name} ${owner.lastName ? owner.lastName : ''}`,
                value: owner.email.toString(),
            })));


            // Clear the selected values if the removed owner was selected
            if (selectedPresident && selectedPresident === selectedOwner.email) {
                setSelectedPresident(null);
                BusinessHistoryForm.patchValue({ president: null });
            }
            if (selectedVicePresident && selectedVicePresident === selectedOwner.email) {
                setSelectedVicePresident(null);
                BusinessHistoryForm.patchValue({ vicePresident: null });
            }
            if (selectedSecretary && selectedSecretary === selectedOwner.email) {
                setSelectedSecretary(null);
                BusinessHistoryForm.patchValue({ secretary: null });
            }
            if (selectedTreasurer && selectedTreasurer === selectedOwner.email) {
                setSelectedTreasurer(null);
                BusinessHistoryForm.patchValue({ treasurer: null });
            }

            ownerDrawerClose();
        }
    };

    const handleMemberSelectChange = (selectedOption) => {
        setSelectedMember(selectedOption);
    };

    const prepareFormDataForSubmission = (formData) => {
        const preparedData = { ...formData };
        const booleanFields = [
            'chkTaxAddress',
            'isCrime',
            'isChooseEntity',
            'isOC',
            'isEpc',
            'reHoldingCompanyNo',
            'reHoldingCompanyYes1',
            'reHoldingCompanyNo1'
        ];

        booleanFields.forEach(field => {
            if (preparedData[field] === "False" || preparedData[field] === null) {
                preparedData[field] = false;
            } else if (preparedData[field] === "True") {
                preparedData[field] = true;
            }
        });

        if (preparedData.BusinessState && typeof preparedData.BusinessState === 'object') {
            preparedData.BusinessState = preparedData.BusinessState.value;
        }
        if (preparedData.anotherState && typeof preparedData.anotherState === 'object') {
            preparedData.anotherState = preparedData.anotherState.value;
        }
        if (preparedData.taxState && typeof preparedData.taxState === 'object') {
            preparedData.taxState = preparedData.taxState.value;
        }
        if (preparedData.mailingState && typeof preparedData.mailingState === 'object') {
            preparedData.mailingState = preparedData.mailingState.value;
        }
        if (preparedData.president && typeof preparedData.president === 'object') {
            preparedData.president = preparedData.president.value.name;
        }
        if (preparedData.vicePresident && typeof preparedData.vicePresident === 'object') {
            preparedData.vicePresident = preparedData.vicePresident.value.name;
        }
        if (preparedData.secretary && typeof preparedData.secretary === 'object') {
            preparedData.secretary = preparedData.secretary.value.name;
        }
        if (preparedData.treasurer && typeof preparedData.treasurer === 'object') {
            preparedData.treasurer = preparedData.treasurer.value.name;
        }
        return preparedData;
    };

    const handleEditEmailClick = () => {
        setIsEmailDisabled(false);
    };

    return (
        <>
            {/* SidePanel */}
            <SidePanel />

            {/* ProfileHeader */}
            <ProfileHeader />
            {loading ? (<Preloader />) : (<div>

                <div className="lm-form-box pb-5">
                    <FieldGroup
                        control={BusinessHistoryForm}
                        render={({ get, invalid }) => (
                            <form action="">
                                {/* <!--  select business card --> */}
                                <div className="lm-card">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label htmlFor="Select_Business">Select Business<span className="asterisk">*</span></label>
                                                <Select
                                                    className="basic-single"
                                                    classNamePrefix="select"
                                                    id="business-select"
                                                    name="business"
                                                    options={BusinessOptions}
                                                    value={BusinessOptions.find(option => option.value === selectedBusinessOption)}
                                                    onChange={handleBusinessSelectChange}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <!--  select business card --> */}

                                {/* <!--  Business Information card --------------------------------> */}
                                <div className="lm-card">
                                    <div className="row">
                                        <div className="col-12">
                                            <h3 className="lm-card-heading">Business Information</h3>
                                        </div>

                                        {/* <!--  Maling Address  ------------------------> */}
                                        <div className="col-12 label text-dark mb-2"><b>MAILING ADDRESS</b></div>

                                        <div className="col-md-4 col-lg-3">
                                            <div className="form-group">
                                                <FormField
                                                    label="Street Address"
                                                    type="text"
                                                    className={`form-control`}
                                                    id="mailingAddressLine1"
                                                    name="mailingAddressLine1"
                                                    placeholder="Enter an Address"
                                                    required={true}
                                                    errorMessage="Address is required"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-lg-3">
                                            <div className="form-group">
                                                <FormField
                                                    name="mailingAddressLine2"
                                                    label="Street Address 2"
                                                    placeholder="Enter an Address"
                                                    id="mailingAddressLine2"
                                                    maxlength={50}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-lg-3">
                                            <div className="form-group">
                                                <FormField
                                                    label="City"
                                                    type="text"
                                                    className={`form-control`}
                                                    id="mailingCity"
                                                    name="mailingCity"
                                                    placeholder="City"
                                                    required={true}
                                                    errorMessage="City is required"
                                                    onlyCharacters={true}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-lg-3">
                                            <FieldControl
                                                name="mailingState"
                                                render={({ handler, touched, hasError }) => (
                                                    <div className="form-group">
                                                        <label htmlFor="mailingState">
                                                            State<span className="asterisk">*</span>
                                                        </label>
                                                        <Select
                                                            ref={setMailingStateRef}
                                                            className={`basic-single`}
                                                            classNamePrefix="select"
                                                            options={stateLists}
                                                            value={stateLists.find(item => item.value == selectedMalingState)}
                                                            id="mailingState"
                                                            name="mailingState"
                                                            required={true}
                                                            onChange={(e) => {
                                                                setSelectedMalingState(e.value);
                                                                handler().onChange(e);
                                                            }}
                                                        />
                                                        {touched && hasError('required') && (<div className="error-message">State is required</div>)}
                                                    </div>
                                                )}
                                            />
                                        </div>
                                        <div className="col-md-4 col-lg-3">
                                            <div className="form-group">
                                                <FormField
                                                    name="mailingZIPCode"
                                                    label="ZIP Code"
                                                    placeholder="Enter ZIP Code"
                                                    id="mailingZIPCode"
                                                    maxlength={5}
                                                    patternRequired={true}
                                                    patternMessage="Enter Numbers Only"
                                                    required={true}
                                                    errorMessage="Zip Code is required"
                                                    onlyNumbers={true}
                                                />
                                            </div>
                                        </div>

                                        <div className="col-12 label text-dark mb-2"><b>Is the mailing address different from:</b></div>


                                        {/* <!--  Physical Address  ------------------------> */}
                                        <div className="col-12">
                                            <div className="form-group form-check">
                                                <FieldControl
                                                    name="chkMaillingAddress"
                                                    render={({ handler, touched, hasError, meta }) => (
                                                        <div className="form-group form-check mb-2 pl-0">
                                                            <input
                                                                type="checkbox"
                                                                className="form-check-input"
                                                                id="chkMaillingAddress"
                                                                checked={BusinessHistoryForm.value.chkMaillingAddress}
                                                                onChange={(e) => {
                                                                    handler().onChange(e)
                                                                }}
                                                            />
                                                            <label className="form-check-label" htmlFor="cash_in_hand_checking">PHYSICAL ADDRESS</label>
                                                        </div>
                                                    )}
                                                />
                                            </div>
                                        </div>
                                        {
                                            BusinessHistoryForm.value.chkMaillingAddress &&
                                            <>
                                                <div className="col-md-4 col-lg-3">
                                                    <FormField
                                                        name="businessAddressLine1"
                                                        label="Street Address"
                                                        placeholder="Enter Street Address"
                                                        id="businessAddressLine1"
                                                        required={BusinessHistoryForm.value.chkMaillingAddress}
                                                        errorMessage="Address is Required"
                                                    />
                                                </div>
                                                <div className="col-md-4 col-lg-3">
                                                    <div className="form-group">
                                                        <FormField
                                                            name="businessAddressLine2"
                                                            label=" Street Address 2"
                                                            placeholder="Enter Street Address"
                                                            id="businessAddressLine2"
                                                            maxlength={50}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-4 col-lg-3">
                                                    <FormField
                                                        name="BusinessCity"
                                                        label="City"
                                                        placeholder="Enter City"
                                                        id="BusinessCity"
                                                        required={BusinessHistoryForm.value.chkMaillingAddress}
                                                        errorMessage="City is Required"
                                                        onlyCharacters={true}
                                                    />
                                                </div>
                                                <div className="col-md-4 col-lg-3">
                                                    <div className="form-group">
                                                        <FieldControl
                                                            name="BusinessState"
                                                            render={({ handler, touched, hasError }) => (
                                                                <div className="form-group">
                                                                    <label htmlFor="BusinessState">
                                                                        State<span className="asterisk">*</span>
                                                                    </label>
                                                                    <Select
                                                                        ref={setStateRef}
                                                                        {...handler()}
                                                                        className={`basic-single ${touched && hasError('required') ? 'is-invalid' : ''}`}
                                                                        classNamePrefix="select"
                                                                        options={stateLists}
                                                                        value={stateLists.find(item => item.value == selectedBusinessState)}
                                                                        onChange={(e) => {
                                                                            setSelectedBusinessState(e.value);
                                                                            handler().onChange(e);
                                                                        }}
                                                                        id="BusinessState"
                                                                        required={BusinessHistoryForm.value.chkMaillingAddress}
                                                                    />
                                                                    {touched && hasError('required') && (<div className="error-message">State is required</div>)}
                                                                </div>
                                                            )}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-4 col-lg-3">
                                                    <FormField
                                                        name="businessZIPCode"
                                                        label="ZIP Code"
                                                        placeholder="Enter ZIP Code"
                                                        id="businessZIPCode"
                                                        required={BusinessHistoryForm.value.chkMaillingAddress}
                                                        errorMessage="ZIP Code is Required"
                                                        maxlength={5}
                                                        patternRequired={true}
                                                        patternMessage="Enter Numbers Only"
                                                        onlyNumbers={true}
                                                    />
                                                </div>
                                            </>
                                        }

                                        {/* <!--  PROJECT Address  not for affiliates------------------------> */}
                                        <div className="col-12">
                                            <div className="form-group form-check">
                                                <FieldControl
                                                    name="chkPropertyAddress"
                                                    render={({ handler, touched, hasError, meta }) => (
                                                        <div className="form-group form-check mb-2 pl-0">
                                                            <input
                                                                type="checkbox"
                                                                className="form-check-input"
                                                                id="chkPropertyAddress"
                                                                checked={BusinessHistoryForm.value.chkPropertyAddress}
                                                                onChange={(e) => {
                                                                    handler().onChange(e)
                                                                }}
                                                            />
                                                            <label className="form-check-label" htmlFor="cash_in_hand_checking">PROJECT ADDRESS</label>
                                                        </div>
                                                    )}
                                                />
                                            </div>
                                        </div>
                                        {
                                            BusinessHistoryForm.value.chkPropertyAddress &&
                                            <>
                                                <div className="col-md-4 col-lg-3">
                                                    <div className="form-group">
                                                        <FormField
                                                            label="Street Address"
                                                            type="text"
                                                            className={`form-control`}
                                                            id="anotherAddressLine1"
                                                            name="anotherAddressLine1"
                                                            placeholder="Enter an Address"
                                                            required={BusinessHistoryForm.value.chkPropertyAddress}
                                                            errorMessage="Address is required"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-4 col-lg-3">
                                                    <div className="form-group">
                                                        <FormField
                                                            name="anotherAddressLine2"
                                                            label="Street Address 2"
                                                            placeholder="Enter an Address"
                                                            id="anotherAddressLine2"
                                                            maxlength={50}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-4 col-lg-3">
                                                    <div className="form-group">
                                                        <FormField
                                                            label="City"
                                                            type="text"
                                                            className={`form-control`}
                                                            id="anotherCity"
                                                            name="anotherCity"
                                                            placeholder="City"
                                                            required={BusinessHistoryForm.value.chkPropertyAddress}
                                                            errorMessage="City is required"
                                                            onlyCharacters={true}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-4 col-lg-3">
                                                    <FieldControl
                                                        name="anotherState"
                                                        render={({ handler, touched, hasError }) => (
                                                            <div className="form-group">
                                                                <label htmlFor="anotherState">
                                                                    State<span className="asterisk">*</span>
                                                                </label>
                                                                <Select
                                                                    ref={setAnotherStateRef}
                                                                    className={`basic-single`}
                                                                    classNamePrefix="select"
                                                                    options={stateLists}
                                                                    value={stateLists.find(item => item.value == selectedAnotherState)}
                                                                    id="anotherState"
                                                                    name="anotherState"
                                                                    required={BusinessHistoryForm.value.chkPropertyAddress}
                                                                    onChange={(e) => {
                                                                        setAnotherState(e.value);
                                                                        handler().onChange(e);
                                                                    }}
                                                                />
                                                                {touched && hasError('required') && (<div className="error-message">State is required</div>)}
                                                            </div>
                                                        )}
                                                    />
                                                </div>
                                                <div className="col-md-4 col-lg-3">
                                                    <div className="form-group">
                                                        <FormField
                                                            name="anotherZIPCode"
                                                            label="ZIP Code"
                                                            placeholder="Enter ZIP Code"
                                                            id="anotherZIPCode"
                                                            maxlength={5}
                                                            patternRequired={true}
                                                            patternMessage="Enter Numbers Only"
                                                            required={BusinessHistoryForm.value.chkPropertyAddress}
                                                            errorMessage="Zip Code is required"
                                                            onlyNumbers={true}
                                                        />
                                                    </div>
                                                </div>
                                            </>
                                        }

                                        {/* <!--  Prior Tax Year Return Address  ------------------------> */}
                                        <div className="col-12">
                                            <div className="form-group form-check">
                                                <FieldControl
                                                    name="chkTaxAddress"
                                                    render={({ handler, touched, hasError, meta }) => (
                                                        <div className="form-group form-check mb-2 pl-0">
                                                            <input
                                                                type="checkbox"
                                                                className="form-check-input"
                                                                id="chkTaxAddress"
                                                                checked={BusinessHistoryForm.value.chkTaxAddress}
                                                                onChange={(e) => {
                                                                    handler().onChange(e)
                                                                }}
                                                            />
                                                            <label className="form-check-label" htmlFor="cash_in_hand_checking">PRIOR TAX YEAR RETURN</label>
                                                        </div>
                                                    )}
                                                />
                                            </div>
                                        </div>
                                        {
                                            BusinessHistoryForm.value.chkTaxAddress &&
                                            <>
                                                <div className="col-md-4 col-lg-3">
                                                    <div className="form-group">
                                                        <FormField
                                                            label="Street Address"
                                                            type="text"
                                                            className={`form-control`}
                                                            id="taxAddressLine1"
                                                            name="taxAddressLine1"
                                                            placeholder="Enter an Address"
                                                            required={BusinessHistoryForm.value.chkTaxAddress}
                                                            errorMessage="Address is required"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-4 col-lg-3">
                                                    <div className="form-group">
                                                        <FormField
                                                            name="taxAddressLine2"
                                                            label="Street Address 2"
                                                            placeholder="Enter an Address"
                                                            id="taxAddressLine2"
                                                            maxlength={50}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-4 col-lg-3">
                                                    <div className="form-group">
                                                        <FormField
                                                            label="City"
                                                            type="text"
                                                            className={`form-control`}
                                                            id="taxCity"
                                                            name="taxCity"
                                                            placeholder="City"
                                                            required={BusinessHistoryForm.value.chkTaxAddress}
                                                            errorMessage="City is required"
                                                            onlyCharacters={true}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-4 col-lg-3">
                                                    <FieldControl
                                                        name="taxState"
                                                        render={({ handler, touched, hasError }) => (
                                                            <div className="form-group">
                                                                <label htmlFor="taxState">
                                                                    State<span className="asterisk">*</span>
                                                                </label>
                                                                <Select
                                                                    ref={setTaxReturnStateRef}
                                                                    className={`basic-single`}
                                                                    classNamePrefix="select"
                                                                    options={stateLists}
                                                                    value={stateLists.find(item => item.value == selectedTaxReturnState)}
                                                                    id="taxState"
                                                                    name="taxState"
                                                                    required={BusinessHistoryForm.value.chkTaxAddress}
                                                                    onChange={(e) => {
                                                                        setSelectedTaxReturnState(e.value);
                                                                        handler().onChange(e);
                                                                    }}
                                                                />
                                                                {touched && hasError('required') && (<div className="error-message">State is required</div>)}
                                                            </div>
                                                        )}
                                                    />
                                                </div>
                                                <div className="col-md-4 col-lg-3">
                                                    <div className="form-group">
                                                        <FormField
                                                            name="taxZIPCode"
                                                            label="ZIP Code"
                                                            placeholder="Enter ZIP Code"
                                                            id="taxZIPCode"
                                                            maxlength={5}
                                                            patternRequired={true}
                                                            patternMessage="Enter Numbers Only"
                                                            required={BusinessHistoryForm.value.chkTaxAddress}
                                                            errorMessage="Zip Code is required"
                                                            onlyNumbers={true}
                                                        />
                                                    </div>
                                                </div>
                                            </>
                                        }

                                        {/* <!--  lm common divider to break the section  ------------------------> */}
                                        <div className="lm-divider my-3 mx-3"></div>
                                        {/* <!--  lm common divider to break the section  ------------------------> */}

                                        <div className="col-12">
                                            {
                                                modelBusinessType === "Independent Contractor" || modelBusinessType === "Sole Proprietor" ?
                                                    <div className="row">
                                                        <div className="col-md-6 col-lg-6">
                                                            <div className="form-group">
                                                                <div className="label pb-1">
                                                                    Does the borrower file taxes using their social security number or an employer identification number?
                                                                </div>
                                                                <div className="form-check form-check-inline">
                                                                    <input className="form-check-input" type="radio" name="howBrowerFileTaxes" id="howBrowerFileTaxes_SSN" value={"SSN"} checked={BusinessHistoryForm.value.howBrowerFileTaxes === "SSN"} onChange={() => { BusinessHistoryForm.patchValue({ howBrowerFileTaxes: "SSN" }) }} />
                                                                    <label className="form-check-label" htmlFor="howBrowerFileTaxes_SSN">SSN</label>
                                                                </div>
                                                                <div className="form-check form-check-inline">
                                                                    <input className="form-check-input" type="radio" name="howBrowerFileTaxes" id="howBrowerFileTaxes_EIN" value={"EIN"} checked={BusinessHistoryForm.value.howBrowerFileTaxes === "EIN"} onChange={() => { BusinessHistoryForm.patchValue({ howBrowerFileTaxes: "EIN" }) }} />
                                                                    <label className="form-check-label" htmlFor="howBrowerFileTaxes_EIN">EIN</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div> : null
                                            }

                                            <div className="row">
                                                <div className="col-md-4 col-lg-3">
                                                    <div className="form-group">
                                                        <label htmlFor="Business_Tax_Returns">Business Tax Returns Form</label>
                                                        <Select
                                                            className="basic-single"
                                                            classNamePrefix="select"
                                                            menuPortalTarget={document.body}
                                                            styles={{
                                                                menuPortal: base => ({ ...base, zIndex: 9999 })
                                                            }}
                                                            options={taxReturnFormOptions}
                                                            value={taxReturnFormOptions.find(option => option.value === selectedTaxForm)}
                                                            onChange={handleTaxFormChange}
                                                            isDisabled={modelBusinessType === "Independent Contractor" || modelBusinessType === "Sole Proprietor"}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <div className="form-group">
                                                        <div className="label pb-1">Has the borrower filed business taxes for the prior year?</div>
                                                        <div className="form-check form-check-inline">
                                                            <input className="form-check-input" type="radio" name="isBusinessTaxesPriorYear"
                                                                id="isBusinessTaxesPriorYear_yes"
                                                                value={true}
                                                                checked={BusinessHistoryForm.value.isBusinessTaxesPriorYear === true}
                                                                onChange={() => {
                                                                    BusinessHistoryForm.patchValue({ isBusinessTaxesPriorYear: true });
                                                                }}
                                                            />
                                                            <label className="form-check-label" htmlFor="isBusinessTaxesPriorYear_yes">Yes</label>
                                                        </div>
                                                        <div className="form-check form-check-inline">
                                                            <input className="form-check-input" type="radio" name="isBusinessTaxesPriorYear"
                                                                id="isBusinessTaxesPriorYear_no"
                                                                value={false}
                                                                checked={BusinessHistoryForm.value.isBusinessTaxesPriorYear === false}
                                                                onChange={() => {
                                                                    BusinessHistoryForm.patchValue({ isBusinessTaxesPriorYear: false });
                                                                }}
                                                            />
                                                            <label className="form-check-label" htmlFor="isBusinessTaxesPriorYear_no">No</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-md-12 col-lg-6">
                                                    <div className="form-group">
                                                        <div className="label pb-1">Owned by:</div>
                                                        <div className="form-check form-check-inline">
                                                            <input className="form-check-input"
                                                                type="radio" name="Owned_by"
                                                                id="Owned_by_Individual_owner"
                                                                value={true}
                                                                checked={BusinessHistoryForm.value.isIndividualOwner}
                                                                onChange={() => handleChangedOwnedBy('isIndividualOwner')}
                                                            />

                                                            <label className="form-check-label" htmlFor="Owned_by_Individual_owner">
                                                                Individual owner
                                                            </label>
                                                        </div>
                                                        <div className="form-check form-check-inline">
                                                            <input className="form-check-input"
                                                                type="radio"
                                                                name="Owned_by"
                                                                id="Owned_by_Entity"
                                                                value={true}
                                                                checked={BusinessHistoryForm.value.isEntity}
                                                                onChange={() => handleChangedOwnedBy('isEntity')}
                                                            />
                                                            <label className="form-check-label" htmlFor="Owned_by_Entity">
                                                                Entity
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            {BusinessHistoryForm.value.isEntity && (
                                                <div className="row">
                                                    <div className="col-md-4 col-lg-3">
                                                        <div className="form-group">
                                                            <label htmlFor="Choose_entity">Choose an entity</label>
                                                            <Select
                                                                className="basic-single"
                                                                classNamePrefix="select"
                                                                id="ownedBySelectedEntity"
                                                                name="ownedBySelectedEntity"
                                                                options={entityOptions.filter(opiton => opiton.label.replace(" (Primary)", "") != selectedEntityOption)}
                                                                value={entityOptions.find(option => option.value == selectedownedByEntity)}
                                                                placeholder="Select an entity"
                                                                onChange={(e) => {
                                                                    BusinessHistoryForm.patchValue({ ownedBySelectedEntity: e.value });
                                                                    setSelectedownedByEntity(e.value)
                                                                    fetchOwnersForOwnedByEntity(e.value);
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>


                                        {/* <!--  lm common divider to break the section  --> */}
                                        <div className="lm-divider my-3 mx-3"></div>
                                        {/* <!--  lm common divider to break the section  --> */}

                                        <div className="col-md-3 col-lg-3">
                                            <div className="form-group input-date">
                                                <FieldControl
                                                    name="inceptionDate"
                                                    render={({ handler, touched, hasError, meta }) => (
                                                        <div className="form-group input-date">
                                                            <label htmlFor="inceptionDate">
                                                                Date of Incorporation
                                                            </label>
                                                            <DatePicker
                                                                showIcon
                                                                toggleCalendarOnIconClick
                                                                {...handler()}
                                                                selected={isValidDate(BusinessHistoryForm.value.inceptionDate) ? new Date(BusinessHistoryForm.value.inceptionDate) : null}
                                                                onChange={(date) => {
                                                                    BusinessHistoryForm.patchValue({
                                                                        inceptionDate: date ? formatDate(date) : null,
                                                                    });
                                                                }}
                                                                isClearable={BusinessHistoryForm.value.inceptionDate}
                                                                id="inceptionDate"
                                                                dateFormat="MM/d/yyyy"
                                                                placeholderText="MM/DD/YYYY"
                                                                yearDropdownItemNumber={100}
                                                                scrollableYearDropdown
                                                                showMonthDropdown
                                                                showYearDropdown
                                                                onKeyDown={(e) => {
                                                                    e.preventDefault();
                                                                }}
                                                            />
                                                        </div>
                                                    )}
                                                />

                                            </div>
                                        </div>

                                        <div className="col-md-9 col-lg-6">
                                            <div className="form-group">
                                                <FormField
                                                    name="naicS_Code"
                                                    label="Industry"
                                                    placeholder="NAICS"
                                                    id="naicS_Code"
                                                    maxlength={100}
                                                    disabled={true}
                                                />
                                            </div>
                                        </div>

                                        <div className="col-12">
                                            <div className="row">
                                                <div className="col-md-4 col-lg-3">
                                                    <div className="form-group">
                                                        <div className="label pb-1">Is the business a franchise?</div>
                                                        <div className="form-check form-check-inline">
                                                            <input className="form-check-input" type="radio" name="Is_business_franchise"
                                                                id="Is_business_franchise_yes"
                                                                value={true}
                                                                checked={BusinessHistoryForm.value.isYourBusinessFranchise === true}
                                                                onChange={() => {
                                                                    BusinessHistoryForm.patchValue({ isYourBusinessFranchise: true });
                                                                }}
                                                            />
                                                            <label className="form-check-label" htmlFor="Is_business_franchise_yes">Yes</label>
                                                        </div>
                                                        <div className="form-check form-check-inline">
                                                            <input className="form-check-input" type="radio" name="Is_business_franchise"
                                                                id="Is_business_franchise_no"
                                                                value={false}
                                                                checked={BusinessHistoryForm.value.isYourBusinessFranchise === false}
                                                                onChange={() => {
                                                                    BusinessHistoryForm.patchValue({ isYourBusinessFranchise: false });
                                                                }}
                                                            />
                                                            <label className="form-check-label" htmlFor="Is_business_franchise_no">No</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {BusinessHistoryForm.value.isYourBusinessFranchise && (
                                                <div className="row">
                                                    <div className="col-md-4 col-lg-3">
                                                        <div className="form-group">
                                                            <FormField
                                                                name="nameOfFranchise"
                                                                label="Name of franchise"
                                                                placeholder="Name of franchise"
                                                                id="nameOfFranchise"
                                                                value={BusinessHistoryForm.value.nameOfFranchise || ''}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>

                                        {/* <!--  lm common divider to break the section  --> */}
                                        <div className="lm-divider my-3 mx-3"></div>
                                        {/* <!--  lm common divider to break the section  --> */}

                                        <div className="col-12">
                                            <div className="form-group">
                                                <div className="label pb-1">Entity type</div>
                                                <div className="form-check form-check-inline">
                                                    <input className="form-check-input" type="radio" name="Entity_type"
                                                        id="Entity_type_Operating_Company"
                                                        value={true}
                                                        checked={BusinessHistoryForm.value.isOC}
                                                        onChange={handleIsOCChange}
                                                    />
                                                    <label className="form-check-label" htmlFor="Entity_type_Operating_Company">Operating
                                                        Company</label>
                                                </div>
                                                <div className="form-check form-check-inline">
                                                    <input className="form-check-input" type="radio" name="Entity_type"
                                                        id="Entity_type_Real_Estate_Company"
                                                        value={true}
                                                        checked={BusinessHistoryForm.value.isEpc}
                                                        onChange={handleIsEpcChange}
                                                    />
                                                    <label className="form-check-label" htmlFor="Entity_type_Real_Estate_Company">Real Estate Holding Company</label>
                                                </div>
                                            </div>
                                        </div>
                                        {isEpc && (
                                            <div className="col-12">
                                                <div className="form-group">
                                                    <div className="label pb-1">Is the real estate holding company associated with an operating company?
                                                    </div>
                                                    <div className="form-check form-check-inline">
                                                        <input className="form-check-input" type="radio" name="company_associated_operating_company"
                                                            id="company_associated_operating_company_yes"
                                                            value="company_associated_operating_company_yes"
                                                            checked={BusinessHistoryForm.value.reHoldingCompanyYes}
                                                            onChange={handleReHoldingCompanyYesChange}
                                                        />
                                                        <label className="form-check-label" htmlFor="company_associated_operating_company_yes">Yes</label>
                                                    </div>
                                                    <div className="form-check form-check-inline">
                                                        <input className="form-check-input" type="radio" name="company_associated_operating_company"
                                                            id="company_associated_operating_company_no"
                                                            value="company_associated_operating_company_no"
                                                            checked={!BusinessHistoryForm.value.reHoldingCompanyYes}
                                                            onChange={handleReHoldingCompanyNoChange}
                                                        />
                                                        <label className="form-check-label" htmlFor="company_associated_operating_company_no">No</label>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        {isOC && (
                                            <div className="col-12">
                                                <div className="form-group">
                                                    <div className="label pb-1">Is the operating company associated with a real estate holding company?
                                                    </div>
                                                    <div className="form-check form-check-inline">
                                                        <input className="form-check-input" type="radio" name="company_associated_operating_company"
                                                            id="company_associated_operating_company_yes"
                                                            value="company_associated_operating_company_yes"
                                                            checked={BusinessHistoryForm.value.reHoldingCompanyYes1}
                                                            onChange={handleReHoldingCompanyYes1Change}
                                                        />
                                                        <label className="form-check-label" htmlFor="company_associated_operating_company_yes">Yes</label>
                                                    </div>
                                                    <div className="form-check form-check-inline">
                                                        <input className="form-check-input" type="radio" name="company_associated_operating_company"
                                                            id="company_associated_operating_company_no"
                                                            value="company_associated_operating_company_no"
                                                            checked={!BusinessHistoryForm.value.reHoldingCompanyYes1}
                                                            onChange={handleReHoldingCompanyNo1Change}
                                                        />
                                                        <label className="form-check-label" htmlFor="company_associated_operating_company_no">No</label>
                                                    </div>
                                                </div>
                                            </div>
                                        )}

                                        {(BusinessHistoryForm.value.reHoldingCompanyYes || BusinessHistoryForm.value.reHoldingCompanyYes1) &&
                                            <>
                                                <div className="col-md-4 col-lg-3">
                                                    <div className="form-group">
                                                        <label htmlFor="Choose_entity">Choose an entity</label>
                                                        <Select
                                                            className="basic-single"
                                                            classNamePrefix="select"
                                                            id="BusinessName"
                                                            name="BusinessName"
                                                            options={entityOptions}
                                                            value={entityOptions.find(option => option.value === selectedRealEstateHoldingEntity)}
                                                            placeholder="Select an entity"
                                                            onChange={(e) => {
                                                                setSelectedRealEstateHoldingEntity(e.value);
                                                                BusinessHistoryForm.patchValue({ realEstateHoldingEntity: e.value });
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </>
                                        }

                                        {/* <!--  lm common divider to break the section  --> */}
                                        <div className="lm-divider my-3 mx-3"></div>
                                        {/* <!--  lm common divider to break the section  --> */}

                                        <div className="col-md-4 col-lg-3">
                                            <div className="form-group">
                                                <FormField
                                                    name="numberOfEmpoyee"
                                                    label="Number of Employees"
                                                    placeholder="Enter the Number Of Emplyees"
                                                    id="numberOfEmpoyee"
                                                    errorMessage={"This Field is Required"}
                                                    maxlength={5}
                                                    onlyNumbers={true}
                                                />
                                            </div>
                                        </div>

                                        <div className="col-md-4 col-lg-3">
                                            <div className="form-group">
                                                <FormField
                                                    name="numberOfEmpoyeeAffiliated"
                                                    type="number"
                                                    label="Number of Jobs Retained"
                                                    errorMessage={"This Field is Required"}
                                                    maxlength={5}
                                                    placeholder="Enter Number of Jobs Retained"
                                                    id="numberOfEmpoyeeAffiliated"
                                                    tooltipEnabled={true}
                                                    tooltipContent="The number of full-time jobs saved due to loan funding."
                                                    onlyNumbers={true}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-lg-3">
                                            <div className="form-group">
                                                <FormField
                                                    name="numberOfEmpoyeeHire"
                                                    type="number"
                                                    label="Number of Jobs Created"
                                                    errorMessage={"This Field is Required"}
                                                    maxlength={5}
                                                    placeholder="Enter Number of Jobs Created"
                                                    id="numberOfEmpoyeeHire"
                                                    tooltipEnabled={true}
                                                    tooltipContent="The number of new jobs created due to loan funding."
                                                    onlyNumbers={true}
                                                />
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                {/* <!--  Business Information card --> */}

                                {/* <!-- Owners & Members or Directors card --> */}
                                {(shouldRenderOwnersMembersDiv || shouldRenderOwnersMembersOption) && (
                                    <div className="lm-card p-0">
                                        <div className="d-flex justify-content-between p-3">
                                            <h3 className="lm-card-heading mb-0">
                                                {shouldRenderOwnersMembersDiv ? "Owners & Directors" : "Owners & Members"}
                                            </h3>
                                            {
                                                BusinessHistoryForm.value.isIndividualOwner && (
                                                    <a onClick={() => handleAddOwner()} className="lm-button3">
                                                        <span>+</span> Add an owner
                                                    </a>
                                                )
                                            }

                                        </div>
                                        <DynamicTable
                                            data={intialOwnersList}
                                            dynamicTableDisplay={(owner) => dynamicTableDisplay(owner, modelBusinessType)}
                                            columns={updatedOwnerListHeaders}
                                            changePageSize={setPageSize}
                                            pageSize={pageSize}
                                            total={intialOwnersList.length}
                                            setPageIndex={setPageIndex}
                                            pageIndex={pageIndex}
                                            setList={setInquiries}
                                            hideRowsPerPage={true}
                                            hidePagination={true}
                                        />
                                    </div>
                                )}
                                {/* <!-- Owners & Members or Directors card --> */}


                                {/* <!--  Corporate Officers card --> */}
                                {shouldRenderOwnersMembersDiv && (
                                    <div className="lm-card">
                                        <h3 className="lm-card-heading">Corporate Officers</h3>
                                        <div className="row">
                                            <div className="col-md-4 col-lg-3">
                                                <div className="form-group">
                                                    <FieldControl
                                                        name="president"
                                                        render={({ handler, touched, hasError }) => (
                                                            <div className="form-group">
                                                                <label htmlFor="president">
                                                                    President<span className="asterisk">*</span>
                                                                </label>
                                                                <Select
                                                                    ref={setPresidentRef}
                                                                    className="basic-single"
                                                                    classNamePrefix="select"
                                                                    options={showOwnersList}
                                                                    value={selectedPresident ? showOwnersList.find(item => item.value === selectedPresident) : null}
                                                                    onChange={(e) => {
                                                                        setSelectedPresident(e.value);
                                                                        handler().onChange(e);
                                                                    }}
                                                                />
                                                                {touched && hasError('required') && (
                                                                    <div className="error-message">
                                                                        President selection is required
                                                                    </div>
                                                                )}
                                                            </div>
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-4 col-lg-3">
                                                <div className="form-group">
                                                    <FieldControl
                                                        name="vicePresident"
                                                        render={({ handler, touched, hasError }) => (
                                                            <div className="form-group">
                                                                <label htmlFor="vicePresident">
                                                                    Vice-President
                                                                </label>
                                                                <Select
                                                                    className="basic-single"
                                                                    classNamePrefix="select"
                                                                    options={showOwnersList}
                                                                    value={selectedVicePresident ? showOwnersList.find(item => item.value == selectedVicePresident) : null}
                                                                    onChange={(e) => {
                                                                        setSelectedVicePresident(e.value);
                                                                        handler().onChange(e);
                                                                    }}
                                                                />
                                                            </div>
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-4 col-lg-3">
                                                <FieldControl
                                                    name="secretary"
                                                    render={({ handler, touched, hasError }) => (
                                                        <div className="form-group">
                                                            <label htmlFor="secretary">
                                                                Secretary<span className="asterisk">*</span>
                                                            </label>
                                                            <Select
                                                                className="basic-single"
                                                                ref={setSecretaryRef}
                                                                classNamePrefix="select"
                                                                options={showOwnersList}
                                                                value={selectedSecretary ? showOwnersList.find(item => item.value === selectedSecretary) : null}
                                                                onChange={(e) => {
                                                                    setSelectedSecretary(e.value);
                                                                    handler().onChange(e);
                                                                }}
                                                                menuPortalTarget={document.body}
                                                                styles={{
                                                                    menuPortal: base => ({ ...base, zIndex: 9999 })
                                                                }}
                                                            />
                                                            {touched && hasError('required') && (
                                                                <div className="error-message">
                                                                    Secretary selection is required
                                                                </div>
                                                            )}
                                                        </div>
                                                    )}
                                                />
                                            </div>
                                            <div className="col-md-4 col-lg-3">
                                                <div className="form-group">
                                                    <FieldControl
                                                        name="treasurer"
                                                        render={({ handler, touched, hasError }) => (
                                                            <div className="form-group">
                                                                <label htmlFor="treasurer">
                                                                    Treasurer
                                                                </label>
                                                                <Select
                                                                    className="basic-single"
                                                                    classNamePrefix="select"
                                                                    options={showOwnersList}
                                                                    value={selectedTreasurer ? showOwnersList.find(item => item.value === selectedTreasurer) : null}
                                                                    onChange={(e) => {
                                                                        setSelectedTreasurer(e.value);
                                                                        handler().onChange(e);
                                                                    }}
                                                                />
                                                            </div>
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}

                                {/* <!--  Corporate Officers card --> */}
                                {
                                    isVisible && (
                                        <div className="lm-card">
                                            <div className="row">
                                                <div className="col-12">
                                                    <h3 className="lm-card-heading">Business Details</h3>
                                                </div>
                                                <div className="col-12 label text-dark mb-2"><b>BANKRUPTCY</b></div>

                                                <div className="col-12">
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <div className="label pb-1">Has the business, its owners, or affiliates ever filed for bankruptcy?</div>
                                                                <div className="form-check form-check-inline">
                                                                    <input
                                                                        className="form-check-input"
                                                                        type="radio"
                                                                        name="business_filed_for_bankruptcy"
                                                                        id="business_filed_for_bankruptcy_yes"
                                                                        value={true}
                                                                        checked={BusinessHistoryForm.value.isBankruptcy === true}
                                                                        onChange={() => {
                                                                            BusinessHistoryForm.patchValue({ isBankruptcy: true });
                                                                        }}
                                                                    />
                                                                    <label className="form-check-label" htmlFor="business_filed_for_bankruptcy_yes">Yes</label>
                                                                </div>
                                                                <div className="form-check form-check-inline">
                                                                    <input
                                                                        className="form-check-input"
                                                                        type="radio"
                                                                        name="business_filed_for_bankruptcy"
                                                                        id="business_filed_for_bankruptcy_no"
                                                                        value={false}
                                                                        checked={BusinessHistoryForm.value.isBankruptcy === false}
                                                                        onChange={() => {
                                                                            BusinessHistoryForm.patchValue({ isBankruptcy: false });
                                                                        }}
                                                                    />
                                                                    <label className="form-check-label" htmlFor="business_filed_for_bankruptcy_no">No</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {BusinessHistoryForm.value.isBankruptcy && (
                                                    <>
                                                        <div className="col-md-4 col-lg-3">
                                                            <div className="form-group">
                                                                <FormField
                                                                    name="bankruptcyDetail"
                                                                    label="Name of Business or Individual"
                                                                    placeholder="Write a name"
                                                                    id="bankruptcyDetail"
                                                                    maxlength={50}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4 col-lg-3">
                                                            <div className="form-group">
                                                                <FormField
                                                                    name="typeOfBankruptcy"
                                                                    label="Bankruptcy Type"
                                                                    placeholder="Enter type of Bankruptcy"
                                                                    id="typeOfBankruptcy"
                                                                    maxlength={50}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4 col-lg-3">
                                                            <div className="form-group input-date">
                                                                <FieldControl
                                                                    name="dateOfFilingBankruptcy"
                                                                    render={({ handler, touched, hasError, meta }) => (
                                                                        <div className="form-group input-date">
                                                                            <label htmlFor="dateOfFilingBankruptcy">
                                                                                Date of Filing
                                                                            </label>
                                                                            <DatePicker
                                                                                showIcon
                                                                                toggleCalendarOnIconClick
                                                                                {...handler()}
                                                                                selected={BusinessHistoryForm.value.dateOfFilingBankruptcy}
                                                                                onChange={(date) => {
                                                                                    BusinessHistoryForm.patchValue({ dateOfFilingBankruptcy: date ? formatDate(date) : null });
                                                                                }}
                                                                                isClearable={BusinessHistoryForm.value.dateOfFilingBankruptcy}
                                                                                id="dateOfFilingBankruptcy"
                                                                                dateFormat="MM/d/yyyy"
                                                                                placeholderText="MM/DD/YYYY"
                                                                                yearDropdownItemNumber={100}
                                                                                scrollableYearDropdown
                                                                                showMonthDropdown
                                                                                showYearDropdown
                                                                                onKeyDown={(e) => {
                                                                                    e.preventDefault();
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    )}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4 col-lg-3">
                                                            <div className="form-group input-date">
                                                                <FieldControl
                                                                    name="dateOfDischargeBankruptcy"
                                                                    render={({ handler, touched, hasError, meta }) => (
                                                                        <div className="form-group input-date">
                                                                            <label htmlFor="dateOfDischargeBankruptcy">
                                                                                Date of Discharge
                                                                            </label>
                                                                            <DatePicker
                                                                                showIcon
                                                                                toggleCalendarOnIconClick
                                                                                {...handler()}
                                                                                selected={BusinessHistoryForm.value.dateOfDischargeBankruptcy}
                                                                                onChange={(date) => {
                                                                                    BusinessHistoryForm.patchValue({
                                                                                        dateOfDischargeBankruptcy: date ? formatDate(date) : null,
                                                                                    });
                                                                                }}
                                                                                isClearable={BusinessHistoryForm.value.dateOfDischargeBankruptcy}
                                                                                id="dateOfDischargeBankruptcy"
                                                                                dateFormat="MM/d/yyyy"
                                                                                placeholderText="MM/DD/YYYY"
                                                                                yearDropdownItemNumber={100}
                                                                                scrollableYearDropdown
                                                                                showMonthDropdown
                                                                                showYearDropdown
                                                                                onKeyDown={(e) => {
                                                                                    e.preventDefault();
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    )}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4 col-lg-6">
                                                            <div className="form-group AI_btn_field">
                                                                <FormField
                                                                    name="everFilledBankruptcyReason"
                                                                    label="Reason"
                                                                    placeholder="Enter Reason"
                                                                    id="everFilledBankruptcyReason"
                                                                    maxlength={250}
                                                                />
                                                                <OpenAIComponent
                                                                    fieldName="everFilledBankruptcyReason"
                                                                    formGroup={BusinessHistoryForm}
                                                                />
                                                            </div>
                                                        </div>
                                                    </>
                                                )}

                                                {/* <!--  lm common divider to break the section  --> */}
                                                <div className="lm-divider my-3 mx-3"></div>
                                                {/* <!--  lm common divider to break the section  --> */}

                                                <div className="col-12">
                                                    <div className="row">
                                                        <div className="col-lg-6">
                                                            <div className="form-group">
                                                                <div className="label pb-1">Is the business, its owners, or its affiliates presently suspended, debarred,
                                                                    proposed for debarment, declared ineligible, or voluntarily excluded from
                                                                    participation in any transaction by any federal department or agency?
                                                                </div>
                                                                <div className="form-check form-check-inline">
                                                                    <input className="form-check-input" type="radio" name="presently_suspended"
                                                                        id="presently_suspended_yes"
                                                                        value={true}
                                                                        checked={BusinessHistoryForm.value.isPresentlyInBanckruptcy === true}
                                                                        onChange={() => {
                                                                            BusinessHistoryForm.patchValue({ isPresentlyInBanckruptcy: true });

                                                                        }}
                                                                    />
                                                                    <label className="form-check-label" htmlFor="presently_suspended_yes">Yes</label>
                                                                </div>
                                                                <div className="form-check form-check-inline">
                                                                    <input className="form-check-input" type="radio" name="presently_suspended"
                                                                        id="presently_suspended_no"
                                                                        value={false}
                                                                        checked={BusinessHistoryForm.value.isPresentlyInBanckruptcy === false}
                                                                        onChange={() => {
                                                                            BusinessHistoryForm.patchValue({ isPresentlyInBanckruptcy: false });
                                                                        }}
                                                                    />
                                                                    <label className="form-check-label" htmlFor="presently_suspended_no">No</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {BusinessHistoryForm.value.isPresentlyInBanckruptcy && (
                                                    <>
                                                        <div className="col-md-4 col-lg-3">
                                                            <div className="form-group">
                                                                <FormField
                                                                    name="presentlyInBanckruptcyDetail"
                                                                    label="Name of Business or Individual"
                                                                    placeholder="Enter Name of Business or Individual"
                                                                    id="presentlyInBanckruptcyDetail"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4 col-lg-3">
                                                            <div className="form-group input-date">
                                                                <FieldControl
                                                                    name="presentlyInBanckruptcyActionDate"
                                                                    render={({ handler, touched, hasError, meta }) => (
                                                                        <div className="form-group input-date">
                                                                            <label htmlFor="presentlyInBanckruptcyActionDate">
                                                                                Date of Action
                                                                            </label>
                                                                            <DatePicker
                                                                                showIcon
                                                                                toggleCalendarOnIconClick
                                                                                {...handler()}
                                                                                selected={BusinessHistoryForm.value.presentlyInBanckruptcyActionDate}
                                                                                onChange={(date) => {
                                                                                    BusinessHistoryForm.patchValue({
                                                                                        presentlyInBanckruptcyActionDate: date ? formatDate(date) : null,
                                                                                    });
                                                                                }}
                                                                                isClearable={BusinessHistoryForm.value.presentlyInBanckruptcyActionDate}
                                                                                id="presentlyInBanckruptcyActionDate"
                                                                                dateFormat="MM/d/yyyy"
                                                                                placeholderText="MM/DD/YYYY"
                                                                                yearDropdownItemNumber={100}
                                                                                scrollableYearDropdown
                                                                                showMonthDropdown
                                                                                showYearDropdown
                                                                                onKeyDown={(e) => {
                                                                                    e.preventDefault();
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    )}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4 col-lg-6">
                                                            <div className="form-group AI_btn_field">
                                                                <FormField
                                                                    name="presentlyInBanckruptcyReason"
                                                                    label="Reason"
                                                                    placeholder="Enter Reason"
                                                                    id="presentlyInBanckruptcyReason"
                                                                    maxlength={250}
                                                                />
                                                                <OpenAIComponent
                                                                    fieldName="presentlyInBanckruptcyReason"
                                                                    formGroup={BusinessHistoryForm}
                                                                />
                                                            </div>
                                                        </div>
                                                    </>
                                                )}

                                                {/* <!--  lm common divider to break the section  --> */}
                                                <div className="lm-divider my-3 mx-3"></div>
                                                {/* <!--  lm common divider to break the section  --> */}

                                                <div className="col-12 label text-dark mb-2"><b>GOVERNMENT FINANCING</b></div>

                                                <div className="col-12">
                                                    <div className="row">
                                                        <div className="col-lg-6">
                                                            <div className="form-group">
                                                                <div className="label pb-1">Has the business, its owners or affiliates, ever applied for
                                                                    government financing, including student loans and/or a disaster loan, or guaranteed
                                                                    such a loan?
                                                                </div>
                                                                <div className="form-check form-check-inline">
                                                                    <input className="form-check-input" type="radio" name="applied_for_government_financing"
                                                                        id="applied_for_government_financing_yes"
                                                                        value={true}
                                                                        checked={BusinessHistoryForm.value.isAppliedGovernmentLoan === true}
                                                                        onChange={() => {
                                                                            BusinessHistoryForm.patchValue({ isAppliedGovernmentLoan: true, });
                                                                        }}
                                                                    />
                                                                    <label className="form-check-label"
                                                                        htmlFor="applied_for_government_financing_yes">Yes</label>
                                                                </div>
                                                                <div className="form-check form-check-inline">
                                                                    <input className="form-check-input" type="radio" name="applied_for_government_financing"
                                                                        id="applied_for_government_financing_no"
                                                                        value={false}
                                                                        checked={BusinessHistoryForm.value.isAppliedGovernmentLoan === false}
                                                                        onChange={() => {
                                                                            BusinessHistoryForm.patchValue({ isAppliedGovernmentLoan: false, });
                                                                        }}
                                                                    />
                                                                    <label className="form-check-label" htmlFor="applied_for_government_financing_no">No</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    {
                                                        BusinessHistoryForm.value.isAppliedGovernmentLoan && (
                                                            <>
                                                                <div className="row">
                                                                    <div className="col-lg-6">
                                                                        <div className="form-group">
                                                                            <div className="label pb-1">Is the business, its owners or affiliates, currently
                                                                                delinquent
                                                                                on a government loan or have they ever defaulted on any government loan program?
                                                                            </div>
                                                                            <div className="form-check form-check-inline">
                                                                                <input className="form-check-input" type="radio"
                                                                                    name="currently_delinquent_on_government_loan"
                                                                                    id="currently_delinquent_on_government_loan_yes"
                                                                                    value={true}
                                                                                    checked={BusinessHistoryForm.value.isAppliedGovDelinquent === true}
                                                                                    onChange={() => {
                                                                                        BusinessHistoryForm.patchValue({ isAppliedGovDelinquent: true });
                                                                                    }}
                                                                                />
                                                                                <label className="form-check-label"
                                                                                    htmlFor="currently_delinquent_on_government_loan_yes">Yes</label>
                                                                            </div>
                                                                            <div className="form-check form-check-inline">
                                                                                <input className="form-check-input" type="radio"
                                                                                    name="currently_delinquent_on_government_loan"
                                                                                    id="currently_delinquent_on_government_loan_no"
                                                                                    value={false}
                                                                                    checked={BusinessHistoryForm.value.isAppliedGovDelinquent === false}
                                                                                    onChange={() => {
                                                                                        BusinessHistoryForm.patchValue({ isAppliedGovDelinquent: false });
                                                                                    }}
                                                                                />
                                                                                <label className="form-check-label"
                                                                                    htmlFor="currently_delinquent_on_government_loan_no">No</label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {BusinessHistoryForm.value.isAppliedGovDelinquent && (
                                                                    <>
                                                                        <div className="row">
                                                                            <div className="col-md-4 col-lg-3">
                                                                                <FormField
                                                                                    name="govDelinquentDetail"
                                                                                    label="Name of Business or Individual"
                                                                                    placeholder="Enter Name of Business or Individual"
                                                                                    id="govDelinquentDetail"
                                                                                    maxlength={50}
                                                                                />
                                                                            </div>
                                                                            <div className="col-md-6">
                                                                                <div className="form-group AI_btn_field">
                                                                                    <FormField
                                                                                        name="reasonForDefault"
                                                                                        label="Reason"
                                                                                        placeholder="Enter Reason"
                                                                                        id="reasonForDefault"
                                                                                        maxlength={250}
                                                                                    />
                                                                                    <OpenAIComponent
                                                                                        fieldName="reasonForDefault"
                                                                                        formGroup={BusinessHistoryForm}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                )}
                                                            </>
                                                        )
                                                    }
                                                </div>

                                                {/* <!--  lm common divider to break the section  --> */}
                                                <div className="lm-divider my-3 mx-3"></div>
                                                {/* <!--  lm common divider to break the section  --> */}

                                                <div className="col-12 label text-dark mb-2"><b>LEGAL</b></div>

                                                <div className="col-12">
                                                    <div className="row">
                                                        <div className="col-lg-6">
                                                            <div className="form-group">
                                                                <div className="label pb-1">Is the business, its owners or affiliates, involved in any
                                                                    pending legal actions (including divorce)?
                                                                </div>
                                                                <div className="form-check form-check-inline">
                                                                    <input className="form-check-input" type="radio" name="involved_pending_legal_actions"
                                                                        id="involved_pending_legal_actions_yes"
                                                                        value={true}
                                                                        checked={BusinessHistoryForm.value.isInvolvedLawsuits === true}
                                                                        onChange={() => {
                                                                            BusinessHistoryForm.patchValue({ isInvolvedLawsuits: true });
                                                                        }}
                                                                    />
                                                                    <label className="form-check-label" htmlFor="involved_pending_legal_actions_yes">Yes</label>
                                                                </div>
                                                                <div className="form-check form-check-inline">
                                                                    <input className="form-check-input" type="radio" name="involved_pending_legal_actions"
                                                                        id="involved_pending_legal_actions_no"
                                                                        value={false}
                                                                        checked={BusinessHistoryForm.value.isInvolvedLawsuits === false}
                                                                        onChange={() => {
                                                                            BusinessHistoryForm.patchValue({ isInvolvedLawsuits: false });
                                                                        }}
                                                                    />
                                                                    <label className="form-check-label" htmlFor="involved_pending_legal_actions_no">No</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {BusinessHistoryForm.value.isInvolvedLawsuits && (
                                                    <>
                                                        <div className="col-md-4 col-lg-3">
                                                            <div className="form-group">
                                                                <FormField
                                                                    name="partiesLawsuit"
                                                                    label="Name of Business or Individual"
                                                                    placeholder="Enter Name"
                                                                    id="partiesLawsuit"
                                                                    maxlength={50}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4 col-lg-3">
                                                            <div className="form-group input-date">
                                                                <FieldControl
                                                                    name="dateCommencementLawsuit"
                                                                    render={({ handler, touched, hasError, meta }) => (
                                                                        <div className="form-group input-date">
                                                                            <label htmlFor="dateCommencementLawsuit">
                                                                                Date of Action
                                                                            </label>
                                                                            <DatePicker
                                                                                showIcon
                                                                                toggleCalendarOnIconClick
                                                                                {...handler()}
                                                                                selected={
                                                                                    BusinessHistoryForm.value.dateCommencementLawsuit
                                                                                }
                                                                                onChange={(date) => {
                                                                                    BusinessHistoryForm.patchValue({
                                                                                        dateCommencementLawsuit: date ? formatDate(date) : null,
                                                                                    });
                                                                                }}
                                                                                onKeyDown={(e) => {
                                                                                    e.preventDefault();
                                                                                }}
                                                                                isClearable={BusinessHistoryForm.value.dateCommencementLawsuit}
                                                                                id="dateCommencementLawsuit"
                                                                                dateFormat="MM/d/yyyy"
                                                                                placeholderText="MM/DD/YYYY"
                                                                                yearDropdownItemNumber={100}
                                                                                scrollableYearDropdown
                                                                                showMonthDropdown
                                                                                showYearDropdown
                                                                            />
                                                                        </div>
                                                                    )}
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className="col-md-4 col-lg-6">
                                                            <div className="form-group AI_btn_field">
                                                                <FormField
                                                                    name="involvedLawsuitsDetail"
                                                                    label="Reason"
                                                                    placeholder="Enter Reason"
                                                                    id="involvedLawsuitsDetail"
                                                                    maxlength={250}
                                                                />
                                                                <OpenAIComponent
                                                                    fieldName="involvedLawsuitsDetail"
                                                                    formGroup={BusinessHistoryForm}
                                                                />
                                                            </div>
                                                        </div>
                                                    </>
                                                )}

                                                <div className="col-12">
                                                    <div className="row">
                                                        <div className="col-lg-6">
                                                            <div className="form-group">
                                                                <div className="label pb-1">Is the Applicant or any Associate of the Applicant currently incarcerated,
                                                                    serving a sentence of imprisonment imposed upon adjudication of guilt,
                                                                    or is under indictment for a felony or any crime involving or relating to financial misconduct
                                                                    or a false statement?
                                                                </div>
                                                                <div className="form-check form-check-inline">
                                                                    <input className="form-check-input" type="radio" name="relating_financial_misconduct"
                                                                        id="relating_financial_misconduct_yes"
                                                                        value={true}
                                                                        checked={BusinessHistoryForm.value.isCrime}
                                                                        onChange={handleIsCrimeChange}
                                                                    />
                                                                    <label className="form-check-label" htmlFor="relating_financial_misconduct_yes">Yes</label>
                                                                </div>
                                                                <div className="form-check form-check-inline">
                                                                    <input className="form-check-input" type="radio" name="relating_financial_misconduct"
                                                                        id="relating_financial_misconduct_no"
                                                                        value={false}
                                                                        checked={!BusinessHistoryForm.value.isCrime}
                                                                        onChange={handleIsNoCrimeChange}
                                                                    />
                                                                    <label className="form-check-label" htmlFor="relating_financial_misconduct_no">No</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {BusinessHistoryForm.value.isCrime && (
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <div className="form-group AI_btn_field">
                                                                    <FormField
                                                                        name="reasonForCrime"
                                                                        label="Reason"
                                                                        placeholder="Enter Reason"
                                                                        id="reasonForCrime"
                                                                        maxlength={250}
                                                                    />
                                                                    <OpenAIComponent
                                                                        fieldName="reasonForCrime"
                                                                        formGroup={BusinessHistoryForm}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>



                                                {/* <!--  lm common divider to break the section  --> */}
                                                <div className="lm-divider my-3 mx-3"></div>
                                                {/* <!--  lm common divider to break the section  --> */}

                                                <div className="col-12 label text-dark mb-2"><b>ADDITIONAL</b></div>
                                                <div className="col-12">
                                                    <div className="row">
                                                        <div className="col-lg-6">
                                                            <div className="form-group">
                                                                <div className="label pb-1">
                                                                    Does your business, its owners or affiliates, currently export
                                                                    products or services; will it begin exporting products or services; or does it
                                                                    intend to apply for an Export Working Capital Program (EWCP) loan?
                                                                </div>
                                                                <div className="form-check form-check-inline">
                                                                    <input
                                                                        className="form-check-input"
                                                                        type="radio"
                                                                        name="currently_export_products_services"
                                                                        id="currently_export_products_services_yes"
                                                                        value={true}
                                                                        checked={BusinessHistoryForm.value.productsOrServicesExported === true}
                                                                        onChange={() => {
                                                                            BusinessHistoryForm.patchValue({ productsOrServicesExported: true });
                                                                        }}
                                                                    />
                                                                    <label className="form-check-label" htmlFor="currently_export_products_services_yes">Yes</label>
                                                                </div>
                                                                <div className="form-check form-check-inline">
                                                                    <input
                                                                        className="form-check-input"
                                                                        type="radio"
                                                                        name="currently_export_products_services"
                                                                        id="currently_export_products_services_no"
                                                                        value={false}
                                                                        checked={BusinessHistoryForm.value.productsOrServicesExported === false}
                                                                        onChange={() => {
                                                                            BusinessHistoryForm.patchValue({ productsOrServicesExported: false });
                                                                            removeAllCheckings(BusinessHistoryForm, 'countriesOfExport');
                                                                        }}
                                                                    />
                                                                    <label className="form-check-label" htmlFor="currently_export_products_services_no">No</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {BusinessHistoryForm.value.productsOrServicesExported && (
                                                    <div className="col-lg-7">
                                                        <div className="row">
                                                            <div className="col-md-5">
                                                                <div className="form-group input-dollar">
                                                                    <AmountContainer
                                                                        name="estimatedTotalExportSales"
                                                                        label="Total Export Sales"
                                                                        placeholder="0.00"
                                                                        id="estimatedTotalExportSales"
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-7">
                                                                <label >List the principal countries of export
                                                                    <span className="lm-tooltip ml-1"
                                                                        data-tooltip="Please list up three countries that are going exported to through this loan"
                                                                        data-tooltip-pos="up" data-tooltip-length="cutsom"> <img src={info_tooltip} alt="" />
                                                                    </span>
                                                                </label>
                                                                {exportCountries.map((country, index) => {
                                                                    return (
                                                                        <div key={index} className="d-flex">
                                                                            <div className="form-group addition-feild flex-grow-1 mr-2 mb-2">
                                                                                <input
                                                                                    label=""
                                                                                    className='form-control'
                                                                                    placeholder="Enter country"
                                                                                    maxLength={50}
                                                                                    value={country}
                                                                                    onChange={(e) => handleExportCountryChange(index, e.target.value)}
                                                                                />
                                                                            </div>
                                                                            <div className="d-flex align-items-start">
                                                                                {exportCountries.length > 1 && (
                                                                                    <img
                                                                                        className="cursor-pointer mt-2 mr-2"
                                                                                        src={feild_minus_icon}
                                                                                        alt="minus"
                                                                                        onClick={() => removeExportCountry(index)}
                                                                                    />
                                                                                )}
                                                                                {index === exportCountries.length - 1 && exportCountries.length < 3 && (
                                                                                    <img
                                                                                        className="cursor-pointer mt-2 mr-2"
                                                                                        src={feild_plus_icon}
                                                                                        alt="plus"
                                                                                        onClick={() => addExportCountry(index)}
                                                                                    />
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    );
                                                                })}
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}

                                                <div className="col-12">
                                                    <div className="row mt-2">
                                                        <div className="col-lg-6">
                                                            <div className="form-group">
                                                                <div className="label pb-1">Has the business, its owners or affiliates, committed to pay a
                                                                    fee to the Lender or a third party to assist in the preparation of the loan
                                                                    application or application materials, or committed to pay a referral agent or broker
                                                                    a fee?
                                                                </div>
                                                                <div className="form-check form-check-inline">
                                                                    <input className="form-check-input" type="radio" name="committed_to_pay_fee"
                                                                        id="committed_to_pay_fee_yes"
                                                                        value={true}
                                                                        checked={BusinessHistoryForm.value.usedPackagerBroker === true}
                                                                        onChange={() => {
                                                                            BusinessHistoryForm.patchValue({ usedPackagerBroker: true });
                                                                        }}
                                                                    />
                                                                    <label className="form-check-label" htmlFor="committed_to_pay_fee_yes">Yes</label>
                                                                </div>
                                                                <div className="form-check form-check-inline">
                                                                    <input className="form-check-input" type="radio" name="committed_to_pay_fee"
                                                                        id="committed_to_pay_fee_no"
                                                                        value={false}
                                                                        checked={BusinessHistoryForm.value.usedPackagerBroker === false}
                                                                        onChange={() => {
                                                                            BusinessHistoryForm.patchValue({ usedPackagerBroker: false });
                                                                        }}
                                                                    />
                                                                    <label className="form-check-label" htmlFor="committed_to_pay_fee_no">No</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-lg-6">
                                                            <div className="form-group">
                                                                <div className="label pb-1">Has the business, its owners or affiliates, acquired revenues
                                                                    derived from gambling, loan packaging, lending activities, lobbying activities, or
                                                                    from the sale of products or services, or the presentation of any depiction,
                                                                    displays or live performances, of a prurient sexual nature?
                                                                </div>
                                                                <div className="form-check form-check-inline">
                                                                    <input className="form-check-input" type="radio" name="acquired_revenues_from_gambling"
                                                                        id="acquired_revenues_from_gambling_yes"
                                                                        value={true}
                                                                        checked={BusinessHistoryForm.value.revenuesDerivedFromGambling === true}
                                                                        onChange={() => {
                                                                            BusinessHistoryForm.patchValue({ revenuesDerivedFromGambling: true });
                                                                        }}
                                                                    />
                                                                    <label className="form-check-label"
                                                                        htmlFor="acquired_revenues_from_gambling_yes">Yes</label>
                                                                </div>
                                                                <div className="form-check form-check-inline">
                                                                    <input className="form-check-input" type="radio" name="acquired_revenues_from_gambling"
                                                                        id="acquired_revenues_from_gambling_no"
                                                                        value={false}
                                                                        checked={BusinessHistoryForm.value.revenuesDerivedFromGambling === false}
                                                                        onChange={() => {
                                                                            BusinessHistoryForm.patchValue({ revenuesDerivedFromGambling: false });
                                                                        }}
                                                                    />
                                                                    <label className="form-check-label" htmlFor="acquired_revenues_from_gambling_no">No</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {BusinessHistoryForm.value.revenuesDerivedFromGambling && (
                                                        <div className="row">
                                                            <div className="col-lg-6">
                                                                <div className="form-group">
                                                                    <FormField
                                                                        name="revenuesDerivedFromGamblingExplain_Additional"
                                                                        label="Explain"
                                                                        placeholder="Enter an answer"
                                                                        id="revenuesDerivedFromGamblingExplain_Additional"
                                                                        maxlength={100}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>

                                                <>
                                                    <div className="lm-divider my-3 mx-3"></div>
                                                    <div className="col-12 label text-dark mb-2"><b>GOVERNMENT AGENCY DISCLOSURES</b></div>

                                                    <div className="col-lg-6">
                                                        <div className="form-group">
                                                            <div className="label pb-1">
                                                                Is any sole proprietor, partner, officer, director, stockholder with a 10 percent or more interest in the Applicant an SBA employee or a Household Member of an SBA employee?
                                                                <div class="big-tooltip"> <img src={info_tooltip} alt="info tooltip" />
                                                                    <span class="tooltiptext">A “household member” of an SBA employee includes: <br></br>
                                                                        a) the spouse of the SBA employee; <br></br>
                                                                        b) the minor children of said individual; and <br></br>
                                                                        c) the blood relatives of the employee, and the blood relatives <br></br>
                                                                        of the employee’s spouse who reside <br></br>
                                                                        in the same place of abode as the employee. [13 CFR 105.201 (d)]</span>
                                                                </div>
                                                            </div>
                                                            <div className="form-check form-check-inline">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="radio"
                                                                    name="noSBAEmployee"
                                                                    id="NoSBAEmployeeYes"
                                                                    value="Yes"
                                                                    checked={BusinessHistoryForm.value.noSBAEmployee == true}
                                                                    onChange={(e) => {
                                                                        BusinessHistoryForm.patchValue({ noSBAEmployee: true });
                                                                    }}
                                                                />
                                                                <label className="form-check-label" htmlFor="NoSBAEmployeeYes">
                                                                    Yes
                                                                </label>
                                                            </div>
                                                            <div className="form-check form-check-inline">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="radio"
                                                                    name="noSBAEmployee"
                                                                    id="NoSBAEmployeeNo"
                                                                    value="No"
                                                                    checked={BusinessHistoryForm.value.noSBAEmployee == false}
                                                                    onChange={(e) => {
                                                                        BusinessHistoryForm.patchValue({ noSBAEmployee: false });
                                                                    }} />
                                                                <label className="form-check-label" htmlFor="NoSBAEmployeeNo">
                                                                    No
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-lg-6">
                                                        <div className="form-group">
                                                            <div className="label pb-1">
                                                                Is any sole proprietor, general partner, officer, director, or stockholder with a 10 percent or more interest in the Applicant, or a household member of such individual, a Federal Government employee or Member of the Military having a grade of at least GS-13 or higher (or Military equivalent)?
                                                            </div>
                                                            <div className="form-check form-check-inline">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="radio"
                                                                    name="noGovernmentEmployee"
                                                                    id="NoGovernmentEmployeeYes"
                                                                    value="Yes"
                                                                    checked={BusinessHistoryForm.value.noGovernmentEmployee == true}
                                                                    onChange={(e) => {
                                                                        BusinessHistoryForm.patchValue({ noGovernmentEmployee: true });
                                                                    }}
                                                                />
                                                                <label className="form-check-label" htmlFor="NoGovernmentEmployeeYes">
                                                                    Yes
                                                                </label>
                                                            </div>
                                                            <div className="form-check form-check-inline">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="radio"
                                                                    name="noGovernmentEmployee"
                                                                    id="NoGovernmentEmployeeNo"
                                                                    value="No"
                                                                    checked={BusinessHistoryForm.value.noGovernmentEmployee == false}
                                                                    onChange={(e) => {
                                                                        BusinessHistoryForm.patchValue({ noGovernmentEmployee: false });
                                                                    }} />
                                                                <label className="form-check-label" htmlFor="NoGovernmentEmployeeNo">
                                                                    No
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-lg-6">
                                                        <div className="form-group">
                                                            <div className="label pb-1">
                                                                Is any employee, owner, partner, attorney, agent, owner of stock, officer, director, creditor or debtor of the Applicant a former SBA employee who has been separated from SBA for less than one year prior to the request for financial assistance?
                                                            </div>
                                                            <div className="form-check form-check-inline">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="radio"
                                                                    name="noFormerSBAEmployee"
                                                                    id="NoFormerSBAEmployeeYes"
                                                                    value="Yes"
                                                                    checked={BusinessHistoryForm.value.noFormerSBAEmployee == true}
                                                                    onChange={(e) => {
                                                                        BusinessHistoryForm.patchValue({ noFormerSBAEmployee: true });
                                                                    }}
                                                                />
                                                                <label className="form-check-label" htmlFor="NoFormerSBAEmployeeYes">
                                                                    Yes
                                                                </label>
                                                            </div>
                                                            <div className="form-check form-check-inline">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="radio"
                                                                    name="noFormerSBAEmployee"
                                                                    id="NoFormerSBAEmployeeNo"
                                                                    value="No"
                                                                    checked={BusinessHistoryForm.value.noFormerSBAEmployee == false}
                                                                    onChange={(e) => {
                                                                        BusinessHistoryForm.patchValue({ noFormerSBAEmployee: false });
                                                                    }}
                                                                />
                                                                <label className="form-check-label" htmlFor="NoFormerSBAEmployeeNo">
                                                                    No
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-lg-6">
                                                        <div className="form-group">
                                                            <div className="label pb-1">
                                                                Is any sole proprietor, general partner, officer, director, or stockholder with a 10 percent or more interest in the Applicant, or a household member of such individual, a member or employee of a Small Business Advisory Council or a SCORE volunteer?
                                                            </div>
                                                            <div className="form-check form-check-inline">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="radio"
                                                                    name="noMemberEmployeeOfSmallBusiness"
                                                                    id="NoMemberEmployeeOfSmallBusinessYes"
                                                                    value="Yes"
                                                                    checked={BusinessHistoryForm.value.noMemberEmployeeOfSmallBusiness == true}
                                                                    onChange={(e) => {
                                                                        BusinessHistoryForm.patchValue({ noMemberEmployeeOfSmallBusiness: true });
                                                                    }}
                                                                />
                                                                <label className="form-check-label" htmlFor="NoMemberEmployeeOfSmallBusinessYes">
                                                                    Yes
                                                                </label>
                                                            </div>
                                                            <div className="form-check form-check-inline">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="radio"
                                                                    name="noMemberEmployeeOfSmallBusiness"
                                                                    id="NoMemberEmployeeOfSmallBusinessNo"
                                                                    value="No"
                                                                    checked={BusinessHistoryForm.value.noMemberEmployeeOfSmallBusiness == false}
                                                                    onChange={(e) => {
                                                                        BusinessHistoryForm.patchValue({ noMemberEmployeeOfSmallBusiness: false });
                                                                    }}
                                                                />
                                                                <label className="form-check-label" htmlFor="NoMemberEmployeeOfSmallBusinessNo">
                                                                    No
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-lg-6">
                                                        <div className="form-group">
                                                            <div className="label pb-1">
                                                                Is any sole proprietor, general partner, officer, director, or stockholder with a 10 percent or more interest in the Applicant, or a household member of such individual, a member of Congress, or an appointed official or employee of the legislative or judicial branch of the Federal Government?
                                                            </div>
                                                            <div className="form-check form-check-inline">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="radio"
                                                                    name="noMemberOfCongress"
                                                                    id="NoMemberOfCongressYes"
                                                                    value="Yes"
                                                                    checked={BusinessHistoryForm.value.noMemberOfCongress == true}
                                                                    onChange={(e) => {
                                                                        BusinessHistoryForm.patchValue({ noMemberOfCongress: true });
                                                                    }}
                                                                />
                                                                <label className="form-check-label" htmlFor="NoMemberOfCongressYes">
                                                                    Yes
                                                                </label>
                                                            </div>
                                                            <div className="form-check form-check-inline">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="radio"
                                                                    name="noMemberOfCongress"
                                                                    id="NoMemberOfCongressNo"
                                                                    value="No"
                                                                    checked={BusinessHistoryForm.value.noMemberOfCongress == false}
                                                                    onChange={(e) => {
                                                                        BusinessHistoryForm.patchValue({ noMemberOfCongress: false });
                                                                    }}
                                                                />
                                                                <label className="form-check-label" htmlFor="NoMemberOfCongressNo"                                                    >
                                                                    No
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            </div>
                                        </div>
                                    )}
                                <div className="sticky-btn-footer d-flex justify-content-end">
                                    <button type="button" className="lm-button2" onClick={checkForBackButton}>Back</button>
                                    <button onClick={saveBusinessProfileForm} type="button" className="lm-button1 ml-3">Save</button>
                                </div>
                            </form>
                        )}
                    />


                    {/* <!--  add/edit owner form drawer --> */}
                    <form action="">
                        <Modal
                            className="fade-right drawer-modal"
                            show={ownerDrawerAddShow}
                            onHide={() => setOwnerDrawerAdd(false)}
                            aria-labelledby="ownerDrawerLabel"
                        >

                            <div className="modal-dialog">
                                <div className="modal-content">
                                    <div className="drawer-head d-flex justify-content-between">
                                        <h3>Add an Owner</h3>
                                        <button type="button" className="close" onClick={ownerDrawerAddClose}>
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div className="drawer-body">
                                        <div className="lm-nav-tabs radio-tabs">
                                            <ul className="nav nav-tabs" id="laTab" role="tablist">
                                                <li className="nav-item">
                                                    <button
                                                        className={`nav-link ${activeTab === "Individual" ? "active" : ""}`}
                                                        onClick={() => handleTabClick("Individual")}
                                                    >
                                                        Individual
                                                    </button>
                                                </li>
                                                <li className="nav-item">
                                                    <button
                                                        className={`nav-link ${activeTab === "Entity" ? "active" : ""}`}
                                                    // onClick={() => handleTabClick("Entity")}
                                                    >
                                                        Entity
                                                    </button>
                                                </li>
                                            </ul>
                                        </div>

                                        <div className="tab-content pt-3">
                                            {/* Individual tab */}
                                            <div className={`tab-pane fade ${activeTab === "Individual" ? "show active" : ""}`} id="Individual">
                                                <div className="form-group">
                                                    <label htmlFor="Owner_First_Name">First Name<span className="asterisk">*</span></label>
                                                    <input
                                                        type="text"
                                                        className={`form-control ${ownerErrors.firstName ? 'is-invalid' : ''}`}
                                                        id="Owner_First_Name"
                                                        placeholder="Enter first name"
                                                        required={true}
                                                    />
                                                    {ownerErrors.firstName && <div className="invalid-feedback">{ownerErrors.firstName}</div>}
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="Owner_Last_Name">Last Name<span className="asterisk">*</span></label>
                                                    <input
                                                        type="text"
                                                        className={`form-control ${ownerErrors.lastName ? 'is-invalid' : ''}`}
                                                        id="Owner_Last_Name"
                                                        placeholder="Enter first name"
                                                        required={true}
                                                    />
                                                    {ownerErrors.lastName && <div className="invalid-feedback">{ownerErrors.lastName}</div>}
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="Email">Email<span className="asterisk">*</span></label>
                                                    <input
                                                        type="text"
                                                        className={`form-control ${ownerErrors.email ? 'is-invalid' : ''}`}
                                                        id="Owner_Email"
                                                        name="Email"
                                                        placeholder="Enter email"
                                                        required={true}
                                                    />
                                                    {ownerErrors.email && <div className="invalid-feedback">{ownerErrors.email}</div>}
                                                </div>

                                                <div className="form-group input-percentage">
                                                    <label htmlFor="Owner_Ownership">Ownership</label>
                                                    <input
                                                        type="number"
                                                        className={`form-control ${ownerErrors.ownership ? 'is-invalid' : ''}`}
                                                        id="Owner_Ownership"
                                                        placeholder="0.00"
                                                        required={true}
                                                    />
                                                    {ownerErrors.ownership && <div className="invalid-feedback">{ownerErrors.ownership}</div>}
                                                    <span style={{ fontSize: "12px" }} className='text-danger'>{errMsg}</span>
                                                </div>

                                                {shouldRenderOwnersMembersOption ?
                                                    <div className="form-group">
                                                        <label htmlFor="Select_entity">Member</label>
                                                        <Select
                                                            className={`basic-single ${ownerErrors.member ? 'is-invalid' : ''}`}
                                                            classNamePrefix="select"
                                                            menuPortalTarget={document.body}
                                                            styles={{
                                                                menuPortal: base => ({ ...base, zIndex: 9999 })
                                                            }}
                                                            options={membersDropdownOptions}
                                                            value={membersDropdownOptions.find(index => index.value == selectedMember)}
                                                            onChange={handleMemberSelectChange}
                                                        />
                                                        {ownerErrors.member && <div className="invalid-feedback">{ownerErrors.member}</div>}
                                                    </div>
                                                    :
                                                    <div className="form-group form-check">
                                                        <input type="checkbox" className="form-check-input" id="owner_Director" />
                                                        <label className="form-check-label" htmlFor="Director">Director</label>
                                                    </div>
                                                }
                                            </div>

                                            {/* Entity tab */}
                                            <div className={`tab-pane fade ${activeTab === "Entity" ? "show active" : ""}`} id="Entity">
                                                <div className="form-group">
                                                    <label htmlFor="Select_entity">Select an entity<span className="asterisk">*</span></label>
                                                    <Select
                                                        className={`basic-single ${ownerErrors.entityName ? 'is-invalid' : ''}`}
                                                        classNamePrefix="select"
                                                        id="EntityName"
                                                        name="EntityName"
                                                        options={entityOptions}
                                                        onChange={handleEntitySelectChangeModel}
                                                        value={selectedEntity}
                                                    />
                                                    {ownerErrors.entityName && <div className="invalid-feedback">{ownerErrors.entityName}</div>}
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="Email_entity">Email<span className="asterisk">*</span></label>
                                                    <input
                                                        type="text"
                                                        className={`form-control ${ownerErrors.entityEmail ? 'is-invalid' : ''}`}
                                                        id="Email_entity"
                                                        placeholder="Enter email"
                                                    />
                                                    {ownerErrors.entityEmail && <div className="invalid-feedback">{ownerErrors.entityEmail}</div>}
                                                </div>
                                                <div className="form-group input-percentage">
                                                    <label htmlFor="Ownership_entity">Ownership</label>
                                                    <input
                                                        type="number"
                                                        className={`form-control ${ownerErrors.entityOwnership ? 'is-invalid' : ''}`}
                                                        id="Ownership_entity"
                                                        placeholder="0.00"
                                                    />
                                                    {ownerErrors.entityOwnership && <div className="invalid-feedback">{ownerErrors.entityOwnership}</div>}
                                                    <span style={{ fontSize: "12px" }} className='text-danger'>{errMsg}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="drawer-footer d-flex la-app-modal-footer">
                                        <button type="button" className="lm-button2" onClick={ownerDrawerAddClose}>Cancel</button>
                                        <button type="button" className="lm-button1" onClick={saveOwnerDetails}>Save</button>
                                    </div>
                                </div>
                            </div>

                        </Modal>

                        <Modal
                            className="fade-right drawer-modal"
                            show={ownerDrawerShow}
                            onHide={() => setOwnerDrawer(false)}
                            aria-labelledby="ownerDrawerLabel"
                        >

                            <div className="modal-dialog">
                                <div className="modal-content">
                                    <div className="drawer-head d-flex justify-content-between">
                                        <h3>Edit Owner</h3>
                                        <button type="button" className="close" onClick={ownerDrawerClose}>
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div className="drawer-body">
                                        <div className="lm-nav-tabs radio-tabs">
                                            <ul className="nav nav-tabs" id="laTab" role="tablist">
                                                <li className="nav-item">
                                                    <button
                                                        className={`nav-link ${activeTab === "Individual" ? "active" : ""}`}
                                                        onClick={activeTab === "Individual" ? () => handleTabClick("Individual") : undefined}
                                                    >
                                                        Individual
                                                    </button>
                                                </li>
                                                <li className="nav-item">
                                                    <button
                                                        className={`nav-link ${activeTab === "Entity" ? "active" : ""}`}
                                                        onClick={activeTab === "Entity" ? () => handleTabClick("Entity") : undefined}
                                                    >
                                                        Entity
                                                    </button>
                                                </li>
                                            </ul>
                                        </div>

                                        <div className="tab-content pt-3">
                                            {/* Individual tab */}

                                            <div className={`tab-pane fade ${activeTab === "Individual" ? "show active" : ""}`} id="Individual">
                                                <div className="form-group">
                                                    <label htmlFor="First_Name">First Name<span className="asterisk">*</span></label>
                                                    <input
                                                        type="text"
                                                        className={`form-control ${ownerErrors.firstName ? 'is-invalid' : ''}`}
                                                        id="First_Name"
                                                        placeholder="Enter first name"
                                                        required={true}
                                                        value={selectedOwner ? selectedOwner.name : ''}
                                                        onChange={(e) => setSelectedOwner({ ...selectedOwner, name: e.target.value })}
                                                    />
                                                    {ownerErrors.firstName && <div className="invalid-feedback">{ownerErrors.firstName}</div>}
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="Last_Name">Last Name<span className="asterisk">*</span></label>
                                                    <input
                                                        type="text"
                                                        className={`form-control ${ownerErrors.lastName ? 'is-invalid' : ''}`}
                                                        id="Last_Name"
                                                        placeholder="Enter last name"
                                                        required={true}
                                                        value={selectedOwner ? selectedOwner.lastName : ''}
                                                        onChange={(e) => setSelectedOwner({ ...selectedOwner, lastName: e.target.value })}
                                                    />
                                                    {ownerErrors.lastName && <div className="invalid-feedback">{ownerErrors.lastName}</div>}
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="Email">Email<span className="asterisk">*</span></label>
                                                    <input
                                                        type="text"
                                                        className={`form-control ${ownerErrors.email ? 'is-invalid' : ''}`}
                                                        id="Email"
                                                        placeholder="Enter email"
                                                        required={true}
                                                        value={selectedOwner ? selectedOwner.email : ''}
                                                        disabled={isEmailDisabled}
                                                        onChange={(e) => setSelectedOwner({ ...selectedOwner, email: e.target.value })}
                                                    />
                                                    {ownerErrors.email && <div className="invalid-feedback">{ownerErrors.email}</div>}
                                                    <a className="lm-button3 mt-2" onClick={handleEditEmailClick}>
                                                        <img className="mr-1" src={lm_edit_icon} alt="" /> Edit email address
                                                    </a>
                                                </div>
                                                <div className="form-group input-percentage">
                                                    <label htmlFor="Ownership">Ownership</label>
                                                    <input
                                                        type="number"
                                                        className={`form-control ${ownerErrors.ownership ? 'is-invalid' : ''}`}
                                                        id="Ownership"
                                                        placeholder="0.00"
                                                        required={true}
                                                        value={selectedOwner ? selectedOwner.ownershipePercentage : ''}
                                                        onChange={(e) => setSelectedOwner({ ...selectedOwner, ownershipePercentage: e.target.value })}
                                                    />
                                                    {ownerErrors.ownership && <div className="invalid-feedback">{ownerErrors.ownership}</div>}
                                                    <span style={{ fontSize: "12px" }} className='text-danger'>{errMsg}</span>
                                                </div>

                                                {shouldRenderOwnersMembersOption ?
                                                    <div className="form-group">
                                                        <label htmlFor="Select_entity">Member</label>
                                                        <Select
                                                            className={`basic-single ${ownerErrors.member ? 'is-invalid' : ''}`}
                                                            classNamePrefix="select"
                                                            menuPortalTarget={document.body}
                                                            styles={{
                                                                menuPortal: base => ({ ...base, zIndex: 9999 })
                                                            }}
                                                            value={selectedMember}
                                                            onChange={(option) => setSelectedMember(option)}
                                                            options={membersDropdownOptions}
                                                        />
                                                        {ownerErrors.member && <div className="invalid-feedback">{ownerErrors.member}</div>}
                                                    </div>
                                                    :
                                                    <div className="form-group form-check">
                                                        <input
                                                            type="checkbox"
                                                            className="form-check-input"
                                                            id="Director"
                                                            checked={selectedOwner ? selectedOwner.isDirector : false}
                                                            onChange={(e) => setSelectedOwner({ ...selectedOwner, isDirector: e.target.checked })}
                                                            disabled={!selectedOwner}
                                                        />
                                                        <label className="form-check-label" htmlFor="Director">Director</label>
                                                    </div>
                                                }
                                            </div>

                                            {/* Entity tab */}
                                            <div className={`tab-pane fade ${activeTab === "Entity" ? "show active" : ""}`} id="Entity">
                                                <div className="form-group">
                                                    <label htmlFor="Select_entity">Select an entity<span className="asterisk">*</span></label>
                                                    <Select
                                                        className={`basic-single ${ownerErrors.entityName ? 'is-invalid' : ''}`}
                                                        classNamePrefix="select"
                                                        id="entityName"
                                                        name="entityName"
                                                        options={entityOptions}
                                                        value={selectedEntity}
                                                        onChange={(option) => setSelectedEntity(option)}
                                                        required={true}
                                                    />
                                                    {ownerErrors.entityName && <div className="invalid-feedback">{ownerErrors.entityName}</div>}
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="Email_entity">Email<span className="asterisk">*</span></label>
                                                    <input
                                                        type="text"
                                                        className={`form-control ${ownerErrors.entityEmail ? 'is-invalid' : ''}`}
                                                        id="Email_entity"
                                                        value={selectedOwner ? selectedOwner.email : ''}
                                                        onChange={(e) => setSelectedOwner({ ...selectedOwner, email: e.target.value })}
                                                        disabled={isEmailDisabled}
                                                        required={true}
                                                    />
                                                    {ownerErrors.entityEmail && <div className="invalid-feedback">{ownerErrors.entityEmail}</div>}
                                                </div>
                                                <div className="form-group input-percentage">
                                                    <label htmlFor="Ownership_entity">Ownership<span className="asterisk">*</span></label>
                                                    <input
                                                        type="number"
                                                        className={`form-control ${ownerErrors.entityOwnership ? 'is-invalid' : ''}`}
                                                        id="Ownership_entity"
                                                        placeholder="0.00"
                                                        value={selectedOwner ? selectedOwner.ownershipePercentage : ''}
                                                        onChange={(e) => setSelectedOwner({ ...selectedOwner, ownershipePercentage: e.target.value })}
                                                        required={true}
                                                    />
                                                    {ownerErrors.entityOwnership && <div className="invalid-feedback">{ownerErrors.entityOwnership}</div>}
                                                    <span style={{ fontSize: "12px" }} className='text-danger'>{errMsg}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="drawer-footer d-flex la-app-modal-footer">
                                        <button type="button" className="lm-button2" style={{ color: "crimson" }} onClick={handleRemoveOwner}>Remove</button>
                                        <button type="button" className="lm-button1" onClick={handleEditOwnerDetails}>Save</button>
                                    </div>
                                </div>
                            </div>

                        </Modal>
                    </form>
                    {/* <!--  add/edit owner form drawer --> */}


                    {/* <!--back to Previous Tab Popup / Modal --> */}
                    <Modal
                        className='mark-comp-popup-cust-size'
                        show={PreviousTabPopupShow}
                        onHide={() => setPreviousTabPopup(false)}
                        aria-labelledby="PreviousTabPopupLabel"
                        backdrop="static"
                        keyboard={false}
                        centered
                    >
                        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                            <div className="modal-content">
                                <div className="modal-body signout-modal">
                                    <h3 className="pt-4">
                                        Going back will lead to the loss of unsaved data
                                    </h3>
                                </div>
                                <div className="modal-footer la-app-modal-footer">
                                    <button type="button" className="lm-button2" onClick={PreviousTabPopupClose}>Cancel</button>
                                    <button type="button" className="lm-button1" onClick={backPreviousTab}>Confirm</button>
                                </div>
                            </div>
                        </div>
                    </Modal>
                    {/* <!--back to Previous Tab Popup / Modal --> */}

                    <Modal
                        show={showTaxFormConfirmation}
                        onHide={() => setPreviousTabPopup(false)}
                        aria-labelledby="PreviousTabPopupLabel"
                        backdrop="static"
                        keyboard={false}
                        centered
                    >

                        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                            <div className="modal-content">
                                <div className="modal-body signout-modal">
                                    <h3 className="pt-4">
                                        Making the change may impact the balance sheet under cash flow. Do you want to continue with the change?
                                    </h3>
                                </div>
                                <div className="modal-footer la-app-modal-footer">
                                    <button type="button" className="lm-button2" onClick={() => handleTaxFormConfirmation(false)}>No</button>
                                    <button type="button" className="lm-button1" onClick={() => handleTaxFormConfirmation(true)}>Yes</button>
                                </div>
                            </div>
                        </div>
                    </Modal>
                </div>
            </div>
            )}
        </>
    );
}

export default BusinessProfile;