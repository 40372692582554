import React, { createRef, useEffect, useRef, useState } from "react";
import { Header, Footer } from "@components";
import Select, { createFilter } from 'react-select';
import { Link } from 'react-router-dom';
import './styles/Register.css';
import './styles/WebsiteStyle.css';
import { Dropdown } from "bootstrap";
import { FieldGroup, FormBuilder, Validators, FieldControl } from "react-reactive-form";
import Toaster from "../plugins/Toaster";
import ReCAPTCHA from 'react-google-recaptcha';
import Constants from "../helpers/Constants";
import { getUSAStates, register, USPSAPI } from "../services/commonService";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import reducersAndActions from "../_redux/slices/index";
import Preloader from "../plugins/Preloader";
import login_error_icon from '../assets/icons/login-error-icon.svg';
import { formatNumberWithCommas, zipCodeValidator, borrowAmountValidator, businessNameValidator, contactNumberValidate } from "../helpers/DynamicFunctions";
import ContactNumberField from "../components/ContactField/ContactField"
import { staticLoanReasonList, staticStatesList, staticTypeOfBusinessList } from "../helpers/staticContentData";
import FormField from "../components/FormField/FormField";

const advisorCodeValidator = (control) => {
  if (!control.value) {
    return null;
  } else if (control.value && control.value.length < 5) {
    return { advisorCodeTooShort: true };
  }
  return null;
};

// Form Groups
const firstForm = FormBuilder.group({
  BorrowAmount: ["", [Validators.required, borrowAmountValidator, Validators.pattern("^[0-9,.]*$")]],
  Purpose: ["", Validators.required],
  TypeOfBusiness: ["", Validators.required],
  BusinessName: ["", [Validators.required, businessNameValidator]],
  State: ["", Validators.required],
  advisorCode: ["", advisorCodeValidator],
});

const secondForm = FormBuilder.group({
  FirstName: ["", [Validators.required, Validators.pattern("^[a-zA-Z]*$")]],
  LastName: ["", [Validators.required, Validators.pattern("^[a-zA-Z]*$")]],
  BZipCode: ["", [Validators.required, zipCodeValidator, Validators.pattern("^[0-9]*$")]],
  ContactNo: ["", [Validators.required, contactNumberValidate]],
  EmailAddress: ["", [Validators.required, Validators.email]],
});

// select Dropdown options
let objectiveOptions = [];
let businessTypeOptions = [];
let stateLists = [];
let policyFlag = false;


function Register() {
  // Meta Title
  useEffect(() => {
    document.title = "Request form - Loan Mantra";
    window.scrollTo(0, 0);
    const urlSearchString = window.location.search;
    const params = new URLSearchParams(urlSearchString);
    const advisorCode = params.get("data");
    if (advisorCode) {
      firstForm.patchValue({ advisorCode: params.get("data") });
      setIsAdvisorCode(true);
    }
  }, []);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isAdvisorCode, setIsAdvisorCode] = useState(false);
  const [showInfoMessage, setShowInfoMessage] = useState(false);
  const [TypeOfBusnessOptions, setTypeOfBusnessOptions] = useState([]);

  const [currentState, setCurrentState] = useState(1);
  const [loading, setLoading] = useState(false);


  const errorbox = useSelector((state) => state.errorbox);
  const objectiveRef = createRef();
  const businessRef = createRef();
  const stateRef = createRef();

  const recaptchaRef = React.createRef();
  const sitekey = Constants.ReCAPTCHAKEY;

  const filterConfig = {
    ignoreCase: true,
    ignoreAccents: true,
    trim: true,
    matchFrom: "start"
  };

  useEffect(() => {
    stateLists = staticStatesList;
    objectiveOptions = staticLoanReasonList
    setTypeOfBusnessOptions(staticTypeOfBusinessList);
  }, []);


  // Marking all input fields as touched if the form is not valid
  const markAllAsTouched = (formGroup) => {
    if (objectiveRef.current) objectiveRef.current.focus();
    if (businessRef.current) businessRef.current.focus();
    if (stateRef.current) stateRef.current.focus();

    Object.keys(formGroup.controls).forEach(controlName => {
      if (controlName) {
        const control = formGroup.get(controlName);
        control.active = true;
        control.touched = true;
        document.getElementById(controlName).focus();
      }
    });
  }

  const handleFirstForm = () => {
    if (firstForm.status !== "INVALID") {
      setCurrentState(2);
    } else {
      markAllAsTouched(firstForm);
    }
  }

  const FieldControlInput = ({ name, label, id, placeholder, required, errorMessage, patternMessage, patternRequired, emailRequired }) => {
    return (
      <FieldControl
        name={name}
        render={({ handler, touched, hasError, meta }) =>
        (
          <div className="form-group">
            <label htmlFor={name}>
              {label}{required && <span className="asterisk">*</span>}
            </label>
            <input type="text" className="form-control" id={id} placeholder={placeholder} {...handler()} />

            {/* Error messages below */}
            {required && <>
              <span className="error-message">{touched && hasError("required") && `${errorMessage}`}</span>
              {touched && hasError("required") && <br />}
            </>}
            {(required || patternRequired) && <>
              <span className="error-message">{touched && hasError("pattern") && `${patternMessage}`}</span>
              {touched && hasError("pattern") && <br />}
            </>}
            {(emailRequired) && <>
              <span className="error-message">{touched && hasError("email") && `Enter a valid email address`}</span>
              {touched && hasError("email") && <br />}
            </>}
            {required && <>
              <span className="error-message">{touched && hasError("zipCodeTooShort") && `Zip Code should be of 5 digits.`}</span>
            </>}
          </div>
        )
        }
        meta={{ placeholder: placeholder }}
      />
    );
  };

  const formatValue = (value) => {
    if (typeof value === 'object' && value !== null) {
      return value.label || value.value || JSON.stringify(value);
    }
    return value;
  };

  const formattedValues = Object.keys(firstForm.value).reduce((acc, key) => {
    acc[key] = formatValue(firstForm.value[key]);
    return acc;
  }, {});


  const handleError = () => {
    dispatch(reducersAndActions.actions.errorActions.showError({ visible: true, message: "Please verify the CAPTCHA." }));
    setTimeout(() => dispatch(reducersAndActions.actions.errorActions.hideError()), Constants.TOASTERHIDETIME);
  };


  firstForm.value = formattedValues
  const handleSecondForm = async (e) => {
    e.preventDefault();
    const recaptchaValue = recaptchaRef.current.getValue();
    if (Constants.isCheckCaptcha && !recaptchaValue) {
      handleError();
      return;
    }

    setLoading(true);
    if (policyFlag) {
      if (secondForm.status !== "INVALID" && policyFlag) {

        let finalObj = {
          ...firstForm.value,
          ...secondForm.value,
          RequestUrl: Constants.FRONTEND_URL,
        }


        // USPS API
        const uspsData = await USPSAPI(secondForm.value.BZipCode);
        if (uspsData.status) {
          finalObj['BCity'] = uspsData.BCity;
          finalObj['BState'] = uspsData.BState;
        }

        for (let elem of Object.keys(finalObj)) {
          if (finalObj[elem] && typeof finalObj[elem] == 'string') {
            finalObj[elem] = finalObj[elem].trim();
          }
        }
        register(finalObj).then(data => {
          if (data.status == 200) {
            firstForm.reset();
            secondForm.reset();
            setLoading(false);
            dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: data.message, type: "success" }));
            setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
            navigate("/success-screen", { state: { title: "Congratulations!", subtitle: `Our team will reach out soon to discuss your inquiry.` } });
          } else {
            window.scrollTo(0, 0);
            dispatch(reducersAndActions.actions.errorActions.showError({ visible: true, message: data.message }));
            setTimeout(() => dispatch(reducersAndActions.actions.errorActions.hideError()), Constants.TOASTERHIDETIME);
            setLoading(false);
          }
        }).catch(err => {
          console.log(err);
          window.scrollTo(0, 0);
          dispatch(reducersAndActions.actions.errorActions.showError({ visible: true, message: err.response.data.message }));
          setTimeout(() => dispatch(reducersAndActions.actions.errorActions.hideError()), Constants.TOASTERHIDETIME);
          setLoading(false);
        });
      } else {
        setLoading(false);
        markAllAsTouched(secondForm);
      }
    } else {
      window.scrollTo(0, 0);
      dispatch(reducersAndActions.actions.errorActions.showError({ visible: true, message: "Please accept Terms & Conditions" }));
      setTimeout(() => dispatch(reducersAndActions.actions.errorActions.hideError()), Constants.TOASTERHIDETIME);
      setLoading(false);
    }
  }

  const handleCheckbox = (e) => {
    if (e.target.checked) {
      policyFlag = true;
    } else {
      policyFlag = false;
    }
  }

  const handleInputChange = (e, handler) => {
    const { value } = e.target;
    // Remove leading spaces only
    const formattedValue = value.replace(/^\s+/, '').trim();
    e.target.value = formattedValue;
    handler().onChange(e);
  };

  const handleChangeTypeOfBusiness = (selectedOption, handler) => {
    const showMsg = selectedOption.value === "Sole Proprietor" || selectedOption.value === "Independent Contractor";
    setShowInfoMessage(showMsg);
    handler().onChange(selectedOption);
  };

  const ShowMessage = ({ visible }) => {
    return visible ? <span style={{ fontSize: "12px", marginTop: "-10px" }}> (Owner's name is acceptable)</span> : "";
  }

  return (
    <>
      {/* Header */}
      <Header />

      {/* Loader */}
      {loading ? <Preloader /> :

        <>
          {/* Main Body */}
          <div className="website-wrap">
            <section className="register-body">
              <main>
                <div className="container">
                  <h2>Apply for a business loan</h2>

                  {/* validation-box start*/}
                  {/* validation-box start*/}
                  {
                    errorbox.visible && <div className="register-validation-box">
                      <img className="mr-2" src={login_error_icon} alt="" />
                      {errorbox.message}
                    </div>
                  }
                  {currentState == 1 && (
                    <div className="register-box step-1">
                      <h4> <span>1. </span>Credit information </h4>
                      <FieldGroup
                        control={firstForm}
                        render={({ get, invalid }) => (
                          <form>
                            <div className="row">
                              <div className="col-md-6">
                                <FieldControl
                                  name="BorrowAmount"
                                  render={({ handler, touched, hasError, meta }) => {
                                    const handlerProps = handler();
                                    const handleChange = (e) => {
                                      const rawValue = e.target.value.replace(/,/g, ''); // Remove existing commas
                                      const formattedValue = formatNumberWithCommas(rawValue);
                                      e.target.value = formattedValue;
                                      if (handlerProps.onChange) {
                                        handlerProps.onChange(e);
                                      }
                                    };

                                    return (<div className="form-group input-dollar">
                                      <label htmlFor="BorrowAmount">
                                        How much would you like to borrow?
                                        <span className="asterisk">*</span>
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        id="BorrowAmount"
                                        placeholder="400,000.00"
                                        {...handlerProps}
                                        onChange={handleChange}
                                      />

                                      {/* Error messages below */}
                                      {touched && hasError("required") && <><span className="error-message">Borrow amount is required</span><br /></>}
                                      {touched && hasError("borrowAmountIssue") && <><span className="error-message">Amount should be greater than $25,000</span><br /></>}
                                      {touched && hasError("pattern") && <><span className="error-message">Amount should be only numeric values</span><br /></>}
                                      {touched && hasError("borrowAmountMaxIssue") && <><span className="error-message">Amount should be less than or equal to 10000000000</span><br /></>}
                                    </div>)
                                  }
                                  }
                                  meta={{ placeholder: "400,000.00" }}
                                />
                              </div>
                              <div className="col-md-6">
                                <FieldControl
                                  name="Purpose"
                                  render={({ handler, touched, hasError, meta }) =>
                                  (<div className="form-group">
                                    <label htmlFor="Purpose">The objective of the loan<span className="asterisk">*</span></label>
                                    <Select
                                      className="basic-single"
                                      classNamePrefix="select"
                                      id="Purpose"
                                      name="Purpose"
                                      ref={objectiveRef}
                                      autoComplete="off"
                                      options={objectiveOptions}
                                      defaultValue={objectiveOptions[0]}
                                      {...handler()}
                                    />

                                    {/* Error messages below */}
                                    <span className="error-message">{touched && hasError("required") && `Objective is required`}</span>
                                  </div>)
                                  }
                                />
                              </div>
                              <div className="col-md-6">
                                <FieldControl
                                  name="TypeOfBusiness"
                                  render={({ handler, touched, hasError, meta }) => (
                                    <div className="form-group">
                                      <label htmlFor="TypeOfBusiness">Type of business<span className="asterisk">*</span></label>
                                      <Select
                                        ref={businessRef}
                                        className="basic-single"
                                        classNamePrefix="select"
                                        id="TypeOfBusiness"
                                        name="TypeOfBusiness"
                                        options={staticTypeOfBusinessList}
                                        onChange={(selectedOption) => { handleChangeTypeOfBusiness(selectedOption, handler) }}
                                      />

                                      {/* Error messages below */}
                                      <span className="error-message">{touched && hasError("required") && `Business type is required`}</span>
                                    </div>
                                  )}
                                />
                              </div>
                              <div className="col-md-6">
                                <label htmlFor="BusinessName">
                                  Business name<span className="asterisk">*</span>
                                </label>
                                <ShowMessage visible={showInfoMessage} />
                                <FieldControl
                                  name="BusinessName"
                                  render={({ handler, touched, hasError, meta }) =>
                                  (<div className="form-group">

                                    <input type="text"
                                      ref={(input) => input && input.setAttribute('autocomplete', 'off')}
                                      className="form-control" id="BusinessName" placeholder="Sotanic Agro" {...handler()} />

                                    {/* Error messages below */}
                                    <span className="error-message">{touched && hasError("required") && `Business name is required`}</span>
                                    <span className="error-message">{touched && hasError("businessNameIssue") && `Business name should be less than 100`}</span>
                                    <span className="error-message">{touched && hasError("extraSpaces") && `Please remove unwanted space`}</span>
                                  </div>
                                  )
                                  }
                                />
                              </div>
                              <div className="col-md-6">
                                <FieldControl
                                  name="State"
                                  render={({ handler, touched, hasError, meta }) =>
                                  (<div className="form-group">
                                    <label htmlFor="State"> Business state<span className="asterisk">*</span></label>
                                    <Select
                                      className="basic-single"
                                      classNamePrefix="select"
                                      id="State"
                                      name="State"
                                      autoComplete="off"
                                      ref={stateRef}
                                      options={stateLists}
                                      filterOption={createFilter(filterConfig)}
                                      {...handler()}
                                    />

                                    {/* Error messages below */}
                                    <span className="error-message">{touched && hasError("required") && `Business state is required`}</span>
                                  </div>)
                                  }
                                />
                              </div>
                              <div className="col-md-6">
                                <FormField
                                  name="advisorCode"
                                  label="Business code"
                                  id="advisorCode"
                                  placeholder="Enter a code"
                                  required={false}
                                  patternRequired={true}
                                  disabled={isAdvisorCode}
                                  maxlength={6}
                                />
                              </div>
                            </div>
                            <div className="btn-grp">
                              {/* <button type="button" className="btn btn-back"><Link to="/">Back to Login</Link></button> */}
                              <button type="button" id="step-btn-1" className="btn form-btn btn-next" onClick={handleFirstForm}>Next</button>
                            </div>
                          </form>
                        )}
                      />
                    </div>
                  )}

                  {currentState == 2 && (
                    <div className="register-box step-2">
                      <h4>
                        <span>2. </span>Personal information
                      </h4>
                      <FieldGroup
                        control={secondForm}
                        render={({ get, invalid }) => (
                          <form>
                            <div className="row">
                              <div className="col-md-6">
                                <FieldControlInput
                                  name="FirstName"
                                  label="First name"
                                  id="FirstName"
                                  placeholder="Peter"
                                  required={true}
                                  errorMessage="First name is required"
                                  patternMessage="First name should only contain text"
                                />
                              </div>
                              <div className="col-md-6">
                                <FieldControlInput
                                  name="LastName"
                                  label="Last name"
                                  id="LastName"
                                  placeholder="Darvin"
                                  required={true}
                                  errorMessage="Last name is required"
                                  patternMessage="Last name should only contain text"
                                  patternRequired={true}
                                />
                              </div>
                              <div className="col-md-6">
                                <FieldControlInput
                                  name="BZipCode"
                                  label="Zip code"
                                  id="BZipCode"
                                  placeholder="10001"
                                  required={true}
                                  errorMessage="Zip Code is required"
                                  patternMessage={"Zip Code should be numeric"}
                                  patternRequired={true}
                                  maxLength={5}
                                />
                              </div>
                              <div className="col-md-6">
                                <ContactNumberField
                                  name="ContactNo"
                                  label="Mobile number"
                                  id="ContactNo"
                                  required={true}
                                  errorMessage="Mobile Number is required"
                                />
                              </div>
                              <div className="col-md-6">
                                <FieldControlInput
                                  name="EmailAddress"
                                  label="Email address"
                                  id="EmailAddress"
                                  autoComplete="off"
                                  placeholder="peterdarvinosco@gmail.com"
                                  required={true}
                                  errorMessage="Email is required"
                                  emailRequired={true}
                                />
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <div className="captcha-box">
                                    <ReCAPTCHA
                                      ref={recaptchaRef}
                                      sitekey={sitekey}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-6">
                                <div className="cust-check">
                                  <input id="form-check" name="form-check" autoComplete="off" type="checkbox" onClick={handleCheckbox} value={policyFlag} />
                                  <label htmlFor="form-check">
                                    l Agree to the <a href="https://loanmantra.com/privacy-policy/" target="_blank">Privacy Notice</a> and
                                    <br />
                                    <a href="https://loanmantra.com/general-terms-of-use/" target="_blank">General Terms of Use</a>
                                  </label>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="btn-grp">
                                  <button type="button" id="step-btn-2" className="btn btn-back" onClick={() => setCurrentState(1)}>
                                    Back
                                  </button>
                                  <button type="button" className="btn form-btn btn-next" disabled={loading} onClick={handleSecondForm}>
                                    Send a loan request
                                  </button>
                                </div>
                              </div>
                            </div>
                          </form>
                        )}
                      />
                    </div>
                  )}
                </div>
              </main>
            </section>
          </div>

          {/* Footer */}
          <Footer />
        </>

      }
    </>
  );
}

export default Register;
