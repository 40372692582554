import React, { useState, useEffect, createRef } from 'react';
import Modal from 'react-bootstrap/Modal'
import { Link, useNavigate, useLocation } from "react-router-dom";
import Select, { createFilter } from 'react-select';
import DatePicker from "react-datepicker";
import { SidePanel, ProfileHeader } from "@components";
import Preloader from '../../plugins/Preloader';
import back_arrow from '../../assets/icons/back_arrow.svg';
import brdcrm_nav from '../../assets/icons/brdcrm_nav.svg';
import Info_tooltip from '../../assets/icons/Info-tooltip.svg';
import lm_edit_icon from '../../assets/icons/lm-edit-icon.svg';
import close_popup from '../../assets/icons/close_popup.svg';
import feild_minus_icon from '../../assets/icons/feild_minus_icon.svg';
import feild_plus_icon from '../../assets/icons/feild_plus_icon.svg';
import pfs_card_cross from '../../assets/icons/pfs_card_cross.svg';
import pfs_card_tick from '../../assets/icons/pfs_card_tick.svg';
import lm_timeslight from '../../assets/icons/lm-times-light.svg';
import { GetPFSListByLoanId, insertUpdatePersonalFinance } from '../../services/loanApplicationServices';
import { getSessionId } from '../../services/storageData';
import { FieldGroup, FormBuilder, Validators, FieldControl, FormArray, FormControl } from 'react-reactive-form';
import FormField from '../../components/FormField/FormField';
import { getUSAStates } from "../../services/commonService";
import moment from 'moment';

import {
    addCheckings,
    removeCheckings,
    returnPfsFormGroupInArray,
    returnCdsFormGroupInArray,
    returnPfsOwnedRealEstateDetailsListArray,
    returnPfsAutomobileDetailsListArray,
    returnPfsMarketableSecuritiesDetailsListArray,
    returnPfsAccountReceivablesDetailsListArray,
    returnPfsLifeInsuranceDetailsListArray,
    returnPfsRetirementAccountsDetailsListArray,
    returnPfsNotesPayableToBanksAndInstitutionsDetailsListArray,
    returnPfsNotesPayableToIndividualsDetailsListArray,
    returnPfsInstallmentAccountDetailsListArray,
    returnPfsUnpaidTaxesDetailsListArray,
    returnOtherLiabilitiesFormGroupInArray,
    returnPfsOtherPersonalPropertyDetailList,
    returnPfsOtherAssetsDetailList,
    returnContingentLiabilitiesDetailsList,
    returnPfsAccountPayablesDetailListArray,
    removeAllCheckings,
    returnPfsSavingFormGroupInArray,
} from '../../helpers/PfsScreenFunctions';
import { decryptText, encryptText, formatNumberWithCommas, zipCodeValidator } from '../../helpers/DynamicFunctions';
import { format } from 'crypto-js';
import reducersAndActions from '../../_redux/slices';
import Constants from '../../helpers/Constants';
import { useDispatch } from 'react-redux';
import SidePanelEmail from '../../components/SidePanel/SidePanelEmail';

const validateNumber = (value) => {
    // Allow negative numbers, zero, and positive numbers with optional decimal
    const pattern = /^-?\d*(\.\d{0,2})?$/;  // Allow up to two decimal places
    return pattern.test(value);
};

const AmountContainer = ({ name, label, placeholder, required, withoutDollar = false, setIsEdit, maxlength = 14 }) => (
    <FieldControl
        name={name}
        render={({ handler, touched, hasError, meta }) => {
            const handlerProps = handler();
            handlerProps.value = handlerProps.value !== "0.00" && handlerProps.value ? formatNumberWithCommas(handlerProps.value) : '';

            const handleChange = (e) => {
                let rawValue = e.target.value.replace(/,/g, '');

                if (validateNumber(rawValue)) {
                    setIsEdit(true);
                    const formattedValue = formatNumberWithCommas(rawValue);
                    e.target.value = formattedValue;
                    if (handlerProps.onChange) {
                        handlerProps.onChange(e);
                    }
                } else {
                    e.target.value = handlerProps.value;
                }
            };

            return (
                <div className={withoutDollar ? "form-group" : "form-group input-dollar"}>
                    <label htmlFor={name}>{label}{required && <span className="asterisk">*</span>}</label>
                    <input
                        type="text"
                        className="form-control"
                        id={name}
                        placeholder={placeholder}
                        maxLength={maxlength}
                        {...handlerProps}
                        onChange={handleChange}
                    />

                    {/* Error messages for validation */}
                    {touched && hasError("pattern") && <><span className="error-message">Amount should be only numeric values</span><br /></>}
                    {touched && hasError("borrowAmountMaxIssue") && <><span className="error-message">Amount should be less than or equal to 10000000000</span><br /></>}
                    {touched && hasError("required") && <><span className="error-message">Amount is required</span><br /></>}
                </div>
            );
        }}
        meta={{ placeholder: placeholder }}
    />
);

// Function Declerations
const borrowAmountValidator = (control) => {
    if (!control.value) {
        return null;
    } else if (control.value && Number(control.value.split(",").join("")) < 0) {
        return { borrowAmountIssue: true };
    } else if (control.value && Number(control.value.split(",").join("")) > 10000000000) {
        return { borrowAmountMaxIssue: true };
    }
    return null;
};

const getValue = (value) => {
    if (typeof value === "string") {
        value = value.replace(/,/g, '');
    }
    return value;
};

// FormGroups Declerations
let pfsFormGroup = FormBuilder.group({
    loanId: [""],
    ownerID: [""],
    pfsid: [""],
    pfS_ApplicationType: [''],
    name: [""],
    lastName: [""],
    asOfDate: ["", [Validators.required]],
    checkings: [""],
    savings: [""],
    cDs: [""],
    cash: [""],
    cashInHand: ["", [borrowAmountValidator, Validators.pattern("^[0-9,.]*$")]],
    pfscDsDetailsList: FormBuilder.array([]),
    pfsSavingsDetailsList: FormBuilder.array([]),
    pfsCheckingsDetailsList: FormBuilder.array([]),

    pfsOtherLiabilitiesDetailList: FormBuilder.array([]),
    pfsOtherPersonalPropertyDetailList: FormBuilder.array([]),
    pfsOtherAssetsDetailList: FormBuilder.array([]),
    pfsContingentLiabilitiesDetailList: FormBuilder.array([]),

    pfsRealEstateAdditionalList: FormBuilder.array([]),
    pfsOwnedRealEstateDetailsList: FormBuilder.array([]),
    pfsAutomobileDetailsList: FormBuilder.array([]),
    pfsMarketableSecuritiesDetailsList: FormBuilder.array([]),
    pfsAccountReceivablesDetailsList: FormBuilder.array([]),
    pfsLifeInsuranceDetailsList: FormBuilder.array([]),
    pfsRetirementAccountsDetailsList: FormBuilder.array([]),
    pfsNotesPayableToBanksAndInstitutionsDetailsList: FormBuilder.array([]),
    pfsNotesPayableToIndividualsDetailsList: FormBuilder.array([]),
    pfsInstallmentAccountDetailsList: FormBuilder.array([]),
    pfsUnpaidTaxesDetailsList: FormBuilder.array([]),
    pfsAccountPayablesDetailList: FormBuilder.array([]),

    otherPersonalProperty_TypeOfOtherPersonalProperty: [""],
    otherPersonalProperty_MarketValue: [""],
    otherAssets_TypeOfAsset: [""],
    otherAssets_MarketValue: [""],
    additionalAssetNotes: [""],

    otherLiabilities_AsEndorser: [""],
    otherLiabilities_LegalClaims: [""],
    otherLiabilities_ProvisionForFederalIncomeTax: [""],
    otherLiabilities: [""],
    additionalLiabilitiesNotes: [""],

    annualExpenditure_AlimonyChildSupport: ["", [borrowAmountValidator, Validators.pattern("^[0-9,.]*$")]],
    annualExpenditure_IncomeTax: ["", [borrowAmountValidator, Validators.pattern("^[0-9,.]*$")]],
    annualExpenditure_InsurancePremiums: ["", [borrowAmountValidator, Validators.pattern("^[0-9,.]*$")]],
    annualExpenditure_LivingExpenses: ["", [borrowAmountValidator, Validators.pattern("^[0-9,.]*$")]],
    annualExpenditure_MedicalExpenses: ["", [borrowAmountValidator, Validators.pattern("^[0-9,.]*$")]],
    annualExpenditure_MortgagePayment: ["", [borrowAmountValidator, Validators.pattern("^[0-9,.]*$")]],
    annualExpenditure_OtherExpensesAmount: ["", [borrowAmountValidator, Validators.pattern("^[0-9,.]*$")]],
    annualExpenditure_PropertyTax: ["", [borrowAmountValidator, Validators.pattern("^[0-9,.]*$")]],
    annualExpenditure_RentLeasePayment: ["", [borrowAmountValidator, Validators.pattern("^[0-9,.]*$")]],
    annualExpenditure_AutoPayments: ["", [borrowAmountValidator, Validators.pattern("^[0-9,.]*$")]],
    annualExpenditure_CreditCardPayment: ["", [borrowAmountValidator, Validators.pattern("^[0-9,.]*$")]],
    annualIncome_BonusCommissions: ["", [borrowAmountValidator, Validators.pattern("^[0-9,.]*$")]],
    annualIncome_DividendIncome: ["", [borrowAmountValidator, Validators.pattern("^[0-9,.]*$")]],
    annualIncome_InterestIncome: ["", [borrowAmountValidator, Validators.pattern("^[0-9,.]*$")]],
    annualIncome_InvestmentIncome: ["", [borrowAmountValidator, Validators.pattern("^[0-9,.]*$")]],
    annualIncome_OtherIncomeAmount: ["", [borrowAmountValidator, Validators.pattern("^[0-9,.]*$")]],
    annualIncome_PartnershipIncome: ["", [borrowAmountValidator, Validators.pattern("^[0-9,.]*$")]],
    annualIncome_RentalIncome: ["", [borrowAmountValidator, Validators.pattern("^[0-9,.]*$")]],
    annualIncome_SalarySelf: ["", [borrowAmountValidator, Validators.pattern("^[0-9,.]*$")]],
    annualIncome_SalarySpouse: ["", [borrowAmountValidator, Validators.pattern("^[0-9,.]*$")]],

    anticipateSignificantChanges: [""],
    explanation: [""],

    primaryResidence: [""],
    rent_MonthlyRent: [""],
    rent_Address: [""],
    rent_AdditionalProperty: [""],
    own_AggregateMarketValue: [""],
    own_AggregateMortgageBalance: [""],
    automobiles: [""],
    automobiles_AggregateMarketValue: [""],
    automobiles_AggregateLoanAmount: [""],
    automobiles_AggregateMonthlyPayments: [""],
    marketableSecurities: [""],
    retirementAccounts: [""],
    accountReceivables: [""],
    accountReceivables_AggregateCurrentBalance: [""],
    lifeInsurance: [""],
    lifeInsurance_AggregateCashSurrenderValue: [""],
    lifeInsurance_AggregateBorrowerAmount: [""],
    otherPersonalProperty: [""],
    otherAssets: [""],
    notesPayableToBanksAndInstitutions: [""],
    notesPayableToBanksAndInstitutions_AggregateCurrentBalance: [""],
    notesPayableToIndividuals: [""],
    notesPayableToIndividuals_AggregateCurrentBalance: [""],
    installmentAccount: [""],
    installmentAccount_AggregateMarketValue: [""],
    installmentAccount_LoanAmount: [""],
    installmentAccount_MonthlyPayment: [""],
    unpaidTaxes: [""],
    unpaidTaxes_AggregateUnpaidTaxBalance: [""],
    isOtherLiabilities: [""],
    annualIncome_OtherIncome: [""],
    annualExpenditure_OtherExpenses: [""],
    modifiedBy: [""],
    payableToBankss: [""],
    publiclyTradesList: [""],
    closelyHeldBusinessList: [""],
    descriptionofincomeList: [""],
    realEstateOwnedList: [""],
    marketableSecurities_AggregateMarketValue: [""],
});


let ownedRealEstateFormGroup = FormBuilder.group({
    index: [""],
    type: ["Owned"],
    pfsId: [""],
    ownerID: [""],
    propertyType: ["", [Validators.required]],
    streetAddress1: [""],
    streetAddress2: [""],
    city: [""],
    state: ["", [Validators.required]],
    zipcode: ["", [zipCodeValidator]],
    purchaseDate: [""],
    originalCost: [""],
    presentMarketValue: [""],
    monthlyNetRentalIncome: [""],
    nameAddressOfMortgageHolder: [""],
    mortgageAccountNumber: [""],
    mortgageBalance: [""],
    monthlyPayment: [""],
    includesTaxAndInsurance: [],
    status: [""],
    isAdditionalProperty: [""],
    additionalRealEstateDetailsId: [0],
});

let rentedRealEstateFormGroup = FormBuilder.group({
    index: [""],
    type: ["Rental"],
    monthlyNetRentalIncome: ["", [Validators.required]],
    streetAddress1: [""],
    city: [""],
    state: [""],
    zipcode: ["", [zipCodeValidator]],
});

let automobileFormGroup = FormBuilder.group({
    index: [""],
    lender: [""],
    make: [""],
    marketValue: [""],
    model: [""],
    monthlyPayment: [""],
    outstandingLoanBalance: [""],
    ownerID: [""],
    pfsId: [""],
    status: [""],
    year: ["", [Validators.required]],
    vin: [""],
});
let marketableSecuritiesFormGroup = FormBuilder.group({
    index: [""],
    dateOfValue: [""],
    exchangeListed: [""],
    marketValue: [""],
    name: [""],
    numberOfShares: ["", [Validators.required]],
    originalCost: [""],
    ownerID: [""],
    pfsId: [""],
    pledged: [""],
    totalValue: [""],
});

let accountReceivablesFormGroup = FormBuilder.group({
    index: [""],
    address: [""],
    streetAddress1: [""],
    streetAddress2: [""],
    city: [""],
    state: [""],
    zipcode: ["", [zipCodeValidator]],
    currentBalance: [""],
    frequency: [""],
    maturityDate: [""],
    nameOfNotePayer: ["", [Validators.required]],
    originalBalance: [""],
    ownerID: [""],
    paymentAmount: [""],
    pfsId: [""],
    secured: [""],
    status: [""],
});

let lifeInsuranceFormGroup = FormBuilder.group({
    index: [""],
    amountBorrowed: [""],
    beneficiaries: [""],
    cashSurrenderValue: [""],
    faceValue: [""],
    insuranceCompany: ["", [Validators.required]],
    ownerID: [""],
    pfsId: [""],
    pledged: [""],
    state: [""],
    purchaseDate: [""],
})

let notesPayableBanksFormGroup = FormBuilder.group({
    index: [""],
    pfsId: [""],
    nameOfNoteHolder: ["", [Validators.required]],
    originalBalance: [""],
    currentBalance: [""],
    paymentAmount: [""],
    frequency: [""],
    maturityDate: [""],
    secured: [""],
    typeofCollateral: [""],
});

let notesPayableIndividualsFormGroup = FormBuilder.group({
    index: [""],
    nameOfNoteHolder: ["", [Validators.required]],
    originalBalance: [""],
    currentBalance: [""],
    paymentAmount: [""],
    frequency: [""],
    maturityDate: ["", [Validators.required]],
    secured: [""],
    typeOfCollateral: [""],
    ownerID: [""],
    pfsId: [""],
})

let installmentAccountFormGroup = FormBuilder.group({
    index: [""],
    asset: [""],
    lender: [""],
    marketValue: [""],
    monthlyPayment: [""],
    outstandingLoanBalance: [""],
    ownerID: [""],
    pfsId: [""],
    status: [""],
    installmentType: ["", [Validators.required]],
});

let unpaidTaxBalanceFormGroup = FormBuilder.group({
    index: [""],
    typeOfTax: ["", [Validators.required]],
    agency: [""],
    unpaidTaxBalance: [""],
    dueDate: ["", [Validators.required]],
    lien: [""],
    lienType: [""],
})

let accountsPayableFormGroup = FormBuilder.group({
    index: [""],
    pfsId: [""],
    ownerID: [""],
    nameOfNotePayer: ["", [Validators.required]],
    originalBalance: [""],
    currentBalance: [""],
    paymentAmount: [""],
    frequency: [""],
    maturityDate: [""],
    secured: [""],
    status: [""],
    streetAddress1: [""],
    city: [""],
    state: [""],
    zipcode: ["", [zipCodeValidator]],
})

const FormArrayComponent = ({ control, render }) => {
    return (
        <div>
            {
                control.controls.map((ctrl, index) => (
                    <div key={index}>{render(ctrl, index)}</div>
                ))
            }
        </div>
    );
};

function PFSDetail() {
    const location = useLocation();

    // Meta Title   
    useEffect(() => {
        document.title = "PFS Detail - Loan Application";
        window.scrollTo(0, 0);
    }, []);

    // Initializing functions
    const urlSearchString = window.location.search;
    const params = new URLSearchParams(urlSearchString);
    const decryptedValue = params.get('data') ? decryptText(params.get('data')) : ""

    // Split the decrypted value into individual parameters
    const paramsArray = decryptedValue ? decryptedValue.split("&") : [];

    const appLoanID = paramsArray.length > 0 ? paramsArray[0].split("=")[1] : null;
    const appOwnerID = paramsArray.length > 1 ? paramsArray[1].split("=")[1] : null;
    const appNumber = paramsArray.length > 2 ? paramsArray[2].split("=")[1] : null;
    const guest = params.get('guest') == "true" ? true : false;

    const LoanId = appLoanID;
    const OwnerId = appOwnerID;

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        dispatch(reducersAndActions.actions.sessionActions.setLoading(loading));
    }, [loading]);

    const [stateList, setStateList] = useState([]);
    const [isEdit, setIsEdit] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const residenceTypeRef = createRef();
    const businessRef = createRef();
    const stateRef = createRef();
    const statusRef = createRef();
    const yearRef = createRef();

    const filterConfig = {
        ignoreCase: true,
        ignoreAccents: true,
        trim: true,
        matchFrom: "start"
    };

    useEffect(() => {
        let years = [];
        const currentYear = new Date().getFullYear();
        for (let i = currentYear - 50; i <= currentYear + 50; i++) {
            years.push({ label: i, value: i });
        }
        setAutoMobileYears(years);

        getPfsDetails();
        getStatesLIst()
    }, []);

    const getStatesLIst = async () => {
        const USAStates = await getUSAStates()
        setStateList(USAStates)
    }

    // Function to truncate a string after 25 characters
    const truncateText = (text, maxLength = 25) => {
        if (text && text.length > maxLength) {
            return text.substring(0, maxLength) + '...';
        }
        return text;
    };

    const getPfsDetails = async () => {
        try {
            setLoading(true);

            pfsFormGroup.reset();
            removeAllCheckings(pfsFormGroup, 'pfsCheckingsDetailsList');
            removeAllCheckings(pfsFormGroup, 'pfsSavingsDetailsList');
            removeAllCheckings(pfsFormGroup, 'pfscDsDetailsList');

            removeAllCheckings(pfsFormGroup, 'pfsOtherLiabilitiesDetailList');
            removeAllCheckings(pfsFormGroup, 'pfsContingentLiabilitiesDetailList');
            removeAllCheckings(pfsFormGroup, 'pfsOtherPersonalPropertyDetailList');
            removeAllCheckings(pfsFormGroup, 'pfsOtherAssetsDetailList');

            removeAllCheckings(pfsFormGroup, 'pfsRealEstateAdditionalList');
            removeAllCheckings(pfsFormGroup, 'pfsOwnedRealEstateDetailsList');
            removeAllCheckings(pfsFormGroup, 'pfsAutomobileDetailsList');
            removeAllCheckings(pfsFormGroup, 'pfsMarketableSecuritiesDetailsList');
            removeAllCheckings(pfsFormGroup, 'pfsAccountReceivablesDetailsList');
            removeAllCheckings(pfsFormGroup, 'pfsLifeInsuranceDetailsList');
            removeAllCheckings(pfsFormGroup, 'pfsRetirementAccountsDetailsList');
            removeAllCheckings(pfsFormGroup, 'pfsNotesPayableToBanksAndInstitutionsDetailsList');
            removeAllCheckings(pfsFormGroup, 'pfsNotesPayableToIndividualsDetailsList');
            removeAllCheckings(pfsFormGroup, 'pfsInstallmentAccountDetailsList');
            removeAllCheckings(pfsFormGroup, 'pfsUnpaidTaxesDetailsList');
            removeAllCheckings(pfsFormGroup, 'pfsAccountPayablesDetailList');


            let data = { LoanId, OwnerId, SessionId: getSessionId() }
            const getDetails = await GetPFSListByLoanId(data, guest);

            pfsFormGroup.patchValue({ ...getDetails.resultObject });

            if (getDetails.resultObject.pfsCheckingsDetailsList.length > 0) returnPfsFormGroupInArray(getDetails.resultObject.pfsCheckingsDetailsList, pfsFormGroup, "pfsCheckingsDetailsList");
            if (getDetails.resultObject.pfsSavingsDetailsList.length > 0) returnPfsSavingFormGroupInArray(getDetails.resultObject.pfsSavingsDetailsList, pfsFormGroup, "pfsSavingsDetailsList");
            if (getDetails.resultObject.pfscDsDetailsList.length > 0) returnCdsFormGroupInArray(getDetails.resultObject.pfscDsDetailsList, pfsFormGroup, "pfscDsDetailsList");
            if (getDetails.resultObject.pfsOwnedRealEstateDetailsList.length > 0) returnPfsOwnedRealEstateDetailsListArray(getDetails.resultObject.pfsOwnedRealEstateDetailsList, pfsFormGroup, "pfsOwnedRealEstateDetailsList");
            if (getDetails.resultObject.pfsAutomobileDetailsList.length > 0) returnPfsAutomobileDetailsListArray(getDetails.resultObject.pfsAutomobileDetailsList, pfsFormGroup, "pfsAutomobileDetailsList");
            if (getDetails.resultObject.pfsMarketableSecuritiesDetailsList.length > 0) returnPfsMarketableSecuritiesDetailsListArray(getDetails.resultObject.pfsMarketableSecuritiesDetailsList, pfsFormGroup, "pfsMarketableSecuritiesDetailsList");
            if (getDetails.resultObject.pfsAccountReceivablesDetailsList.length > 0) returnPfsAccountReceivablesDetailsListArray(getDetails.resultObject.pfsAccountReceivablesDetailsList, pfsFormGroup, "pfsAccountReceivablesDetailsList");
            if (getDetails.resultObject.pfsLifeInsuranceDetailsList.length > 0) returnPfsLifeInsuranceDetailsListArray(getDetails.resultObject.pfsLifeInsuranceDetailsList, pfsFormGroup, "pfsLifeInsuranceDetailsList");
            if (getDetails.resultObject.pfsRetirementAccountsDetailsList.length > 0) returnPfsRetirementAccountsDetailsListArray(getDetails.resultObject.pfsRetirementAccountsDetailsList, pfsFormGroup, "pfsRetirementAccountsDetailsList");
            if (getDetails.resultObject.pfsNotesPayableToBanksAndInstitutionsDetailsList.length > 0) returnPfsNotesPayableToBanksAndInstitutionsDetailsListArray(getDetails.resultObject.pfsNotesPayableToBanksAndInstitutionsDetailsList, pfsFormGroup, "pfsNotesPayableToBanksAndInstitutionsDetailsList");
            if (getDetails.resultObject.pfsNotesPayableToIndividualsDetailsList.length > 0) returnPfsNotesPayableToIndividualsDetailsListArray(getDetails.resultObject.pfsNotesPayableToIndividualsDetailsList, pfsFormGroup, "pfsNotesPayableToIndividualsDetailsList");
            if (getDetails.resultObject.pfsInstallmentAccountDetailsList.length > 0) returnPfsInstallmentAccountDetailsListArray(getDetails.resultObject.pfsInstallmentAccountDetailsList, pfsFormGroup, "pfsInstallmentAccountDetailsList");
            if (getDetails.resultObject.pfsUnpaidTaxesDetailsList.length > 0) returnPfsUnpaidTaxesDetailsListArray(getDetails.resultObject.pfsUnpaidTaxesDetailsList, pfsFormGroup, "pfsUnpaidTaxesDetailsList");
            if (getDetails.resultObject.pfsOtherLiabilitiesDetailList.length > 0) returnOtherLiabilitiesFormGroupInArray(getDetails.resultObject.pfsOtherLiabilitiesDetailList, pfsFormGroup, "pfsOtherLiabilitiesDetailList")
            if (getDetails.resultObject.pfsAccountPayablesDetailList.length > 0) returnPfsAccountPayablesDetailListArray(getDetails.resultObject.pfsAccountPayablesDetailList, pfsFormGroup, "pfsAccountPayablesDetailList");
            if (getDetails.resultObject.pfsOtherPersonalPropertyDetailList.length > 0) returnPfsOtherPersonalPropertyDetailList(getDetails.resultObject.pfsOtherPersonalPropertyDetailList, pfsFormGroup, "pfsOtherPersonalPropertyDetailList");
            if (getDetails.resultObject.pfsOtherAssetsDetailList.length > 0) returnPfsOtherAssetsDetailList(getDetails.resultObject.pfsOtherAssetsDetailList, pfsFormGroup, "pfsOtherAssetsDetailList");
            if (getDetails.resultObject.pfsContingentLiabilitiesDetailList.length > 0) returnContingentLiabilitiesDetailsList(getDetails.resultObject.pfsContingentLiabilitiesDetailList, pfsFormGroup, "pfsContingentLiabilitiesDetailList");

            console.log(pfsFormGroup.value)
            setLoading(false);
        } catch (err) {
            console.log(err);
            setLoading(false);
        }
    }

    //CASH IN HAND & BANK Validation Handling
    const [validationError, setValidationError] = useState('');
    const [formErrors, setFormErrors] = useState({});

    // Popup function
    const [AddRealEstateShow, setAddRealEstate] = useState(false);
    const AddRealEstateClose = () => setAddRealEstate(false);

    const [EditOwnRealEstateShow, setEditOwnRealEstate] = useState(false);
    const EditOwnRealEstateClose = () => setEditOwnRealEstate(false);

    const [EditRentalRealEstateShow, setEditRentalRealEstate] = useState(false);
    const EditRentalRealEstateClose = () => setEditRentalRealEstate(false);

    const [AutomobilePopupShow, setAutomobilePopup] = useState(false);
    const [autoMobileType, setAutoMobileType] = useState("Add");
    const [autoMobileYears, setAutoMobileYears] = useState([]);
    const AutomobilePopupClose = () => setAutomobilePopup(false);

    const [MarketablePopupShow, setMarketablePopup] = useState(false);
    const [marketableType, setMarketableType] = useState("Add");
    const MarketablePopupClose = () => setMarketablePopup(false);

    const [AccountNotePopupShow, setAccountNotePopup] = useState(false);
    const [accountNoteType, setAccountNoteType] = useState("Add");
    const AccountNotePopupClose = () => setAccountNotePopup(false);

    const [LifeInsurancePopupShow, setLifeInsurancePopup] = useState(false);
    const [lifeInsuranceType, setLifeInsuranceType] = useState("Add");
    const LifeInsurancePopupClose = () => setLifeInsurancePopup(false);

    const [NotesPayableIndividualsPopupShow, setNotesPayableIndividualsPopup] = useState(false);
    const [NotesPayableIndividualsType, setNotesPayableIndividualsType] = useState(false);
    const NotesPayableIndividualsPopupClose = () => setNotesPayableIndividualsPopup(false);

    const [InstallmentAccountPopupShow, setInstallmentAccountPopup] = useState(false);
    const [InstallmentAccountType, setInstallmentAccountType] = useState(false);
    const InstallmentAccountPopupClose = () => setInstallmentAccountPopup(false);

    const [AccountPayablePopupShow, setAccountPayablePopup] = useState(false);
    const [AccountPayableType, setAccountPayableType] = useState(false);
    const AccountPayablePopupClose = () => setAccountPayablePopup(false);

    const [PreviousTabPopupShow, setPreviousTabPopup] = useState(false);
    const PreviousTabPopupClose = () => setPreviousTabPopup(false);

    const backPreviousTab = () => {
        navigate(`/application-detail/${encryptText(LoanId)}`, { state: { tabName: 'PFS' } });
        setPreviousTabPopup(false);
    }

    //customized  Popup function
    const [isNotesPayableBanksOpen, setNotesPayableBanks] = useState(false);
    const [NotesPayableBanksType, setNotesPayableBanksType] = useState(false);

    const NotesPayableBanksShow = () => {
        setNotesPayableBanks(true);
        notesPayableBanksFormGroup.reset();
    };

    const NotesPayableBanksClose = () => {
        setNotesPayableBanks(false);
    };

    const [isUnpaidTaxesPopupOpen, setUnpaidTaxesPopup] = useState(false);
    const [UnpaidTaxesType, setUnpaidTaxesType] = useState("Add");

    const UnpaidTaxesPopupShow = () => {
        setUnpaidTaxesPopup(true);
    };

    const UnpaidTaxesPopupClose = () => {
        setUnpaidTaxesPopup(false);
    };

    // real estate popup form tabs
    const [activeTab, setActiveTab] = useState("Own");
    const handleTabClick = (tabId) => {
        setActiveTab(tabId);
    };


    const calculateTotalIncome = () => {
        let total = 0;
        let array = ["annualIncome_SalarySpouse", "annualIncome_SalarySelf", "annualIncome_RentalIncome", "annualIncome_PartnershipIncome", "annualIncome_OtherIncomeAmount", "annualIncome_InvestmentIncome", "annualIncome_InterestIncome", "annualIncome_DividendIncome", "annualIncome_BonusCommissions"]
        for (let elem of array) {
            if (pfsFormGroup.value[elem]) {
                total += Number(pfsFormGroup.value[elem].split(",").join(""));
            }
        }
        return formatNumberWithCommas(total);
    }

    const calculateTotalExpenditures = () => {
        let total = 0;

        let array = ["annualExpenditure_MortgagePayment", "annualExpenditure_RentLeasePayment", "annualExpenditure_AutoPayments", "annualExpenditure_CreditCardPayment", "annualExpenditure_IncomeTax", "annualExpenditure_PropertyTax", "annualExpenditure_InsurancePremiums", "annualExpenditure_AlimonyChildSupport", "annualExpenditure_MedicalExpenses", "annualExpenditure_LivingExpenses", "annualExpenditure_OtherExpensesAmount"]
        for (let elem of array) {
            if (pfsFormGroup.value[elem]) {
                total += Number(pfsFormGroup.value[elem].split(",").join(""));
            }
        }
        return formatNumberWithCommas(total);
    }

    const handleEditFormModal = (e, list, data, closePopup) => {
        e.preventDefault();
        if (data.status !== "INVALID") {
            const { index, ...otherData } = data.value;
            setIsEdit(true);
            otherData['pfsId'] = LoanId;
            otherData['ownerID'] = OwnerId;

            if (otherData['propertyType']) otherData['propertyType'] = otherData['propertyType'].value;
            if (otherData['status']) otherData['status'] = otherData['status'].value;
            if (otherData['state']) otherData['state'] = otherData['state'].value;
            if (otherData['year']) otherData['year'] = otherData['year'].value;
            if (otherData['frequency']) otherData['frequency'] = otherData['frequency'].value;

            pfsFormGroup.value[list][index] = otherData
            pfsFormGroup.patchValue({ ...pfsFormGroup.value, list: pfsFormGroup.value[list] })
            closePopup()
        } else {
            markAllAsTouched(data, list);
        }
    }

    const handleRemoveFormModal = (e, list, index, closePopup) => {
        e.preventDefault();
        setIsEdit(true);
        const formArray = pfsFormGroup.get(list);
        let newData = formArray.controls.filter((ctrl, i) => i !== index);
        while (formArray.length) {
            formArray.removeAt(0);
        }
        newData.forEach(control => formArray.push(control));
        closePopup()
    }

    const handleAddFormModal = (e, list, data, closePopup, extra = "") => {
        e.preventDefault();
        setIsEdit(true);
        if (data.status !== "INVALID") {
            const { ...otherData } = data.value;
            otherData['pfsId'] = parseInt(LoanId);
            otherData['ownerID'] = parseInt(OwnerId);

            delete otherData['index'];
            if (otherData['propertyType']) otherData['propertyType'] = otherData['propertyType'].value;
            if (otherData['status']) otherData['status'] = otherData['status'].value;
            if (otherData['state']) otherData['state'] = otherData['state'].value;
            if (otherData['year']) otherData['year'] = otherData['year'].value;
            if (otherData['frequency']) otherData['frequency'] = otherData['frequency'].value;

            if (list === "pfsOwnedRealEstateDetailsList") {
                otherData['additionalRealEstateDetailsId'] = 0;

                if (!otherData['isAdditionalProperty']) otherData['isAdditionalProperty'] = false;
                if (!otherData['includesTaxAndInsurance']) otherData['includesTaxAndInsurance'] = false;

                if (extra === "Owned") otherData['type'] = "Owned";
                if (extra === "Rental") otherData['type'] = "Rental";
            }

            if (list === "pfsRealEstateAdditionalList") {
                otherData['isAdditionalProperty'] = false;
                otherData['includesTaxAndInsurance'] = false;
            }

            const formArray = pfsFormGroup.get(list);
            formArray.push(new FormControl(otherData));
            closePopup()
        } else {
            markAllAsTouched(data, list);
        }
    };

    const formatAddress = (data) => {
        const { streetAddress1 = '', streetAddress2 = '', city = '', state = '', zipcode = '' } = data;
        const addressParts = [streetAddress1, streetAddress2].filter(part => part && part.trim() !== '').join(' ');
        const locationParts = [city, state, zipcode].filter(part => part && part.trim() !== '').join(', ');
        const formattedAddress = [addressParts, locationParts].filter(part => part && part.trim() !== '').join(', ');
        return formattedAddress;
    };


    const validateCashInHandFields = () => {
        let isValid = true;

        const showError = (message) => {
            dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message, type: "error" }));
            setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
            isValid = false;
        };

        const validateDetails = (details, type) => {
            for (let detail of details) {
                if (!detail.financialInstitution) {
                    showError(`${type} Financial Institution is required`);
                    return false;
                }
                if (type !== 'CD' && !detail.currentBalance) {
                    showError(`${type} Current Balance is required`);
                    return false;
                }
                if (type === 'CD') {
                    if (!detail.maturityDate) {
                        showError('CD Maturity Date is required');
                        return false;
                    }
                    if (!detail.currentValue) {
                        showError('CD Current Value is required');
                        return false;
                    }
                }
            }
            return true;
        };

        if (pfsFormGroup.value.checkings) {
            if (!validateDetails(pfsFormGroup.value.pfsCheckingsDetailsList, 'Checking')) return false;
        }

        if (pfsFormGroup.value.savings) {
            if (!validateDetails(pfsFormGroup.value.pfsSavingsDetailsList, 'Saving')) return false;
        }

        if (pfsFormGroup.value.cDs) {
            if (!validateDetails(pfsFormGroup.value.pfscDsDetailsList, 'CD')) return false;
        }

        if (pfsFormGroup.value.cash && !pfsFormGroup.value.cashInHand) {
            showError('Cash in Hand is required')
            return false;
        }

        return isValid;
    };


    const handleSubmitButton = async (e) => {
        e.preventDefault();

        pfsFormGroup.patchValue({
            loanId: LoanId,
            ownerID: OwnerId,
            modifiedBy: JSON.parse(localStorage.getItem("user"))?.id || 0
        });

        const { checkings, savings, cDs, cash } = pfsFormGroup.value;
        if (!checkings && !savings && !cDs && !cash) {
            dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: 'Please select at least one option from Checkings, Savings, CDs, Cash', type: "error" }));
            setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
            return false;
        }

        let array = ["annualExpenditure_AlimonyChildSupport", "annualExpenditure_AutoPayments", "annualExpenditure_IncomeTax", "annualExpenditure_InsurancePremiums", "annualExpenditure_LivingExpenses", "annualExpenditure_MedicalExpenses", "annualExpenditure_MortgagePayment", "annualExpenditure_OtherExpensesAmount", "annualExpenditure_PropertyTax", "annualExpenditure_RentLeasePayment", "annualIncome_SalarySpouse", "annualIncome_SalarySelf", "annualIncome_RentalIncome", "annualIncome_PartnershipIncome", "annualIncome_OtherIncomeAmount", "annualIncome_InvestmentIncome", "annualIncome_InterestIncome", "annualIncome_DividendIncome", "annualIncome_BonusCommissions", "annualExpenditure_CreditCardPayment",];
        array.map((elem) => {
            pfsFormGroup.patchValue({ [elem]: getValue(pfsFormGroup.value[elem]) });
        })

        if (!checkings) removeAllCheckings(pfsFormGroup, "pfsCheckingsDetailsList");
        if (!savings) removeAllCheckings(pfsFormGroup, "pfsSavingsDetailsList");
        if (!cDs) removeAllCheckings(pfsFormGroup, "pfscDsDetailsList");
        if (!cash) pfsFormGroup.patchValue({ cashInHand: "" });

        if (!validateCashInHandFields()) {
            return;
        }

        let fianlData = pfsFormGroup.value;

        fianlData.pfsOtherLiabilitiesDetailList.map((elem) => { elem['marketValue'] = getValue(elem['marketValue']) })
        fianlData.pfsOtherAssetsDetailList.map((elem) => { elem['marketValue'] = getValue(elem['marketValue']) })
        fianlData.pfsOtherPersonalPropertyDetailList.map((elem) => { elem['marketValue'] = getValue(elem['marketValue']) })
        fianlData.explanation = fianlData.anticipateSignificantChanges ? fianlData.explanation : "";

        if (pfsFormGroup.status !== "INVALID") {
            setLoading(true);
            const data = await insertUpdatePersonalFinance(fianlData, guest);
            setLoading(false);
            if (data.status === 200) {
                setIsEdit(false);
                dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: data.message, type: "success" }));
                setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
                navigate(`/application-detail/${encryptText(LoanId)}`, { state: { tabName: 'PFS' } });
            } else {
                dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: data?.response?.data?.title, type: "error" }));
                setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
            }
        } else {
            dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: "Please fill all required fields correctly.", type: "error" }));
            setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
        }

    };



    // Marking all input fields as touched if the form is not valid
    const markAllAsTouched = (formGroup, list) => {
        if (residenceTypeRef.current) residenceTypeRef.current.focus();
        if (businessRef.current) businessRef.current.focus();
        if (stateRef.current) stateRef.current.focus();
        if (statusRef.current) statusRef.current.focus();
        if (yearRef.current) yearRef.current.focus();

        Object.keys(formGroup.controls).forEach((controlName) => {
            const control = formGroup.get(controlName);
            if (control && control.status === "INVALID") {
                control.active = true;
                control.touched = true;

                if (list === 'pfsNotesPayableToIndividualsDetailsList') {
                    if (controlName === 'maturityDate') {
                        document.getElementById('notesPayableIndividualsMaturityDate').focus();
                    } else if (controlName === 'nameOfNoteHolder') {
                        document.getElementById('notesPayableIndividualsNameOfNoteHolder').focus();
                    } else {
                        document.getElementById(controlName).focus();
                    }
                } else if (list === "pfsUnpaidTaxesDetailsList") {
                    if (controlName === 'dueDate') {
                        document.getElementById('unpaidTaxDueDate').focus();
                    } else {
                        document.getElementById(controlName).focus();
                    }
                } else if (list === "pfsAccountPayablesDetailList") {
                    if (controlName === 'nameOfNotePayer') {
                        document.getElementById('accountsPayableNameOfNotePayer').focus();
                    } else {
                        document.getElementById(controlName).focus();
                    }
                } else {
                    document.getElementById(controlName).focus();
                }
            }
        });
    };

    return (
        <>
            {/* SidePanel */}
            {guest ? <SidePanelEmail /> : <SidePanel />}

            {/* ProfileHeader */}
            <ProfileHeader />

            {loading ? (<Preloader />) : (<div>

                <div className="dashboard-wrap application-detail bg-white padding-reg pfs-detail">
                    {/* <!-- PFS header sec start --> */}
                    {/* <!-- PFS breadcrumb start --> */}
                    <div className="lm-breadcrumb d-flex">
                        <a onClick={() => { isEdit ? setPreviousTabPopup(true) : navigate('/loan-application') }} className="back-btn mr-2 cursor-pointer"><img src={back_arrow} alt="" /></a>
                        <span onClick={() => { isEdit ? setPreviousTabPopup(true) : navigate('/loan-application') }} className="text-light mr-1 cursor-pointer">Loan Applications</span>
                        <img src={brdcrm_nav} alt="" />
                        <span className="text-dark ml-1">{appNumber}</span>
                    </div>
                    <h1 className="appliaction-no my-3">{pfsFormGroup.value.name + " " + pfsFormGroup.value.lastName}</h1>

                    {/* <!-- PFS breadcrumb end --> */}
                    {/* <!-- PFS header sec end --> */}

                    <div className="lm-form-box pb-5">
                        <FieldGroup
                            control={pfsFormGroup}
                            render={({ get, invalid }) => (
                                <form>
                                    {/* Personal Financial Statement start */}
                                    <div className="lm-card">
                                        <div className="row">
                                            <div className="col-12">
                                                <h3 className="lm-card-heading">Personal Financial Statement</h3>
                                            </div>
                                            <div className="col-md-4 col-lg-3">
                                                <FormField
                                                    name="name"
                                                    label="First Name"
                                                    id="name"
                                                    placeholder="Enter your first name"
                                                    disabled={true}
                                                    maxlength={50}
                                                    onlyCharacters={true}
                                                />
                                            </div>
                                            <div className="col-md-4 col-lg-3">
                                                <FormField
                                                    name="lastName"
                                                    label="Last Name"
                                                    id="lastName"
                                                    placeholder="Enter your last name"
                                                    disabled={true}
                                                    maxlength={50}
                                                    onlyCharacters={true}
                                                />
                                            </div>
                                            <div className="col-12">
                                                <div className="row">
                                                    <div className="col-md-4 col-lg-3">
                                                        <FieldControl
                                                            name="asOfDate"
                                                            render={({ handler, touched, hasError, meta }) => (
                                                                <div className="form-group input-date">
                                                                    <label htmlFor="Select_Business">As of <span className="asterisk">*</span></label>
                                                                    <DatePicker
                                                                        showIcon
                                                                        toggleCalendarOnIconClick
                                                                        {...handler()}
                                                                        selected={pfsFormGroup.value.asOfDate ? moment(pfsFormGroup.value.asOfDate).format('MMM DD, YYYY') : ''}
                                                                        value={pfsFormGroup.value.asOfDate ? moment(pfsFormGroup.value.asOfDate).format('ll') : ""}
                                                                        isClearable={handler().value}
                                                                        id='asOfDate'
                                                                        required={true}
                                                                        dateFormat="MMM d, yyyy"
                                                                        placeholderText="Month DD, YYYY"
                                                                        yearDropdownItemNumber={100}
                                                                        scrollableYearDropdown
                                                                        showMonthDropdown
                                                                        showYearDropdown
                                                                        onChange={(date) => {
                                                                            setIsEdit(true);
                                                                            const formattedDate = date ? moment(date).format('YYYY-MM-DD') : '';
                                                                            handler().onChange(formattedDate);
                                                                        }}
                                                                        onKeyDown={(e) => {
                                                                            e.preventDefault();
                                                                        }}
                                                                    />
                                                                    {/* Error messages below */}
                                                                    <span className="error-message">{hasError("required") && `As of Date is required`} </span>
                                                                </div>
                                                            )}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            {/* <!--  lm common divider to break the section  --> */}
                                            <div className="lm-divider my-3 mx-3"></div>
                                            {/* <!--  lm common divider to break the section  --> */}

                                            {/* CASH IN HAND & BANK section */}
                                            <div className="col-12 label text-dark mt-2 mb-4"><b>CASH IN HAND & BANK</b></div>

                                            {/* Checking */}
                                            <div className="col-12">
                                                <FieldControl
                                                    name="checkings"
                                                    render={({ handler, touched, hasError, meta }) => (
                                                        <div className="form-group form-check mb-2">
                                                            <input type="checkbox"
                                                                className="form-check-input"
                                                                id="checkings"
                                                                checked={pfsFormGroup.value.checkings}
                                                                onChange={(e) => {
                                                                    if (e.target.checked) {
                                                                        handler().onChange(true);
                                                                        if (pfsFormGroup.value.pfsCheckingsDetailsList.length === 0) {
                                                                            addCheckings(pfsFormGroup, 'pfsCheckingsDetailsList');
                                                                        }
                                                                    } else {
                                                                        handler().onChange(false);
                                                                    }
                                                                }}
                                                            />
                                                            <label className="form-check-label" htmlFor="cash_in_hand_checking">Checking</label>
                                                        </div>
                                                    )}
                                                />
                                            </div>
                                            {pfsFormGroup.value.checkings &&
                                                <div className="col-12">
                                                    <FormArrayComponent
                                                        control={pfsFormGroup.get("pfsCheckingsDetailsList")}
                                                        render={(pfsValue, index) => (
                                                            <>
                                                                {
                                                                    pfsValue.value.isDeleted !== 1 && (
                                                                        <div className="row align-items-center">
                                                                            <div className="col-md-4 col-lg-3">
                                                                                <FormField
                                                                                    name={`pfsCheckingsDetailsList.${index}.financialInstitution`}
                                                                                    label="Name of Financial Institution*"
                                                                                    id={`checking_Name_Financial_Institution_${index}`}
                                                                                    placeholder="Financial Institution"
                                                                                    error={formErrors[`checkings_${index}_financialInstitution`]}
                                                                                    maxlength={100}
                                                                                />
                                                                            </div>
                                                                            <div className="col-md-4 col-lg-3">
                                                                                <AmountContainer
                                                                                    setIsEdit={setIsEdit}
                                                                                    name={`pfsCheckingsDetailsList.${index}.currentBalance`}
                                                                                    label="Current Balance*"
                                                                                    placeholder="0.00"
                                                                                    amountType="currentBalance"
                                                                                    error={formErrors[`checkings_${index}_currentBalance`]}
                                                                                />
                                                                            </div>
                                                                            <div className="col-md-2 d-flex mb-3 mb-sm-0">
                                                                                {index !== 0 && <div onClick={() => removeCheckings(index, pfsFormGroup, 'pfsCheckingsDetailsList')}><img className="cursor-pointer" src={feild_minus_icon} alt="minus" /></div>}
                                                                                <div onClick={() => addCheckings(pfsFormGroup, 'pfsCheckingsDetailsList')} className='ml-2'><img className="cursor-pointer" src={feild_plus_icon} alt="plus" /></div>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                }
                                                            </>
                                                        )}
                                                    />
                                                </div>
                                            }


                                            {/* Savings */}
                                            <div className="col-12">
                                                <FieldControl
                                                    name="savings"
                                                    render={({ handler, touched, hasError, meta }) => (
                                                        <div className="form-group form-check mb-2">
                                                            <input type="checkbox"
                                                                className="form-check-input"
                                                                id="savings"
                                                                checked={pfsFormGroup.value.savings}
                                                                onChange={(e) => {
                                                                    if (e.target.checked) {
                                                                        handler().onChange(true);
                                                                        if (pfsFormGroup.value.pfsSavingsDetailsList.length === 0) {
                                                                            addCheckings(pfsFormGroup, 'pfsSavingsDetailsList');
                                                                        }
                                                                    } else {
                                                                        handler().onChange(false);
                                                                    }
                                                                }} />
                                                            <label className="form-check-label" htmlFor="savings">Savings</label>
                                                        </div>
                                                    )}
                                                />
                                            </div>
                                            {pfsFormGroup.value.savings &&
                                                <div className="col-12">
                                                    <FormArrayComponent
                                                        control={pfsFormGroup.get("pfsSavingsDetailsList")}
                                                        render={(pfsValue, index2) => (
                                                            <>
                                                                {
                                                                    pfsValue.value.isDeleted !== 1 && (
                                                                        <div className="row align-items-center">
                                                                            <div className="col-md-4 col-lg-3">
                                                                                <FormField
                                                                                    name={`pfsSavingsDetailsList.${index2}.financialInstitution`}
                                                                                    label="Name of Financial Institution*"
                                                                                    id={`savings_Name_Financial_Institution_${index2}`}
                                                                                    placeholder="Financial Institution"
                                                                                    error={formErrors[`savings_${index2}_financialInstitution`]}
                                                                                    maxlength={100}
                                                                                />
                                                                            </div>
                                                                            <div className="col-md-4 col-lg-3">
                                                                                <AmountContainer
                                                                                    setIsEdit={setIsEdit}
                                                                                    name={`pfsSavingsDetailsList.${index2}.currentBalance`}
                                                                                    label="Current Balance*"
                                                                                    placeholder="0.00"
                                                                                    amountType="currentBalance"
                                                                                    error={formErrors[`savings_${index2}_currentBalance`]}
                                                                                />
                                                                            </div>
                                                                            <div className="col-md-2 d-flex mb-3 mb-sm-0">
                                                                                {index2 !== 0 && <div onClick={() => removeCheckings(index2, pfsFormGroup, 'pfsSavingsDetailsList')}><img className="cursor-pointer" src={feild_minus_icon} alt="minus" /></div>}
                                                                                <div onClick={() => addCheckings(pfsFormGroup, 'pfsSavingsDetailsList')} className="ml-2"><img className="cursor-pointer" src={feild_plus_icon} alt="plus" /></div>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                }
                                                            </>
                                                        )}
                                                    />
                                                </div>
                                            }

                                            {/* CDs */}
                                            <div className="col-12">
                                                <FieldControl
                                                    name="cDs"
                                                    render={({ handler, touched, hasError, meta }) => (
                                                        <div className="form-group form-check mb-2">
                                                            <input type="checkbox"
                                                                className="form-check-input"
                                                                id="cDs"
                                                                checked={pfsFormGroup.value.cDs}
                                                                onChange={(e) => {
                                                                    if (e.target.checked) {
                                                                        handler().onChange(true);
                                                                        if (pfsFormGroup.value.pfscDsDetailsList.length === 0) {
                                                                            addCheckings(pfsFormGroup, 'pfscDsDetailsList');
                                                                        }
                                                                    } else {
                                                                        handler().onChange(false);
                                                                    }
                                                                }} />
                                                            <label className="form-check-label" htmlFor="cash_in_hand_CDs">CDs</label>
                                                        </div>
                                                    )}
                                                />
                                            </div>
                                            {pfsFormGroup.value.cDs &&
                                                <div className="col-12">
                                                    <FormArrayComponent
                                                        control={pfsFormGroup.get("pfscDsDetailsList")}
                                                        render={(pfsValue, index3) => (
                                                            <>
                                                                {
                                                                    pfsValue.value.isDeleted !== 1 && (
                                                                        <div className="row align-items-center">
                                                                            <div className="col-md-4 col-lg-3">
                                                                                <FormField
                                                                                    name={`pfscDsDetailsList.${index3}.financialInstitution`}
                                                                                    label="Name of Financial Institution*"
                                                                                    id={`CDs_Name_Financial_Institution_${index3}`}
                                                                                    placeholder="Financial Institution"
                                                                                    error={formErrors[`cDs_${index3}_financialInstitution`]}
                                                                                    maxlength={100}
                                                                                />
                                                                            </div>
                                                                            <div className="col-md-4 col-lg-3">
                                                                                <FieldControl
                                                                                    name={`pfscDsDetailsList.${index3}.maturityDate`}
                                                                                    render={({ handler, touched, hasError, meta }) => (
                                                                                        <div className={`form-group input-date ${formErrors[`cDs_${index3}_maturityDate`] ? 'has-error' : ''}`}>
                                                                                            <label htmlFor={`CDs_Maturity_Date_${index3}`}>Maturity Date *</label>
                                                                                            <DatePicker
                                                                                                showIcon
                                                                                                toggleCalendarOnIconClick
                                                                                                {...handler()}
                                                                                                selected={pfsValue.value.maturityDate ? moment(pfsFormGroup.value.maturityDate).format('MMM DD, YYYY') : ''}
                                                                                                value={pfsValue.value.maturityDate ? moment(pfsValue.value.maturityDate).format('ll') : ""}
                                                                                                isClearable={handler().value}
                                                                                                id={`CDs_Maturity_Date_${index3}`}
                                                                                                dateFormat="MMM d, yyyy"
                                                                                                placeholderText="Month DD, YYYY"
                                                                                                yearDropdownItemNumber={100}
                                                                                                scrollableYearDropdown
                                                                                                showMonthDropdown
                                                                                                showYearDropdown
                                                                                                onChange={(date) => {
                                                                                                    const formattedDate = date ? moment(date).format('YYYY-MM-DD') : '';
                                                                                                    handler().onChange(formattedDate);
                                                                                                }}
                                                                                                onKeyDown={(e) => {
                                                                                                    e.preventDefault();
                                                                                                }}
                                                                                                className={formErrors[`cDs_${index3}_maturityDate`] ? 'is-invalid' : ''}
                                                                                            />
                                                                                            {formErrors[`cDs_${index3}_maturityDate`] && <div className="invalid-feedback">{formErrors[`cDs_${index3}_maturityDate`]}</div>}
                                                                                        </div>
                                                                                    )}
                                                                                />
                                                                            </div>
                                                                            <div className="col-12"></div>
                                                                            <div className="col-md-4 col-lg-3">
                                                                                <AmountContainer
                                                                                    setIsEdit={setIsEdit}
                                                                                    name={`pfscDsDetailsList.${index3}.currentValue`}
                                                                                    label="Current Value*"
                                                                                    placeholder="0.00"
                                                                                    amountType="currentValue"
                                                                                    error={formErrors[`cDs_${index3}_currentValue`]}
                                                                                />
                                                                            </div>
                                                                            <div className="col-md-2 d-flex mb-3 mb-sm-0">
                                                                                {index3 !== 0 && <div onClick={() => removeCheckings(index3, pfsFormGroup, 'pfscDsDetailsList')}><img className="cursor-pointer" src={feild_minus_icon} alt="minus" /></div>}
                                                                                <div onClick={() => addCheckings(pfsFormGroup, 'pfscDsDetailsList')} className="ml-2"><img className="cursor-pointer" src={feild_plus_icon} alt="plus" /></div>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                }
                                                            </>
                                                        )}
                                                    />
                                                </div>
                                            }


                                            {/* Cash */}
                                            <div className="col-12">
                                                <FieldControl
                                                    name="cash"
                                                    render={({ handler, touched, hasError, meta }) => (
                                                        <div className="form-group form-check mb-2">
                                                            <input type="checkbox" className="form-check-input" id="cash" checked={pfsFormGroup.value.cash} {...handler()} />
                                                            <label className="form-check-label" htmlFor="cash_in_hand_Cash">Cash</label>
                                                        </div>
                                                    )}
                                                />
                                            </div>
                                            {pfsFormGroup.value.cash &&
                                                <div className="col-md-4 col-lg-3">
                                                    <AmountContainer
                                                        setIsEdit={setIsEdit}
                                                        name="cashInHand"
                                                        label="Cash in Hand*"
                                                        placeholder="0.00"
                                                        amountType="cashInHand"
                                                        error={formErrors.cashInHand}
                                                    />
                                                </div>
                                            }
                                        </div>
                                    </div>

                                    {/* Assets Section Start */}
                                    <div className="lm-card p-0">
                                        <h3 className="padding-reg lm-card-heading mb-0">Assets</h3>
                                        {/* Real Estate card  */}
                                        <div className="pfs-card">
                                            <div className="pfs-card-head d-flex justify-content-between bg-grey">
                                                <span className="label text-dark mb-0"><b>REAL ESTATE</b></span>
                                                <a className="lm-button3" onClick={() => {
                                                    ownedRealEstateFormGroup.reset();
                                                    rentedRealEstateFormGroup.reset();
                                                    setAddRealEstate(true)
                                                }}><span>+</span> Add Real Estate</a>
                                            </div>
                                            <FormArrayComponent
                                                control={pfsFormGroup.get("pfsOwnedRealEstateDetailsList")}
                                                render={(pfsValue, index) => (
                                                    <div className="row p-3 pfs-data-loop-row">
                                                        {
                                                            pfsValue.value.hasOwnProperty("propertyType") && pfsValue.value.propertyType !== "" ?
                                                                <>
                                                                    <div className="col-lg-fifth col-md-4 col-6 mb-3">
                                                                        <div className="pfs-card-label">Property type</div>
                                                                        <div className="pfs-card-value">{pfsValue.value.propertyType}</div>
                                                                    </div>
                                                                    <div className="col-lg-fifth-double col-md-4 col-12 mb-3">
                                                                        <div className="pfs-card-label">Address</div>
                                                                        <div className="pfs-card-value">{formatAddress(pfsValue.value)}</div>
                                                                    </div>
                                                                    <div className="col-lg-fifth col-md-4 col-6 mb-3">
                                                                        <div className="pfs-card-label">Purchase date</div>
                                                                        <div className="pfs-card-value">{pfsValue.value.purchaseDate ? moment(pfsValue.value.purchaseDate).format('ll') : ""}</div>
                                                                    </div>
                                                                    <div className="col-lg-fifth col-md-4 col-6 mb-3">
                                                                        <div className="pfs-card-label">Original cost</div>
                                                                        <div className="pfs-card-value">{formatNumberWithCommas(pfsValue.value.originalCost)}</div>
                                                                    </div>
                                                                    <div className="col-lg-fifth col-md-4 col-6 mb-3">
                                                                        <div className="pfs-card-label">Present Market Value</div>
                                                                        <div className="pfs-card-value">{formatNumberWithCommas(pfsValue.value.presentMarketValue)}</div>
                                                                    </div>
                                                                    <div className="col-lg-fifth col-md-4 col-6 mb-3">
                                                                        <div className="pfs-card-label">mortgage Holder Name</div>
                                                                        <div className="pfs-card-value">{pfsValue.value.nameAddressOfMortgageHolder}</div>
                                                                    </div>
                                                                    <div className="col-lg-fifth col-md-4 col-6 mb-3">
                                                                        <div className="pfs-card-label">Mortgage Account #</div>
                                                                        <div className="pfs-card-value">{truncateText(pfsValue.value.mortgageAccountNumber)}</div>
                                                                    </div>
                                                                    <div className="col-lg-fifth col-md-4 col-6 mb-3">
                                                                        <div className="pfs-card-label">Mortgage Balance</div>
                                                                        <div className="pfs-card-value">{formatNumberWithCommas(pfsValue.value.mortgageBalance)}</div>
                                                                    </div>
                                                                    <div className="col-lg-fifth col-md-4 col-6 mb-3">
                                                                        <div className="pfs-card-label">Monthly Payment</div>
                                                                        <div className="pfs-card-value">{formatNumberWithCommas(pfsValue.value.monthlyPayment)}</div>
                                                                    </div>
                                                                    <div className="col-lg-fifth col-md-4 col-6 mb-3">
                                                                        <div className="pfs-card-label">Includes Tax & Insurance</div>
                                                                        <div className="pfs-card-value"><img src={pfsValue.value.includesTaxAndInsurance ? pfs_card_tick : pfs_card_cross} /></div>
                                                                    </div>
                                                                    <div className="col-lg-fifth col-md-4 col-6 mb-3">
                                                                        <div className="pfs-card-label">Status</div>
                                                                        <div className={`pfs-card-value status ${pfsValue?.value?.status?.toLowerCase() || ''}`}>  {pfsValue?.value?.status || ''}</div>
                                                                    </div>
                                                                    <div className="col-lg-fifth col-md-4 col-6 mb-3">
                                                                        <div className="pfs-card-label">Do you rent it to someone?</div>
                                                                        <div className="pfs-card-value"><img src={pfsValue.value.isAdditionalProperty ? pfs_card_tick : pfs_card_cross} /></div>
                                                                    </div>
                                                                    {
                                                                        pfsValue.value.isAdditionalProperty ?
                                                                            <div className="col-lg-fifth col-md-4 col-6 mb-3">
                                                                                <div className="pfs-card-label">Monthly rental Income</div>
                                                                                <div className="pfs-card-value">{formatNumberWithCommas(pfsValue.value.monthlyNetRentalIncome) || "0.00"}</div>
                                                                            </div> : ""
                                                                    }
                                                                </>
                                                                :
                                                                <>
                                                                    <div className="col-lg-fifth col-md-4 col-6 mb-3">
                                                                        <div className="pfs-card-label">Monthly Rent</div>
                                                                        <div className="pfs-card-value">{formatNumberWithCommas(pfsValue.value.monthlyNetRentalIncome) || "0.00"}</div>
                                                                    </div>
                                                                    <div className="col-lg-fifth-double col-md-4 col-12 mb-3">
                                                                        <div className="pfs-card-label">Address</div>
                                                                        <div className="pfs-card-value">{formatAddress(pfsValue.value)}</div>
                                                                    </div>
                                                                </>
                                                        }
                                                        <div className="col-12">
                                                            {
                                                                pfsValue.value.hasOwnProperty("propertyType") && pfsValue.value.propertyType !== "" ? (
                                                                    <button onClick={() => {
                                                                        ownedRealEstateFormGroup.patchValue({
                                                                            index: index,
                                                                            pfsId: pfsValue.value.pfsId,
                                                                            ownerID: pfsValue.value.ownerID,
                                                                            propertyType: { value: pfsValue.value.propertyType, label: pfsValue.value.propertyType },
                                                                            streetAddress1: pfsValue.value.streetAddress1,
                                                                            streetAddress2: pfsValue.value.streetAddress2,
                                                                            city: pfsValue.value.city,
                                                                            state: { value: pfsValue.value.state, label: pfsValue.value.state },
                                                                            zipcode: pfsValue.value.zipcode,
                                                                            purchaseDate: pfsValue.value.purchaseDate,
                                                                            originalCost: pfsValue.value.originalCost,
                                                                            presentMarketValue: pfsValue.value.presentMarketValue,
                                                                            monthlyNetRentalIncome: pfsValue.value.monthlyNetRentalIncome,
                                                                            nameAddressOfMortgageHolder: pfsValue.value.nameAddressOfMortgageHolder,
                                                                            mortgageAccountNumber: pfsValue.value.mortgageAccountNumber,
                                                                            mortgageBalance: pfsValue.value.mortgageBalance,
                                                                            monthlyPayment: pfsValue.value.monthlyPayment,
                                                                            includesTaxAndInsurance: pfsValue.value.includesTaxAndInsurance,
                                                                            status: pfsValue.value.status ? { value: pfsValue.value.status, label: pfsValue.value.status } : "",
                                                                            isAdditionalProperty: pfsValue.value.isAdditionalProperty,
                                                                            additionalRealEstateDetailsId: 0,
                                                                        })
                                                                        setEditOwnRealEstate(true)
                                                                    }
                                                                    } type="button" className="lm-button2 w-100"><img src={lm_edit_icon}
                                                                        alt="" /> Edit</button>
                                                                ) : (
                                                                    <button onClick={() => {
                                                                        rentedRealEstateFormGroup.patchValue({
                                                                            index: index,
                                                                            monthlyNetRentalIncome: pfsValue.value.monthlyNetRentalIncome,
                                                                            streetAddress1: pfsValue.value.streetAddress1,
                                                                            city: pfsValue.value.city,
                                                                            state: pfsValue.value.state ? { value: pfsValue.value.state, label: pfsValue.value.state } : "",
                                                                            zipcode: pfsValue.value.zipcode,
                                                                        })
                                                                        setEditRentalRealEstate(true)
                                                                    }
                                                                    } type="button" className="lm-button2 w-100"><img src={lm_edit_icon}
                                                                        alt="" /> Edit</button>
                                                                )
                                                            }
                                                        </div>
                                                    </div>
                                                )}
                                            />
                                        </div>
                                        {/* Automobiles card  */}
                                        <div className="pfs-card">
                                            <div className="pfs-card-head d-flex justify-content-between bg-grey">
                                                <span className="label text-dark mb-0"><b>AUTOMOBILES</b></span>
                                                <a className="lm-button3" onClick={() => {
                                                    automobileFormGroup.reset();
                                                    setAutoMobileType("Add");
                                                    setAutomobilePopup(true)
                                                }}><span>+</span>Add Automobiles</a>
                                            </div>
                                            <FormArrayComponent
                                                control={pfsFormGroup.get("pfsAutomobileDetailsList")}
                                                render={(pfsValue, index) => (
                                                    <div className="row p-3 pfs-data-loop-row">
                                                        <div className="col-lg-fifth col-md-4 col-6 mb-3">
                                                            <div className="pfs-card-label">Year</div>
                                                            <div className="pfs-card-value">{pfsValue.value.year}</div>
                                                        </div>
                                                        <div className="col-lg-fifth col-md-4 col-6 mb-3">
                                                            <div className="pfs-card-label">Make</div>
                                                            <div className="pfs-card-value">{pfsValue.value.make}</div>
                                                        </div>
                                                        <div className="col-lg-fifth col-md-4 col-6 mb-3">
                                                            <div className="pfs-card-label">Model</div>
                                                            <div className="pfs-card-value">{pfsValue.value.model}</div>
                                                        </div>
                                                        <div className="col-lg-fifth col-md-4 col-6 mb-3">
                                                            <div className="pfs-card-label">Market value</div>
                                                            <div className="pfs-card-value">{formatNumberWithCommas(pfsValue.value.marketValue)}</div>
                                                        </div>
                                                        <div className="col-lg-fifth col-md-4 col-6 mb-3">
                                                            <div className="pfs-card-label">VIN</div>
                                                            <div className="pfs-card-value">{pfsValue.value.vin}</div>
                                                        </div>
                                                        <div className="col-lg-fifth col-md-4 col-6 mb-3">
                                                            <div className="pfs-card-label">Lender</div>
                                                            <div className="pfs-card-value">{pfsValue.value.lender}</div>
                                                        </div>
                                                        <div className="col-lg-fifth col-md-4 col-6 mb-3">
                                                            <div className="pfs-card-label">Outstanding Loan Balance</div>
                                                            <div className="pfs-card-value">{formatNumberWithCommas(pfsValue.value.outstandingLoanBalance)}</div>
                                                        </div>
                                                        <div className="col-lg-fifth col-md-4 col-6 mb-3">
                                                            <div className="pfs-card-label">Monthly Payment</div>
                                                            <div className="pfs-card-value">{formatNumberWithCommas(pfsValue.value.monthlyPayment)}</div>
                                                        </div>
                                                        <div className="col-lg-fifth col-md-4 col-6 mb-3">
                                                            <div className="pfs-card-label">Status</div>
                                                            <div className={`pfs-card-value status ${pfsValue?.value?.status?.toLowerCase() || ''}`}>  {pfsValue?.value?.status || ''}</div>
                                                        </div>
                                                        <div className="col-12">
                                                            <button onClick={() => {
                                                                automobileFormGroup.patchValue({
                                                                    index: index,
                                                                    pfsId: pfsValue.value.pfsId,
                                                                    year: { value: pfsValue.value.year, label: pfsValue.value.year },
                                                                    make: pfsValue.value.make,
                                                                    model: pfsValue.value.model,
                                                                    marketValue: pfsValue.value.marketValue,
                                                                    vin: pfsValue.value.vin,
                                                                    lender: pfsValue.value.lender,
                                                                    outstandingLoanBalance: pfsValue.value.outstandingLoanBalance,
                                                                    ownerID: pfsValue.value.ownerID,
                                                                    monthlyPayment: pfsValue.value.monthlyPayment,
                                                                    status: pfsValue.value.status ? { value: pfsValue.value.status, label: pfsValue.value.status } : ""
                                                                })
                                                                setAutoMobileType("Edit");
                                                                setAutomobilePopup(true)
                                                            }} type="button" className="lm-button2 w-100"><img src={lm_edit_icon}
                                                                alt="" />Edit</button>
                                                        </div>
                                                    </div>
                                                )}
                                            />
                                        </div>
                                        {/* Marketable Securities card  */}
                                        <div className="pfs-card">
                                            <div className="pfs-card-head d-flex justify-content-between bg-grey">
                                                <span className="label text-dark mb-0"><b className="small-tooltip">MARKETABLE SECURITIES
                                                    <div className="lm-tooltip" data-tooltip="Examples include shares and bonds" data-tooltip-pos="right" data-tooltip-length="fit"> <img src={Info_tooltip} alt="" /> </div>
                                                </b>
                                                </span>
                                                <a onClick={() => {
                                                    marketableSecuritiesFormGroup.reset();
                                                    setMarketableType("Add");
                                                    setMarketablePopup(true);
                                                }} className="lm-button3"><span>+</span>Add Marketable Securities</a>
                                            </div>
                                            <FormArrayComponent
                                                control={pfsFormGroup.get("pfsMarketableSecuritiesDetailsList")}
                                                render={(pfsValue, index) => (
                                                    <div className="row p-3 pfs-data-loop-row">
                                                        <div className="col-lg-fifth col-md-4 col-6 mb-3">
                                                            <div className="pfs-card-label">Number of Shares</div>
                                                            <div className="pfs-card-value">{pfsValue.value.numberOfShares}</div>
                                                        </div>
                                                        <div className="col-lg-fifth col-md-4 col-6 mb-3">
                                                            <div className="pfs-card-label">Name</div>
                                                            <div className="pfs-card-value">{pfsValue.value.name}</div>
                                                        </div>
                                                        <div className="col-lg-fifth col-md-4 col-6 mb-3">
                                                            <div className="pfs-card-label">Original Cost</div>
                                                            <div className="pfs-card-value">{formatNumberWithCommas(pfsValue.value.originalCost)}</div>
                                                        </div>
                                                        <div className="col-lg-fifth col-md-4 col-6 mb-3">
                                                            <div className="pfs-card-label">Market value</div>
                                                            <div className="pfs-card-value">{formatNumberWithCommas(pfsValue.value.marketValue)}</div>
                                                        </div>
                                                        <div className="col-lg-fifth col-md-4 col-6 mb-3">
                                                            <div className="pfs-card-label">Date of Valuation</div>
                                                            <div className="pfs-card-value">{pfsValue.value.dateOfValue ? moment(pfsValue.value.dateOfValue).format('ll') : ""}</div>
                                                        </div>
                                                        <div className="col-lg-fifth col-md-4 col-6 mb-3">
                                                            <div className="pfs-card-label">Total Value</div>
                                                            <div className="pfs-card-value">{formatNumberWithCommas(pfsValue.value.totalValue)}</div>
                                                        </div>
                                                        <div className="col-lg-fifth col-md-4 col-6 mb-3">
                                                            <div className="pfs-card-label small-tooltip">Pledged
                                                                <div className="lm-tooltip" data-tooltip="The shares are being used as collateral on another loan" data-tooltip-pos="up" data-tooltip-length="fit"> <img src={Info_tooltip} alt="" /> </div>
                                                            </div>
                                                            <div className="pfs-card-value"><img src={pfsValue.value.pledged === "Yes" ? pfs_card_tick : pfsValue.value.pledged === "No" ? pfs_card_cross : ""} /></div>
                                                        </div>
                                                        <div className="col-12">
                                                            <button onClick={() => {
                                                                marketableSecuritiesFormGroup.patchValue({
                                                                    index: index,
                                                                    dateOfValue: pfsValue.value.dateOfValue,
                                                                    exchangeListed: pfsValue.value.exchangeListed,
                                                                    marketValue: pfsValue.value.marketValue,
                                                                    name: pfsValue.value.name,
                                                                    numberOfShares: pfsValue.value.numberOfShares,
                                                                    originalCost: pfsValue.value.originalCost,
                                                                    ownerID: pfsValue.value.ownerID,
                                                                    pfsId: pfsValue.value.pfsId,
                                                                    pledged: pfsValue.value.pledged,
                                                                    totalValue: pfsValue.value.totalValue,
                                                                });
                                                                setMarketablePopup(true);
                                                                setMarketableType("Edit");
                                                            }} type="button" className="lm-button2 w-100"><img src={lm_edit_icon} alt="" /> Edit</button>
                                                        </div>
                                                    </div>
                                                )}
                                            />
                                        </div>
                                        {/* Accounts / Notes Receivables card  */}
                                        <div className="pfs-card">
                                            <div className="pfs-card-head d-flex justify-content-between bg-grey">
                                                <span className="label text-dark mb-0"><b>ACCOUNTS / NOTES RECEIVABLES</b>
                                                </span>
                                                <a onClick={() => {
                                                    setAccountNoteType("Add");
                                                    accountReceivablesFormGroup.reset();
                                                    setAccountNotePopup(true);
                                                }} className="lm-button3"><span>+</span> Add Accounts / Notes Receivables</a>
                                            </div>
                                            <FormArrayComponent
                                                control={pfsFormGroup.get("pfsAccountReceivablesDetailsList")}
                                                render={(pfsValue, index) => (
                                                    <div className="row p-3 pfs-data-loop-row">
                                                        <div className="col-lg-fifth col-md-4 col-6 mb-3">
                                                            <div className="pfs-card-label">Name of Note Payer</div>
                                                            <div className="pfs-card-value">{pfsValue.value.nameOfNotePayer}</div>
                                                        </div>
                                                        <div className="col-lg-fifth-double col-md-4 col-12 mb-3">
                                                            <div className="pfs-card-label">Address</div>
                                                            <div className="pfs-card-value">{formatAddress(pfsValue.value)}</div>
                                                        </div>
                                                        <div className="col-lg-fifth col-md-4 col-6 mb-3">
                                                            <div className="pfs-card-label">Original Balance</div>
                                                            <div className="pfs-card-value">{formatNumberWithCommas(pfsValue.value.originalBalance)}</div>
                                                        </div>
                                                        <div className="col-lg-fifth col-md-4 col-6 mb-3">
                                                            <div className="pfs-card-label">Current Balance</div>
                                                            <div className="pfs-card-value">{formatNumberWithCommas(pfsValue.value.currentBalance)}</div>
                                                        </div>
                                                        <div className="col-lg-fifth col-md-4 col-6 mb-3">
                                                            <div className="pfs-card-label">Payment Amount</div>
                                                            <div className="pfs-card-value">{formatNumberWithCommas(pfsValue.value.paymentAmount)}</div>
                                                        </div>
                                                        <div className="col-lg-fifth col-md-4 col-6 mb-3">
                                                            <div className="pfs-card-label">Frequency</div>
                                                            <div className="pfs-card-value">{pfsValue.value.frequency}</div>
                                                        </div>
                                                        <div className="col-lg-fifth col-md-4 col-6 mb-3">
                                                            <div className="pfs-card-label">Maturity Date</div>
                                                            <div className="pfs-card-value">{pfsValue.value.maturityDate ? moment(pfsValue.value.maturityDate).format('ll') : ""}</div>
                                                        </div>
                                                        <div className="col-lg-fifth col-md-4 col-6 mb-3">
                                                            <div className="pfs-card-label">Secured</div>
                                                            <div className="pfs-card-value"><img src={pfsValue.value.secured == "Yes" ? pfs_card_tick : pfsValue.value.secured === "No" ? pfs_card_cross : ""} /></div>
                                                        </div>
                                                        <div className="col-lg-fifth col-md-4 col-6 mb-3">
                                                            <div className="pfs-card-label">Status</div>
                                                            <div className={`pfs-card-value status ${pfsValue?.value?.status?.toLowerCase() || ''}`}>  {pfsValue?.value?.status || ''}</div>
                                                        </div>
                                                        <div className="col-12">
                                                            <button onClick={() => {
                                                                accountReceivablesFormGroup.patchValue({
                                                                    index: index,
                                                                    address: pfsValue.value.address,
                                                                    currentBalance: pfsValue.value.currentBalance,
                                                                    frequency: pfsValue.value.frequency ? { label: pfsValue.value.frequency, value: pfsValue.value.frequency } : "",
                                                                    maturityDate: pfsValue.value.maturityDate,
                                                                    nameOfNotePayer: pfsValue.value.nameOfNotePayer,
                                                                    originalBalance: pfsValue.value.originalBalance,
                                                                    ownerID: pfsValue.value.ownerID,
                                                                    paymentAmount: pfsValue.value.paymentAmount,
                                                                    pfsId: pfsValue.value.pfsId,
                                                                    secured: pfsValue.value.secured,
                                                                    streetAddress1: pfsValue.value.streetAddress1,
                                                                    streetAddress2: pfsValue.value.streetAddress2,
                                                                    city: pfsValue.value.city,
                                                                    state: pfsValue.value.state ? { value: pfsValue.value.state, label: pfsValue.value.state } : "",
                                                                    zipcode: pfsValue.value.zipcode,
                                                                    status: pfsValue.value.status ? { label: pfsValue.value.status, value: pfsValue.value.status } : ""
                                                                });
                                                                setAccountNoteType("Edit");
                                                                setAccountNotePopup(true)
                                                            }} type="button" className="lm-button2 w-100"><img src={lm_edit_icon} alt="" /> Edit</button>
                                                        </div>
                                                    </div>
                                                )}
                                            />
                                        </div>
                                        {/* Life insurance card  */}
                                        <div className="pfs-card">
                                            <div className="pfs-card-head d-flex justify-content-between bg-grey">
                                                <span className="label text-dark mb-0"><b className="small-tooltip">LIFE INSURANCE
                                                    <div className="lm-tooltip" data-tooltip="Please note insurance with cash surrender values only." data-tooltip-pos="right" data-tooltip-length="fit"> <img src={Info_tooltip} alt="" /> </div>
                                                </b>
                                                </span>
                                                <a className="lm-button3" onClick={() => {
                                                    lifeInsuranceFormGroup.reset()
                                                    setLifeInsuranceType("Add")
                                                    setLifeInsurancePopup(true)
                                                }}><span>+</span> Add Life insurance</a>
                                            </div>
                                            <FormArrayComponent
                                                control={pfsFormGroup.get("pfsLifeInsuranceDetailsList")}
                                                render={(pfsValue, index) => (
                                                    <div className="row p-3 pfs-data-loop-row">
                                                        <div className="col-lg-fifth col-md-4 col-6 mb-3">
                                                            <div className="pfs-card-label">Insurance Company</div>
                                                            <div className="pfs-card-value">{pfsValue.value.insuranceCompany}</div>
                                                        </div>
                                                        <div className="col-lg-fifth-double col-md-4 col-12 mb-3">
                                                            <div className="pfs-card-label">Beneficiaries</div>
                                                            <div className="pfs-card-value">{pfsValue.value.beneficiaries}</div>
                                                        </div>
                                                        <div className="col-lg-fifth-double col-md-4 col-12 mb-3">
                                                            <div className="pfs-card-label">Purchase Date</div>
                                                            <div className="pfs-card-value">{pfsValue.value.purchaseDate ? moment(pfsValue.value.purchaseDate).format('ll') : ""}</div>
                                                        </div>
                                                        <div className="col-lg-fifth col-md-4 col-6 mb-3">
                                                            <div className="pfs-card-label">Face Value</div>
                                                            <div className="pfs-card-value">{formatNumberWithCommas(pfsValue.value.faceValue)}</div>
                                                        </div>
                                                        <div className="col-lg-fifth col-md-4 col-6 mb-3">
                                                            <div className="pfs-card-label">Cash Surrender Value</div>
                                                            <div className="pfs-card-value">{formatNumberWithCommas(pfsValue.value.cashSurrenderValue)}</div>
                                                        </div>
                                                        <div className="col-lg-fifth col-md-4 col-6 mb-3">
                                                            <div className="pfs-card-label">Amount Borrowed</div>
                                                            <div className="pfs-card-value">{formatNumberWithCommas(pfsValue.value.amountBorrowed)}</div>
                                                        </div>
                                                        <div className="col-lg-fifth col-md-4 col-6 mb-3">
                                                            <div className="pfs-card-label">Pledged</div>
                                                            <div className="pfs-card-value"><img src={pfsValue.value.pledged === "Yes" ? pfs_card_tick : pfsValue.value.pledged === "No" ? pfs_card_cross : ""} /></div>
                                                        </div>
                                                        <div className="col-12">
                                                            <button onClick={() => {
                                                                lifeInsuranceFormGroup.patchValue({
                                                                    index: index,
                                                                    insuranceCompany: pfsValue.value.insuranceCompany,
                                                                    beneficiaries: pfsValue.value.beneficiaries,
                                                                    faceValue: pfsValue.value.faceValue,
                                                                    cashSurrenderValue: pfsValue.value.cashSurrenderValue,
                                                                    amountBorrowed: pfsValue.value.amountBorrowed,
                                                                    pledged: pfsValue.value.pledged,
                                                                    ownerID: pfsValue.value.ownerID,
                                                                    pfsId: pfsValue.value.pfsId,
                                                                    state: pfsValue.value.state ? { value: pfsValue.value.state, label: pfsValue.value.state } : "",
                                                                    purchaseDate: pfsValue.value.purchaseDate,
                                                                })
                                                                setLifeInsuranceType("Edit");
                                                                setLifeInsurancePopup(true)
                                                            }} type="button" className="lm-button2 w-100"><img src={lm_edit_icon}
                                                                alt="" /> Edit</button>
                                                        </div>
                                                    </div>
                                                )}
                                            />
                                        </div>
                                        {/* Retirement Accounts card  */}
                                        <div className="pfs-card">
                                            <div className="pfs-card-head d-flex justify-content-between bg-grey">
                                                <span className="label text-dark mb-0"><b>RETIREMENT ACCOUNTS</b>
                                                </span>
                                                <a className="lm-button3" onClick={() => addCheckings(pfsFormGroup, 'pfsRetirementAccountsDetailsList')}><span>+</span> Add Retirement Accounts</a>
                                            </div>
                                            <FormArrayComponent
                                                control={pfsFormGroup.get("pfsRetirementAccountsDetailsList")}
                                                render={(pfsValue, index) => (
                                                    <>
                                                        {
                                                            pfsValue.value.isDeleted !== 1 && (
                                                                <div className="row p-3 pfs-data-loop-row">
                                                                    <div className="col-md-4 col-lg-6">
                                                                        <div className="form-group">
                                                                            <FormField
                                                                                name={`pfsRetirementAccountsDetailsList.${index}.descOfHoldings`}
                                                                                label="Description of Holdings"
                                                                                id={`Description_Holdings_${index}`}
                                                                                placeholder="Description"
                                                                                maxlength={250}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-4 col-lg-3">
                                                                        <div className="form-group input-dollar">
                                                                            <AmountContainer
                                                                                setIsEdit={setIsEdit}
                                                                                name={`pfsRetirementAccountsDetailsList.${index}.aggregateMarketValue`}
                                                                                label="Market Value"
                                                                                placeholder="0.00"
                                                                                amountType="aggregateMarketValue"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-1 col-lg-1">
                                                                        <div className="d-flex justify-content-center align-items-center h-100">
                                                                            <div onClick={() => removeCheckings(index, pfsFormGroup, 'pfsRetirementAccountsDetailsList')}><img className="cursor-pointer" src={feild_minus_icon} alt="minus" /></div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        }
                                                    </>
                                                )}
                                            />
                                        </div>
                                        {/* Other Personal Property card  */}
                                        <div className="pfs-card">
                                            <div className="pfs-card-head d-flex justify-content-between bg-grey">
                                                <span className="label text-dark mb-0"><b className="small-tooltip">OTHER PERSONAL PROPERTY
                                                    <div className="lm-tooltip" data-tooltip="Examples include furniture, fixtures,
                                                    precious metals, jewelry and art" data-tooltip-pos="right" data-tooltip-length="large"> <img src={Info_tooltip} alt="" /> </div>
                                                </b>
                                                </span>
                                                <a className="lm-button3" onClick={() => addCheckings(pfsFormGroup, 'pfsOtherPersonalPropertyDetailList')}><span>+</span> Add Other Personal Property</a>
                                            </div>
                                            <FormArrayComponent
                                                control={pfsFormGroup.get("pfsOtherPersonalPropertyDetailList")}
                                                render={(pfsValue, index) => (
                                                    <>
                                                        {
                                                            pfsValue.value.isDeleted !== 1 && (
                                                                <div className="row p-3 pfs-data-loop-row">
                                                                    <div className="col-md-4 col-lg-3">
                                                                        <div className="form-group">
                                                                            <FormField
                                                                                name={`pfsOtherPersonalPropertyDetailList.${index}.typeOfOtherPersonalProperty`}
                                                                                label="Type of Personal Property"
                                                                                placeholder="Personal Property"
                                                                                id="typeOfOtherPersonalProperty"
                                                                                maxlength={250}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-4 col-lg-3">
                                                                        <div className="form-group input-dollar">
                                                                            <AmountContainer
                                                                                setIsEdit={setIsEdit}
                                                                                name={`pfsOtherPersonalPropertyDetailList.${index}.marketValue`}
                                                                                label="Market Value"
                                                                                placeholder="0.00"
                                                                                id="marketValue"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-1 col-lg-1">
                                                                        <div className="d-flex justify-content-center align-items-center h-100">
                                                                            <div onClick={() => removeCheckings(index, pfsFormGroup, 'pfsOtherPersonalPropertyDetailList')}><img className="cursor-pointer" src={feild_minus_icon} alt="minus" /></div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        }
                                                    </>
                                                )}
                                            />
                                        </div>
                                        {/* Other Assets card  */}
                                        <div className="pfs-card">
                                            <div className="pfs-card-head d-flex justify-content-between bg-grey">
                                                <span className="label text-dark mb-0"><b className="small-tooltip">OTHER ASSETS
                                                    <div className="lm-tooltip" data-tooltip="Examples include cryptocurrencies 
                                                    and time shares" data-tooltip-pos="right" data-tooltip-length="large"> <img src={Info_tooltip} alt="" /> </div>
                                                </b>
                                                </span>
                                                <a className="lm-button3" onClick={() => addCheckings(pfsFormGroup, 'pfsOtherAssetsDetailList')}><span>+</span> Add Other Assets</a>
                                            </div>
                                            <FormArrayComponent
                                                control={pfsFormGroup.get("pfsOtherAssetsDetailList")}
                                                render={(pfsValue, index) => (
                                                    <>
                                                        {
                                                            pfsValue.value.isDeleted !== 1 && (
                                                                <div className="row p-3 pfs-data-loop-row">
                                                                    <div className="col-md-4 col-lg-3">
                                                                        <div className="form-group">
                                                                            <FormField
                                                                                name={`pfsOtherAssetsDetailList.${index}.typeOfAsset`}
                                                                                label="Type of Asset"
                                                                                placeholder="Asset Type"
                                                                                id="typeOfAsset"
                                                                                maxlength={250}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-4 col-lg-3">
                                                                        <div className="form-group input-dollar">
                                                                            <AmountContainer
                                                                                setIsEdit={setIsEdit}
                                                                                name={`pfsOtherAssetsDetailList.${index}.marketValue`}
                                                                                label="Market Value"
                                                                                placeholder="0.00"
                                                                                id="marketValue"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-1 col-lg-1">
                                                                        <div className="d-flex justify-content-center align-items-center h-100">
                                                                            <div onClick={() => removeCheckings(index, pfsFormGroup, 'pfsOtherAssetsDetailList')}><img className="cursor-pointer" src={feild_minus_icon} alt="minus" /></div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        }
                                                    </>
                                                )}
                                            />
                                        </div>
                                        {/* Assets notes card  */}
                                        <div className="pfs-card">
                                            <div className="pfs-card-head d-flex justify-content-between bg-grey">
                                                <span className="label text-dark mb-0"><b>NOTES</b>
                                                </span>
                                            </div>
                                            <div className="row p-3 pfs-data-loop-row">
                                                <div className="col-md-6">
                                                    <div className="form-group mb-0">
                                                        <FieldControl
                                                            name="additionalAssetNotes"
                                                            render={({ handler, touched, hasError, meta }) => (
                                                                <textarea
                                                                    className="form-control"
                                                                    id="additionalAssetNotes"
                                                                    name="additionalAssetNotes"
                                                                    placeholder="Enter text"
                                                                    {...handler()}
                                                                    maxLength={250} // Use camelCase for React props, i.e., 'maxLength'
                                                                />
                                                            )}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* Liabilities Section Start */}
                                    <div className="lm-card p-0">
                                        <h3 className="padding-reg lm-card-heading mb-0">Liabilities</h3>
                                        {/* Notes Payable to Banks & Institutions card  */}
                                        <div className="pfs-card">
                                            <div className="pfs-card-head d-flex justify-content-between bg-grey">
                                                <span className="label text-dark mb-0"><b>NOTES PAYABLE TO BANKS & OTHERS</b></span>
                                                <a onClick={NotesPayableBanksShow} className="lm-button3"><span>+</span> Add Notes Payable to Banks & Others</a>
                                            </div>
                                            <FormArrayComponent
                                                control={pfsFormGroup.get("pfsNotesPayableToBanksAndInstitutionsDetailsList")}
                                                render={(pfsValue, index) => (
                                                    <div className="row p-3 pfs-data-loop-row">
                                                        <div className="col-lg-fifth col-md-4 col-6 mb-3">
                                                            <div className="pfs-card-label">NAME OF NOTEHOLDER</div>
                                                            <div className="pfs-card-value">{pfsValue.value.nameOfNoteHolder}</div>
                                                        </div>
                                                        <div className="col-lg-fifth col-md-4 col-6 mb-3">
                                                            <div className="pfs-card-label">Original Balance</div>
                                                            <div className="pfs-card-value">{formatNumberWithCommas(pfsValue.value.originalBalance)}</div>
                                                        </div>
                                                        <div className="col-lg-fifth col-md-4 col-6 mb-3">
                                                            <div className="pfs-card-label">Current Balance</div>
                                                            <div className="pfs-card-value">{formatNumberWithCommas(pfsValue.value.currentBalance)}</div>
                                                        </div>
                                                        <div className="col-lg-fifth col-md-4 col-6 mb-3">
                                                            <div className="pfs-card-label">Payment Amount</div>
                                                            <div className="pfs-card-value">{formatNumberWithCommas(pfsValue.value.paymentAmount)}</div>
                                                        </div>
                                                        <div className="col-lg-fifth col-md-4 col-6 mb-3">
                                                            <div className="pfs-card-label">Frequency</div>
                                                            <div className="pfs-card-value">{pfsValue.value.frequency}</div>
                                                        </div>
                                                        <div className="col-lg-fifth col-md-4 col-6 mb-3">
                                                            <div className="pfs-card-label">Maturity Date</div>
                                                            <div className="pfs-card-value">{pfsValue.value.maturityDate ? moment(pfsValue.value.maturityDate).format('ll') : ""}</div>
                                                        </div>
                                                        <div className="col-lg-fifth col-md-4 col-6 mb-3">
                                                            <div className="pfs-card-label">Secured</div>
                                                            <div className="pfs-card-value"><img src={pfsValue.value.secured === "Yes" ? pfs_card_tick : pfsValue.value.secured === "No" ? pfs_card_cross : ""} /></div>
                                                        </div>
                                                        <div className="col-lg-fifth col-md-4 col-6 mb-3">
                                                            <div className="pfs-card-label">Type of Collateral</div>
                                                            <div className="pfs-card-value">{pfsValue.value.typeofCollateral}</div>
                                                        </div>
                                                        <div className="col-12">
                                                            <button onClick={() => {
                                                                notesPayableBanksFormGroup.patchValue({
                                                                    index: index,
                                                                    nameOfNoteHolder: pfsValue.value.nameOfNoteHolder,
                                                                    originalBalance: pfsValue.value.originalBalance,
                                                                    currentBalance: pfsValue.value.currentBalance,
                                                                    paymentAmount: pfsValue.value.paymentAmount,
                                                                    frequency: pfsValue.value.frequency ? { label: pfsValue.value.frequency, value: pfsValue.value.frequency } : "",
                                                                    maturityDate: pfsValue.value.maturityDate,
                                                                    secured: pfsValue.value.secured,
                                                                    typeofCollateral: pfsValue.value.typeofCollateral,
                                                                    ownerID: pfsValue.value.ownerID,
                                                                    pfsId: pfsValue.value.pfsId,
                                                                })
                                                                setNotesPayableBanks(true)
                                                                setNotesPayableBanksType("Edit")
                                                            }} type="button" className="lm-button2 w-100"><img src={lm_edit_icon}
                                                                alt="" /> Edit</button>
                                                        </div>
                                                    </div>
                                                )}
                                            />
                                        </div>
                                        {/* Notes payable to individuals card  */}
                                        <div className="pfs-card">
                                            <div className="pfs-card-head d-flex justify-content-between bg-grey">
                                                <span className="label text-dark mb-0"><b>NOTE PAYABLE TO INDIVIDUALS</b></span>
                                                <a onClick={() => {
                                                    notesPayableIndividualsFormGroup.reset()
                                                    setNotesPayableIndividualsPopup(true)
                                                    setNotesPayableIndividualsType("Add")
                                                }} className="lm-button3"><span>+</span> Add Note Payable to Individuals</a>
                                            </div>
                                            <FormArrayComponent
                                                control={pfsFormGroup.get("pfsNotesPayableToIndividualsDetailsList")}
                                                render={(pfsValue, index) => (
                                                    <div className="row p-3 pfs-data-loop-row">
                                                        <div className="col-lg-fifth col-md-4 col-6 mb-3">
                                                            <div className="pfs-card-label">NAME OF NOTEHOLDER</div>
                                                            <div className="pfs-card-value">{pfsValue.value.nameOfNoteHolder}</div>
                                                        </div>
                                                        <div className="col-lg-fifth col-md-4 col-6 mb-3">
                                                            <div className="pfs-card-label">Original Balance</div>
                                                            <div className="pfs-card-value">{formatNumberWithCommas(pfsValue.value.originalBalance)}</div>
                                                        </div>
                                                        <div className="col-lg-fifth col-md-4 col-6 mb-3">
                                                            <div className="pfs-card-label">Current Balance</div>
                                                            <div className="pfs-card-value">{formatNumberWithCommas(pfsValue.value.currentBalance)}</div>
                                                        </div>
                                                        <div className="col-lg-fifth col-md-4 col-6 mb-3">
                                                            <div className="pfs-card-label">Payment Amount</div>
                                                            <div className="pfs-card-value">{formatNumberWithCommas(pfsValue.value.paymentAmount)}</div>
                                                        </div>
                                                        <div className="col-lg-fifth col-md-4 col-6 mb-3">
                                                            <div className="pfs-card-label">Frequency</div>
                                                            <div className="pfs-card-value">{pfsValue.value.frequency}</div>
                                                        </div>
                                                        <div className="col-lg-fifth col-md-4 col-6 mb-3">
                                                            <div className="pfs-card-label">Maturity Date</div>
                                                            <div className="pfs-card-value">{pfsValue.value.maturityDate ? moment(pfsValue.value.maturityDate).format('ll') : ""}</div>
                                                        </div>
                                                        <div className="col-lg-fifth col-md-4 col-6 mb-3">
                                                            <div className="pfs-card-label">Secured</div>
                                                            <div className="pfs-card-value"><img src={pfsValue.value.secured == "Yes" ? pfs_card_tick : pfsValue.value.secured === "No" ? pfs_card_cross : ""} /></div>
                                                        </div>
                                                        <div className="col-lg-fifth col-md-4 col-6 mb-3">
                                                            <div className="pfs-card-label">type of collateral</div>
                                                            <div className="pfs-card-value">{pfsValue.value.typeOfCollateral}</div>
                                                        </div>
                                                        <div className="col-12">
                                                            <button
                                                                onClick={() => {
                                                                    notesPayableIndividualsFormGroup.patchValue({
                                                                        index: index,
                                                                        nameOfNoteHolder: pfsValue.value.nameOfNoteHolder,
                                                                        originalBalance: pfsValue.value.originalBalance,
                                                                        currentBalance: pfsValue.value.currentBalance,
                                                                        paymentAmount: pfsValue.value.paymentAmount,
                                                                        frequency: pfsValue.value.frequency ? { value: pfsValue.value.frequency, label: pfsValue.value.frequency } : "",
                                                                        maturityDate: pfsValue.value.maturityDate,
                                                                        secured: pfsValue.value.secured,
                                                                        typeOfCollateral: pfsValue.value.typeOfCollateral,
                                                                        ownerID: pfsValue.value.ownerID,
                                                                        pfsId: pfsValue.value.pfsId,
                                                                    })
                                                                    setNotesPayableIndividualsPopup(true)
                                                                    setNotesPayableIndividualsType("Edit")
                                                                }}
                                                                type="button" className="lm-button2 w-100"><img src={lm_edit_icon}
                                                                    alt="" /> Edit</button>
                                                        </div>
                                                    </div>
                                                )}
                                            />
                                        </div>
                                        {/* Installment Account (Other) card  */}
                                        <div className="pfs-card">
                                            <div className="pfs-card-head d-flex justify-content-between bg-grey">
                                                <span className="label text-dark mb-0"><b>INSTALLMENT ACCOUNT (OTHER)</b></span>
                                                <a onClick={() => {
                                                    installmentAccountFormGroup.reset()
                                                    setInstallmentAccountPopup(true)
                                                    setInstallmentAccountType("Add")
                                                }} className="lm-button3"><span>+</span> Add Installment Account</a>
                                            </div>
                                            <FormArrayComponent
                                                control={pfsFormGroup.get("pfsInstallmentAccountDetailsList")}
                                                render={(pfsValue, index) => (
                                                    <div className="row p-3 pfs-data-loop-row">
                                                        <div className="col-lg-fifth col-md-4 col-6 mb-3">
                                                            <div className="pfs-card-label">Installment Type</div>
                                                            <div className="pfs-card-value">{pfsValue.value.installmentType}</div>
                                                        </div>
                                                        <div className="col-lg-fifth col-md-4 col-6 mb-3">
                                                            <div className="pfs-card-label">Asset</div>
                                                            <div className="pfs-card-value">{formatNumberWithCommas(pfsValue.value.asset)}</div>
                                                        </div>
                                                        <div className="col-lg-fifth col-md-4 col-6 mb-3">
                                                            <div className="pfs-card-label">Market Value</div>
                                                            <div className="pfs-card-value">{formatNumberWithCommas(pfsValue.value.marketValue)}</div>
                                                        </div>
                                                        <div className="col-lg-fifth col-md-4 col-6 mb-3">
                                                            <div className="pfs-card-label">Lender</div>
                                                            <div className="pfs-card-value">{pfsValue.value.lender}</div>
                                                        </div>
                                                        <div className="col-lg-fifth col-md-4 col-6 mb-3">
                                                            <div className="pfs-card-label">Outstanding Loan Balance</div>
                                                            <div className="pfs-card-value">{formatNumberWithCommas(pfsValue.value.outstandingLoanBalance)}</div>
                                                        </div>
                                                        <div className="col-lg-fifth col-md-4 col-6 mb-3">
                                                            <div className="pfs-card-label">Monthly Payment</div>
                                                            <div className="pfs-card-value">{formatNumberWithCommas(pfsValue.value.monthlyPayment)}</div>
                                                        </div>
                                                        <div className="col-lg-fifth col-md-4 col-6 mb-3">
                                                            <div className="pfs-card-label">Status</div>
                                                            <div className={`pfs-card-value status ${pfsValue?.value?.status?.toLowerCase() || ''}`}>  {pfsValue?.value?.status || ''}</div>
                                                        </div>
                                                        <div className="col-12">
                                                            <button
                                                                onClick={() => {
                                                                    installmentAccountFormGroup.patchValue({
                                                                        index: index,
                                                                        asset: pfsValue.value.asset,
                                                                        lender: pfsValue.value.lender,
                                                                        marketValue: pfsValue.value.marketValue,
                                                                        monthlyPayment: pfsValue.value.monthlyPayment,
                                                                        outstandingLoanBalance: pfsValue.value.outstandingLoanBalance,
                                                                        ownerID: pfsValue.value.ownerID,
                                                                        pfsId: pfsValue.value.pfsId,
                                                                        status: pfsValue.value.status ? { value: pfsValue.value.status, label: pfsValue.value.status } : "",
                                                                        installmentType: pfsValue.value.installmentType,
                                                                    })
                                                                    setInstallmentAccountPopup(true)
                                                                    setInstallmentAccountType("Edit")
                                                                }}
                                                                type="button" className="lm-button2 w-100"><img src={lm_edit_icon}
                                                                    alt="" /> Edit</button>
                                                        </div>
                                                    </div>
                                                )}
                                            />
                                        </div>

                                        {/* Accounts Payable card  */}
                                        <div className="pfs-card">
                                            <div className="pfs-card-head d-flex justify-content-between bg-grey">
                                                <span className="label text-dark mb-0"><b>ACCOUNTS PAYABLE</b></span>
                                                <a onClick={() => {
                                                    accountsPayableFormGroup.reset()
                                                    setAccountPayableType("Add")
                                                    setAccountPayablePopup(true)
                                                }} className="lm-button3"><span>+</span> Add Accounts Payable</a>
                                            </div>
                                            <FormArrayComponent
                                                control={pfsFormGroup.get("pfsAccountPayablesDetailList")}
                                                render={(pfsValue, index) => (
                                                    <div className="row p-3 pfs-data-loop-row">
                                                        <div className="col-lg-fifth col-md-4 col-6 mb-3">
                                                            <div className="pfs-card-label">Name of Note Payer</div>
                                                            <div className="pfs-card-value">{pfsValue.value.nameOfNotePayer}</div>
                                                        </div>
                                                        <div className="col-lg-fifth-double col-md-4 col-12 mb-3">
                                                            <div className="pfs-card-label">Address</div>
                                                            <div className="pfs-card-value">{formatAddress(pfsValue.value)}</div>
                                                        </div>
                                                        <div className="col-lg-fifth col-md-4 col-6 mb-3">
                                                            <div className="pfs-card-label">Original Balance</div>
                                                            <div className="pfs-card-value">{formatNumberWithCommas(pfsValue.value.originalBalance)}</div>
                                                        </div>
                                                        <div className="col-lg-fifth col-md-4 col-6 mb-3">
                                                            <div className="pfs-card-label">Current Balance</div>
                                                            <div className="pfs-card-value">{formatNumberWithCommas(pfsValue.value.currentBalance)}</div>
                                                        </div>
                                                        <div className="col-lg-fifth col-md-4 col-6 mb-3">
                                                            <div className="pfs-card-label">Payment Amount</div>
                                                            <div className="pfs-card-value">{formatNumberWithCommas(pfsValue.value.paymentAmount)}</div>
                                                        </div>
                                                        <div className="col-lg-fifth col-md-4 col-6 mb-3">
                                                            <div className="pfs-card-label">Frequency</div>
                                                            <div className="pfs-card-value">{pfsValue.value.frequency}</div>
                                                        </div>
                                                        <div className="col-lg-fifth col-md-4 col-6 mb-3">
                                                            <div className="pfs-card-label">Maturity Date</div>
                                                            <div className="pfs-card-value">{pfsValue.value.maturityDate ? moment(pfsValue.value.maturityDate).format('ll') : ""}</div>
                                                        </div>
                                                        <div className="col-lg-fifth col-md-4 col-6 mb-3">
                                                            <div className="pfs-card-label">Secured</div>
                                                            <div className="pfs-card-value"><img src={pfsValue.value.secured == "Yes" ? pfs_card_tick : pfsValue.value.secured === "No" ? pfs_card_cross : ""} /></div>
                                                        </div>
                                                        {/* <div className="col-lg-fifth col-md-4 col-6 mb-3">
                                                            <div className="pfs-card-label">Status</div>
                                                            <div className={`pfs-card-value status ${pfsValue?.value?.status?.toLowerCase() || ''}`}>  {pfsValue?.value?.status || ''}</div>
                                                        </div> */}
                                                        <div className="col-12">
                                                            <button onClick={() => {
                                                                accountsPayableFormGroup.patchValue({
                                                                    index: index,
                                                                    streetAddress1: pfsValue.value.streetAddress1,
                                                                    city: pfsValue.value.city,
                                                                    state: pfsValue.value.state ? { label: pfsValue.value.state, value: pfsValue.value.state } : "",
                                                                    zipcode: pfsValue.value.zipcode,
                                                                    nameOfNotePayer: pfsValue.value.nameOfNotePayer,
                                                                    originalBalance: pfsValue.value.originalBalance,
                                                                    currentBalance: pfsValue.value.currentBalance,
                                                                    paymentAmount: pfsValue.value.paymentAmount,
                                                                    frequency: pfsValue.value.frequency ? { label: pfsValue.value.frequency, value: pfsValue.value.frequency } : "",
                                                                    maturityDate: pfsValue.value.maturityDate,
                                                                    secured: pfsValue.value.secured,
                                                                    status: pfsValue.value.status ? { label: pfsValue.value.status, value: pfsValue.value.status } : "",
                                                                })
                                                                setAccountPayablePopup(true)
                                                                setAccountPayableType("Edit")
                                                            }} type="button" className="lm-button2 w-100"><img src={lm_edit_icon}
                                                                alt="" /> Edit</button>
                                                        </div>
                                                    </div>
                                                )}
                                            />
                                        </div>

                                        {/* Unpaid taxes card  */}
                                        <div className="pfs-card">
                                            <div className="pfs-card-head d-flex justify-content-between bg-grey">
                                                <span className="label text-dark mb-0"><b>UNPAID TAXES</b></span>
                                                <a className="lm-button3" onClick={(e) => {
                                                    unpaidTaxBalanceFormGroup.reset()
                                                    UnpaidTaxesPopupShow();
                                                    setUnpaidTaxesType("Add")
                                                }} ><span>+</span> Add Unpaid taxes</a>
                                            </div>
                                            <FormArrayComponent
                                                control={pfsFormGroup.get("pfsUnpaidTaxesDetailsList")}
                                                render={(pfsValue, index) => (
                                                    <div className="row p-3 pfs-data-loop-row">
                                                        <div className="col-lg-fifth col-md-4 col-6 mb-3">
                                                            <div className="pfs-card-label">Type of Tax</div>
                                                            <div className="pfs-card-value">{pfsValue.value.typeOfTax}</div>
                                                        </div>
                                                        <div className="col-lg-fifth col-md-4 col-6 mb-3">
                                                            <div className="pfs-card-label">Agency</div>
                                                            <div className="pfs-card-value">{pfsValue.value.agency}</div>
                                                        </div>
                                                        <div className="col-lg-fifth col-md-4 col-6 mb-3">
                                                            <div className="pfs-card-label">Unpaid Tax Balance</div>
                                                            <div className="pfs-card-value">{formatNumberWithCommas(pfsValue.value.unpaidTaxBalance)}</div>
                                                        </div>
                                                        <div className="col-lg-fifth col-md-4 col-6 mb-3">
                                                            <div className="pfs-card-label">Due Date</div>
                                                            <div className="pfs-card-value">{pfsValue.value.dueDate ? moment(pfsValue.value.dueDate).format('ll') : ""}</div>
                                                        </div>
                                                        <div className="col-lg-fifth col-md-4 col-6 mb-3">
                                                            <div className="pfs-card-label">Lien</div>
                                                            <div className="pfs-card-value"><img src={pfsValue.value.lien == "Yes" ? pfs_card_tick : pfs_card_cross} /></div>
                                                        </div>
                                                        <div className="col-12">
                                                            <button
                                                                onClick={() => {
                                                                    unpaidTaxBalanceFormGroup.patchValue({
                                                                        index: index,
                                                                        typeOfTax: pfsValue.value.typeOfTax,
                                                                        agency: pfsValue.value.agency,
                                                                        unpaidTaxBalance: pfsValue.value.unpaidTaxBalance,
                                                                        dueDate: pfsValue.value.dueDate,
                                                                        lien: pfsValue.value.lien,
                                                                        lienType: pfsValue.value.lienType,
                                                                    })
                                                                    UnpaidTaxesPopupShow()
                                                                    setUnpaidTaxesType("Edit")
                                                                }}
                                                                type="button" className="lm-button2 w-100"><img src={lm_edit_icon}
                                                                    alt="" /> Edit</button>
                                                        </div>
                                                    </div>
                                                )}
                                            />
                                        </div>
                                        {/* Other Liabilities card  */}
                                        <div className="pfs-card">
                                            <div className="pfs-card-head d-flex justify-content-between bg-grey">
                                                <span className="label text-dark mb-0"><b>OTHER LIABILITIES</b></span>
                                                <a className="lm-button3" onClick={() => addCheckings(pfsFormGroup, 'pfsOtherLiabilitiesDetailList')}><span>+</span> Add Other Liability</a>
                                            </div>
                                            <FormArrayComponent
                                                control={pfsFormGroup.get("pfsOtherLiabilitiesDetailList")}
                                                render={(pfsValue, index) => (
                                                    <>
                                                        {
                                                            pfsValue.value.isDeleted !== 1 && (
                                                                <div className="row p-3 pfs-data-loop-row">
                                                                    <div className="col-md-4 col-lg-3">
                                                                        <div className="form-group">
                                                                            <FormField
                                                                                name={`pfsOtherLiabilitiesDetailList.${index}.liabilityType`}
                                                                                label="Liability Type"
                                                                                placeholder="Enter a Liability Type"
                                                                                id="liabilityType"
                                                                                maxlength={100}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-4 col-lg-3">
                                                                        <div className="form-group input-dollar">
                                                                            <AmountContainer
                                                                                setIsEdit={setIsEdit}
                                                                                name={`pfsOtherLiabilitiesDetailList.${index}.marketValue`}
                                                                                label="Market Value"
                                                                                placeholder="0.00"
                                                                                id="marketValue"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-1 col-lg-1">
                                                                        <div className="d-flex justify-content-center align-items-center h-100">
                                                                            <div onClick={() => removeCheckings(index, pfsFormGroup, 'pfsOtherLiabilitiesDetailList')}><img className="cursor-pointer" src={feild_minus_icon} alt="minus" /></div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        }
                                                    </>
                                                )}
                                            />
                                        </div>
                                        {/* Contingent Liabilities card  */}
                                        <div className="pfs-card">
                                            <div className="pfs-card-head d-flex justify-content-between bg-grey">
                                                <span className="label text-dark mb-0"><b>CONTINGENT LIABILITIES</b>
                                                </span>
                                                <a className="lm-button3" onClick={() => addCheckings(pfsFormGroup, 'pfsContingentLiabilitiesDetailList')}><span>+</span> Add Contingent Liabilities</a>
                                            </div>
                                            <FormArrayComponent
                                                control={pfsFormGroup.get("pfsContingentLiabilitiesDetailList")}
                                                render={(pfsValue, index) => (
                                                    <>
                                                        {
                                                            pfsValue.value.isDeleted !== 1 && (
                                                                <div className="row p-3 pfs-data-loop-row">
                                                                    <div className="col-md-4 col-lg-3">
                                                                        <div className="form-group input-dollar">
                                                                            <AmountContainer
                                                                                setIsEdit={setIsEdit}
                                                                                name={`pfsContingentLiabilitiesDetailList.${index}.asEndorser`}
                                                                                label="As Endorser or Co-Maker"
                                                                                placeholder="0.00"
                                                                                id="asEndorser"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-4 col-lg-3">
                                                                        <div className="form-group input-dollar">
                                                                            <AmountContainer
                                                                                setIsEdit={setIsEdit}
                                                                                name={`pfsContingentLiabilitiesDetailList.${index}.legalClaims`}
                                                                                label="Legal Claims or Judgements"
                                                                                placeholder="0.00"
                                                                                id="legalClaims"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-4 col-lg-3">
                                                                        <div className="form-group input-dollar">
                                                                            <AmountContainer
                                                                                setIsEdit={setIsEdit}
                                                                                name={`pfsContingentLiabilitiesDetailList.${index}.provisionForFederalIncomeTax`}
                                                                                label="Provision for Federal Income Tax"
                                                                                placeholder="0.00"
                                                                                id="provisionForFederalIncomeTax"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-4 col-lg-2">
                                                                        <div className="form-group input-dollar">
                                                                            <AmountContainer
                                                                                setIsEdit={setIsEdit}
                                                                                name={`pfsContingentLiabilitiesDetailList.${index}.otherSpecialDebt`}
                                                                                label="Other Special Debt"
                                                                                placeholder="0.00"
                                                                                id="otherSpecialDebt"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-1 col-lg-1">
                                                                        <div className="d-flex justify-content-center align-items-center h-100">
                                                                            <div onClick={() => removeCheckings(index, pfsFormGroup, 'pfsContingentLiabilitiesDetailList')}><img className="cursor-pointer" src={feild_minus_icon} alt="minus" /></div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        }
                                                    </>
                                                )}
                                            />
                                        </div>
                                        {/* Liabilities notes card  */}
                                        <div className="pfs-card">
                                            <div className="pfs-card-head d-flex justify-content-between bg-grey">
                                                <span className="label text-dark mb-0"><b>NOTES</b>
                                                </span>
                                            </div>
                                            <div className="row p-3 pfs-data-loop-row">
                                                <div className="col-md-6">
                                                    <div className="form-group mb-0">
                                                        <FieldControl
                                                            name="additionalLiabilitiesNotes"
                                                            render={({ handler, touched, hasError, meta }) => (
                                                                <textarea
                                                                    className="form-control"
                                                                    id="additionalLiabilitiesNotes"
                                                                    name='additionalLiabilitiesNotes'
                                                                    placeholder="Enter text"
                                                                    {...handler()}
                                                                    maxLength={250}
                                                                >
                                                                </textarea>
                                                            )}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* ANNUAL INCOME Section start */}
                                    <div className="lm-card">
                                        <div className="row">
                                            <div className="col-12 label text-dark mt-2 mb-4"><b>ANNUAL INCOME</b></div>
                                            <div className="col-md-4 col-lg-3">
                                                <AmountContainer
                                                    setIsEdit={setIsEdit}
                                                    name="annualIncome_SalarySelf"
                                                    label="Salary (Self)"
                                                    placeholder="0.00"
                                                    amountType="annualIncome"
                                                />
                                            </div>
                                            <div className="col-md-4 col-lg-3">
                                                <AmountContainer
                                                    setIsEdit={setIsEdit}
                                                    name="annualIncome_SalarySpouse"
                                                    label="Salary (Spouse)"
                                                    placeholder="0.00"
                                                    amountType="annualIncome"
                                                />
                                            </div>
                                            <div className="col-md-4 col-lg-3">
                                                <AmountContainer
                                                    setIsEdit={setIsEdit}
                                                    name="annualIncome_BonusCommissions"
                                                    label="Bonuses & Commissions"
                                                    placeholder="0.00"
                                                    amountType="annualIncome"
                                                />
                                            </div>
                                            <div className="col-md-4 col-lg-3">
                                                <AmountContainer
                                                    setIsEdit={setIsEdit}
                                                    name="annualIncome_RentalIncome"
                                                    label="Rental Income"
                                                    placeholder="0.00"
                                                    amountType="annualIncome"
                                                />
                                            </div>
                                            <div className="col-md-4 col-lg-3">
                                                <AmountContainer
                                                    setIsEdit={setIsEdit}
                                                    name="annualIncome_InvestmentIncome"
                                                    label="Investment Income"
                                                    placeholder="0.00"
                                                    amountType="annualIncome"
                                                />
                                            </div>
                                            <div className="col-md-4 col-lg-3">
                                                <AmountContainer
                                                    setIsEdit={setIsEdit}
                                                    name="annualIncome_PartnershipIncome"
                                                    label="Partnership Income"
                                                    placeholder="0.00"
                                                    amountType="annualIncome"
                                                />
                                            </div>
                                            <div className="col-md-4 col-lg-3">
                                                <AmountContainer
                                                    setIsEdit={setIsEdit}
                                                    name="annualIncome_DividendIncome"
                                                    label="Dividend Income"
                                                    placeholder="0.00"
                                                    amountType="annualIncome"
                                                />
                                            </div>
                                            <div className="col-md-4 col-lg-3">
                                                <AmountContainer
                                                    setIsEdit={setIsEdit}
                                                    name="annualIncome_InterestIncome"
                                                    label="Interest Income"
                                                    placeholder="0.00"
                                                    amountType="annualIncome"
                                                />
                                            </div>
                                            <div className="col-md-4 col-lg-3">
                                                <AmountContainer
                                                    setIsEdit={setIsEdit}
                                                    name="annualIncome_OtherIncomeAmount"
                                                    label="Other Income"
                                                    placeholder="0.00"
                                                    amountType="annualIncome"
                                                />
                                            </div>
                                            {/* <!--  lm common divider to break the section  --> */}
                                            <div className="lm-divider my-3 mx-3"></div>
                                            {/* <!--  lm common divider to break the section  --> */}
                                            <div className="col-12 d-flex justify-content-between">
                                                <span className="total-label">Total Income</span>
                                                <h3 className="total-amount">${calculateTotalIncome()}</h3>
                                            </div>
                                        </div>
                                    </div>

                                    {/* ANNUAL EXPENDITURE Section start */}
                                    <div className="lm-card">
                                        <div className="row">
                                            <div className="col-12 label text-dark mt-2 mb-4"><b>ANNUAL EXPENDITURE</b></div>
                                            <div className="col-md-4 col-lg-3">
                                                <AmountContainer
                                                    setIsEdit={setIsEdit}
                                                    name="annualExpenditure_MortgagePayment"
                                                    label="Mortgage Payment"
                                                    placeholder="0.00"
                                                    amountType="annualExpenditure"
                                                />
                                            </div>
                                            <div className="col-md-4 col-lg-3">
                                                <AmountContainer
                                                    setIsEdit={setIsEdit}
                                                    name="annualExpenditure_RentLeasePayment"
                                                    label="Rent / Lease Payments"
                                                    placeholder="0.00"
                                                    amountType="annualExpenditure"
                                                />
                                            </div>
                                            <div className="col-md-4 col-lg-3">
                                                <AmountContainer
                                                    setIsEdit={setIsEdit}
                                                    name="annualExpenditure_AutoPayments"
                                                    label="Auto Payments"
                                                    placeholder="0.00"
                                                    amountType="annualExpenditure"
                                                />
                                            </div>
                                            <div className="col-md-4 col-lg-3">
                                                <AmountContainer
                                                    setIsEdit={setIsEdit}
                                                    name="annualExpenditure_CreditCardPayment"
                                                    label="Credit Card Payments"
                                                    placeholder="0.00"
                                                    amountType="annualExpenditure"
                                                />
                                            </div>
                                            <div className="col-md-4 col-lg-3">
                                                <AmountContainer
                                                    setIsEdit={setIsEdit}
                                                    name="annualExpenditure_IncomeTax"
                                                    label="Income Tax (Federal, State, City)"
                                                    placeholder="0.00"
                                                    amountType="annualExpenditure"
                                                />
                                            </div>
                                            <div className="col-md-4 col-lg-3">
                                                <AmountContainer
                                                    setIsEdit={setIsEdit}
                                                    name="annualExpenditure_PropertyTax"
                                                    label="Property Tax"
                                                    placeholder="0.00"
                                                    amountType="annualExpenditure"
                                                />
                                            </div>
                                            <div className="col-md-4 col-lg-3">
                                                <AmountContainer
                                                    setIsEdit={setIsEdit}
                                                    name="annualExpenditure_InsurancePremiums"
                                                    label="Insurance Premiums (all types)"
                                                    placeholder="0.00"
                                                    amountType="annualExpenditure"
                                                />
                                            </div>
                                            <div className="col-md-4 col-lg-3">
                                                <AmountContainer
                                                    setIsEdit={setIsEdit}
                                                    name="annualExpenditure_AlimonyChildSupport"
                                                    label="Alimony / Child Support"
                                                    placeholder="0.00"
                                                    amountType="annualExpenditure"
                                                />
                                            </div>
                                            <div className="col-md-4 col-lg-3">
                                                <AmountContainer
                                                    setIsEdit={setIsEdit}
                                                    name="annualExpenditure_MedicalExpenses"
                                                    label="Medical Expenses"
                                                    placeholder="0.00"
                                                    amountType="annualExpenditure"
                                                />
                                            </div>
                                            <div className="col-md-4 col-lg-3">
                                                <AmountContainer
                                                    setIsEdit={setIsEdit}
                                                    name="annualExpenditure_LivingExpenses"
                                                    label="Living Expenses"
                                                    placeholder="0.00"
                                                    amountType="annualExpenditure"
                                                />
                                            </div>
                                            <div className="col-md-4 col-lg-3">
                                                <AmountContainer
                                                    setIsEdit={setIsEdit}
                                                    name="annualExpenditure_OtherExpensesAmount"
                                                    label="Other Expenses"
                                                    placeholder="0.00"
                                                    amountType="annualExpenditure"
                                                />
                                            </div>
                                            {/* <!--  lm common divider to break the section  --> */}
                                            <div className="lm-divider my-3 mx-3"></div>
                                            {/* <!--  lm common divider to break the section  --> */}
                                            <div className="col-12 d-flex justify-content-between">
                                                <span className="total-label">Total expenditures</span>
                                                <h3 className="total-amount">${calculateTotalExpenditures()}</h3>
                                            </div>
                                        </div>
                                    </div>

                                    {/* forecast Section start */}
                                    <div className="lm-card">
                                        <div className="row">
                                            <div className="col-12 label text-dark mt-2 mb-4"><b>FORECAST</b></div>
                                            <div className="col-12">
                                                <FieldControl
                                                    name="anticipateSignificantChanges"
                                                    render={({ handler, touched, hasError, meta }) => (
                                                        <div className="form-group">
                                                            <div className="label pb-1">
                                                                Do you anticipate any significant changes in the next 12 months?
                                                            </div>
                                                            <div className="form-check form-check-inline">
                                                                <input className="form-check-input" type="radio" name="anticipateSignificantChanges" id="anticipate_significant_changes_Yes" value="true" checked={pfsFormGroup.value.anticipateSignificantChanges}
                                                                    onChange={(e) => {
                                                                        setIsEdit(true);
                                                                        handler().onChange(true)
                                                                    }}
                                                                />
                                                                <label className="form-check-label" htmlFor="anticipate_significant_changes_Yes">Yes</label>
                                                            </div>
                                                            <div className="form-check form-check-inline">
                                                                <input className="form-check-input" type="radio" name="anticipateSignificantChanges" id="anticipate_significant_changes_No" value="false" checked={!pfsFormGroup.value.anticipateSignificantChanges}
                                                                    onChange={(e) => {
                                                                        setIsEdit(true);
                                                                        handler().onChange(false)
                                                                    }}
                                                                />
                                                                <label className="form-check-label" htmlFor="anticipate_significant_changes_No">No</label>
                                                            </div>
                                                        </div>
                                                    )}
                                                />
                                            </div>
                                            {
                                                pfsFormGroup.value.anticipateSignificantChanges &&
                                                <div className="col-lg-6">
                                                    <FormField
                                                        name="explanation"
                                                        label="Please Explain"
                                                        id="explanation"
                                                        placeholder="Enter an answer"
                                                        maxlength={250}
                                                    />
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </form>
                            )}
                        />
                        <div className="sticky-btn-footer d-flex justify-content-end">
                            <button onClick={() => { isEdit ? setPreviousTabPopup(true) : backPreviousTab() }} type="button" className="lm-button2">Back</button>
                            <button type="button" className="lm-button1 ml-3" onClick={handleSubmitButton}>Save</button>
                        </div>
                    </div>


                    {/*add Real Estate */}
                    <Modal
                        size="lg"
                        show={AddRealEstateShow}
                        onHide={() => setAddRealEstate(false)}
                        aria-labelledby="AddRealEstateLabel"
                        backdrop="static"
                        keyboard={false}
                        centered
                    >
                        <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="AddRealEstateLabel">Add Real Estate</h5>
                                    <button type="button" className="close" onClick={AddRealEstateClose}>
                                        <span aria-hidden="true"><img src={close_popup} alt="" /></span>
                                    </button>
                                </div>
                                <div className='px-4'>
                                    <div className="lm-nav-tabs radio-tabs pfs-radio-tabs">
                                        <ul className="nav nav-tabs" id="laTab" role="tablist">
                                            <li className="nav-item">
                                                <button className={`nav-link ${activeTab === "Own" ? "active" : ""}`} onClick={() => handleTabClick("Own")}>
                                                    Own
                                                </button>
                                            </li>
                                            <li className="nav-item">
                                                <button className={`nav-link ${activeTab === "Rental" ? "active" : ""}`} onClick={() => handleTabClick("Rental")}>
                                                    Rental
                                                </button>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                <div className="tab-content pt-3" id="laTabContent">
                                    <div className={`tab-pane fade ${activeTab === "Own" ? "show active" : ""}`} id="Own">
                                        <FieldGroup
                                            control={ownedRealEstateFormGroup}
                                            render={({ get, invalid }) => (
                                                <form>
                                                    <Modal.Body>
                                                        <div className="row">
                                                            <div className="col-md-6 col-lg-4">
                                                                <FieldControl
                                                                    name="propertyType"
                                                                    render={({ handler, touched, hasError, meta }) => (
                                                                        <div className="form-group">
                                                                            <label htmlFor="Real_Estate_Property_Type">Property Type<span className="asterisk">*</span></label>
                                                                            <Select
                                                                                className="basic-single"
                                                                                classNamePrefix="select"
                                                                                menuPortalTarget={document.body}
                                                                                styles={{
                                                                                    menuPortal: base => ({ ...base, zIndex: 9999 })
                                                                                }}
                                                                                ref={residenceTypeRef}
                                                                                filterOption={createFilter(filterConfig)}
                                                                                options={[
                                                                                    { label: "Primary Residence", value: "Primary Residence" },
                                                                                    { label: "Investment Property", value: "Investment Property" },
                                                                                ]}
                                                                                defaultValue={{ label: "Primary Residence", value: "Primary Residence" }}
                                                                                id='propertyType'
                                                                                name='propertyType'
                                                                                required={true}
                                                                                {...handler()}
                                                                            />
                                                                            {/* Error messages below */}
                                                                            <span className="error-message">{touched && hasError("required") && `Property type is required`} </span>
                                                                        </div>
                                                                    )}
                                                                />
                                                            </div>
                                                            <div className="col-md-6 col-lg-4">
                                                                <FormField
                                                                    name="streetAddress1"
                                                                    label="Street Address"
                                                                    id="streetAddress1"
                                                                    placeholder="Enter an Address"
                                                                    maxlength={100}
                                                                />
                                                            </div>
                                                            <div className="col-md-6 col-lg-4">
                                                                <FormField
                                                                    name="streetAddress2"
                                                                    label="Street Address 2"
                                                                    id="streetAddress2"
                                                                    placeholder="Enter an Address"
                                                                    maxlength={100}
                                                                />
                                                            </div>
                                                            <div className="col-md-6 col-lg-4">
                                                                <FormField
                                                                    name="city"
                                                                    label="City"
                                                                    id="city"
                                                                    placeholder="Enter a City"
                                                                    maxlength={100}
                                                                    onlyCharacters={true}
                                                                />
                                                            </div>
                                                            <div className="col-md-6 col-lg-4">
                                                                <FieldControl
                                                                    name="state"
                                                                    render={({ handler, touched, hasError, meta }) => (
                                                                        <div className="form-group">
                                                                            <label htmlFor="Real_Estate_State">State<span className="asterisk">*</span></label>
                                                                            <Select
                                                                                className="basic-single"
                                                                                classNamePrefix="select"
                                                                                menuPortalTarget={document.body}
                                                                                styles={{
                                                                                    menuPortal: base => ({ ...base, zIndex: 9999 })
                                                                                }}
                                                                                filterOption={createFilter(filterConfig)}
                                                                                ref={stateRef}
                                                                                options={stateList}
                                                                                id='state'
                                                                                name='state'
                                                                                {...handler()}
                                                                            />
                                                                            {/* Error messages below */}
                                                                            <span className="error-message">{touched && hasError("required") && `State is required`} </span>
                                                                        </div>
                                                                    )}
                                                                />
                                                            </div>
                                                            <div className="col-md-6 col-lg-4">
                                                                <FormField
                                                                    name="zipcode"
                                                                    label="ZIP Code"
                                                                    id="zipcode"
                                                                    placeholder="Enter a ZIP Code"
                                                                    maxlength={5}
                                                                    patternRequired={true}
                                                                    onlyNumbers={true}
                                                                />
                                                            </div>
                                                            <div className="col-md-6 col-lg-4">
                                                                <FieldControl
                                                                    name="purchaseDate"
                                                                    render={({ handler, touched, hasError, meta }) => (
                                                                        <div className="form-group input-date">
                                                                            <label htmlFor="Real_Estate_Purchase_Date">Purchase Date</label>
                                                                            <DatePicker
                                                                                showIcon
                                                                                toggleCalendarOnIconClick
                                                                                selected={handler().value ? moment(handler().value).format('MMM DD, YYYY') : ''}
                                                                                value={handler().value ? moment(handler().value).format('MMM DD, YYYY') : ''}
                                                                                isClearable={handler().value}
                                                                                dateFormat="MMM d, yyyy"
                                                                                placeholderText="Month DD, YYYY"
                                                                                id='purchaseDate'
                                                                                name='purchaseDate'
                                                                                yearDropdownItemNumber={100}
                                                                                scrollableYearDropdown
                                                                                showMonthDropdown
                                                                                showYearDropdown
                                                                                onChange={(date) => {
                                                                                    const formattedDate = date ? moment(date).format('YYYY-MM-DD') : '';
                                                                                    handler().onChange(formattedDate);
                                                                                }}
                                                                                onKeyDown={(e) => {
                                                                                    e.preventDefault();
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    )}
                                                                />
                                                            </div>
                                                            <div className="col-md-6 col-lg-4">
                                                                <AmountContainer
                                                                    setIsEdit={setIsEdit}
                                                                    name="originalCost"
                                                                    label="Original Cost"
                                                                    id="originalCost"
                                                                    placeholder="0.00"
                                                                />
                                                            </div>
                                                            <div className="col-md-6 col-lg-4">
                                                                <AmountContainer
                                                                    setIsEdit={setIsEdit}
                                                                    name="presentMarketValue"
                                                                    label="Present Market Value"
                                                                    id="presentMarketValue"
                                                                    placeholder="0.00"
                                                                />
                                                            </div>
                                                            <div className="col-md-6 col-lg-4">
                                                                <FormField
                                                                    name="nameAddressOfMortgageHolder"
                                                                    label="Mortgage Holder Name"
                                                                    id="nameAddressOfMortgageHolder"
                                                                    placeholder="Enter a Mortgage Holder Name"
                                                                    maxlength={100}
                                                                />
                                                            </div>
                                                            <div className="col-md-6 col-lg-4">
                                                                <FormField
                                                                    name="mortgageAccountNumber"
                                                                    label="Mortgage Account"
                                                                    id="mortgageAccountNumber"
                                                                    placeholder="Enter a Mortgage Account"
                                                                    maxlength={100}
                                                                    onlyNumbers={true}
                                                                />
                                                            </div>
                                                            <div className="col-md-6 col-lg-4">
                                                                <AmountContainer
                                                                    setIsEdit={setIsEdit}
                                                                    name="monthlyPayment"
                                                                    label="Monthly Payment"
                                                                    id="monthlyPayment"
                                                                    placeholder="0.00"
                                                                />
                                                            </div>
                                                            <div className="col-md-6 col-lg-4">
                                                                <AmountContainer
                                                                    setIsEdit={setIsEdit}
                                                                    name="mortgageBalance"
                                                                    label="Mortgage Balance"
                                                                    id="mortgageBalance"
                                                                    placeholder="0.00"
                                                                />
                                                            </div>
                                                            <div className="col-md-6 col-lg-4">
                                                                <FieldControl
                                                                    name="includesTaxAndInsurance"
                                                                    render={({ handler, touched, hasError, meta }) => (
                                                                        <div className="form-group">
                                                                            <div className="label pb-1">Includes Tax & Insurance
                                                                            </div>
                                                                            <div className="form-check form-check-inline">
                                                                                <input className="form-check-input" type="radio" name="includesTaxAndInsurance" id="Real_Estate_Includes_Tax_Insurance_Yes" value={true} checked={ownedRealEstateFormGroup.value.includesTaxAndInsurance} onClick={(e) => { handler().onChange(true) }} />
                                                                                <label className="form-check-label" htmlFor="Real_Estate_Includes_Tax_Insurance_Yes">Yes</label>
                                                                            </div>
                                                                            <div className="form-check form-check-inline">
                                                                                <input className="form-check-input" type="radio" name="includesTaxAndInsurance" id="Real_Estate_Includes_Tax_Insurance_No" value={false} checked={!ownedRealEstateFormGroup.value.includesTaxAndInsurance} onClick={(e) => { handler().onChange(false) }} />
                                                                                <label className="form-check-label" htmlFor="Real_Estate_Includes_Tax_Insurance_No">No</label>
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                />
                                                            </div>
                                                            <div className="col-md-6 col-lg-4">
                                                                <FieldControl
                                                                    name="status"
                                                                    render={({ handler, touched, hasError, meta }) => (
                                                                        <div className="form-group">
                                                                            <label htmlFor="Real_estate_Status">Status</label>
                                                                            <Select
                                                                                className="basic-single"
                                                                                classNamePrefix="select"
                                                                                menuPortalTarget={document.body}
                                                                                styles={{
                                                                                    menuPortal: base => ({ ...base, zIndex: 9999 })
                                                                                }}
                                                                                ref={statusRef}
                                                                                filterOption={createFilter(filterConfig)}
                                                                                options={[
                                                                                    { label: "Current", value: "Current" },
                                                                                    { label: "Delinquent", value: "Delinquent" },
                                                                                ]}
                                                                                id='status'
                                                                                name='status'
                                                                                {...handler()}
                                                                            />
                                                                        </div>
                                                                    )}
                                                                />
                                                            </div>
                                                            <div className="col-md-6 col-lg-4">
                                                                <FieldControl
                                                                    name="isAdditionalProperty"
                                                                    render={({ handler, touched, hasError, meta }) => (
                                                                        <div className="form-group">
                                                                            <div className="label pb-1">Do you rent this property to someone?
                                                                            </div>
                                                                            <div className="form-check form-check-inline">
                                                                                <input
                                                                                    className="form-check-input"
                                                                                    type="radio"
                                                                                    name="isAdditionalProperty"
                                                                                    id="rent_property_Yes"
                                                                                    value={true}
                                                                                    checked={ownedRealEstateFormGroup.value.isAdditionalProperty}
                                                                                    onChange={(e) => { handler().onChange(true) }}
                                                                                />

                                                                                <label className="form-check-label" htmlFor="rent_property_Yes">Yes</label>
                                                                            </div>
                                                                            <div className="form-check form-check-inline">
                                                                                <input
                                                                                    className="form-check-input"
                                                                                    type="radio"
                                                                                    name="isAdditionalProperty"
                                                                                    id="rent_property_No"
                                                                                    value={false}
                                                                                    checked={!ownedRealEstateFormGroup.value.isAdditionalProperty}
                                                                                    onChange={(e) => { handler().onChange(false) }}
                                                                                />
                                                                                <label className="form-check-label" htmlFor="rent_property_No">No</label>
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                />
                                                            </div>
                                                            {ownedRealEstateFormGroup.value.isAdditionalProperty && (
                                                                <div className="col-md-6 col-lg-4">
                                                                    <AmountContainer
                                                                        setIsEdit={setIsEdit}
                                                                        name="monthlyNetRentalIncome"
                                                                        label="Monthly Rental Income"
                                                                        id="monthlyNetRentalIncome"
                                                                        placeholder="0.00"
                                                                    />
                                                                </div>
                                                            )}
                                                        </div>
                                                    </Modal.Body>
                                                    <div className="modal-footer la-app-modal-footer">
                                                        <button type="button" onClick={(e) => handleAddFormModal(e, "pfsOwnedRealEstateDetailsList", ownedRealEstateFormGroup, AddRealEstateClose, "Owned")} className="lm-button1">Save</button>
                                                    </div>
                                                </form>
                                            )}
                                        />
                                    </div>
                                    <div className={`tab-pane fade ${activeTab === "Rental" ? "show active" : ""}`} id="Rental">
                                        <FieldGroup
                                            control={rentedRealEstateFormGroup}
                                            render={({ get, invalid }) => (
                                                <form>
                                                    <Modal.Body>
                                                        <div className="row">
                                                            <div className="col-12 mb-3">
                                                                If you rent your primary residence, please share the following information:
                                                            </div>
                                                            <div className="col-md-6 col-12">
                                                                <AmountContainer
                                                                    setIsEdit={setIsEdit}
                                                                    name="monthlyNetRentalIncome"
                                                                    label="Monthly Rent"
                                                                    id="rent_monthlyNetRentalIncome"
                                                                    placeholder="0.00"
                                                                    required={true}
                                                                    errorMessage="Monthly Rent is required"
                                                                />
                                                            </div>
                                                            <div className="col-md-6 col-12">
                                                                <FormField
                                                                    name="streetAddress1"
                                                                    label="Street Address"
                                                                    id="streetAddress1"
                                                                    placeholder="Enter an Address"
                                                                    maxlength={100}
                                                                />
                                                            </div>
                                                            <div className="col-md-6 col-12">
                                                                <FormField
                                                                    name="city"
                                                                    label="City"
                                                                    id="city"
                                                                    placeholder="Enter a City"
                                                                    maxlength={100}
                                                                    onlyCharacters={true}
                                                                />
                                                            </div>
                                                            <div className="col-md-6 col-12">
                                                                <FieldControl
                                                                    name="state"
                                                                    render={({ handler, touched, hasError, meta }) => (
                                                                        <div className="form-group">
                                                                            <label htmlFor="Rental_State">State</label>
                                                                            <Select
                                                                                className="basic-single"
                                                                                classNamePrefix="select"
                                                                                menuPortalTarget={document.body}
                                                                                styles={{
                                                                                    menuPortal: base => ({ ...base, zIndex: 9999 })
                                                                                }}
                                                                                filterOption={createFilter(filterConfig)}
                                                                                options={stateList}
                                                                                // defaultValue={{ label: "Texas", value: "Texas" }}
                                                                                id='state'
                                                                                name='state'
                                                                                {...handler()}
                                                                            />
                                                                        </div>
                                                                    )}
                                                                />
                                                            </div>
                                                            <div className="col-md-6 col-12">
                                                                <FormField
                                                                    name="zipcode"
                                                                    label="ZIP Code"
                                                                    id="zipcode"
                                                                    placeholder="Enter a ZIP Code"
                                                                    maxlength={5}
                                                                    patternRequired={true}
                                                                    onlyNumbers={true}
                                                                />
                                                            </div>
                                                        </div>
                                                    </Modal.Body>
                                                    <div className="modal-footer la-app-modal-footer">
                                                        <button type="button" onClick={(e) => handleAddFormModal(e, "pfsOwnedRealEstateDetailsList", rentedRealEstateFormGroup, AddRealEstateClose, "Rental")} className="lm-button1">Save</button>
                                                    </div>
                                                </form>
                                            )}
                                        />
                                    </div>
                                </div>

                            </div>
                        </div>
                    </Modal>
                    {/*add Real Estate */}

                    {/*edit own Real Estate */}
                    <Modal
                        size="lg"
                        show={EditOwnRealEstateShow}
                        onHide={() => setEditOwnRealEstate(false)}
                        aria-labelledby="EditOwnRealEstateLabel"
                        backdrop="static"
                        keyboard={false}
                        centered
                    >
                        <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="EditOwnRealEstateLabel">Edit Real Estate</h5>
                                    <button type="button" className="close" onClick={EditOwnRealEstateClose}>
                                        <span aria-hidden="true"><img src={close_popup} alt="" /></span>
                                    </button>
                                </div>
                                <FieldGroup
                                    control={ownedRealEstateFormGroup}
                                    render={({ get, invalid }) => (
                                        <form>
                                            <Modal.Body>
                                                <div className="row">
                                                    <div className="col-md-6 col-lg-4">
                                                        <FieldControl
                                                            name="propertyType"
                                                            render={({ handler, touched, hasError, meta }) => (
                                                                <div className="form-group">
                                                                    <label htmlFor="Real_Estate_Property_Type">Property Type</label>
                                                                    <Select
                                                                        className="basic-single"
                                                                        classNamePrefix="select"
                                                                        menuPortalTarget={document.body}
                                                                        styles={{
                                                                            menuPortal: base => ({ ...base, zIndex: 9999 })
                                                                        }}
                                                                        options={[
                                                                            { label: "Primary Residence", value: "Primary Residence" },
                                                                            { label: "Investment Property", value: "Investment Property" },
                                                                        ]}
                                                                        filterOption={createFilter(filterConfig)}
                                                                        // defaultValue={{ label: "Primary Residence", value: "Primary Residence" }}
                                                                        id='propertyType'
                                                                        name='propertyType'
                                                                        {...handler()}
                                                                    />
                                                                </div>
                                                            )}
                                                        />
                                                    </div>
                                                    <div className="col-md-6 col-lg-4">
                                                        <FormField
                                                            name="streetAddress1"
                                                            label="Street Address"
                                                            id="streetAddress1"
                                                            placeholder="Enter an Address"
                                                            maxlength={100}
                                                        />
                                                    </div>
                                                    <div className="col-md-6 col-lg-4">
                                                        <FormField
                                                            name="streetAddress2"
                                                            label="Street Address 2"
                                                            id="streetAddress2"
                                                            placeholder="Enter an Address"
                                                            maxlength={100}
                                                        />
                                                    </div>
                                                    <div className="col-md-6 col-lg-4">
                                                        <FormField
                                                            name="city"
                                                            label="City"
                                                            id="city"
                                                            placeholder="Enter a City"
                                                            maxlength={100}
                                                            onlyCharacters={true}
                                                        />
                                                    </div>
                                                    <div className="col-md-6 col-lg-4">
                                                        <FieldControl
                                                            name="state"
                                                            render={({ handler, touched, hasError, meta }) => (
                                                                <div className="form-group">
                                                                    <label htmlFor="Real_Estate_State">State</label>
                                                                    <Select
                                                                        className="basic-single"
                                                                        classNamePrefix="select"
                                                                        menuPortalTarget={document.body}
                                                                        styles={{
                                                                            menuPortal: base => ({ ...base, zIndex: 9999 })
                                                                        }}
                                                                        options={stateList}
                                                                        filterOption={createFilter(filterConfig)}
                                                                        id='state'
                                                                        name='state'
                                                                        {...handler()}
                                                                    />
                                                                </div>
                                                            )}
                                                        />
                                                    </div>
                                                    <div className="col-md-6 col-lg-4">
                                                        <FormField
                                                            name="zipcode"
                                                            label="ZIP Code"
                                                            id="zipcode"
                                                            placeholder="Enter a ZIP Code"
                                                            maxlength={5}
                                                            patternRequired={true}
                                                            onlyNumbers={true}
                                                        />
                                                    </div>
                                                    <div className="col-md-6 col-lg-4">
                                                        <FieldControl
                                                            name="purchaseDate"
                                                            render={({ handler, touched, hasError, meta }) => (
                                                                <div className="form-group input-date">
                                                                    <label htmlFor="Real_Estate_Purchase_Date">Purchase Date</label>
                                                                    <DatePicker
                                                                        showIcon
                                                                        toggleCalendarOnIconClick
                                                                        selected={handler().value ? moment(handler().value).format('MMM DD, YYYY') : ''}
                                                                        value={handler().value ? moment(handler().value).format('MMM DD, YYYY') : ''}
                                                                        isClearable={handler().value}
                                                                        dateFormat="MMM d, yyyy"
                                                                        placeholderText="Month DD, YYYY"
                                                                        id='purchaseDate'
                                                                        name='purchaseDate'
                                                                        yearDropdownItemNumber={100}
                                                                        scrollableYearDropdown
                                                                        showMonthDropdown
                                                                        showYearDropdown
                                                                        onChange={(date) => {
                                                                            const formattedDate = date ? moment(date).format('YYYY-MM-DD') : '';
                                                                            handler().onChange(formattedDate);
                                                                        }}
                                                                        onKeyDown={(e) => {
                                                                            e.preventDefault();
                                                                        }}
                                                                    />
                                                                </div>
                                                            )}
                                                        />
                                                    </div>
                                                    <div className="col-md-6 col-lg-4">
                                                        <AmountContainer
                                                            setIsEdit={setIsEdit}
                                                            name="originalCost"
                                                            label="Original Cost"
                                                            id="originalCost"
                                                            placeholder="0.00"
                                                        />
                                                    </div>
                                                    <div className="col-md-6 col-lg-4">
                                                        <AmountContainer
                                                            setIsEdit={setIsEdit}
                                                            name="presentMarketValue"
                                                            label="Present Market Value"
                                                            id="presentMarketValue"
                                                            placeholder="0.00"
                                                        />
                                                    </div>
                                                    <div className="col-md-6 col-lg-4">
                                                        <FormField
                                                            name="nameAddressOfMortgageHolder"
                                                            label="Mortgage Holder Name"
                                                            id="nameAddressOfMortgageHolder"
                                                            placeholder="Enter a Mortgage Holder Name"
                                                            maxlength={100}
                                                        />
                                                    </div>
                                                    <div className="col-md-6 col-lg-4">
                                                        <FormField
                                                            name="mortgageAccountNumber"
                                                            label="Mortgage Account"
                                                            id="mortgageAccountNumber"
                                                            placeholder="Enter a Mortgage Account"
                                                            maxlength={100}
                                                        />
                                                    </div>
                                                    <div className="col-md-6 col-lg-4">
                                                        <AmountContainer
                                                            setIsEdit={setIsEdit}
                                                            name="monthlyPayment"
                                                            label="Monthly Payment"
                                                            id="monthlyPayment"
                                                            placeholder="0.00"
                                                        />
                                                    </div>
                                                    <div className="col-md-6 col-lg-4">
                                                        <AmountContainer
                                                            setIsEdit={setIsEdit}
                                                            name="mortgageBalance"
                                                            label="Mortgage Balance"
                                                            id="mortgageBalance"
                                                            placeholder="0.00"
                                                        />
                                                    </div>
                                                    <div className="col-md-6 col-lg-4">
                                                        <FieldControl
                                                            name="includesTaxAndInsurance"
                                                            render={({ handler, touched, hasError, meta }) => (
                                                                <div className="form-group">
                                                                    <div className="label pb-1">Includes Tax & Insurance
                                                                    </div>
                                                                    <div className="form-check form-check-inline">
                                                                        <input className="form-check-input" type="radio" name="includesTaxAndInsurance" id="Real_Estate_Includes_Tax_Insurance_Yes" value="true" checked={ownedRealEstateFormGroup.value.includesTaxAndInsurance} onChange={(e) => { handler().onChange(true) }} />
                                                                        <label className="form-check-label" htmlFor="Real_Estate_Includes_Tax_Insurance_Yes">Yes</label>
                                                                    </div>
                                                                    <div className="form-check form-check-inline">
                                                                        <input className="form-check-input" type="radio" name="includesTaxAndInsurance" id="Real_Estate_Includes_Tax_Insurance_No" value="false" checked={!ownedRealEstateFormGroup.value.includesTaxAndInsurance} onChange={(e) => { handler().onChange(false) }} />
                                                                        <label className="form-check-label" htmlFor="Real_Estate_Includes_Tax_Insurance_No">No</label>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        />
                                                    </div>
                                                    <div className="col-md-6 col-lg-4">
                                                        <FieldControl
                                                            name="status"
                                                            render={({ handler, touched, hasError, meta }) => (
                                                                <div className="form-group">
                                                                    <label htmlFor="Real_estate_Status">Status</label>
                                                                    <Select
                                                                        className="basic-single"
                                                                        classNamePrefix="select"
                                                                        menuPortalTarget={document.body}
                                                                        styles={{
                                                                            menuPortal: base => ({ ...base, zIndex: 9999 })
                                                                        }}
                                                                        options={[
                                                                            { label: "Current", value: "Current" },
                                                                            { label: "Delinquent", value: "Delinquent" },
                                                                        ]}
                                                                        filterOption={createFilter(filterConfig)}
                                                                        id='status'
                                                                        name='status'
                                                                        {...handler()}
                                                                    />
                                                                </div>
                                                            )}
                                                        />
                                                    </div>
                                                    <div className="col-md-6 col-lg-4">
                                                        <FieldControl
                                                            name="isAdditionalProperty"
                                                            render={({ handler, touched, hasError, meta }) => (
                                                                <div className="form-group">
                                                                    <div className="label pb-1">Do you rent this property to someone?
                                                                    </div>
                                                                    <div className="form-check form-check-inline">
                                                                        <input className="form-check-input" type="radio" name="isAdditionalProperty" id="rent_property_Yes" value={true} checked={ownedRealEstateFormGroup.value.isAdditionalProperty} onChange={(e) => { handler().onChange(true) }} />
                                                                        <label className="form-check-label" htmlFor="rent_property_Yes">Yes</label>
                                                                    </div>
                                                                    <div className="form-check form-check-inline">
                                                                        <input className="form-check-input" type="radio" name="isAdditionalProperty" id="rent_property_No" value={false} checked={!ownedRealEstateFormGroup.value.isAdditionalProperty} onChange={(e) => { handler().onChange(false) }} />
                                                                        <label className="form-check-label" htmlFor="rent_property_No">No</label>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        />
                                                    </div>
                                                    {ownedRealEstateFormGroup.value.isAdditionalProperty && (
                                                        <div className="col-md-6 col-lg-4">
                                                            <AmountContainer
                                                                setIsEdit={setIsEdit}
                                                                name="monthlyNetRentalIncome"
                                                                label="Monthly Rental Income"
                                                                id="monthlyNetRentalIncome"
                                                                placeholder="0.00"
                                                            />
                                                        </div>
                                                    )}
                                                </div>
                                            </Modal.Body>
                                            <div className="modal-footer la-app-modal-footer">
                                                <button type="button" className="lm-button2 text-red" onClick={(e) => handleRemoveFormModal(e, "pfsOwnedRealEstateDetailsList", ownedRealEstateFormGroup.value.index, EditOwnRealEstateClose)} >Remove</button>
                                                <button type="button" className="lm-button1" onClick={(e) => { handleEditFormModal(e, "pfsOwnedRealEstateDetailsList", ownedRealEstateFormGroup, EditOwnRealEstateClose) }}>Save</button>
                                            </div>
                                        </form>
                                    )}
                                />
                            </div>
                        </div>
                    </Modal>
                    {/*edit own Real Estate */}

                    {/*edit rental Real Estate */}
                    <Modal
                        size="md"
                        show={EditRentalRealEstateShow}
                        onHide={() => setEditRentalRealEstate(false)}
                        aria-labelledby="EditRentalRealEstateLabel"
                        backdrop="static"
                        keyboard={false}
                        centered
                    >
                        <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="EditRentalRealEstateLabel">Edit Real Estate</h5>
                                    <button type="button" className="close" onClick={EditRentalRealEstateClose}>
                                        <span aria-hidden="true"><img src={close_popup} alt="" /></span>
                                    </button>
                                </div>
                                <FieldGroup
                                    control={rentedRealEstateFormGroup}
                                    render={({ get, invalid }) => (
                                        <form>
                                            <Modal.Body>
                                                <div className="row">
                                                    <div className="col-md-6 col-12">
                                                        <div className="form-group input-dollar">
                                                            <AmountContainer
                                                                setIsEdit={setIsEdit}
                                                                name="monthlyNetRentalIncome"
                                                                label="Monthly Rent"
                                                                id="monthlyNetRentalIncome"
                                                                placeholder="0.00"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 col-12">
                                                        <div className="form-group">
                                                            <FormField
                                                                name="streetAddress1"
                                                                label="Street Address"
                                                                id="streetAddress1"
                                                                placeholder="Enter an Address"
                                                                maxlength={100}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 col-12">
                                                        <div className="form-group">
                                                            <FormField
                                                                name="city"
                                                                label="City"
                                                                id="Update_Rental_City"
                                                                placeholder="Enter a City"
                                                                maxlength={100}
                                                                onlyCharacters={true}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 col-12">
                                                        <FieldControl
                                                            name="state"
                                                            render={({ handler, touched, hasError, meta }) => (
                                                                <div className="form-group">
                                                                    <label htmlFor="Update_Rental_State">State </label>
                                                                    <Select
                                                                        className="basic-single"
                                                                        classNamePrefix="select"
                                                                        menuPortalTarget={document.body}
                                                                        styles={{
                                                                            menuPortal: base => ({ ...base, zIndex: 9999 })
                                                                        }}
                                                                        options={stateList}
                                                                        filterOption={createFilter(filterConfig)} id='state'
                                                                        name='state'
                                                                        {...handler()}
                                                                    />
                                                                </div>
                                                            )}
                                                        />
                                                    </div>
                                                    <div className="col-md-6 col-12">
                                                        <div className="form-group">
                                                            <FormField
                                                                name="zipcode"
                                                                label="ZIP Code"
                                                                id="Update_Rental_ZIP_Code"
                                                                placeholder="Enter a ZIP Code"
                                                                maxlength={5}
                                                                patternRequired={true}
                                                                onlyNumbers={true}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </Modal.Body>
                                            <div className="modal-footer la-app-modal-footer">
                                                <button type="button" className="lm-button2 text-red" onClick={(e) => handleRemoveFormModal(e, "pfsOwnedRealEstateDetailsList", rentedRealEstateFormGroup.value.index, EditRentalRealEstateClose)} >Remove</button>
                                                <button type="button" className="lm-button1" onClick={(e) => { handleEditFormModal(e, "pfsOwnedRealEstateDetailsList", rentedRealEstateFormGroup, EditRentalRealEstateClose) }}>Save</button>
                                            </div>
                                        </form>
                                    )}
                                />
                            </div>
                        </div>
                    </Modal>
                    {/*edit rental Real Estate */}

                    {/*add / edit Automobile */}
                    <Modal
                        size="md"
                        show={AutomobilePopupShow}
                        onHide={() => setAutomobilePopup(false)}
                        aria-labelledby="AutomobilePopupLabel"
                        backdrop="static"
                        keyboard={false}
                        centered
                    >
                        <div className="modal-dialog modal-md modal-dialog-centered modal-dialog-scrollable">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="AutomobilePopupLabel">{autoMobileType} Automobile</h5>
                                    <button type="button" className="close" onClick={AutomobilePopupClose}>
                                        <span aria-hidden="true"><img src={close_popup} alt="" /></span>
                                    </button>
                                </div>
                                <FieldGroup
                                    control={automobileFormGroup}
                                    render={({ get, invalid }) => (
                                        <form>
                                            <Modal.Body>
                                                <div className="row">
                                                    <div className="col-md-6 col-12">
                                                        <FieldControl
                                                            name="year"
                                                            render={({ handler, touched, hasError, meta }) => (
                                                                <div className="form-group">
                                                                    <label htmlFor="Automobile_Year">Year <span className="asterisk">*</span></label>
                                                                    <Select
                                                                        className="basic-single"
                                                                        classNamePrefix="select"
                                                                        menuPortalTarget={document.body}
                                                                        styles={{
                                                                            menuPortal: base => ({ ...base, zIndex: 9999 })
                                                                        }}
                                                                        ref={yearRef}
                                                                        options={autoMobileYears}
                                                                        defaultValue={autoMobileYears[50]}
                                                                        filterOption={createFilter(filterConfig)}
                                                                        id='year'
                                                                        name='year'
                                                                        {...handler()}
                                                                    />
                                                                    {/* Error messages below */}
                                                                    <span className="error-message">{touched && hasError("required") && `Year is required`} </span>
                                                                </div>
                                                            )}
                                                        />
                                                    </div>
                                                    <div className="col-md-6 col-12">
                                                        <FormField
                                                            name="make"
                                                            label="Make"
                                                            id="make"
                                                            placeholder="Enter make"
                                                            maxlength={100}
                                                        />
                                                    </div>
                                                    <div className="col-md-6 col-12">
                                                        <FormField
                                                            name="model"
                                                            label="Model"
                                                            id="model"
                                                            placeholder="Enter model"
                                                            maxlength={100}
                                                        />
                                                    </div>
                                                    <div className="col-md-6 col-12">
                                                        <AmountContainer
                                                            setIsEdit={setIsEdit}
                                                            name="marketValue"
                                                            label="Market value"
                                                            id="marketValue"
                                                            placeholder="0.00"
                                                        />
                                                    </div>
                                                    <div className="col-md-6 col-12">
                                                        <FormField
                                                            name="vin"
                                                            label="VIN (optional)"
                                                            id="vin"
                                                            placeholder="Enter a VIN"
                                                            maxlength={100}
                                                        />
                                                    </div>
                                                    <div className="col-md-6 col-12">
                                                        <AmountContainer
                                                            setIsEdit={setIsEdit}
                                                            name="outstandingLoanBalance"
                                                            label="Outstanding balance"
                                                            id="outstandingLoanBalance"
                                                            placeholder="0.00"
                                                        />
                                                    </div>
                                                    <div className="col-md-6 col-12">
                                                        <AmountContainer
                                                            setIsEdit={setIsEdit}
                                                            name="monthlyPayment"
                                                            label="Monthly Pay"
                                                            id="monthlyPayment"
                                                            placeholder="0.00"
                                                        />
                                                    </div>
                                                    <div className="col-md-6 col-12">
                                                        <FormField
                                                            name="lender"
                                                            label="Lender"
                                                            id="lender"
                                                            placeholder="Enter text"
                                                            maxlength={100}
                                                        />
                                                    </div>
                                                    <div className="col-md-6 col-12">
                                                        <FieldControl
                                                            name="status"
                                                            render={({ handler, touched, hasError, meta }) => (
                                                                <div className="form-group">
                                                                    <label htmlFor="Automobile_Status">Status</label>
                                                                    <Select
                                                                        className="basic-single"
                                                                        classNamePrefix="select"
                                                                        menuPortalTarget={document.body}
                                                                        styles={{
                                                                            menuPortal: base => ({ ...base, zIndex: 9999 })
                                                                        }}
                                                                        options={[
                                                                            { label: "Current", value: "Current" },
                                                                            { label: "Delinquent", value: "Delinquent" },
                                                                        ]}
                                                                        defaultValue={{ label: "Current", value: "Current" }}
                                                                        filterOption={createFilter(filterConfig)}
                                                                        id='status'
                                                                        name='status'
                                                                        {...handler()}
                                                                    />
                                                                </div>
                                                            )}
                                                        />
                                                    </div>
                                                </div>
                                            </Modal.Body>
                                            <div className="modal-footer la-app-modal-footer">
                                                {autoMobileType == "Edit" ?
                                                    (
                                                        <>
                                                            <button type="button" className="lm-button2 text-red" onClick={(e) => handleRemoveFormModal(e, "pfsAutomobileDetailsList", automobileFormGroup.value.index, AutomobilePopupClose)} >Remove</button>
                                                            <button type="button" className="lm-button1" onClick={(e) => { handleEditFormModal(e, "pfsAutomobileDetailsList", automobileFormGroup, AutomobilePopupClose) }}>Save</button>
                                                        </>
                                                    ) :
                                                    <button type="button" onClick={(e) => handleAddFormModal(e, "pfsAutomobileDetailsList", automobileFormGroup, AutomobilePopupClose)} className="lm-button1">Save</button>
                                                }
                                            </div>
                                        </form>
                                    )}
                                />
                            </div>
                        </div>
                    </Modal>
                    {/*add / Edit Notes Payable To Banks & Institution */}

                    {/*add / edit Marketable security */}
                    {/* This is customized modal used for datepicker isuue purpose don't use this anywhere else */}
                    <div className={`custom-modal cust-modal-md modal-open modal-backdrop ${MarketablePopupShow ? "cust-modal-show" : "cust-modal-hide"}`}>
                        <div className="modal-box">
                            <div className="modal-header">
                                <h5 className="modal-title" id="MarketablePopupLabel">{marketableType} Marketable security</h5>
                                <button type="button" className="close" onClick={MarketablePopupClose}>
                                    <span aria-hidden="true"><img src={close_popup} alt="" /></span>
                                </button>
                            </div>
                            <FieldGroup
                                control={marketableSecuritiesFormGroup}
                                render={({ get, invalid }) => (
                                    <form>
                                        <Modal.Body>
                                            <div className="row">
                                                <div className="col-md-6 col-12">
                                                    <AmountContainer
                                                        setIsEdit={setIsEdit}
                                                        name="numberOfShares"
                                                        label="Number of Shares"
                                                        id="numberOfShares"
                                                        placeholder="Enter a Number of Shares"
                                                        required={true}
                                                        errorMessage={"Number of Shares is required"}
                                                        withoutDollar={true}
                                                    />
                                                </div>
                                                <div className="col-md-6 col-12">
                                                    <FormField
                                                        name="name"
                                                        label="Name"
                                                        id="name"
                                                        placeholder="Enter a Name of Marketable"
                                                        maxlength={100}
                                                    />
                                                </div>
                                                <div className="col-md-6 col-12">
                                                    <AmountContainer
                                                        setIsEdit={setIsEdit}
                                                        name="originalCost"
                                                        label="Original Cost"
                                                        id="originalCost"
                                                        placeholder="0.00"
                                                    />
                                                </div>
                                                <div className="col-md-6 col-12">
                                                    <AmountContainer
                                                        setIsEdit={setIsEdit}
                                                        name="totalValue"
                                                        label="Total Value"
                                                        id="totalValue"
                                                        placeholder="0.00"
                                                    />
                                                </div>
                                                <div className="col-md-6 col-12">
                                                    <FieldControl
                                                        name="dateOfValue"
                                                        render={({ handler, touched, hasError, meta }) => (
                                                            <div className="form-group input-date">
                                                                <label htmlFor="Marketable_Date_Valuation">Date of Valuation</label>
                                                                <DatePicker
                                                                    showIcon
                                                                    toggleCalendarOnIconClick
                                                                    selected={handler().value ? moment(handler().value).format('MMM DD, YYYY') : ''}
                                                                    value={handler().value ? moment(handler().value).format('MMM DD, YYYY') : ''}
                                                                    isClearable={handler().value}
                                                                    dateFormat="MMM d, yyyy"
                                                                    placeholderText="Month DD, YYYY"
                                                                    id='dateofaluation'
                                                                    name='dateofaluation'
                                                                    onChange={(date) => {
                                                                        const formattedDate = date ? moment(date).format('YYYY-MM-DD') : '';
                                                                        handler().onChange(formattedDate);
                                                                    }}
                                                                    yearDropdownItemNumber={100}
                                                                    scrollableYearDropdown
                                                                    showMonthDropdown
                                                                    showYearDropdown
                                                                    onKeyDown={(e) => {
                                                                        e.preventDefault();
                                                                    }}
                                                                />
                                                            </div>
                                                        )}
                                                    />
                                                </div>
                                                <div className="col-md-6 col-12">
                                                    <AmountContainer
                                                        setIsEdit={setIsEdit}
                                                        name="marketValue"
                                                        label="Present Market Value"
                                                        id="marketValue"
                                                        placeholder="0.00"
                                                    />
                                                </div>
                                                <div className="col-md-6 col-12">
                                                    <FieldControl
                                                        name="pledged"
                                                        render={({ handler, touched, hasError, meta }) => (
                                                            <div className="form-group">
                                                                <div className="label pb-1">Pledged</div>
                                                                <div className="form-check form-check-inline">
                                                                    <input className="form-check-input" type="radio" name="pledged" id="Marketable_Pledged_Yes" value="Yes" checked={marketableSecuritiesFormGroup.value.pledged === "Yes"} onChange={(e) => { handler().onChange("Yes") }} />
                                                                    <label className="form-check-label" htmlFor="Marketable_Pledged_Yes">Yes</label>
                                                                </div>
                                                                <div className="form-check form-check-inline">
                                                                    <input className="form-check-input" type="radio" name="pledged" id="Marketable_Pledged_No" value="No" checked={marketableSecuritiesFormGroup.value.pledged === "No"} onChange={(e) => { handler().onChange("No") }} />
                                                                    <label className="form-check-label" htmlFor="Marketable_Pledged_No">No</label>
                                                                </div>
                                                            </div>
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                        </Modal.Body>
                                        <div className="modal-footer la-app-modal-footer">
                                            {marketableType == "Edit" ? (
                                                <>
                                                    <button type="button" className="lm-button2 text-red" onClick={(e) => handleRemoveFormModal(e, "pfsMarketableSecuritiesDetailsList", marketableSecuritiesFormGroup.value.index, MarketablePopupClose)} >Remove</button>
                                                    <button type="button" className="lm-button1" onClick={(e) => { handleEditFormModal(e, "pfsMarketableSecuritiesDetailsList", marketableSecuritiesFormGroup, MarketablePopupClose) }}>Save</button>
                                                </>
                                            ) :
                                                <button type="button" onClick={(e) => handleAddFormModal(e, "pfsMarketableSecuritiesDetailsList", marketableSecuritiesFormGroup, MarketablePopupClose)} className="lm-button1">Save</button>
                                            }
                                        </div>
                                    </form>
                                )}
                            />
                        </div>
                    </div>
                    {/* add / edit Marketable security */}

                    {/* add/ Edit Account / Note */}
                    {/* This is customized modal used for datepicker isuue purpose don't use this anywhere else */}
                    <div className={`custom-modal cust-modal-lg modal-open modal-backdrop ${AccountNotePopupShow ? "cust-modal-show" : "cust-modal-hide"}`}>
                        <div className="modal-box">
                            <div className="modal-header">
                                <h5 className="modal-title" id="AccountNotePopupLabel">{accountNoteType} Account / Note</h5>
                                <button type="button" className="close" onClick={AccountNotePopupClose}>
                                    <span aria-hidden="true"><img src={close_popup} alt="" /></span>
                                </button>
                            </div>
                            <FieldGroup
                                control={accountReceivablesFormGroup}
                                render={({ get, invalid }) => (
                                    <form>
                                        <Modal.Body>
                                            <div className="row">
                                                <div className="col-md-6 col-lg-4">
                                                    <FormField
                                                        name="nameOfNotePayer"
                                                        label="Name of Note Payer"
                                                        id="nameOfNotePayer"
                                                        placeholder="Enter a Name of Note Payer"
                                                        required={true}
                                                        errorMessage={"Name of Note Payer is required"}
                                                        maxlength={100}
                                                    />
                                                </div>
                                                <div className="col-md-6 col-lg-4">
                                                    <FormField
                                                        name="streetAddress1"
                                                        label="Street Address"
                                                        id="streetAddress1"
                                                        placeholder="Enter an Address"
                                                        maxlength={100}
                                                    />
                                                </div>
                                                <div className="col-md-6 col-lg-4">
                                                    <FormField
                                                        name="city"
                                                        label="City"
                                                        id="city"
                                                        placeholder="Enter a City"
                                                        maxlength={100}
                                                        onlyCharacters={true}
                                                    />
                                                </div>
                                                <div className="col-md-6 col-lg-4">
                                                    <FieldControl
                                                        name="state"
                                                        render={({ handler, touched, hasError, meta }) => (
                                                            <div className="form-group">
                                                                <label htmlFor="Account_State">State</label>
                                                                <Select
                                                                    className="basic-single"
                                                                    classNamePrefix="select"
                                                                    menuPortalTarget={document.body}
                                                                    styles={{
                                                                        menuPortal: base => ({ ...base, zIndex: 9999 })
                                                                    }}
                                                                    options={stateList}
                                                                    filterOption={createFilter(filterConfig)} id='state'
                                                                    name='state'
                                                                    {...handler()}
                                                                />
                                                            </div>
                                                        )}
                                                    />
                                                </div>
                                                <div className="col-md-6 col-lg-4">
                                                    <FormField
                                                        name="zipcode"
                                                        label="ZIP Code"
                                                        id="zipcode"
                                                        placeholder="Enter a ZIP Code"
                                                        maxlength={5}
                                                        patternRequired={true}
                                                        onlyNumbers={true}
                                                    />
                                                </div>
                                                <div className="col-md-6 col-lg-4">
                                                    <AmountContainer
                                                        setIsEdit={setIsEdit}
                                                        name="originalBalance"
                                                        label="Original Balance"
                                                        id="originalBalance"
                                                        placeholder="0.00"
                                                    />
                                                </div>
                                                <div className="col-md-6 col-lg-4">
                                                    <AmountContainer
                                                        setIsEdit={setIsEdit}
                                                        name="currentBalance"
                                                        label="Current Balance"
                                                        id="currentBalance"
                                                        placeholder="0.00"
                                                    />
                                                </div>
                                                <div className="col-md-6 col-lg-4">
                                                    <AmountContainer
                                                        setIsEdit={setIsEdit}
                                                        name="paymentAmount"
                                                        label="Payment Amount"
                                                        id="paymentAmount"
                                                        placeholder="0.00"
                                                    />
                                                </div>
                                                <div className="col-md-6 col-lg-4">
                                                    <FieldControl
                                                        name="frequency"
                                                        render={({ handler, touched, hasError, meta }) => (
                                                            <div className="form-group">
                                                                <label htmlFor="Account_Frequency">Frequency</label>
                                                                <Select
                                                                    className="basic-single"
                                                                    classNamePrefix="select"
                                                                    menuPortalTarget={document.body}
                                                                    styles={{
                                                                        menuPortal: base => ({ ...base, zIndex: 9999 })
                                                                    }}
                                                                    options={[
                                                                        { label: "Daily", value: "Daily" },
                                                                        { label: "Weekly", value: "Weekly" },
                                                                        { label: "Monthly", value: "Monthly" },
                                                                        { label: "Annually", value: "Annually" },
                                                                        { label: "Upon Maturity", value: "Upon Maturity" },
                                                                    ]}
                                                                    defaultValue={{ label: "Daily", value: "Daily" }}
                                                                    filterOption={createFilter(filterConfig)}
                                                                    id='frequency'
                                                                    name='frequency'
                                                                    {...handler()}
                                                                />
                                                            </div>
                                                        )}
                                                    />
                                                </div>
                                                <div className="col-md-6 col-lg-4">
                                                    <FieldControl
                                                        name="maturityDate"
                                                        render={({ handler, touched, hasError, meta }) => (
                                                            <div className="form-group input-date">
                                                                <label htmlFor="Account_Maturity_Date">Maturity Date</label>
                                                                <DatePicker
                                                                    showIcon
                                                                    toggleCalendarOnIconClick
                                                                    selected={handler().value ? moment(handler().value).format('MMM DD, YYYY') : ''}
                                                                    value={handler().value ? moment(handler().value).format('MMM DD, YYYY') : ''}
                                                                    isClearable={handler().value}
                                                                    dateFormat="MMM d, yyyy"
                                                                    placeholderText="Month DD, YYYY"
                                                                    id='maturityDate'
                                                                    name='maturityDate'
                                                                    onChange={(date) => {
                                                                        const formattedDate = date ? moment(date).format('YYYY-MM-DD') : '';
                                                                        handler().onChange(formattedDate);
                                                                    }}
                                                                    yearDropdownItemNumber={100}
                                                                    scrollableYearDropdown
                                                                    showMonthDropdown
                                                                    showYearDropdown
                                                                    onKeyDown={(e) => {
                                                                        e.preventDefault();
                                                                    }}
                                                                />
                                                            </div>
                                                        )}
                                                    />
                                                </div>
                                                <div className="col-md-6 col-lg-4">
                                                    <FieldControl
                                                        name="secured"
                                                        render={({ handler, touched, hasError, meta }) => (
                                                            <div className="form-group">
                                                                <div className="label pb-1">Secured</div>
                                                                <div className="form-check form-check-inline">
                                                                    <input className="form-check-input" type="radio" name="secured" id="Secured_Yes" value="Yes" checked={accountReceivablesFormGroup.value.secured === "Yes"} onChange={(e) => { handler().onChange("Yes") }} />
                                                                    <label className="form-check-label" htmlFor="Secured_Yes">Yes</label>
                                                                </div>
                                                                <div className="form-check form-check-inline">
                                                                    <input className="form-check-input" type="radio" name="secured" id="Secured_No" value="No" checked={accountReceivablesFormGroup.value.secured === "No"} onChange={(e) => { handler().onChange("No") }} />
                                                                    <label className="form-check-label" htmlFor="Secured_No">No</label>
                                                                </div>
                                                            </div>
                                                        )}
                                                    />
                                                </div>
                                                <div className="col-md-6 col-lg-4">
                                                    <FieldControl
                                                        name="status"
                                                        render={({ handler, touched, hasError, meta }) => (
                                                            <div className="form-group">
                                                                <label htmlFor="Real_estate_Status">Status</label>
                                                                <Select
                                                                    className="basic-single"
                                                                    classNamePrefix="select"
                                                                    menuPortalTarget={document.body}
                                                                    styles={{
                                                                        menuPortal: base => ({ ...base, zIndex: 9999 })
                                                                    }}
                                                                    options={[
                                                                        { label: "Current", value: "Current" },
                                                                        { label: "Delinquent", value: "Delinquent" },
                                                                    ]}
                                                                    defaultValue={{ label: "Current", value: "Current" }}
                                                                    filterOption={createFilter(filterConfig)}
                                                                    id='status'
                                                                    name='status'
                                                                    {...handler()}
                                                                />
                                                            </div>
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                        </Modal.Body>
                                        <div className="modal-footer la-app-modal-footer">
                                            {
                                                accountNoteType == "Edit" ?
                                                    (
                                                        <>
                                                            <button type="button" className="lm-button2 text-red" onClick={(e) => handleRemoveFormModal(e, "pfsAccountReceivablesDetailsList", accountReceivablesFormGroup.value.index, AccountNotePopupClose)} >Remove</button>
                                                            <button type="button" className="lm-button1" onClick={(e) => { handleEditFormModal(e, "pfsAccountReceivablesDetailsList", accountReceivablesFormGroup, AccountNotePopupClose) }}>Save</button>
                                                        </>
                                                    ) :
                                                    <button type="button" onClick={(e) => handleAddFormModal(e, "pfsAccountReceivablesDetailsList", accountReceivablesFormGroup, AccountNotePopupClose)} className="lm-button1">Save</button>
                                            }
                                        </div>
                                    </form>
                                )}
                            />
                        </div>
                    </div>
                    {/* add/ Edit Account / Note */}

                    {/*add / Edit Life Insurance */}
                    {/* This is customized modal used for datepicker isuue purpose don't use this anywhere else */}
                    <div className={`custom-modal cust-modal-md modal-open modal-backdrop ${LifeInsurancePopupShow ? "cust-modal-show" : "cust-modal-hide"}`}>
                        <div className="modal-box">
                            <div className="modal-header">
                                <h5 className="modal-title" id="LifeInsurancePopupLabel">{lifeInsuranceType} Life Insurance</h5>
                                <button type="button" className="close" onClick={LifeInsurancePopupClose}>
                                    <span aria-hidden="true"><img src={close_popup} alt="" /></span>
                                </button>
                            </div>
                            <FieldGroup
                                control={lifeInsuranceFormGroup}
                                render={({ get, invalid }) => (
                                    <form>
                                        <Modal.Body>
                                            <div className="row">
                                                <div className="col-md-6 col-12">
                                                    <div className="form-group">
                                                        <FormField
                                                            name="insuranceCompany"
                                                            label="Insurance Copmpany"
                                                            id="insuranceCompany"
                                                            placeholder="Enter a Insurance Copmpany"
                                                            required={true}
                                                            errorMessage={"Insurance Copmpany is required"}
                                                            maxlength={100}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-12">
                                                    <div className="form-group">
                                                        <FormField
                                                            name="beneficiaries"
                                                            label="Beneficiaries"
                                                            id="beneficiaries"
                                                            placeholder="Enter"
                                                            maxlength={100}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-12">
                                                    <FieldControl
                                                        name="state"
                                                        render={({ handler, touched, hasError, meta }) => (
                                                            <div className="form-group">
                                                                <label htmlFor="Insurance_Copmpany_State">State</label>
                                                                <Select
                                                                    className="basic-single"
                                                                    classNamePrefix="select"
                                                                    menuPortalTarget={document.body}
                                                                    styles={{
                                                                        menuPortal: base => ({ ...base, zIndex: 9999 })
                                                                    }}
                                                                    options={stateList}
                                                                    filterOption={createFilter(filterConfig)} id='state'
                                                                    name='state'
                                                                    {...handler()}
                                                                />
                                                            </div>
                                                        )}
                                                    />
                                                </div>
                                                <div className="col-md-6 col-12">
                                                    <FieldControl
                                                        name="purchaseDate"
                                                        render={({ handler, touched, hasError, meta }) => (
                                                            <div className="form-group input-date">
                                                                <label htmlFor="Insurance_Copmpany_Purchase_Date">Purchase Date</label>
                                                                <DatePicker
                                                                    showIcon
                                                                    toggleCalendarOnIconClick
                                                                    selected={handler().value ? moment(handler().value).format('MMM DD, YYYY') : ''}
                                                                    value={handler().value ? moment(handler().value).format('MMM DD, YYYY') : ''}
                                                                    isClearable={handler().value}
                                                                    dateFormat="MMM d, yyyy"
                                                                    placeholderText="Month DD, YYYY"
                                                                    id='purchaseDate'
                                                                    name='purchaseDate'
                                                                    onChange={(date) => {
                                                                        const formattedDate = date ? moment(date).format('YYYY-MM-DD') : '';
                                                                        handler().onChange(formattedDate);
                                                                    }}
                                                                    yearDropdownItemNumber={100}
                                                                    scrollableYearDropdown
                                                                    showMonthDropdown
                                                                    showYearDropdown
                                                                    onKeyDown={(e) => {
                                                                        e.preventDefault();
                                                                    }}
                                                                />
                                                            </div>
                                                        )}
                                                    />
                                                </div>
                                                <div className="col-md-6 col-12">
                                                    <div className="form-group input-dollar">
                                                        <AmountContainer
                                                            setIsEdit={setIsEdit}
                                                            name="faceValue"
                                                            label="Face value"
                                                            id="faceValue"
                                                            placeholder="0.00"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-12">
                                                    <div className="form-group input-dollar">
                                                        <AmountContainer
                                                            setIsEdit={setIsEdit}
                                                            name="cashSurrenderValue"
                                                            label="Cash Surrender Value"
                                                            id="cashSurrenderValue"
                                                            placeholder="0.00"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-12">
                                                    <div className="form-group input-dollar">
                                                        <AmountContainer
                                                            setIsEdit={setIsEdit}
                                                            name="amountBorrowed"
                                                            label="Amount Borrowed"
                                                            id="amountBorrowed"
                                                            placeholder="0.00"
                                                        />
                                                    </div>
                                                </div>
                                                <FieldControl
                                                    name="pledged"
                                                    render={({ handler, touched, hasError, meta }) => (
                                                        <div className="form-group form-check mb-2">
                                                            <div className="label pb-1">Pledged</div>
                                                            <div className="form-check form-check-inline">
                                                                <input className="form-check-input" type="radio" name="Insurance_Copmpany_Pledged" id="Insurance_Copmpany_Pledged_Yes" value="Yes" checked={lifeInsuranceFormGroup.value.pledged === "Yes"} onChange={(e) => { handler().onChange("Yes") }} />
                                                                <label className="form-check-label" htmlFor="Insurance_Copmpany_Pledged_Yes">Yes</label>
                                                            </div>
                                                            <div className="form-check form-check-inline">
                                                                <input className="form-check-input" type="radio" name="Insurance_Copmpany_Pledged" id="Insurance_Copmpany_Pledged_No" value="No" checked={lifeInsuranceFormGroup.value.pledged === "No"} onChange={(e) => { handler().onChange("No") }} />
                                                                <label className="form-check-label" htmlFor="Insurance_Copmpany_Pledged_No">No</label>
                                                            </div>
                                                        </div>
                                                    )}
                                                />
                                            </div>
                                        </Modal.Body>
                                        <div className="modal-footer la-app-modal-footer">
                                            {
                                                lifeInsuranceType == "Edit" ?
                                                    (
                                                        <>
                                                            <button type="button" className="lm-button2 text-red" onClick={(e) => handleRemoveFormModal(e, "pfsLifeInsuranceDetailsList", lifeInsuranceFormGroup.value.index, LifeInsurancePopupClose)} >Remove</button>
                                                            <button type="button" className="lm-button1" onClick={(e) => { handleEditFormModal(e, "pfsLifeInsuranceDetailsList", lifeInsuranceFormGroup, LifeInsurancePopupClose) }}>Save</button>
                                                        </>
                                                    ) :
                                                    <button type="button" onClick={(e) => handleAddFormModal(e, "pfsLifeInsuranceDetailsList", lifeInsuranceFormGroup, LifeInsurancePopupClose)} className="lm-button1">Save</button>
                                            }
                                        </div>
                                    </form>
                                )}
                            />
                        </div>
                    </div>
                    {/* add / Edit Life Insurance */}

                    {/*add / Edit Notes Payable To Banks & Institution */}
                    {/* This is customized modal used for datepicker isuue purpose don't use this anywhere else */}
                    <div className={`custom-modal cust-modal-md modal-open modal-backdrop ${isNotesPayableBanksOpen ? "cust-modal-show" : "cust-modal-hide"}`}>
                        <div className="modal-box">
                            <div className="modal-header">
                                <h5 className="modal-title" id="NotesPayableBanksLabel">{NotesPayableBanksType} Notes Payable To Banks & Others</h5>
                                <button type="button" className="close" onClick={NotesPayableBanksClose}>
                                    <span aria-hidden="true"><img src={close_popup} alt="" /></span>
                                </button>
                            </div>
                            <FieldGroup
                                control={notesPayableBanksFormGroup}
                                render={({ get, invalid }) => (
                                    <form>
                                        <div className="modal-body">
                                            <div className="row">
                                                <div className="col-md-6 col-12">
                                                    <div className="form-group">
                                                        <FormField
                                                            name="nameOfNoteHolder"
                                                            label="Name of Noteholder"
                                                            id="nameOfNoteHolder"
                                                            placeholder="Enter a Name of Noteholder"
                                                            required={true}
                                                            errorMessage={"Name of Noteholder is required"}
                                                            maxlength={100}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-12">
                                                    <div className="form-group input-dollar">
                                                        <AmountContainer
                                                            setIsEdit={setIsEdit}
                                                            name="originalBalance"
                                                            label="Original Balance"
                                                            id="originalBalance"
                                                            placeholder="0.00"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-12">
                                                    <div className="form-group input-dollar">
                                                        <AmountContainer
                                                            setIsEdit={setIsEdit}
                                                            name="currentBalance"
                                                            label="Current Balance"
                                                            id="currentBalance"
                                                            placeholder="0.00"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-12">
                                                    <div className="form-group input-dollar">
                                                        <AmountContainer
                                                            setIsEdit={setIsEdit}
                                                            name="paymentAmount"
                                                            label="Payment Amount"
                                                            id="paymentAmount"
                                                            placeholder="0.00"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-12">
                                                    <FieldControl
                                                        name="frequency"
                                                        render={({ handler, touched, hasError, meta }) => (
                                                            <div className="form-group">
                                                                <label htmlFor="Notes_Banks_Frequency">Frequency</label>
                                                                <Select
                                                                    className="basic-single"
                                                                    classNamePrefix="select"
                                                                    menuPortalTarget={document.body}
                                                                    styles={{
                                                                        menuPortal: base => ({ ...base, zIndex: 9999 })
                                                                    }}
                                                                    options={[
                                                                        { label: "Daily", value: "Daily" },
                                                                        { label: "Weekly", value: "Weekly" },
                                                                        { label: "Monthly", value: "Monthly" },
                                                                        { label: "Annually", value: "Annually" },
                                                                        { label: "Upon Maturity", value: "Upon Maturity" },
                                                                    ]}
                                                                    filterOption={createFilter(filterConfig)}
                                                                    id='frequency'
                                                                    name='frequency'
                                                                    {...handler()}
                                                                />
                                                            </div>
                                                        )}
                                                    />
                                                </div>
                                                <div className="col-md-6 col-12">
                                                    <FieldControl
                                                        name="maturityDate"
                                                        render={({ handler, touched, hasError, meta }) => (
                                                            <div className="form-group input-date">
                                                                <label htmlFor="Account_Maturity_Date">Maturity Date</label>
                                                                <DatePicker
                                                                    showIcon
                                                                    toggleCalendarOnIconClick
                                                                    selected={handler().value ? moment(handler().value).format('MMM DD, YYYY') : ''}
                                                                    value={handler().value ? moment(handler().value).format('MMM DD, YYYY') : ''}
                                                                    isClearable={handler().value}
                                                                    dateFormat="MMM d, yyyy"
                                                                    placeholderText="Month DD, YYYY"
                                                                    id='maturityDate'
                                                                    name='maturityDate'
                                                                    onChange={(date) => {
                                                                        const formattedDate = date ? moment(date).format() : '';
                                                                        handler().onChange(formattedDate);
                                                                    }}
                                                                    required={true}
                                                                    yearDropdownItemNumber={100}
                                                                    scrollableYearDropdown
                                                                    showMonthDropdown
                                                                    showYearDropdown
                                                                    onKeyDown={(e) => {
                                                                        e.preventDefault();
                                                                    }}
                                                                />

                                                                {/* Error messages below */}
                                                                <span className="error-message">{touched && hasError("required") && `Maturity date is required`} </span>
                                                            </div>
                                                        )}
                                                    />
                                                </div>
                                                <div className="col-md-6 col-12">
                                                    <div className="form-group">
                                                        <FormField
                                                            name="typeofCollateral"
                                                            label="Type of Collateral"
                                                            id="typeofCollateral"
                                                            placeholder="Enter a Type of Collateral"
                                                            maxlength={100}
                                                        />
                                                    </div>
                                                </div>
                                                <FieldControl
                                                    name="secured"
                                                    render={({ handler, touched, hasError, meta }) => (
                                                        <div className="form-group">
                                                            <div className="label pb-1">Secured</div>
                                                            <div className="form-check form-check-inline">
                                                                <input className="form-check-input" type="radio" name="Notes_Banks_Secured" id="Notes_Banks_Secured_Yes" value="Yes" checked={notesPayableBanksFormGroup.value.secured === "Yes"} onChange={(e) => { handler().onChange("Yes") }} />
                                                                <label className="form-check-label" htmlFor="Notes_Banks_Secured_Yes">Yes</label>
                                                            </div>
                                                            <div className="form-check form-check-inline">
                                                                <input className="form-check-input" type="radio" name="Notes_Banks_Secured" id="Notes_Banks_Secured_No" value="No" checked={notesPayableBanksFormGroup.value.secured === "No"} onChange={(e) => { handler().onChange("No") }} />
                                                                <label className="form-check-label" htmlFor="Notes_Banks_Secured_No">No</label>
                                                            </div>
                                                        </div>
                                                    )}
                                                />
                                            </div>
                                        </div>
                                        <div className="modal-footer la-app-modal-footer">
                                            {
                                                NotesPayableBanksType == "Edit" ?
                                                    (
                                                        <>
                                                            <button type="button" className="lm-button2 text-red" onClick={(e) => handleRemoveFormModal(e, "pfsNotesPayableToBanksAndInstitutionsDetailsList", notesPayableBanksFormGroup.value.index, NotesPayableBanksClose)} >Remove</button>
                                                            <button type="button" className="lm-button1" onClick={(e) => { handleEditFormModal(e, "pfsNotesPayableToBanksAndInstitutionsDetailsList", notesPayableBanksFormGroup, NotesPayableBanksClose) }}>Save</button>
                                                        </>
                                                    ) :
                                                    <button type="button" onClick={(e) => handleAddFormModal(e, "pfsNotesPayableToBanksAndInstitutionsDetailsList", notesPayableBanksFormGroup, NotesPayableBanksClose)} className="lm-button1">Save</button>
                                            }
                                        </div>
                                    </form>
                                )}
                            />
                        </div>
                    </div>
                    {/*add / Edit Notes Payable To Banks & Institution */}

                    {/*add / Edit Payable to Individuals */}
                    {/* This is customized modal used for datepicker isuue purpose don't use this anywhere else */}
                    <div className={`custom-modal cust-modal-md modal-open modal-backdrop ${NotesPayableIndividualsPopupShow ? "cust-modal-show" : "cust-modal-hide"}`}>
                        <div className="modal-box">
                            <div className="modal-header">
                                <h5 className="modal-title" id="NotesPayableIndividualsPopupLabel">{NotesPayableIndividualsType} Payable to Individuals</h5>
                                <button type="button" className="close" onClick={NotesPayableIndividualsPopupClose}>
                                    <span aria-hidden="true"><img src={close_popup} alt="" /></span>
                                </button>
                            </div>
                            <FieldGroup
                                control={notesPayableIndividualsFormGroup}
                                render={({ get, invalid }) => (
                                    <form>
                                        <Modal.Body>
                                            <div className="row">
                                                <div className="col-md-6 col-12">
                                                    <div className="form-group">
                                                        <FormField
                                                            name="nameOfNoteHolder"
                                                            label="Name of Noteholder"
                                                            id="notesPayableIndividualsNameOfNoteHolder"
                                                            placeholder="Enter a Name of Noteholder"
                                                            maxlength={100}
                                                            required={true}
                                                            errorMessage="Name of Noteholder is required"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-12">
                                                    <div className="form-group input-dollar">
                                                        <AmountContainer
                                                            setIsEdit={setIsEdit}
                                                            name="originalBalance"
                                                            label="Original Balance"
                                                            id="originalBalance"
                                                            placeholder="0.00"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-12">
                                                    <div className="form-group input-dollar">
                                                        <AmountContainer
                                                            setIsEdit={setIsEdit}
                                                            name="currentBalance"
                                                            label="Current Balance"
                                                            id="currentBalance"
                                                            placeholder="0.00"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-12">
                                                    <div className="form-group input-dollar">
                                                        <AmountContainer
                                                            setIsEdit={setIsEdit}
                                                            name="paymentAmount"
                                                            label="Payment Amount"
                                                            id="paymentAmount"
                                                            placeholder="0.00"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-12">
                                                    <FieldControl
                                                        name="frequency"
                                                        render={({ handler, touched, hasError, meta }) => (
                                                            <div className="form-group">
                                                                <label htmlFor="Notes_Individuals_Frequency">Frequency</label>
                                                                <Select
                                                                    className="basic-single"
                                                                    classNamePrefix="select"
                                                                    menuPortalTarget={document.body}
                                                                    styles={{
                                                                        menuPortal: base => ({ ...base, zIndex: 9999 })
                                                                    }}
                                                                    options={[
                                                                        { label: "Daily", value: "Daily" },
                                                                        { label: "Weekly", value: "Weekly" },
                                                                        { label: "Monthly", value: "Monthly" },
                                                                        { label: "Annually", value: "Annually" },
                                                                        { label: "Upon Maturity", value: "Upon Maturity" },
                                                                    ]}
                                                                    filterOption={createFilter(filterConfig)}
                                                                    id='frequency'
                                                                    name='frequency'
                                                                    {...handler()}
                                                                />
                                                            </div>
                                                        )}
                                                    />
                                                </div>
                                                <div className="col-md-6 col-12">
                                                    <FieldControl
                                                        name="maturityDate"
                                                        render={({ handler, touched, hasError, meta }) => (
                                                            <div className="form-group input-date">
                                                                <label htmlFor="Maturity Date">Maturity Date <span className="text-danger">*</span></label>
                                                                <DatePicker
                                                                    showIcon
                                                                    toggleCalendarOnIconClick
                                                                    selected={handler().value ? moment(handler().value).format('MMM DD, YYYY') : ''}
                                                                    value={handler().value ? moment(handler().value).format('MMM DD, YYYY') : ''}
                                                                    isClearable={handler().value}
                                                                    dateFormat="MMM d, yyyy"
                                                                    placeholderText="Month DD, YYYY"
                                                                    id='notesPayableIndividualsMaturityDate'
                                                                    name='maturityDate'
                                                                    onChange={(date) => {
                                                                        const formattedDate = date ? moment(date).format('YYYY-MM-DD') : '';
                                                                        handler().onChange(formattedDate);
                                                                    }}
                                                                    yearDropdownItemNumber={100}
                                                                    scrollableYearDropdown
                                                                    showMonthDropdown
                                                                    showYearDropdown
                                                                    onKeyDown={(e) => {
                                                                        e.preventDefault();
                                                                    }}
                                                                />
                                                                {/* Error messages below */}
                                                                <span className="error-message">{touched && hasError("required") && `Maturity date is required`} </span>
                                                            </div>
                                                        )}
                                                    />
                                                </div>
                                                <div className="col-md-6 col-12">
                                                    <div className="form-group">
                                                        <FormField
                                                            name="typeOfCollateral"
                                                            label="Type of Collateral"
                                                            id="typeOfCollateral"
                                                            placeholder="Enter a Type of Collateral"
                                                            maxlength={100}
                                                        />
                                                    </div>
                                                </div>
                                                <FieldControl
                                                    name="secured"
                                                    render={({ handler, touched, hasError, meta }) => (
                                                        <div className="form-group">
                                                            <div className="label pb-1">Secured
                                                            </div>
                                                            <div className="form-check form-check-inline">
                                                                <input className="form-check-input" type="radio" name="Notes_Individuals_Secured" id="Notes_Individuals_Secured_Yes" value="Yes" checked={notesPayableIndividualsFormGroup.value.secured === "Yes"} onChange={(e) => { handler().onChange("Yes") }} />
                                                                <label className="form-check-label" htmlFor="Notes_Individuals_Secured_Yes">Yes</label>
                                                            </div>
                                                            <div className="form-check form-check-inline">
                                                                <input className="form-check-input" type="radio" name="Notes_Individuals_Secured" id="Notes_Individuals_Secured_No" value="No" checked={notesPayableIndividualsFormGroup.value.secured === "No"} onChange={(e) => { handler().onChange("No") }} />
                                                                <label className="form-check-label" htmlFor="Notes_Individuals_Secured_No">No</label>
                                                            </div>
                                                        </div>
                                                    )}
                                                />
                                            </div>
                                        </Modal.Body>
                                        <div className="modal-footer la-app-modal-footer">
                                            {
                                                NotesPayableIndividualsType == "Edit" ?
                                                    (
                                                        <>
                                                            <button type="button" className="lm-button2 text-red" onClick={(e) => handleRemoveFormModal(e, "pfsNotesPayableToIndividualsDetailsList", notesPayableIndividualsFormGroup.value.index, NotesPayableIndividualsPopupClose)} >Remove</button>
                                                            <button type="button" className="lm-button1" onClick={(e) => { handleEditFormModal(e, "pfsNotesPayableToIndividualsDetailsList", notesPayableIndividualsFormGroup, NotesPayableIndividualsPopupClose) }}>Save</button>
                                                        </>
                                                    ) :
                                                    <button type="button" onClick={(e) => handleAddFormModal(e, "pfsNotesPayableToIndividualsDetailsList", notesPayableIndividualsFormGroup, NotesPayableIndividualsPopupClose)} className="lm-button1">Save</button>

                                            }
                                        </div>
                                    </form>
                                )}
                            />
                        </div>
                    </div>
                    {/* add / Edit Payable to Individuals */}

                    {/*add / Edit Installment Account */}
                    <Modal
                        size="md"
                        show={InstallmentAccountPopupShow}
                        onHide={() => setInstallmentAccountPopup(false)}
                        aria-labelledby="InstallmentAccountPopupLabel"
                        backdrop="static"
                        keyboard={false}
                        centered
                    >
                        <div className="modal-dialog modal-md modal-dialog-centered modal-dialog-scrollable">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="InstallmentAccountPopupLabel">{InstallmentAccountType} Installment Account</h5>
                                    <button type="button" className="close" onClick={InstallmentAccountPopupClose}>
                                        <span aria-hidden="true"><img src={close_popup} alt="" /></span>
                                    </button>
                                </div>
                                <FieldGroup
                                    control={installmentAccountFormGroup}
                                    render={({ get, invalid }) => (
                                        <form>
                                            <Modal.Body>
                                                <div className="row">
                                                    <div className="col-md-6 col-12">
                                                        <div className="form-group">
                                                            <FormField
                                                                name="installmentType"
                                                                label="Installment Type"
                                                                id="installmentType"
                                                                placeholder="Enter a Installment Type"
                                                                maxlength={100}
                                                                required={true}
                                                                errorMessage={"Installment Type is required"}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 col-12">
                                                        <div className="form-group input-dollar">
                                                            <AmountContainer
                                                                setIsEdit={setIsEdit}
                                                                name="asset"
                                                                label="Asset"
                                                                id="asset"
                                                                placeholder="0.00"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 col-12">
                                                        <div className="form-group input-dollar">
                                                            <AmountContainer
                                                                setIsEdit={setIsEdit}
                                                                name="marketValue"
                                                                label="Market Value"
                                                                id="marketValue"
                                                                placeholder="0.00"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 col-12">
                                                        <div className="form-group">
                                                            <FormField
                                                                name="lender"
                                                                label="Lender"
                                                                id="lender"
                                                                placeholder="Enter a Lender"
                                                                maxlength={100}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 col-12">
                                                        <div className="form-group input-dollar">
                                                            <AmountContainer
                                                                setIsEdit={setIsEdit}
                                                                name="outstandingLoanBalance"
                                                                label="Outstanding Loan Balance"
                                                                id="outstandingLoanBalance"
                                                                placeholder="0.00"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 col-12">
                                                        <div className="form-group input-dollar">
                                                            <AmountContainer
                                                                setIsEdit={setIsEdit}
                                                                name="monthlyPayment"
                                                                label="Monthly Payment"
                                                                id="Installment_Monthly_Payment"
                                                                placeholder="0.00"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 col-12">
                                                        <FieldControl
                                                            name="status"
                                                            render={({ handler, touched, hasError, meta }) => (
                                                                <div className="form-group">
                                                                    <label htmlFor="Installment_Status">Status</label>
                                                                    <Select
                                                                        className="basic-single"
                                                                        classNamePrefix="select"
                                                                        menuPortalTarget={document.body}
                                                                        styles={{
                                                                            menuPortal: base => ({ ...base, zIndex: 9999 })
                                                                        }}
                                                                        options={[
                                                                            { label: "Current", value: "Current" },
                                                                            { label: "Delinquent", value: "Delinquent" },
                                                                        ]}
                                                                        defaultValue={{ label: "Current", value: "Current" }}
                                                                        filterOption={createFilter(filterConfig)}
                                                                        id='status'
                                                                        name='status'
                                                                        {...handler()}
                                                                    />
                                                                </div>
                                                            )}
                                                        />
                                                    </div>
                                                </div>
                                            </Modal.Body>
                                            <div className="modal-footer la-app-modal-footer">
                                                {
                                                    InstallmentAccountType == "Edit" ?
                                                        (
                                                            <>
                                                                <button type="button" className="lm-button2 text-red" onClick={(e) => handleRemoveFormModal(e, "pfsInstallmentAccountDetailsList", installmentAccountFormGroup.value.index, InstallmentAccountPopupClose)} >Remove</button>
                                                                <button type="button" className="lm-button1" onClick={(e) => { handleEditFormModal(e, "pfsInstallmentAccountDetailsList", installmentAccountFormGroup, InstallmentAccountPopupClose) }}>Save</button>
                                                            </>
                                                        ) :
                                                        <button type="button" onClick={(e) => handleAddFormModal(e, "pfsInstallmentAccountDetailsList", installmentAccountFormGroup, InstallmentAccountPopupClose)} className="lm-button1">Save</button>
                                                }
                                            </div>
                                        </form>
                                    )}
                                />
                            </div>
                        </div>
                    </Modal>
                    {/* add / Edit Installment Account */}

                    {/*add / Edit Unpaid Taxes */}
                    {/* This is customized modal used for datepicker isuue purpose don't use this anywhere else */}
                    <div className={`custom-modal cust-modal-md modal-open modal-backdrop ${isUnpaidTaxesPopupOpen ? "cust-modal-show" : "cust-modal-hide"}`}>
                        <div className="modal-box">
                            <div className="modal-header">
                                <h5 className="modal-title" id="UnpaidTaxesPopupLabel">
                                    {UnpaidTaxesType} Unpaid Taxes
                                </h5>
                                <button type="button" className="close" onClick={UnpaidTaxesPopupClose}>
                                    <span aria-hidden="true">
                                        <img src={close_popup} alt="" />
                                    </span>
                                </button>
                            </div>
                            <FieldGroup
                                control={unpaidTaxBalanceFormGroup}
                                render={({ get, invalid }) => (
                                    <form>
                                        <div className="modal-body">
                                            <div className="row">
                                                <div className="col-md-6 col-12">
                                                    <div className="form-group">
                                                        <FormField
                                                            name="typeOfTax"
                                                            label="Type of Tax"
                                                            id="typeOfTax"
                                                            placeholder="Enter a Type of Tax"
                                                            maxlength={100}
                                                            required={true}
                                                            errorMessage={"Type of Tax is required"}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-12">
                                                    <FormField
                                                        name="agency"
                                                        label="Agency"
                                                        id="agency"
                                                        placeholder="Enter a Agency"
                                                        maxlength={100}
                                                    />
                                                </div>
                                                <div className="col-md-6 col-12">
                                                    <div className="form-group input-dollar">
                                                        <AmountContainer
                                                            setIsEdit={setIsEdit}
                                                            name="unpaidTaxBalance"
                                                            label="Unpaid Tax Balance"
                                                            id="unpaidTaxBalance"
                                                            placeholder="0.00"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-12">
                                                    <FieldControl
                                                        name="dueDate"
                                                        render={({ handler, touched, hasError, meta }) => (
                                                            <div className="form-group input-date">
                                                                <label htmlFor="Unpaid_Tax_Due_Date">Due Date <span className="text-danger">*</span></label>
                                                                <DatePicker
                                                                    showIcon
                                                                    toggleCalendarOnIconClick
                                                                    selected={handler().value ? moment(handler().value).format('MMM DD, YYYY') : ''}
                                                                    value={handler().value ? moment(handler().value).format('MMM DD, YYYY') : ''}
                                                                    isClearable={handler().value}
                                                                    dateFormat="MMM d, yyyy"
                                                                    placeholderText="Month DD, YYYY"
                                                                    id="unpaidTaxDueDate"
                                                                    name="dueDate"
                                                                    onChange={(date) => {
                                                                        const formattedDate = date ? moment(date).format() : "";
                                                                        handler().onChange(formattedDate);
                                                                    }}
                                                                    yearDropdownItemNumber={100}
                                                                    scrollableYearDropdown
                                                                    showMonthDropdown
                                                                    showYearDropdown
                                                                    onKeyDown={(e) => {
                                                                        e.preventDefault();
                                                                    }}
                                                                />
                                                                {/* Error messages below */}
                                                                <span className="error-message">{touched && hasError("required") && `Due date is required`}</span>
                                                            </div>
                                                        )}
                                                    />
                                                </div>
                                                <div className="col-md-6 col-12">
                                                    <FieldControl
                                                        name="lien"
                                                        render={({ handler, touched, hasError, meta }) => (
                                                            <div className="form-group">
                                                                <div className="label pb-1">Lien</div>
                                                                <div className="form-check form-check-inline">
                                                                    <input className="form-check-input" type="radio" name="Unpaid_Tax_Lien" id="Unpaid_Tax_Lien_Yes" value="Yes" checked={unpaidTaxBalanceFormGroup.value.lien === "Yes"} onChange={(e) => { handler().onChange("Yes") }} />
                                                                    <label className="form-check-label" htmlFor="Unpaid_Tax_Lien_Yes">Yes</label>
                                                                </div>
                                                                <div className="form-check form-check-inline">
                                                                    <input className="form-check-input" type="radio" name="Unpaid_Tax_Lien" id="Unpaid_Tax_Lien_No" value="No" checked={unpaidTaxBalanceFormGroup.value.lien === "No"} onChange={(e) => { handler().onChange("No"); }} />
                                                                    <label className="form-check-label" htmlFor="Unpaid_Tax_Lien_No">No</label>
                                                                </div>
                                                            </div>
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="modal-footer la-app-modal-footer">
                                            {UnpaidTaxesType === "Edit" ? (
                                                <>
                                                    <button type="button" className="lm-button2 text-red" onClick={(e) => handleRemoveFormModal(e, "pfsUnpaidTaxesDetailsList", unpaidTaxBalanceFormGroup.value.index, UnpaidTaxesPopupClose)}>Remove</button>
                                                    <button type="button" className="lm-button1" onClick={(e) => handleEditFormModal(e, "pfsUnpaidTaxesDetailsList", unpaidTaxBalanceFormGroup, UnpaidTaxesPopupClose)}>Save</button>
                                                </>
                                            ) : (
                                                <button type="button" className="lm-button1" onClick={(e) => handleAddFormModal(e, "pfsUnpaidTaxesDetailsList", unpaidTaxBalanceFormGroup, UnpaidTaxesPopupClose)}>Save</button>
                                            )}
                                        </div>
                                    </form>
                                )}
                            />
                        </div>
                    </div>

                    {/* add / Edit Unpaid Taxes */}

                    {/* add/ Edit Account Payable */}
                    <Modal
                        size="lg"
                        show={AccountPayablePopupShow}
                        onHide={() => setAccountPayablePopup(false)}
                        aria-labelledby="AccountPayablePopupLabel"
                        backdrop="static"
                        keyboard={false}
                        centered
                    >
                        <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="AccountPayablePopupLabel">{AccountPayableType} Account Payable</h5>
                                    <button type="button" className="close" onClick={AccountPayablePopupClose}>
                                        <span aria-hidden="true"><img src={close_popup} alt="" /></span>
                                    </button>
                                </div>
                                <FieldGroup
                                    control={accountsPayableFormGroup}
                                    render={({ get, invalid }) => (
                                        <form>
                                            <Modal.Body>
                                                <div className="row">
                                                    <div className="col-md-6 col-lg-4">
                                                        <div className="form-group">
                                                            <FormField
                                                                name="nameOfNotePayer"
                                                                label="Name of Note Payer"
                                                                id="accountsPayableNameOfNotePayer"
                                                                placeholder="Enter a Name of Note Payer"
                                                                maxlength={100}
                                                                required={true}
                                                                errorMessage={"Name of Note Payer is required"}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 col-lg-4">
                                                        <div className="form-group">
                                                            <FormField
                                                                name="streetAddress1"
                                                                label="Street Address"
                                                                id="streetAddress1"
                                                                placeholder="Enter Street Address"
                                                                maxlength={100}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 col-lg-4">
                                                        <div className="form-group">
                                                            <FormField
                                                                name="city"
                                                                label="City"
                                                                id="city"
                                                                placeholder="Enter a City"
                                                                maxlength={100}
                                                                onlyCharacters={true}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 col-lg-4">
                                                        <FieldControl
                                                            name="state"
                                                            render={({ handler, touched, hasError, meta }) => (
                                                                <div className="form-group">
                                                                    <label htmlFor="Real_Estate_State">State</label>
                                                                    <Select
                                                                        className="basic-single"
                                                                        classNamePrefix="select"
                                                                        menuPortalTarget={document.body}
                                                                        styles={{
                                                                            menuPortal: base => ({ ...base, zIndex: 9999 })
                                                                        }}
                                                                        ref={stateRef}
                                                                        options={stateList}
                                                                        filterOption={createFilter(filterConfig)}
                                                                        id='state'
                                                                        name='state'
                                                                        {...handler()}
                                                                    />
                                                                    {/* Error messages below */}
                                                                    <span className="error-message">{touched && hasError("required") && `State is required`} </span>
                                                                </div>
                                                            )}
                                                        />
                                                    </div>
                                                    <div className="col-md-6 col-lg-4">
                                                        <div className="form-group">
                                                            <FormField
                                                                name="zipcode"
                                                                label="ZIP Code"
                                                                id="zipcode"
                                                                placeholder="Enter a ZIP Code"
                                                                maxlength={5}
                                                                patternRequired={true}
                                                                onlyNumbers={true}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 col-lg-4">
                                                        <div className="form-group input-dollar">
                                                            <AmountContainer
                                                                setIsEdit={setIsEdit}
                                                                name="originalBalance"
                                                                label="Original Balance"
                                                                id="originalBalance"
                                                                placeholder="0.00"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 col-lg-4">
                                                        <div className="form-group input-dollar">
                                                            <AmountContainer
                                                                setIsEdit={setIsEdit}
                                                                name="currentBalance"
                                                                label="Current Balance"
                                                                id="currentBalance"
                                                                placeholder="0.00"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 col-lg-4">
                                                        <div className="form-group input-dollar">
                                                            <AmountContainer
                                                                setIsEdit={setIsEdit}
                                                                name="paymentAmount"
                                                                label="Payment Amount"
                                                                id="paymentAmount"
                                                                placeholder="0.00"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 col-lg-4">
                                                        <FieldControl
                                                            name="frequency"
                                                            render={({ handler, touched, hasError, meta }) => (
                                                                <div className="form-group">
                                                                    <label htmlFor="Accounts_Payable_Frequency">Frequency</label>
                                                                    <Select
                                                                        className="basic-single"
                                                                        classNamePrefix="select"
                                                                        menuPortalTarget={document.body}
                                                                        styles={{
                                                                            menuPortal: base => ({ ...base, zIndex: 9999 })
                                                                        }}
                                                                        options={[
                                                                            { label: "Daily", value: "Daily" },
                                                                            { label: "Weekly", value: "Weekly" },
                                                                            { label: "Monthly", value: "Monthly" },
                                                                            { label: "Annually", value: "Annually" },
                                                                            { label: "Upon Maturity", value: "Upon Maturity" },
                                                                        ]}
                                                                        filterOption={createFilter(filterConfig)}
                                                                        id='frequency'
                                                                        name='frequency'
                                                                        {...handler()}
                                                                    />
                                                                </div>
                                                            )}
                                                        />
                                                    </div>
                                                    <div className="col-md-6 col-lg-4">
                                                        <FieldControl
                                                            name="maturityDate"
                                                            render={({ handler, touched, hasError, meta }) => (
                                                                <div className="form-group input-date">
                                                                    <label htmlFor="Maturity_Date">Maturity Date</label>
                                                                    <DatePicker
                                                                        showIcon
                                                                        toggleCalendarOnIconClick
                                                                        selected={handler().value ? moment(handler().value).format('MMM DD, YYYY') : ''}
                                                                        value={handler().value ? moment(handler().value).format('MMM DD, YYYY') : ''}
                                                                        isClearable={handler().value}
                                                                        dateFormat="MMM d, yyyy"
                                                                        placeholderText="Month DD, YYYY"
                                                                        id='maturityDate'
                                                                        name='maturityDate'
                                                                        yearDropdownItemNumber={100}
                                                                        scrollableYearDropdown
                                                                        showMonthDropdown
                                                                        showYearDropdown
                                                                        onChange={(date) => {
                                                                            const formattedDate = date ? moment(date).format('YYYY-MM-DD') : '';
                                                                            handler().onChange(formattedDate);
                                                                        }}
                                                                        onKeyDown={(e) => {
                                                                            e.preventDefault();
                                                                        }}
                                                                    />
                                                                </div>
                                                            )}
                                                        />
                                                    </div>
                                                    <div className="col-md-6 col-lg-4">
                                                        <FieldControl
                                                            name='secured'
                                                            render={({ handler, touched, hasError, meta }) => (
                                                                <div className="form-group">
                                                                    <div className="label pb-1">Secured</div>
                                                                    <div className="form-check form-check-inline">
                                                                        <input className="form-check-input" type="radio" name="Account_Payable_Secured" id="Account_Payable_Secured_Yes" value="Yes" checked={accountsPayableFormGroup.value.secured === "Yes"} onChange={(e) => { handler().onChange("Yes") }} />
                                                                        <label className="form-check-label" htmlFor="Account_Payable_Secured_Yes">Yes</label>
                                                                    </div>
                                                                    <div className="form-check form-check-inline">
                                                                        <input className="form-check-input" type="radio" name="Account_Payable_Secured" id="Account_Payable_Secured_No" value="No" checked={accountsPayableFormGroup.value.secured === "No"} onChange={(e) => { handler().onChange("No") }} />
                                                                        <label className="form-check-label" htmlFor="Account_Payable_Secured_No">No</label>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        />
                                                    </div>
                                                </div>
                                            </Modal.Body>
                                            <div className="modal-footer la-app-modal-footer">
                                                {
                                                    AccountPayableType == "Edit" ?
                                                        (
                                                            <>
                                                                <button type="button" className="lm-button2 text-red" onClick={(e) => handleRemoveFormModal(e, "pfsAccountPayablesDetailList", accountsPayableFormGroup.value.index, AccountPayablePopupClose)} >Remove</button>
                                                                <button type="button" className="lm-button1" onClick={(e) => { handleEditFormModal(e, "pfsAccountPayablesDetailList", accountsPayableFormGroup, AccountPayablePopupClose) }}>Save</button>
                                                            </>
                                                        ) :
                                                        <button type="button" onClick={(e) => handleAddFormModal(e, "pfsAccountPayablesDetailList", accountsPayableFormGroup, AccountPayablePopupClose)} className="lm-button1">Save</button>
                                                }
                                            </div>
                                        </form>
                                    )}
                                />
                            </div>
                        </div>
                    </Modal>
                    {/* add/ Edit Account Payable */}

                    {/* <!--back to Previous Tab Popup / Modal --> */}
                    <Modal
                        className='mark-comp-popup-cust-size'
                        show={PreviousTabPopupShow}
                        onHide={() => setPreviousTabPopup(false)}
                        aria-labelledby="PreviousTabPopupLabel"
                        backdrop="static"
                        keyboard={false}
                        centered
                    >
                        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                            <div className="modal-content">
                                <div className="modal-body signout-modal">
                                    <h3 className="pt-4">
                                        Going back will lead to the loss of unsaved data
                                    </h3>
                                </div>
                                <div className="modal-footer la-app-modal-footer">
                                    <button type="button" className="lm-button2" onClick={PreviousTabPopupClose}>Cancel</button>
                                    <Link className="lm-button1" onClick={backPreviousTab}> Confirm</Link>
                                </div>
                            </div>
                        </div>
                    </Modal>
                    {/* <!--back to Previous Tab Popup / Modal --> */}
                </div >
            </div >)
            }

        </>
    );
}

export default PFSDetail;