import React, { useState, useEffect, useCallback } from 'react';
import Preloader from '../plugins/Preloader';
import no_data_icon from '../assets/img/no_data_icon_new.svg';
import './styles/Users.css';
import { Link } from "react-router-dom";
import phone_icon_grid from '../assets/icons/phone_icon_grid.svg';
import { useDispatch } from 'react-redux';
import reducersAndActions from '../_redux/slices';
import Constants from '../helpers/Constants';
import DynamicTable from '../plugins/DynamicTable';
import { BorrowersHeader } from '../helpers/DropdownsAndMappers';
import { getAdminAdviserList } from '../services/inquiryServices';
import { SidePanel, ProfileHeader } from "@components";
import convertToIST from './user/commonFunctions/User';
import { debounce } from 'lodash';

function UserBorrowers() {
    // Set meta title
    useEffect(() => {
        document.title = "Borrowers";
    }, []);

    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        dispatch(reducersAndActions.actions.sessionActions.setLoading(loading));
    }, [loading]);


    const [totalData, setTotalData] = useState(0);
    const [inquiries, setInquiries] = useState([]);
    const [pageIndex, setPageIndex] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [searchText, setSearchText] = useState("");


    // Fetch inquiries whenever page size or page index changes
    const debouncedGetLoanApplications = useCallback(debounce(() => {
        setLoading(true);
        getInquiries()
    }, 1000), [pageSize, pageIndex, searchText]);

    useEffect(() => {
        debouncedGetLoanApplications();
        return () => {
            debouncedGetLoanApplications.cancel();
        };
    }, [debouncedGetLoanApplications]);

    // Function to fetch inquiries
    const getInquiries = async () => {
        try {
            setLoading(true);
            let queryObj = {
                PageIndex: pageIndex,
                PageSize: pageSize,
                RoleName: "Borrower",
                SessionId: localStorage.getItem('sessionId')
            };

            if (searchText) {
                queryObj["SearchText"] = searchText;
            }

            let response = await getAdminAdviserList(queryObj);
            if (response.status === 200 && response.resultObject) {
                setInquiries(response.resultObject);
                let total = response.message.split(" ");
                setTotalData(Number(total[1]));
            } else {
                dispatch(reducersAndActions.actions.toasterActions.showToaster({
                    visible: true,
                    message: response.message,
                    type: "error"
                }));
                setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
                setTotalData(0);
                setInquiries([]);
            }
            setLoading(false);
        } catch (err) {
            console.log(err);
            setLoading(false);
            setTotalData(0);
        }
    }

    // Function to render dynamic table rows
    const dynamicTableDisplay = (data) => {
        return (
            <tr className={data.statusDesc === "Active" ? "active" : "inactive"}>
                <td>
                    <Link
                        to={`/users/borrowers-detail`}
                        state={{ data }}
                        className="user_name"
                    >
                        {data.firstName} {data.lastName}
                    </Link>
                    <br />
                    <span className="user_email text-light">{data.email}</span>
                </td>
                <td>{data.companyName}</td>
                <td><div className="tel_number"><img src={phone_icon_grid} alt="" /> {data.mobile}</div></td>
                <td>{data.roleName}</td>
                <td><div className="status">{data.statusDesc}</div></td>
                <td className="text-light">
                    <span>
                        {(data?.lastLoginDateTime || data?.loginedUserCity || data?.loginedUserState || data?.loginedUserCountry)
                            ? (
                                <>
                                    {data?.lastLoginDateTime ? convertToIST(data.lastLoginDateTime) : null}
                                    {data?.lastLoginDateTime && (data?.loginedUserCity || data?.loginedUserState || data?.loginedUserCountry) ? <br /> : null}
                                    {[
                                        data?.loginedUserCity,
                                        data?.loginedUserState,
                                        data?.loginedUserCountry
                                    ]
                                        .filter(Boolean)
                                        .join(', ')}
                                </>
                            )
                            : 'NA'}
                    </span>
                </td>
            </tr>
        )
    };

    const handleChangeInput = (e) => {
        setPageIndex(1);
        setSearchText(e.target.value);
    }

    return (
        <>
            {/* SidePanel */}
            <SidePanel />

            {/* ProfileHeader */}
            <ProfileHeader />

            {loading ? (
                <Preloader />
            ) : (
                <div>
                    <div className="dashboard-wrap users-screen">
                        <div className="d-flex justify-content-between filter-search-group">
                            <h3 className="user-page-heading">Borrowers</h3>
                            <div className="d-flex justify-content-between search-button-group">
                                <div className="lm-search-bar">
                                    <input
                                        type="text"
                                        id="searchInput"
                                        autoComplete="off"
                                        placeholder="Search by number, name, or other"
                                        value={searchText}
                                        onChange={handleChangeInput}
                                    />
                                </div>
                            </div>
                        </div>
                        {
                            inquiries.length > 0 ?
                                <DynamicTable
                                    data={inquiries}
                                    dynamicTableDisplay={dynamicTableDisplay}
                                    columns={BorrowersHeader}
                                    changePageSize={setPageSize}
                                    pageSize={pageSize}
                                    total={totalData}
                                    setList={setInquiries}
                                    setPageIndex={setPageIndex}
                                    pageIndex={pageIndex}
                                />
                                : (
                                    <div className='no-data-card text-center place-content-center'>
                                        <img src={no_data_icon} alt="" className='mb-4 ml-4' />
                                        <h3 className='f-700 mb-2'>No data found</h3>
                                        <p className='text-light'>Check back soon! Your details will be displayed<br />here shortly.</p>
                                    </div>
                                )
                        }

                    </div>
                </div>
            )}
        </>
    );
}

export default UserBorrowers;
